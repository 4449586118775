import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Lead = App.Models.Lead

export interface LeadFormState {
  is_mobile_view: boolean
  current_step: number
  total_step: number
  lead_id: string | null
  csrfToken: string
  lead_form_uuid: string
  lead_form_agency_id: string
  lead: Lead | null
  show_personal_detail: boolean
  show_preference_detail: boolean
  show_education_detail: boolean
  show_language_aptitude_test_detail: boolean
  show_work_experience_detail: boolean
  show_visa_passport_detail: boolean
  is_visitor: boolean
  visitor_check: boolean
  visitor_phone: string | null
  visitor_country: string | null
  visitor_country_code: string | null
  visitor_country_calling_code: string | null,
  is_profile_locked: boolean,
}

const initialState: LeadFormState = {
  is_mobile_view: false,
  current_step: 0,
  total_step: 0,
  csrfToken: '',
  lead_id: null,
  lead_form_uuid: '',
  lead_form_agency_id: '',
  lead: null,
  show_personal_detail: false,
  show_preference_detail: false,
  show_education_detail: false,
  show_language_aptitude_test_detail: false,
  show_work_experience_detail: false,
  show_visa_passport_detail: false,
  is_visitor: false,
  visitor_check: false,
  visitor_phone: null,
  visitor_country: null,
  visitor_country_code: null,
  visitor_country_calling_code: null,
  is_profile_locked: false,
}
export const leadFormSlice = createSlice({
  name: 'leadFormSlice',
  initialState,
  reducers: {
    updateIsMobileView(state, action: PayloadAction<boolean>) {
      state.is_mobile_view = action.payload
    },
    updateCurrentStep(state, action: PayloadAction<number>) {
      state.current_step = action.payload || 0
    },
    updateTotalStep(state, action: PayloadAction<number>) {
      state.total_step = action.payload || 0
    },
    updateCsrfToken(state, action: PayloadAction<string>) {
      state.csrfToken = action.payload
    },
    updateLeadFormUuid(state, action: PayloadAction<string>) {
      state.lead_form_uuid = action.payload
    },
    updateLeadFormAgencyId(state, action: PayloadAction<string>) {
      state.lead_form_agency_id = action.payload
    },
    updateLeadId(state, action: PayloadAction<string | null>) {
      state.lead_id = action.payload
    },
    updateLead(state, action: PayloadAction<Lead | null>) {
      state.lead = action.payload
    },
    updateShowPersonalDetail(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_personal_detail = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowPreferenceDetail(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_preference_detail = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowEducationDetail(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_education_detail = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowLanguageAptitudeTestDetail(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_language_aptitude_test_detail = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowWorkExperienceDetail(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_work_experience_detail = action.payload.show
      state.lead = action.payload.lead
    },
    updateShowVisaPassportDetail(state, action: PayloadAction<{ show: boolean; lead: Lead | null }>) {
      state.show_visa_passport_detail = action.payload.show
      state.lead = action.payload.lead
    },
    updateVisitorCheck(state, action: PayloadAction<{ visitor_check: boolean; is_visitor: boolean }>) {
      state.visitor_check = action.payload.visitor_check
      state.is_visitor = action.payload.is_visitor
    },
    updateVisitorPhone(
      state,
      action: PayloadAction<{
        visitor_phone: string | null
        visitor_country: string | null
        visitor_country_code: string | null
        visitor_country_calling_code: string | null
      }>
    ) {
      state.visitor_phone = action.payload.visitor_phone
      state.visitor_country = action.payload.visitor_country
      state.visitor_country_code = action.payload.visitor_country_code
      state.visitor_country_calling_code = action.payload.visitor_country_calling_code
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateIsMobileView,
  updateCurrentStep,
  updateLeadId,
  updateLead,
  updateShowPersonalDetail,
  updateShowPreferenceDetail,
  updateShowEducationDetail,
  updateShowLanguageAptitudeTestDetail,
  updateShowWorkExperienceDetail,
  updateShowVisaPassportDetail,
  updateCsrfToken,
  updateTotalStep,
  updateLeadFormUuid,
  updateLeadFormAgencyId,
  updateVisitorCheck,
  updateVisitorPhone,
} = leadFormSlice.actions

export default leadFormSlice.reducer
