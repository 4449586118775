import React, { FC, HTMLAttributes, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import Shadow from '@/XelaReact/Shadow/Shadow'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface DialogProps extends HTMLAttributes<HTMLDivElement> {
  isShown: boolean
  hide: () => void
  children?: ReactNode
  bg?: string
  backdrop?: boolean
  borderRadius?: string
  width?: string
  height?: string
  padding?: string
}

const DialogComponent = styled.div``

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`

const StyledModal = styled.div<{
  bg: string
  borderRadius: string
  width: string
  height: string
  padding: string
}>`
  z-index: 100;
  background: ${(props) => props.bg};
  position: relative;
  margin: auto;
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  width: ${(props) =>
    props.width !== 'auto' && props.width !== 'none'
      ? 'calc(' + props.width + ' - ' + props.padding + ')'
      : props.width};
  height: ${(props) => props.height};
`

export const Dialog: FC<DialogProps> = ({
  children,
  bg = XelaColor.White,
  isShown,
  hide,
  backdrop = true,
  borderRadius = '24px',
  width = 'auto',
  padding = '32px',
  height = 'auto',
}) => {
  const modal = (
    <DialogComponent>
      {backdrop && <Backdrop onClick={hide} />}
      <Wrapper>
        <Shadow>
          <StyledModal width={width} height={height} bg={bg} borderRadius={borderRadius} padding={padding}>
            {children}
          </StyledModal>
        </Shadow>
      </Wrapper>
    </DialogComponent>
  )

  return isShown ? createPortal(modal, document.body) : null
}

export default Dialog
