import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const InvoiceCreatedNotification: FC<{
  notification: Notification
}> = ({ notification }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        router.visit(route('hub.commission.invoices'))
      }}
    >
      <Typography variant="body-small-bold" color={XelaColor.Blue3}>
        {notification.data.event_by}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        created a new University Commission Invoice {notification.data.invoice_number}
      </Typography>
    </HStack>
  )
}

export default InvoiceCreatedNotification
