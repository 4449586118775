import * as React from 'react'

function IconFileInvoice(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2.5A1.5 1.5 0 012.5 1h8.207L14 4.293V14.5a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 011 14.5v-12zm3 2.497h7v1H4v-1zm7 2.998H4v1h7v-1zM11 11H4v1h7v-1z"
        fill="#E6ECFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9281 0.779029L10.8365 0.6875H10.7071H2.5C1.49898 0.6875 0.6875 1.49898 0.6875 2.5V14.5C0.6875 15.501 1.49898 16.3125 2.5 16.3125H12.5C13.501 16.3125 14.3125 15.501 14.3125 14.5V4.29289V4.16345L14.221 4.07192L10.9281 0.779029ZM10 10.3125H10.3125V10V8.99542V8.68292H10H8H7.6875V8.99542V10V10.3125H8H10ZM10 8.30792H10.3125V7.99542V7V6.6875H10H8H7.6875V7V7.99542V8.30792H8H10ZM5 6.6875H4.6875V7V7.99542V8.30792H5H7H7.3125V7.99542V7V6.6875H7H5ZM5 8.68292H4.6875V8.99542V10V10.3125H5H7H7.3125V10V8.99542V8.68292H7H5ZM6.6875 4.6875H4.3125V4.3125H6.6875V4.6875ZM4.3125 6.3125H10.6875V10.6875H4.3125V6.3125ZM10.6875 12.3125V12.6875H8.3125V12.3125H10.6875Z"
        fill="#E6ECFF"
        stroke={'#003CFF'}
        strokeWidth={'0.66'}
      />
    </svg>
  )
}

export default IconFileInvoice
