import * as React from 'react'

function IconVisa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 10.5V10a2.5 2.5 0 015 0v.5h6a1 1 0 001-1v-8a1 1 0 00-1-1h-12a1 1 0 00-1 1v8a1 1 0 001 1h1zm1-6.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
        fill="#E6ECFF"
      />
      <path
        d="M13.5 10.5h-12m12 0a1 1 0 001-1v-8a1 1 0 00-1-1h-12a1 1 0 00-1 1v8a1 1 0 001 1m12 0h-6V10a2.5 2.5 0 10-5 0v.5h-1m7.5-7h3m-3 3h3m-7-4a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        stroke="#003CFF"
      />
    </svg>
  )
}

export default IconVisa
