import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import InsuranceOrder = App.Models.InsuranceOrder
import InsuranceCompany = App.Models.InsuranceCompany
import Agency = App.Models.Agency

export interface OrderState {
  selected_entity: string
  total_orders: number
  is_filter_applied: boolean
  is_order_edited: boolean
  is_selected_all: boolean
  is_need_to_refresh: boolean
  selected_order: InsuranceOrder | null
  selected_company: InsuranceCompany | null
  selected_agency: Agency | null
  order_filters: {
    search: string
    status_type: string
    status_value: string
    entity_name: string
  }
  selected_orders: InsuranceOrder[]
  show_update_policy_document_modal: boolean
}

const initialState: OrderState = {
  selected_entity: 'Agency',
  total_orders: 0,
  is_filter_applied: false,
  is_order_edited: false,
  is_selected_all: false,
  is_need_to_refresh: false,
  selected_order: null,
  selected_company: null,
  selected_agency: null,
  order_filters: {
    search: '',
    status_type: 'All',
    status_value: 'All',
    entity_name: 'Agency',
  },
  selected_orders: [],
  show_update_policy_document_modal: false,
}

export const adminInsuranceOrderSlice = createSlice({
  name: 'insuranceOrderSlice',
  initialState,
  reducers: {
    updateSelectedEntity(state, action: PayloadAction<string>) {
      state.selected_entity = action.payload
    },
    updateTotalAdminInsuranceOrders(state, action: PayloadAction<number>) {
      state.total_orders = action.payload
    },
    updateEditAdminInsuranceOrder(
      state,
      action: PayloadAction<{
        is_order_edited: boolean
        selected_order: InsuranceOrder | null
      }>
    ) {
      state.is_order_edited = action.payload.is_order_edited
      state.selected_order = action.payload.selected_order
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
    updateSelectedAllInsuranceOrders(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateSelectedCompany(state, action: PayloadAction<InsuranceCompany | null>) {
      state.selected_company = action.payload
    },
    updateSelectedAgency(state, action: PayloadAction<Agency | null>) {
      state.selected_agency = action.payload
    },
    updateAdminInsuranceOrderFilters(
      state,
      action: PayloadAction<{
        search: string
        status_type: string
        status_value: string
        entity_name: string
      }>
    ) {
      state.order_filters = action.payload
    },
    clearAdminInsuranceOrderFilters(state) {
      state.order_filters = {
        search: '',
        status_type: 'All',
        status_value: 'All',
        entity_name: '',
      }
    },
    updateSelectedInsuranceOrders: (state, action) => {
      state.selected_orders = action.payload
    },
    updateShowUpdatePolicyDocumentModal: (
      state,
      action: PayloadAction<{
        show: boolean
        selected_order: InsuranceOrder | null
      }>
    ) => {
      state.show_update_policy_document_modal = action.payload.show
      state.selected_order = action.payload.selected_order
    },
  },
})

export const {
  updateSelectedEntity,
  updateEditAdminInsuranceOrder,
  updateTotalAdminInsuranceOrders,
  updateIsNeedToRefresh,
  updateSelectedAllInsuranceOrders,
  updateSelectedInsuranceOrders,
  updateAdminInsuranceOrderFilters,
  clearAdminInsuranceOrderFilters,
  updateSelectedCompany,
  updateSelectedAgency,
  updateShowUpdatePolicyDocumentModal,
} = adminInsuranceOrderSlice.actions

export default adminInsuranceOrderSlice.reducer
