import React, { FC, useEffect, useRef, useState } from 'react'
import { HStack, VStack, XelaColor } from '@/XelaReact'
import { Box, Text } from '@mantine/core'
import axios from 'axios'
import { AttachmentType } from '@/Types/TemplateTypes'
import { IconCircleX } from '@tabler/icons-react'
import { openModal } from '@mantine/modals'
import ConfirmModal from '@/Components/ConfirmModal'

export type RedactorXType = {
  editor: {
    setContent: (content: { html: string }) => void
    setFocus: (at: string) => void
  }
  destroy: () => void
}
export interface AppEvent {
  name: string
  params: { [key: string]: unknown }
  stopped: boolean

  is(name: string | string[]): boolean
  has(name: string): boolean
  getAll(): { [key: string]: unknown }
  get(name: string): unknown
  set(name: string, value: unknown): void
  stop(): void
  isStopped(): boolean
}

let redactorX: RedactorXType | null = null
const ReactRedactorX: FC<{
  entity: string
  onChange?: (value: string) => void
  onFileUpload?: (attachments: AttachmentType[]) => void
  defaultValue?: string
  defaultAttachments?: AttachmentType[]
  error?: string
  height?: string
  plugins?: string[]
  variables?: string[]
}> = ({
  entity,
  onChange,
  onFileUpload,
  defaultValue = '<p></p>',
  defaultAttachments,
  error,
  plugins = [],
  height = '200px',
  variables = [
    'Given Name',
    'Surname',
    'Name',
    'Phone',
    'Email',
    'Counsellor Name',
    'Previous Status',
    'Current Status',
    'Admission Manager Name',
    'Visa Manager Name',
    'Course Name',
    'University Name',
    'Intake',
  ],
}) => {
  const [attachments, setAttachments] = useState<AttachmentType[]>([])
  const [uploadProgress, setUploadProgress] = useState<number>(0)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const uploadFileToSpaces = async (file: File) => {
    try {
      axios
        .get<{ status: string; url: string; path: string }>(
          route('global.file-upload-url', {
            filename: file.name,
            entity: entity,
          })
        )
        .then((response) => {
          if (response.data.status === 'success') {
            const filePath = response.data.path

            const xhr = new XMLHttpRequest()
            xhr.open('PUT', response.data.url)
            xhr.setRequestHeader('Content-Type', file.type)

            xhr.upload.onprogress = (event) => {
              if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100
                setUploadProgress(percentComplete)
              }
            }

            xhr.onload = () => {
              if (xhr.status === 200) {
                setAttachments((prevFiles) => [
                  ...prevFiles,
                  {
                    filename: file.name,
                    path: filePath,
                    mime_type: file.type,
                    size: file.size,
                  },
                ])
                setUploadProgress(0)
              } else {
                console.error('Error uploading file:', xhr.statusText)
              }
            }

            xhr.send(file)
          }
        })
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  useEffect(() => {
    redactorX = window.RedactorX('#redactorx-editor', {
      image: {
        upload: function (
          upload: { complete: (response: { file: { url: string; id: string } }, e: Event) => void },
          data: { files: FileList; e: Event }
        ) {
          const formData = new FormData()
          formData.append('file', data.files[0])
          axios
            .post(route('global.file-upload'), formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                upload.complete(response.data, data.e)
              }
            })
        },
        url: false,
        multiple: false,
      },
      editor: {
        minHeight: height,
        maxHeight: height,
      },
      plugins: plugins,
      variable: {
        items: variables,
      },
      buttons: {
        topbar: ['shortcut', 'undo', 'redo'],
      },
      toolbar: {
        hide: ['html'],
      },
      subscribe: {
        'editor.change': (event: AppEvent) => {
          const content = event.get('html') as string
          if (onChange) onChange(content)
        },
        'editor.click': function (_: AppEvent) {
          // @ts-ignore
          if (this.app.editor.getContent() === '') {
            // @ts-ignore
            this.app.editor.setBlur()
            // @ts-ignore
            this.app.editor.setFocus('start')
          }
        },
      },
    })

    redactorX.editor.setContent({ html: defaultValue })

    return () => {
      if (redactorX) {
        redactorX.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (onFileUpload) onFileUpload(attachments.filter((attachment) => attachment.size <= 26214400))
  }, [attachments])

  useEffect(() => {
    if (defaultAttachments) {
      setAttachments(defaultAttachments)
    }
  }, [defaultAttachments])

  useEffect(() => {
    if (redactorX && defaultValue) {
      redactorX.editor.setContent({ html: defaultValue })
    }

    if (onChange) {
      onChange(defaultValue)
    }
  }, [defaultValue])

  return (
    <VStack spacing="4px">
      <textarea id="redactorx-editor"></textarea>
      {error && (
        <Text color="red" size={'xs'}>
          {error}
        </Text>
      )}
      {onFileUpload && (
        <VStack>
          <HStack>
            <Box
              style={{
                padding: '4px 8px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current?.click()
                }
              }}
            >
              <Text size={'sm'} color={XelaColor.Blue3} weight={600}>
                Attach Files {uploadProgress > 0 && `(${uploadProgress.toFixed(2)}%)`}
              </Text>
            </Box>
          </HStack>
          <input
            multiple
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                  uploadFileToSpaces(event.target.files[i])
                }
              }
            }}
          />
          <HStack spacing="8px">
            {attachments.slice(0, 2).map((attachment, index) => {
              return (
                <VStack
                  key={index}
                  spacing="4px"
                  style={{
                    width: 'auto',
                  }}
                >
                  <HStack
                    spacing="8px"
                    style={{
                      padding: '4px 12px',
                      cursor: 'pointer',
                      border: '1px solid #E4E4E7',
                      borderRadius: '16px',
                    }}
                  >
                    <Text size={'sm'} color={XelaColor.Gray6} weight={600} className="w-50 truncate">
                      {attachment.filename.length > 30
                        ? attachment.filename.substring(0, 30) + '...'
                        : attachment.filename}
                    </Text>
                    <Box
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        openModal({
                          centered: true,
                          overlayProps: {
                            color: '#000000',
                            opacity: 0.55,
                            blur: 3,
                          },
                          closeOnEscape: false,
                          closeOnClickOutside: false,
                          radius: 16,
                          size: 600,
                          withCloseButton: false,
                          children: (
                            <ConfirmModal
                              title={'Delete'}
                              message={'Are you sure you want to delete this item?'}
                              onConfirm={() => {
                                setAttachments((prevAttachments) => {
                                  return prevAttachments.filter(
                                    (prevAttachment) => prevAttachment.filename !== attachment.filename
                                  )
                                })
                              }}
                            ></ConfirmModal>
                          ),
                        })
                      }}
                    >
                      <IconCircleX color={XelaColor.Red3} size={16}></IconCircleX>
                    </Box>
                  </HStack>
                  {attachment.size > 100000000 && (
                    <HStack style={{ padding: '0 12px' }}>
                      <Text size={'xs'} color={XelaColor.Red3}>
                        File size exceeds 100MB
                      </Text>
                    </HStack>
                  )}
                </VStack>
              )
            })}
            {attachments.length > 2 && (
              <VStack
                width="initial"
                style={{
                  padding: '4px 12px',
                  cursor: 'pointer',
                  border: '1px solid #E4E4E7',
                  borderRadius: '16px',
                }}
              >
                <Text size={'sm'} color={XelaColor.Gray6} weight={600}>
                  +{attachments.length - 2} more files
                </Text>
              </VStack>
            )}
          </HStack>
        </VStack>
      )}
    </VStack>
  )
}

export default ReactRedactorX
