import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Drawer, LoadingOverlay } from '@mantine/core'
import { useForm } from '@inertiajs/react'
import { XTextarea } from '@/Mantine/XTextarea'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateIsEditFollowUpModalOpen, updateSelectedFollowUp } from '@/Store/followUpSlice'

const EditFollowUpModal: FC = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const followUpStore = useSelector((state: RootState) => state.followUp)

  const { data, setData, post, reset, errors, clearErrors, recentlySuccessful } = useForm<{
    follow_up: string | null
  }>({
    follow_up: null,
  })

  useEffect(() => {
    if (followUpStore.selected_follow_up) {
      setData((prev) => {
        return {
          ...prev,
          follow_up: followUpStore.selected_follow_up?.follow_up ?? null,
        }
      })
    }
  }, [followUpStore.is_edit_follow_up_modal_open])

  const closeHandler = () => {
    dispatch(updateSelectedFollowUp(null))
    dispatch(updateIsEditFollowUpModalOpen(false))
    clearErrors()
    reset()
  }

  useEffect(() => {
    if (recentlySuccessful) {
      closeHandler()
    }
  }, [recentlySuccessful])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      position={'right'}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      size={650}
      opened={followUpStore.is_edit_follow_up_modal_open}
      onClose={() => {
        closeHandler()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height={'100%'}>
        <HStack>
          <Typography variant="subheadline">Edit Follow Up</Typography>
        </HStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        <VStack
          spacing="12px"
          style={{
            height: '100%',
            position: 'relative',
          }}
        >
          {' '}
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack
            className={'pr-2 overflow-y-auto'}
            spacing={'12px'}
            style={{
              flex: '1 1 0',
              height: 0,
            }}
          >
            {' '}
            <HStack>
              <Typography variant="body-small-bold" color={XelaColor.Gray4}>
                Follow Up
              </Typography>
            </HStack>
            <HStack spacing="12px">
              <XTextarea
                error={errors.follow_up}
                placeholder="Add a follow up here..."
                minRows={8}
                value={data.follow_up !== null ? data.follow_up : ''}
                onChange={(e) => {
                  setData('follow_up', e.currentTarget.value)
                }}
              ></XTextarea>
            </HStack>
          </VStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack spacing="12px" justifyContent="flex-end">
            <Button
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                closeHandler()
              }}
            />
            <Button
              label={'Edit'}
              onClick={() => {
                if (followUpStore.selected_follow_up) {
                  setLoading(true)
                  post(route('follow-ups.edit', { id: followUpStore.selected_follow_up.id }), {
                    preserveState: true,
                    preserveScroll: true,
                    onFinish: () => {
                      setLoading(false)
                    },
                  })
                }
              }}
            ></Button>
          </HStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default EditFollowUpModal
