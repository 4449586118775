import React, { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import './HStack.css';

export interface HStackProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  spacing?: string;
  width?: string;
  height?: string;
  bg?: string;
  borderRadius?: string;
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}

const HStackComponent = styled.div<HStackProps>`
  gap: ${(props) => props.spacing};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
  border-radius: ${(props) => props.borderRadius};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

export const HStack: FC<HStackProps> = ({
  children,
  className,
  spacing = '0px',
  width = '100%',
  height = 'initial',
  bg = 'none',
  borderRadius = '0px',
  justifyContent = 'flex-start',
  alignItems = 'center',
  ...props
}) => {
  return (
    <HStackComponent
      spacing={spacing}
      width={width}
      height={height}
      bg={bg}
      borderRadius={borderRadius}
      justifyContent={justifyContent}
      className={`xela-hstack${className !== undefined ? ' ' + className : ''}`}
      alignItems={alignItems}
      {...props}
    >
      {children}
    </HStackComponent>
  );
};

export default HStack;
