import COUNTRY_FORMATS from '@/Constants/CountryFormats'

const formattedCountries = Object.keys(COUNTRY_FORMATS).map((countryCode) => {
  return { value: countryCode, label: COUNTRY_FORMATS[countryCode] }
})

const LEAD_FIELDS = [
  {
    group_name: 'personal_details',
    group_label: 'Personal Details',
    fields: [
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'lead_source',
        field_label: 'Lead Source',
        field_default: 'Walk In',
        field_options: [
          'Walk In',
          'QR',
          'Form',
          'Social',
          'Import',
          'Sub-Agent',
          'Old Student',
          'Youtube',
          'Google',
          'Facebook',
          'Instagram',
          'Other',
        ],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'given_name',
        field_label: 'Given Name',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'surname',
        field_label: 'Surname',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'email',
        field_label: 'Email',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'phone',
        field_label: 'Phone',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'date',
        field_name: 'date_of_birth',
        field_label: 'Date of Birth',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'gender',
        field_label: 'Gender',
        field_default: 'Male',
        field_options: ['Male', 'Female', 'Gender Fluid/Non Binary'],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'martial_status',
        field_label: 'Martial Status',
        field_default: 'Not Married',
        field_options: ['Married', 'Not Married'],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'visa_type',
        field_label: 'Visa Type',
        field_default: 'Single',
        field_options: [
          'Single',
          'Spouse',
          'Spouse + 1 Kid',
          'Spouse + 2 Kids',
          'Spouse + 3 Kids',
          'Spouse + 4 Kids',
          'Spouse + 5 Kids',
        ],
      },
      // { type: 'divider' },
      // { type: 'show_more_button' },
      {
        type: 'field',
        field_show: false,
        field_type: 'text',
        field_name: 'father_name',
        field_label: 'Father Name',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: false,
        field_type: 'text',
        field_name: 'mother_name',
        field_label: 'Mother Name',
        field_default: '',
        field_options: [],
      },
      // {
      //   type: 'field',
      //   field_show: false,
      //   field_type: 'text',
      //   field_name: 'alternate_phone',
      //   field_label: 'Alternate Phone',
      //   field_default: '',
      //   field_options: [],
      // },
      // {
      //   type: 'field',
      //   field_show: false,
      //   field_type: 'text',
      //   field_name: 'pin_code',
      //   field_label: 'Pin Code',
      //   field_default: '',
      //   field_options: [],
      // },
      {
        type: 'field',
        field_show: false,
        field_type: 'text',
        field_name: 'city',
        field_label: 'City',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: false,
        field_type: 'text',
        field_name: 'state',
        field_label: 'State',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: false,
        field_type: 'select',
        field_name: 'country_code',
        field_label: 'Country',
        field_default: '',
        field_options: formattedCountries,
      },
      {
        type: 'field',
        field_show: false,
        field_type: 'text',
        field_name: 'address',
        field_label: 'Address',
        field_default: '',
        field_options: [],
      },
      // {
      //   type: 'field',
      //   field_show: false,
      //   field_type: 'text',
      //   field_name: 'application_email',
      //   field_label: 'Email for Admission',
      //   field_default: '',
      //   field_options: [],
      // },
      // {
      //   type: 'field',
      //   field_show: false,
      //   field_type: 'text',
      //   field_name: 'application_password',
      //   field_label: 'Email Password',
      //   field_default: '',
      //   field_options: [],
      // },
      // {
      //   type: 'field',
      //   field_show: false,
      //   field_type: 'text',
      //   field_name: 'vfs_password',
      //   field_label: 'VFS Password',
      //   field_default: '',
      //   field_options: [],
      // },
      // {
      //   type: 'field',
      //   field_show: false,
      //   field_type: 'text',
      //   field_name: 'visa_form_password',
      //   field_label: 'Visa Form Password',
      //   field_default: '',
      //   field_options: [],
      // },
    ],
  },
  {
    group_name: 'preferences',
    group_label: 'Preferences',
    fields: [
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'preferred_country_code',
        field_label: 'Preferred Country',
        field_default: '',
        field_options: formattedCountries,
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'preferred_course_type',
        field_label: 'Preferred Course Type',
        field_default: '',
        field_options: ['Master', 'Bachelor'],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'preferred_course_name',
        field_label: 'Preferred Course Name',
        field_default: '',
        field_options: [],
      },
    ],
  },
  {
    group_name: 'education_details',
    group_label: 'Education Details',
    fields: [
      {
        type: 'custom',
        field_show: true,
        field_type: 'custom',
        field_name: 'tenth_details',
        field_label: 'Tenth Details',
        field_default: '',
        field_options: [],
        field_inputs: ['tenth_year', 'tenth_board', 'tenth_percentage_or_cgpa'],
      },
      {
        type: 'custom',
        field_show: true,
        field_type: 'custom',
        field_name: 'twelfth_details',
        field_label: 'Twelfth Details',
        field_default: '',
        field_options: [],
        field_inputs: ['twelfth_year', 'twelfth_board', 'twelfth_percentage_or_cgpa'],
      },
    ],
  },
  {
    group_name: 'language_aptitude_tests',
    group_label: 'Language/Aptitude Tests',
    fields: [
      {
        type: 'custom',
        field_show: true,
        field_type: 'custom',
        field_name: 'language_test_details',
        field_label: 'Language Test Details',
        field_default: '',
        field_options: [],
      },
      /*{
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'language_test_given',
        field_label: 'Language Test Given',
        field_default: 'No',
        field_options: ['No', 'IELTS', 'PTE', 'TOEFL', 'GRE', 'GMAT', 'SAT'],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'number',
        field_name: 'language_test_score',
        field_label: 'Language Test Score',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'date',
        field_name: 'language_test_date',
        field_label: 'Language Test Date',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'aptitude_test_given',
        field_label: 'Aptitude Test Given',
        field_default: 'No',
        field_options: ['No', 'SAT', 'GRE', 'GMAT'],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'number',
        field_name: 'aptitude_test_score',
        field_label: 'Aptitude Test Score',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'date',
        field_name: 'aptitude_test_date',
        field_label: 'Aptitude Test Date',
        field_default: '',
        field_options: [],
      },*/
    ],
  },
  {
    group_name: 'visa_passport_details',
    group_label: 'Visa/Passport Details',
    fields: [
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'passport_number',
        field_label: 'Passport Number',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'date',
        field_name: 'passport_issue_date',
        field_label: 'Passport Issue Date',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'date',
        field_name: 'passport_expiry_date',
        field_label: 'Passport Expiry Date',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'passport_nationality',
        field_label: 'Passport Nationality',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'select',
        field_name: 'is_visa_rejected_in_past',
        field_label: 'Is Visa Rejected In Past',
        field_default: 'No',
        field_options: ['Yes', 'No'],
      },
      // {
      //   type: 'field',
      //   field_show: true,
      //   field_type: 'text',
      //   field_name: 'past_visa_rejected_country',
      //   field_label: 'Past Visa Rejected Country',
      //   field_default: '',
      //   field_options: formattedCountries,
      // },
      // {
      //   type: 'field',
      //   field_show: true,
      //   field_type: 'text',
      //   field_name: 'past_visa_rejected_year',
      //   field_label: 'Past Visa Rejected Year',
      //   field_default: '',
      //   field_options: yearRange,
      // },
    ],
  },
  {
    group_name: 'work_experiences',
    group_label: 'Work Experiences',
    fields: [
      {
        type: 'custom',
        field_show: true,
        field_type: 'custom',
        field_name: 'work_experience_details',
        field_label: 'Work Experience Details',
        field_default: '',
        field_options: [],
      },
      /*{
        type: 'field',
        field_show: true,
        field_type: 'date',
        field_name: 'recent_work_experience_start_date',
        field_label: 'Recent Work Experience Start Date',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'date',
        field_name: 'recent_work_experience_end_date',
        field_label: 'Recent Work Experience End Date',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'recent_work_experience_organisation_name',
        field_label: 'Recent Work Experience Organisation Name',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'text',
        field_name: 'recent_work_experience_job_title',
        field_label: 'Recent Work Experience Job Title',
        field_default: '',
        field_options: [],
      },
      {
        type: 'field',
        field_show: true,
        field_type: 'past_experiences',
        field_name: 'past_experiences',
        field_label: 'Past Experiences',
        field_default: [],
        field_options: [],
      },*/
    ],
  },
]

export default LEAD_FIELDS
