import { usePage } from '@inertiajs/react'

export interface FlashProps {
  timestamp: number
  action: string | null
  payload: string | number | Record<never, never> | null
  error: string | null
  message: string | null
  type: string | null
}

const useFlash = () => {
  const props = usePage().props

  return props.flash as FlashProps
}

export default useFlash
