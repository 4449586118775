import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import {
  BranchDataProps,
  CoachingBranchDataProps,
  CoachingLeadManagerDataProps,
  CoachingLeadStatusDataProps,
  LeadManagerDataProps,
  LeadStatusDataProps,
  MigrateCoachingLeadsCriteriaFilters,
  MigrateLeadsCriteriaFilters,
  MigrateVisitorLeadsCriteriaFilters,
  SelectedCoachingLeadManager,
  SelectedCoachingLeadStatus,
  SelectedLeadManager,
  SelectedLeadStatus,
  SelectedVisitorLeadManager,
  SelectedVisitorLeadStatus,
  VisitorBranchDataProps,
  VisitorLeadManagerDataProps,
  VisitorLeadStatusDataProps,
} from '@/Types/LeadMigrateTypes'
import Lead = App.Models.Lead

export interface MigrateLeadSliceState {
  show_migrate_leads_drawer: boolean
  show_view_study_leads_drawer: boolean
  show_view_visitor_leads_drawer: boolean
  show_view_coaching_leads_drawer: boolean
  lead_ids: string[]
  lead_type: string
  show_migrate_visitor_leads_drawer: boolean
  show_migrate_coaching_leads_drawer: boolean
  active_stepper: number
  is_filter_applied: boolean
  criteria_filters: MigrateLeadsCriteriaFilters | null
  visitor_criteria_filters: MigrateVisitorLeadsCriteriaFilters | null
  coaching_criteria_filters: MigrateCoachingLeadsCriteriaFilters | null
  selected_study_leads: Lead[]
  selected_lead_ids: string[]
  selected_lead_branches: number[]
  selected_lead_managers: SelectedLeadManager[]
  selected_visitor_lead_managers: SelectedVisitorLeadManager[]
  selected_coaching_lead_managers: SelectedCoachingLeadManager[]
  selected_lead_statuses: SelectedLeadStatus[]
  selected_visitor_lead_statuses: SelectedVisitorLeadStatus[]
  selected_coaching_lead_statuses: SelectedCoachingLeadStatus[]
  selected_branches_data: BranchDataProps[]
  selected_visitor_branches_data: VisitorBranchDataProps[]
  selected_coaching_branches_data: CoachingBranchDataProps[]
  selected_lead_managers_data: LeadManagerDataProps[]
  selected_visitor_lead_managers_data: VisitorLeadManagerDataProps[]
  selected_coaching_lead_managers_data: CoachingLeadManagerDataProps[]
  selected_lead_statuses_data: LeadStatusDataProps[]
  selected_visitor_lead_statuses_data: VisitorLeadStatusDataProps[]
  selected_coaching_lead_statuses_data: CoachingLeadStatusDataProps[]
  selected_leads_action: string | null
}

const initialState: MigrateLeadSliceState = {
  show_migrate_leads_drawer: false,
  show_view_study_leads_drawer: false,
  show_view_visitor_leads_drawer: false,
  show_view_coaching_leads_drawer: false,
  lead_ids: [],
  lead_type: '',
  show_migrate_visitor_leads_drawer: false,
  show_migrate_coaching_leads_drawer: false,
  active_stepper: 0,
  is_filter_applied: false,
  criteria_filters: {
    statuses: [],
    lead_source: '',
    sub_lead_source: '',
    lead_dates: [],
    resident_states: [],
    resident_cities: [],
    preferred_countries: [],
    branch_id: '',
    lead_managers: [],
  },
  visitor_criteria_filters: {
    statuses: [],
    lead_source: '',
    sub_lead_source: '',
    lead_dates: [],
    resident_states: [],
    resident_cities: [],
    travel_countries: [],
    branch_id: '',
    lead_managers: [],
  },
  coaching_criteria_filters: {
    statuses: [],
    lead_source: '',
    sub_lead_source: '',
    lead_dates: [],
    resident_states: [],
    resident_cities: [],
    branch_id: '',
    lead_managers: [],
  },
  selected_study_leads: [],
  selected_lead_ids: [],
  selected_lead_branches: [],
  selected_lead_managers: [],
  selected_visitor_lead_managers: [],
  selected_coaching_lead_managers: [],
  selected_lead_statuses: [],
  selected_visitor_lead_statuses: [],
  selected_coaching_lead_statuses: [],
  selected_branches_data: [],
  selected_visitor_branches_data: [],
  selected_coaching_branches_data: [],
  selected_lead_managers_data: [],
  selected_visitor_lead_managers_data: [],
  selected_coaching_lead_managers_data: [],
  selected_lead_statuses_data: [],
  selected_visitor_lead_statuses_data: [],
  selected_coaching_lead_statuses_data: [],
  selected_leads_action: null,
}

export const migrateLeadSlice = createSlice({
  name: 'migrateLeadSlice',
  initialState,
  reducers: {
    updateShowMigrateLeadsDrawer(state, action: PayloadAction<boolean>) {
      state.show_migrate_leads_drawer = action.payload
    },
    updateShowViewStudyLeadsDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        lead_ids: string[] | []
      }>
    ) {
      state.show_view_study_leads_drawer = action.payload.show
      state.lead_ids = action.payload.lead_ids
    },
    updateShowViewVisitorLeadsDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        lead_ids: string[] | []
      }>
    ) {
      state.show_view_visitor_leads_drawer = action.payload.show
      state.lead_ids = action.payload.lead_ids
    },
    updateShowViewCoachingLeadsDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        lead_ids: string[] | []
      }>
    ) {
      state.show_view_coaching_leads_drawer = action.payload.show
      state.lead_ids = action.payload.lead_ids
    },
    updateShowMigrateVisitorLeadsDrawer(state, action: PayloadAction<boolean>) {
      state.show_migrate_visitor_leads_drawer = action.payload
    },
    updateShowMigrateCoachingLeadsDrawer(state, action: PayloadAction<boolean>) {
      state.show_migrate_coaching_leads_drawer = action.payload
    },
    updateActiveStepper(state, action: PayloadAction<number>) {
      state.active_stepper = action.payload
    },
    updateCriteriaFilters(state, action: PayloadAction<MigrateLeadsCriteriaFilters | null>) {
      state.is_filter_applied = true
      state.criteria_filters = action.payload
    },
    updateVisitorCriteriaFilters(state, action: PayloadAction<MigrateVisitorLeadsCriteriaFilters | null>) {
      state.is_filter_applied = true
      state.visitor_criteria_filters = action.payload
    },
    updateCoachingCriteriaFilters(state, action: PayloadAction<MigrateCoachingLeadsCriteriaFilters | null>) {
      state.is_filter_applied = true
      state.coaching_criteria_filters = action.payload
    },
    updateSelectedStudyLeads(state, action: PayloadAction<Lead[]>) {
      state.selected_study_leads = action.payload
    },
    updateSelectedLeadIds(state, action: PayloadAction<string[]>) {
      state.selected_lead_ids = action.payload
    },
    updateSelectedLeadBranches(state, action: PayloadAction<number[]>) {
      state.selected_lead_branches = action.payload
    },
    updateSelectedLeadManagers(state, action: PayloadAction<SelectedLeadManager[]>) {
      state.selected_lead_managers = action.payload
    },
    updateSelectedVisitorLeadManagers(state, action: PayloadAction<SelectedVisitorLeadManager[]>) {
      state.selected_visitor_lead_managers = action.payload
    },
    updateSelectedCoachingLeadManagers(state, action: PayloadAction<SelectedCoachingLeadManager[]>) {
      state.selected_coaching_lead_managers = action.payload
    },
    updateSelectedLeadStatuses(state, action: PayloadAction<SelectedLeadStatus[]>) {
      state.selected_lead_statuses = action.payload
    },
    updateSelectedVisitorLeadStatuses(state, action: PayloadAction<SelectedVisitorLeadStatus[]>) {
      state.selected_visitor_lead_statuses = action.payload
    },
    updateSelectedCoachingLeadStatuses(state, action: PayloadAction<SelectedCoachingLeadStatus[]>) {
      state.selected_coaching_lead_statuses = action.payload
    },
    updateSelectedBranchData(state, action: PayloadAction<BranchDataProps[]>) {
      state.selected_branches_data = action.payload
    },
    updateSelectedVisitorBranchData(state, action: PayloadAction<VisitorBranchDataProps[]>) {
      state.selected_visitor_branches_data = action.payload
    },
    updateSelectedCoachingBranchData(state, action: PayloadAction<CoachingBranchDataProps[]>) {
      state.selected_coaching_branches_data = action.payload
    },
    updateSelectedLeadManagersData(state, action: PayloadAction<LeadManagerDataProps[]>) {
      state.selected_lead_managers_data = action.payload
    },
    updateSelectedVisitorLeadManagersData(state, action: PayloadAction<VisitorLeadManagerDataProps[]>) {
      state.selected_visitor_lead_managers_data = action.payload
    },
    updateSelectedCoachingLeadManagersData(state, action: PayloadAction<CoachingLeadManagerDataProps[]>) {
      state.selected_coaching_lead_managers_data = action.payload
    },
    updateSelectedLeadStatusesData(state, action: PayloadAction<LeadStatusDataProps[]>) {
      state.selected_lead_statuses_data = action.payload
    },
    updateSelectedVisitorLeadStatusesData(state, action: PayloadAction<VisitorLeadStatusDataProps[]>) {
      state.selected_visitor_lead_statuses_data = action.payload
    },
    updateSelectedCoachingLeadStatusesData(state, action: PayloadAction<CoachingLeadStatusDataProps[]>) {
      state.selected_coaching_lead_statuses_data = action.payload
    },
    updateSelectedLeadsAction(state, action: PayloadAction<string | null>) {
      state.selected_leads_action = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowMigrateLeadsDrawer,
  updateShowViewStudyLeadsDrawer,
  updateShowViewVisitorLeadsDrawer,
  updateShowViewCoachingLeadsDrawer,
  updateShowMigrateVisitorLeadsDrawer,
  updateShowMigrateCoachingLeadsDrawer,
  updateActiveStepper,
  updateSelectedStudyLeads,
  updateSelectedLeadIds,
  updateCriteriaFilters,
  updateVisitorCriteriaFilters,
  updateCoachingCriteriaFilters,
  updateSelectedLeadBranches,
  updateSelectedLeadManagers,
  updateSelectedVisitorLeadManagers,
  updateSelectedCoachingLeadManagers,
  updateSelectedLeadStatuses,
  updateSelectedVisitorLeadStatuses,
  updateSelectedCoachingLeadStatuses,
  updateSelectedBranchData,
  updateSelectedVisitorBranchData,
  updateSelectedCoachingBranchData,
  updateSelectedLeadManagersData,
  updateSelectedVisitorLeadManagersData,
  updateSelectedCoachingLeadManagersData,
  updateSelectedLeadStatusesData,
  updateSelectedVisitorLeadStatusesData,
  updateSelectedCoachingLeadStatusesData,
  updateSelectedLeadsAction,
} = migrateLeadSlice.actions

export default migrateLeadSlice.reducer
