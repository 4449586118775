import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const InvoiceAcceptedOrRejectedNotification: FC<{
  notification: Notification
}> = ({ notification }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        router.visit('/b2b/commission/invoices')
      }}
    >
      <Typography variant="body-small" color={XelaColor.Gray6}>
        Agent
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Pink3}>
        {notification.data.event_by}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        {notification.data.new_status}{' '}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        a University Commission Invoice
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {notification.data.invoice_number}
      </Typography>
    </HStack>
  )
}

export default InvoiceAcceptedOrRejectedNotification
