import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import { getTransition } from '@/XelaReact/helpers/getTransitions'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
  count: number
  defaultPage?: number
  firstButtonIcon?: ReactNode
  lastButtonIcon?: ReactNode
  prevIcon?: ReactNode
  nextIcon?: ReactNode
  prevText?: string
  nextText?: string
  boundaryCount?: number
  siblingCount?: number
  showFirstButton?: boolean
  showLastButton?: boolean
  defaultColor?: string
  activeColor?: string
  hoverColor?: string
  activeBg?: string
  onChangeHandle?: (page: number) => void
}

const PaginationComponent = styled(HStack)<{
  defaultColor: string
  activeColor: string
  currentPage: number
  count: number
  activeBg?: string
  hoverColor: string
}>`
  user-select: none;

  & .pagination-btn {
    fill: ${(props) => props.defaultColor};
    stroke: ${(props) => props.defaultColor};
    color: ${(props) => props.defaultColor};
  }

  & .pagination-btn > * {
    cursor: pointer;
  }

  & .pagination-btn > *:hover {
    fill: ${(props) => props.hoverColor};
    stroke: ${(props) => props.hoverColor};
    color: ${(props) => props.hoverColor};
  }

  & .pagination-page-btn {
    cursor: pointer;
    border-radius: 12px;
    ${getTransition(200, ['background'], 'linear')}
  }

  & .pagination-page-btn:hover {
    color: ${(props) => props.hoverColor};
  }

  & .pagination-page-btn.active {
    color: ${(props) => props.activeColor};
    ${(props) =>
      props.activeBg &&
      css`
        background: ${props.activeBg};
      `}
  }

  ${(props) =>
    props.currentPage == 1 &&
    css`
      & .pagination-first-page-btn,
      & .pagination-prev-page-btn {
        opacity: 0.4;
        cursor: initial;
      }

      & .pagination-first-page-btn:hover,
      & .pagination-prev-page-btn:hover {
        fill: ${props.defaultColor};
        stroke: ${props.defaultColor};
        color: ${props.defaultColor};
      }
    `}

  ${(props) =>
    props.currentPage == props.count &&
    css`
      & .pagination-last-page-btn,
      & .pagination-next-page-btn {
        opacity: 0.4;
        cursor: initial;
      }

      & .pagination-last-page-btn:hover,
      & .pagination-next-page-btn:hover {
        fill: ${props.defaultColor};
        stroke: ${props.defaultColor};
        color: ${props.defaultColor};
      }
    `}
`

export const Pagination: FC<PaginationProps> = ({
  count,
  defaultPage = 1,
  firstButtonIcon,
  lastButtonIcon,
  prevIcon,
  nextIcon,
  prevText = 'Prev',
  nextText = 'Next',
  boundaryCount = 1,
  siblingCount = 1,
  showFirstButton = false,
  showLastButton = false,
  defaultColor = XelaColor.Gray2,
  activeColor = XelaColor.Blue3,
  activeBg,
  hoverColor = XelaColor.Blue3,
  onChangeHandle,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(defaultPage)

  const pItems = []

  let pi = 0
  while (++pi <= count) pItems.push(pi)

  return (
    <PaginationComponent
      spacing="24px"
      defaultColor={defaultColor}
      activeColor={activeColor}
      currentPage={currentPage}
      activeBg={activeBg}
      hoverColor={hoverColor}
      count={count}
      {...props}
    >
      <HStack className="pagination-btn" width="auto" spacing="12px">
        {showFirstButton && (
          <Center
            onClick={() => {
              setCurrentPage(1)
              if (onChangeHandle) {
                onChangeHandle(1)
              }
            }}
            className="pagination-first-page-btn"
          >
            {firstButtonIcon ? (
              firstButtonIcon
            ) : (
              <svg
                width="15"
                height="15"
                fill="none"
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49997 1L1.49997 7.5L8.49997 14M14 1L6.99997 7.5L14 14"
                  fill="none"
                  strokeLinecap="square"
                />
              </svg>
            )}
          </Center>
        )}
        <HStack
          onClick={() => {
            const page = +currentPage - 1
            if (+currentPage == 1) return
            setCurrentPage(page)
            if (onChangeHandle) {
              onChangeHandle(page)
            }
          }}
          className="pagination-prev-page-btn"
          spacing="8px"
          width="auto"
        >
          {prevIcon ? (
            prevIcon
          ) : (
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 14.5L4 8L11 1.5"
                fill="none"
                strokeLinecap="square"
              />
            </svg>
          )}
          {prevText && (
            <Typography variant="button-medium">{prevText}</Typography>
          )}
        </HStack>
      </HStack>
      <HStack width="auto" spacing="8px">
        {pItems.map((_x, index) =>
          index < boundaryCount ||
          index > count - boundaryCount - 1 ||
          index == +currentPage - 1 ||
          (index >= +currentPage - siblingCount - 1 &&
            index <= +currentPage + siblingCount - 1) ? (
            <Center
              key={index}
              width="40px"
              className={
                index + 1 == +currentPage
                  ? 'pagination-page-btn active'
                  : 'pagination-page-btn'
              }
              onClick={() => {
                setCurrentPage(index + 1)
                if (onChangeHandle) {
                  onChangeHandle(index + 1)
                }
              }}
              height="40px"
            >
              <Typography
                variant={
                  index + 1 == +currentPage ? 'button-large' : 'button-medium'
                }
              >
                {index + 1}
              </Typography>
            </Center>
          ) : (
            (index == boundaryCount || index == count - boundaryCount - 1) && (
              <Center key={index} width="40px" height="40px">
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.5 8C1.5 8.55228 1.94772 9 2.5 9C3.05228 9 3.5 8.55228 3.5 8C3.5 7.44772 3.05228 7 2.5 7C1.94772 7 1.5 7.44772 1.5 8ZM6.5 8C6.5 8.55228 6.94772 9 7.5 9C8.05228 9 8.5 8.55228 8.5 8C8.5 7.44772 8.05228 7 7.5 7C6.94772 7 6.5 7.44772 6.5 8ZM11.5 8C11.5 8.55228 11.9477 9 12.5 9C13.0523 9 13.5 8.55228 13.5 8C13.5 7.44772 13.0523 7 12.5 7C11.9477 7 11.5 7.44772 11.5 8Z"
                    fill={defaultColor}
                  />
                </svg>
              </Center>
            )
          )
        )}
      </HStack>
      <HStack className="pagination-btn" width="auto" spacing="12px">
        <HStack
          onClick={() => {
            const page = +currentPage + 1
            if (+currentPage == count) return
            setCurrentPage(page)
            if (onChangeHandle) {
              onChangeHandle(page)
            }
          }}
          className="pagination-next-page-btn"
          spacing="8px"
          width="auto"
        >
          {nextText && (
            <Typography variant="button-medium">{nextText}</Typography>
          )}
          {nextIcon ? (
            nextIcon
          ) : (
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 14.5L11 8L4 1.5" fill="none" strokeLinecap="square" />
            </svg>
          )}
        </HStack>
        {showLastButton && (
          <Center
            onClick={() => {
              setCurrentPage(count)
              if (onChangeHandle) {
                onChangeHandle(count)
              }
            }}
            className="pagination-last-page-btn"
          >
            {lastButtonIcon ? (
              lastButtonIcon
            ) : (
              <svg
                width="15"
                height="15"
                fill="none"
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 14L14 7.5L7 1M1.5 14L8.5 7.5L1.5 1"
                  fill="none"
                  strokeLinecap="square"
                />
              </svg>
            )}
          </Center>
        )}
      </HStack>
    </PaginationComponent>
  )
}

export default Pagination
