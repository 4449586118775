import * as React from 'react'

function IconDocuments(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.5A1.5 1.5 0 014.5 0h6.151L14 2.232V11.5a1.5 1.5 0 01-1.5 1.5H12v.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 011 13.5v-10A1.5 1.5 0 012.5 2H3v-.5zM3 3h-.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V13H4.5A1.5 1.5 0 013 11.5V3z"
        fill="#E6ECFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.5V2h-.5A1.5 1.5 0 001 3.5v10A1.5 1.5 0 002.5 15h8a1.5 1.5 0 001.5-1.5V13h.5a1.5 1.5 0 001.5-1.5V2.232L10.651 0H4.5A1.5 1.5 0 003 1.5zm7.349-.5H4.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V2.768L10.349 1zm.151 13a.5.5 0 00.5-.5V13H4.5A1.5 1.5 0 013 11.5V3h-.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h8z"
        fill="#003CFF"
      />
    </svg>
  )
}

export default IconDocuments
