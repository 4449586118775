import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import moment from 'moment'
import Notification = App.Models.Notification

const B2BAnnouncementPublishedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification, portal = 'Hub' }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        if (portal === 'Hub') {
          // router.visit('/announcements?status=Read&announcement_id=' + notification.data.announcement_id)
          router.visit('/announcements')
        }
      }}
    >
      <Typography variant="body-small" color={XelaColor.Gray6}>
        New announcement
      </Typography>
      <Typography variant="body-small" color={XelaColor.Pink3}>
        {notification.data.announcement_title.length > 50
          ? notification.data.announcement_title.substring(0, 50) + '...'
          : notification.data.announcement_title}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        published at
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {moment(notification.data.announcement_published_at).format('D MMM YYYY hh:mm A')}
      </Typography>
    </HStack>
  )
}

export default B2BAnnouncementPublishedNotification
