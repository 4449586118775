import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, Drawer, LoadingOverlay, Text } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import axios, { AxiosResponse } from 'axios'
import { IconCircleCheck, IconDownload, IconFile } from '@tabler/icons-react'
import DOMPurify from 'isomorphic-dompurify'
import { RootState } from '@/Store'
import { updateShowViewAnnouncementDrawer } from '@/Store/announcementSlice'
import useAuth from '@/Hooks/useAuth'
import { router } from '@inertiajs/react'
import Announcement = App.Models.Announcement

interface BlobResponse extends AxiosResponse {
  data: Blob
}

const DashboardAnnouncementsDrawer: FC = () => {
  const auth = useAuth()
  const dispatch = useDispatch()

  const announcementStore = useSelector((state: RootState) => state.announcement)

  const [loading, setLoading] = useState(false)
  const [announcement, setAnnouncement] = useState<Announcement | null>(null)

  useEffect(() => {
    setLoading(true)
    if (announcementStore.selected_view_announcement) {
      setAnnouncement(announcementStore.selected_view_announcement)
    }
    setLoading(false)
  }, [announcementStore.show_view_announcement_drawer])
  const onClose = () => {
    setLoading(false)
    dispatch(
      updateShowViewAnnouncementDrawer({
        show: false,
        announcement: null,
      })
    )
  }

  return (
    <>
      <Drawer
        overlayProps={{
          color: '#000',
          opacity: 0.55,
          blur: 3,
        }}
        position={'right'}
        closeOnEscape={false}
        closeOnClickOutside={true}
        withCloseButton={false}
        size={750}
        opened={announcementStore.show_view_announcement_drawer}
        onClose={() => {
          onClose()
        }}
        styles={{
          body: {
            height: '100%',
          },
        }}
      >
        <LoadingOverlay visible={loading} />
        <VStack
          key={announcement && announcement.id}
          spacing="6px"
          justifyContent="flex-start"
          alignItems="flex-start"
          borderRadius="16px"
          bg={XelaColor.White}
          style={{
            padding: '16px',
            outline: `1.5px solid ${XelaColor.Gray11}`,
            height: '100%',
          }}
        >
          <VStack spacing="12px">
            <HStack spacing="6px" justifyContent="space-between">
              <HStack justifyContent={'space-between'} spacing="12px">
                <HStack>
                  <Typography
                    title={announcement ? announcement.title : ''}
                    variant="body-small-bold"
                    color={XelaColor.Gray3}
                    style={{ wordBreak: 'break-all' }}
                  >
                    {announcement && announcement.title.length > 50
                      ? announcement && announcement.title.substring(0, 50) + '...'
                      : announcement && announcement.title}
                  </Typography>
                </HStack>
              </HStack>

              {announcement &&
                !announcement.announcement_analytics.find((analytics) => analytics.user_id === auth.user.id) && (
                  <HStack
                    spacing="8px"
                    style={{
                      padding: '8px 12px',
                      borderRadius: '12px',
                      border: `1px solid ${XelaColor.Gray9}`,
                      cursor: 'pointer',
                      width: 'auto',
                    }}
                    onClick={() => {
                      setLoading(true)
                      axios
                        .post(route('announcements.read', { id: announcement && announcement.id }))
                        .then(() => {
                          dispatch(
                            updateShowViewAnnouncementDrawer({
                              show: false,
                              announcement: null,
                            })
                          )

                          router.reload()
                        })
                        .finally(() => {
                          setLoading(false)
                        })
                    }}
                  >
                    <IconCircleCheck size={16} color={XelaColor.Blue5} />
                    <Typography variant="caption-bold" color={XelaColor.Blue5} noWrap={true}>
                      Mark Read
                    </Typography>
                  </HStack>
                )}
            </HStack>
            <HStack alignItems="flex-end" justifyContent="space-between">
              <VStack>
                <HStack alignItems={'center'}>
                  <HStack justifyContent={'flex-start'}>
                    <Typography as={'p'}>
                      <Typography as={'span'} variant="sub-caption" color={XelaColor.Gray6}>
                        Created By
                      </Typography>
                      <Typography as={'span'} variant="sub-caption-bold" color={XelaColor.Blue3} className="px-1">
                        {announcement && announcement.created_by && announcement && announcement.created_by.name}
                        {announcement &&
                          announcement.created_by &&
                          announcement &&
                          announcement.created_by.id === auth.user.id &&
                          '(You)'}
                      </Typography>
                      <Typography as={'span'} variant="sub-caption" color={XelaColor.Gray6}>
                        at {announcement && announcement.updated_at_for_humans}
                      </Typography>
                    </Typography>
                  </HStack>
                </HStack>
              </VStack>
              <HStack justifyContent="flex-end">
                <Button
                  variant={'secondary'}
                  label={'Close'}
                  size={'small'}
                  onClick={() => {
                    onClose()
                  }}
                ></Button>
              </HStack>
            </HStack>
          </VStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack spacing="6px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto' }} alignItems="flex-start">
            {announcement &&
              announcement.content &&
              announcement &&
              announcement.content.length > 0 &&
              ((announcement && announcement.content.replace(' ', '') !== '<p></p>') ||
                (announcement && announcement.content.replace(' ', '') !== '<p><br/></p>')) && (
                <pre
                  style={{
                    width: '100%',
                    padding: '0',
                  }}
                  className="ql-editor !text-xs !text-gray-6 !whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(announcement && announcement.content) }}
                ></pre>
              )}
          </HStack>

          <HStack>
            {announcement && announcement.attachments && announcement && announcement.attachments.length > 0 && (
              <VStack>
                <HStack spacing="8px">
                  {announcement &&
                    announcement.attachments.slice(0, 2).map((attachment, index) => {
                      return (
                        <VStack
                          key={index}
                          spacing="4px"
                          style={{
                            width: 'auto',
                          }}
                        >
                          <HStack
                            spacing="8px"
                            style={{
                              padding: '4px 12px',
                              cursor: 'pointer',
                              border: '1px solid #E4E4E7',
                              borderRadius: '16px',
                            }}
                          >
                            <Box>
                              <IconFile color={XelaColor.Blue3} size={16}></IconFile>
                            </Box>
                            <Text size={'sm'} color={XelaColor.Gray6} weight={600} className="w-50 truncate">
                              {attachment.filename.length > 30
                                ? attachment.filename.substring(0, 30) + '...'
                                : attachment.filename}
                            </Text>
                          </HStack>
                          {attachment.size > 26214400 && (
                            <HStack style={{ padding: '0 12px' }}>
                              <Text size={'xs'} color={XelaColor.Red3}>
                                File size exceeds 25mb
                              </Text>
                            </HStack>
                          )}
                        </VStack>
                      )
                    })}
                  {announcement && announcement.attachments.length > 2 && (
                    <VStack
                      width="initial"
                      style={{
                        padding: '4px 12px',
                        cursor: 'pointer',
                        border: '1px solid #E4E4E7',
                        borderRadius: '16px',
                      }}
                    >
                      <Text size={'sm'} color={XelaColor.Gray6} weight={600}>
                        +{announcement && announcement.attachments.length - 2} more files
                      </Text>
                    </VStack>
                  )}
                  <Box
                    title="Download all files"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (!announcement) return
                      axios({
                        url: route('announcements.download-documents', { id: announcement && announcement.id }),
                        method: 'GET',
                        responseType: 'blob',
                      }).then((response: BlobResponse) => {
                        const contentDisposition = response.headers['content-disposition']
                        let filename = 'files.zip'

                        if (contentDisposition) {
                          const match = contentDisposition.match(/filename=(.+)/)
                          filename = match ? match[1] : 'files.zip'
                        }

                        const downloadLink = document.createElement('a')
                        downloadLink.href = URL.createObjectURL(response.data)
                        downloadLink.download = filename
                        downloadLink.click()
                      })
                    }}
                  >
                    <IconDownload size={16} color={XelaColor.Blue3}></IconDownload>
                  </Box>
                </HStack>
              </VStack>
            )}
          </HStack>
        </VStack>
      </Drawer>
    </>
  )
}

export default DashboardAnnouncementsDrawer
