import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type dialogState = boolean | string | null

let dashboardDialogState: dialogState = window.localStorage.getItem('swiftams:show_dashboard_dialog')

dashboardDialogState = !(dashboardDialogState && dashboardDialogState === 'false')

export interface DialogState {
  has_dialog: boolean
  dashboard_dialog: boolean
}

const initialState: DialogState = {
  has_dialog: dashboardDialogState,
  dashboard_dialog: dashboardDialogState,
}

export const dialogSlice = createSlice({
  name: 'dialogSlice',
  initialState,
  reducers: {
    updateHasDialog(state, action: PayloadAction<boolean>) {
      state.has_dialog = action.payload
    },
    updateDashboardDialog(state, action: PayloadAction<boolean>) {
      state.dashboard_dialog = action.payload
      state.has_dialog = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateHasDialog, updateDashboardDialog } = dialogSlice.actions

export default dialogSlice.reducer
