import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Visa = App.Models.Visa

export interface B2BCommissionLeadState {
  selected_visa: Visa | null
  selected_visa_id: number | null
  reload_all_leads: boolean
  current_leads_filters: {
    [key: string]: string
  }
  previous_leads_filters: {
    [key: string]: string
  }
  selected_visas: Visa[]
  selected_visa_ids: string[]
  show_leads_filter_drawer: boolean
  show_create_new_invoice_drawer: boolean
}

const initialState: B2BCommissionLeadState = {
  selected_visa: null,
  selected_visa_id: null,
  selected_visas: [],
  selected_visa_ids: [],
  show_leads_filter_drawer: false,
  reload_all_leads: false,
  show_create_new_invoice_drawer: false,
  current_leads_filters: {
    agent_id: '',
    b2b_relationship_manager_id: '',
    visa_manager_id: '',
    country: '',
    university: '',
    course: '',
    university_commission_status: '',
    b2b_commission_status: '',
  },
  previous_leads_filters: {
    agent_id: '',
    b2b_relationship_manager_id: '',
    visa_manager_id: '',
    country: '',
    university: '',
    course: '',
    university_commission_status: '',
    b2b_commission_status: '',
  },
}

export const b2bCommissionLeadSlice = createSlice({
  name: 'b2bCommissionLeadSlice',
  initialState,
  reducers: {
    updateSelectedVisa(state, action: PayloadAction<Visa | null>) {
      state.selected_visa = action.payload
    },
    updateShowLeadsFilterDrawer(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_leads_filter_drawer = true
      } else {
        state.selected_visa = null
        state.show_leads_filter_drawer = false
      }
    },

    updateSelectedVisaId(state, action: PayloadAction<number | null>) {
      state.selected_visa_id = action.payload
    },
    updateCurrentLeadFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.current_leads_filters = action.payload
    },
    updatePreviousLeadFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.previous_leads_filters = action.payload
    },
    reloadAllLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_leads = action.payload
    },
    updateSelectedVisaIds(state, action: PayloadAction<string[]>) {
      state.selected_visa_ids = action.payload
    },
    updateSelectedVisas(state, action: PayloadAction<Visa[]>) {
      state.selected_visas = action.payload
    },
    showCreateNewInvoiceDrawer(
      state,
      action: PayloadAction<{
        show: boolean
      }>
    ) {
      state.show_create_new_invoice_drawer = action.payload.show
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateSelectedVisa,
  updateShowLeadsFilterDrawer,
  updateSelectedVisaId,
  updateCurrentLeadFilters,
  updatePreviousLeadFilters,
  reloadAllLeads,
  showCreateNewInvoiceDrawer,
  updateSelectedVisaIds,
  updateSelectedVisas,
} = b2bCommissionLeadSlice.actions

export default b2bCommissionLeadSlice.reducer
