import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import IeltsLead = App.Models.IeltsLead
import CoachingStudent = App.Models.CoachingStudent
import CoachingBatch = App.Models.CoachingBatch
import CoachingBatchStudentDetail = App.Models.CoachingBatchStudentDetail
import User = App.Models.User
import Branch = App.Models.Branch

export interface CoachingLeadState {
  show_create_batch_drawer: boolean
  show_mark_attendance_drawer: boolean
  show_mark_performance_drawer: boolean
  show_add_to_coaching_batch_modal: boolean
  show_student_mark_attendance_modal: boolean
  show_student_mark_performance_modal: boolean
  ielts_lead: IeltsLead | null
  ielts_lead_id: string | null
  student_id: string | null
  coaching_batch_id: string | null
  coaching_student: CoachingStudent | null
  coaching_batch: CoachingBatch | null
  coaching_batch_student_detail: CoachingBatchStudentDetail | null
  show_student_filter_drawer: boolean
  users: User[] | []
  branches: Branch[] | []
  coachingBatches: CoachingBatch[] | []
  current_students_filters: {
    [key: string]: string
  }
  previous_students_filters: {
    [key: string]: string
  }
  reload_all_students: boolean
  is_batch_update: boolean
  branch_id: string | null
}

const initialState: CoachingLeadState = {
  show_create_batch_drawer: false,
  show_mark_attendance_drawer: false,
  show_mark_performance_drawer: false,
  show_add_to_coaching_batch_modal: false,
  show_student_mark_attendance_modal: false,
  show_student_mark_performance_modal: false,
  ielts_lead: null,
  ielts_lead_id: null,
  student_id: null,
  coaching_batch_id: null,
  coaching_student: null,
  coaching_batch: null,
  coaching_batch_student_detail: null,
  show_student_filter_drawer: false,
  users: [],
  branches: [],
  coachingBatches: [],
  current_students_filters: {
    branch_id: '',
    batch_id: '',
    batch_faculty_id: '',
  },
  previous_students_filters: {
    branch_id: '',
    batch_id: '',
    batch_faculty_id: '',
  },
  reload_all_students: false,
  is_batch_update: false,
  branch_id: null,
}

export const coachingLeadSlice = createSlice({
  name: 'coachingLeadSlice',
  initialState,
  reducers: {
    updateShowCreateBatchDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        is_batch_update?: boolean
        coaching_batch: CoachingBatch | null
      }>
    ) {
      state.show_create_batch_drawer = action.payload.show
      state.is_batch_update = action.payload.is_batch_update ?? false
      state.coaching_batch = action.payload.coaching_batch
    },
    updateShowMarkAttendanceDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        coaching_batch_id: string | null
      }>
    ) {
      state.show_mark_attendance_drawer = action.payload.show
      state.coaching_batch_id = action.payload.coaching_batch_id
    },
    updateShowMarkPerformanceDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        coaching_batch_id: string | null
      }>
    ) {
      state.show_mark_performance_drawer = action.payload.show
      state.coaching_batch_id = action.payload.coaching_batch_id
    },
    updateShowAddToCoachingBatchModal(
      state,
      action: PayloadAction<{
        show: boolean
        ielts_lead: IeltsLead | null
        ielts_lead_id: string | null
      }>
    ) {
      state.show_add_to_coaching_batch_modal = action.payload.show
      state.ielts_lead = action.payload.ielts_lead
      state.ielts_lead_id = action.payload.ielts_lead_id
    },
    updateShowStudentMarkAttendanceModal(
      state,
      action: PayloadAction<{
        show: boolean
        coaching_student: CoachingStudent | null
        // coaching_batch: CoachingBatch | null
        // coaching_batch_student_detail: CoachingBatchStudentDetail | null
      }>
    ) {
      state.show_student_mark_attendance_modal = action.payload.show
      state.coaching_student = action.payload.coaching_student
      // state.coaching_batch = action.payload.coaching_batch
      // state.coaching_batch_student_detail = action.payload.coaching_batch_student_detail
    },
    updateShowStudentMarkPerformanceModal(
      state,
      action: PayloadAction<{
        show: boolean
        coaching_student: CoachingStudent | null
      }>
    ) {
      state.show_student_mark_performance_modal = action.payload.show
      state.coaching_student = action.payload.coaching_student
    },
    updateShowStudentFilterDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        users: User[] | []
        branches: Branch[] | []
        coachingBatches: CoachingBatch[] | []
      }>
    ) {
      state.show_student_filter_drawer = action.payload.show
      state.users = action.payload.users ?? []
      state.branches = action.payload.branches ?? []
      state.coachingBatches = action.payload.coachingBatches ?? []
    },
    updateCurrentStudentsFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.current_students_filters = action.payload
    },
    updatePreviousStudentsFilters(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.previous_students_filters = action.payload
    },
    reloadAllStudents(state, action: PayloadAction<boolean>) {
      state.reload_all_students = action.payload
    },
    updateBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowCreateBatchDrawer,
  updateShowMarkAttendanceDrawer,
  updateShowMarkPerformanceDrawer,
  updateShowAddToCoachingBatchModal,
  updateShowStudentMarkAttendanceModal,
  updateShowStudentMarkPerformanceModal,
  updateShowStudentFilterDrawer,
  updateCurrentStudentsFilters,
  updatePreviousStudentsFilters,
  reloadAllStudents,
  updateBranchId,
} = coachingLeadSlice.actions

export default coachingLeadSlice.reducer
