import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import HStack from '@/XelaReact/HStack/HStack'

export interface RatingProps extends HTMLAttributes<HTMLInputElement> {
  activeIcon: ReactNode
  deactiveIcon: ReactNode
  count?: number
  value?: number
  childWidth?: string
  childHeight?: string
  spacing?: string
  disabled?: boolean
  onChangeHandle?: (value: number) => void
}

const RatingContainer = styled(HStack)<{ disabled: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
  & > * {
    cursor: ${(props) => (props.disabled ? 'initials' : 'pointer')};
  }
`

export const Rating: FC<RatingProps> = ({
  activeIcon,
  deactiveIcon,
  count = 5,
  value = 0,
  childWidth = '15px',
  childHeight = '15px',
  spacing = '4px',
  onChangeHandle,
  disabled = false,
  ...props
}) => {
  const [val, setVal] = useState(value)

  let items = [],
    i = 0,
    len = count
  while (++i <= len) items.push(i)

  return (
    <RatingContainer disabled={disabled} width="auto" spacing={spacing}>
      {items.map((_x, i) => (
        <Center
          key={i}
          width={childWidth}
          height={childHeight}
          onClick={() => {
            if (!disabled) {
              setVal(i + 1)
              onChangeHandle && onChangeHandle(val)
            }
          }}
        >
          {i >= val ? deactiveIcon : activeIcon}
        </Center>
      ))}

      <input type="hidden" disabled={disabled} value={val} {...props} />
    </RatingContainer>
  )
}

export default Rating
