import React, { FC } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { closeAllModals } from '@mantine/modals'

const ConfirmModal: FC<{
  title: string
  message: React.ReactNode | string
  onConfirm: () => void
  onCancel?: () => void
  onConfirmLabel?: string
}> = (props) => {
  return (
    <VStack spacing="12px" style={{ minHeight: '350px' }}>
      <HStack>
        <Typography variant="subheadline" color={XelaColor.Gray3}>
          {props.title}
        </Typography>
      </HStack>
      <HStack>
        <Divider variant={'dotted'} color={XelaColor.Gray10}></Divider>
      </HStack>
      <HStack alignItems="flex-start" style={{ flexGrow: 1 }}>
        {props.message instanceof String && (
          <Typography variant="body-small" color={XelaColor.Gray6}>
            {props.message}
          </Typography>
        )}
        {!(props.message instanceof String) && props.message}
      </HStack>
      <HStack justifyContent={'flex-end'} style={{ paddingTop: '20px' }} spacing="12px">
        <Button
          label="Cancel"
          variant="secondary"
          onClick={() => {
            if (props.onCancel) props.onCancel()
            closeAllModals()
          }}
        />
        <Button
          label={props.onConfirmLabel || 'Delete'}
          variant="primary"
          onClick={() => {
            props.onConfirm()
            closeAllModals()
          }}
        />
      </HStack>
    </VStack>
  )
}

export default ConfirmModal
