import React, { FC } from 'react'
import { Center, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { useDispatch, useSelector } from 'react-redux'
import { IconUserPlus, IconUsersGroup } from '@tabler/icons-react'
import { motion } from 'framer-motion'
import useAuth from '@/Hooks/useAuth'
import { router, usePage } from '@inertiajs/react'
import { RootState } from '@/Store'
import { Box } from '@mantine/core'
import { toggleCoachingSubSidebar } from '@/Store/globalSlice'
import IconTextDocumentAlt from '@/Icons/IconTextDocumentAlt'
import IconReport from '@/Icons/IconReport'

const CoachingSubSidebar: FC = () => {
  const page = usePage()
  const auth = useAuth()
  const dispatch = useDispatch()
  const globalStore = useSelector((state: RootState) => state.global)

  const tabItems = []

  if (
    auth.role.permissions.includes('ielts_lead_module_manage_lead') ||
    auth.role.permissions.includes('ielts_lead_module_branch_leads') ||
    auth.role.permissions.includes('ielts_lead_module_all_leads')
  ) {
    tabItems.push({
      label: 'Leads',
      route: '/ielts-leads',
      icon: <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />,
    })

    if (
      auth.role.permissions.includes('coaching_module_manage_all_batches') ||
      auth.role.permissions.includes('coaching_module_manage_coaching_batch')
    ) {
      tabItems.push({
        label: 'Batches',
        route: '/coaching-leads/batches',
        icon: <IconUsersGroup width="20" height="20" color={XelaColor.Blue5} />,
      })
    }

    tabItems.push({
      label: 'Students',
      route: '/coaching-leads/students',
      icon: <IconUserPlus width="20" height="20" color={XelaColor.Blue5} />,
    })

    if (
      auth.role.permissions.includes('coaching_module_access_student_reports') ||
      auth.role.permissions.includes('coaching_module_access_faculty_reports')
    ) {
      tabItems.push({
        label: 'Reports',
        route: '/coaching-leads/reports',
        icon: <IconReport width="20" height="20" color={XelaColor.Blue5} />,
      })
    }
  }

  return (
    <motion.div
      className={`${
        globalStore.isCoachingSubSidebarOpen ? 'w-60 block' : 'hidden'
      } z-10 absolute bg-white min-h-screen top-0`}
      initial={{ opacity: 0.5, x: 0 }}
      animate={{ opacity: 1, x: globalStore.isSidebarOpen ? 225 : 65 }}
      transition={{ duration: 0.25 }}
    >
      <div>
        <VStack
          bg={XelaColor.White}
          spacing="12px"
          justifyContent="flex-start"
          height="100vh"
          className="shadow py-8 px-4 overflow-auto"
        >
          <HStack justifyContent={globalStore.isCoachingSubSidebarOpen ? 'space-between' : 'center'}>
            <VStack spacing="4px">
              <HStack justifyContent={globalStore.isCoachingSubSidebarOpen ? 'space-between' : 'center'}>
                {globalStore.isCoachingSubSidebarOpen && (
                  <VStack spacing="4px">
                    <Typography variant={'subheadline'} color={XelaColor.Gray3}>
                      Coaching Leads
                    </Typography>
                  </VStack>
                )}
              </HStack>
            </VStack>
          </HStack>
          <Divider variant="dotted" />
          <VStack spacing="8px">
            {tabItems.map((item, index) => (
              <Box
                key={index}
                onClick={() => {
                  dispatch(toggleCoachingSubSidebar(!globalStore.isCoachingSubSidebarOpen))
                  router.visit(`${item.route}`)
                }}
                style={
                  page.url === item.route
                    ? {
                        padding: '8px',
                        backgroundColor: XelaColor.Blue12,
                        borderRadius: '12px',
                        width: '100%',
                      }
                    : {
                        padding: '8px',
                      }
                }
                className="flex space-x-4 w-full px-2 py-3 cursor-pointer hover:bg-blue-12 rounded-lg"
              >
                <Center width="18px" height="18px" style={{ minWidth: '18px' }}>
                  {item.icon}
                </Center>
                <Box className="flex justify-between w-full">
                  <Typography variant="body-small-bold">{item.label}</Typography>
                </Box>
              </Box>
            ))}
          </VStack>
        </VStack>
      </div>
    </motion.div>
  )
}

export default CoachingSubSidebar
