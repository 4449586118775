import { useEffect, useState } from 'react'

export interface LocationProps {
  country_code: string
  country_calling_code: string
  country_name: string
  timezone: string
  currency: string
}

const useLocation = () => {
  const [location, setLocation] = useState<LocationProps>({
    country_code: '',
    country_calling_code: '',
    country_name: '',
    timezone: '',
    currency: '',
  })

  useEffect(() => {
    const locationStored = window.sessionStorage.getItem('swiftams:location')
    if (locationStored) {
      const data = JSON.parse(locationStored)
      setLocation((prev) => {
        return {
          ...prev,
          country_code: data['country'],
          country_calling_code: data['country_calling_code'],
          country_name: data['country_name'],
          timezone: data['timezone'],
          currency: data['currency'],
        }
      })
    } else {
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((data) => {
          window.sessionStorage.setItem('swiftams:location', JSON.stringify(data))
          setLocation((prev) => {
            return {
              ...prev,
              country_code: data['country'],
              country_calling_code: data['country_calling_code'],
              country_name: data['country_name'],
              timezone: data['timezone'],
              currency: data['currency'],
            }
          })
        })
    }
  }, [])

  return location
}

export default useLocation
