import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Lead = App.Models.Lead
import VisitorLead = App.Models.VisitorLead
import IeltsLead = App.Models.IeltsLead
import InsuranceLead = App.Models.InsuranceLead

export interface FollowUpState {
  is_modal_open: boolean
  is_follow_up_modal_open: boolean
  selected_follow_up_lead: Lead | null
  selected_follow_up_visitor_lead: VisitorLead | null
  selected_follow_up_ielts_lead: IeltsLead | null
  selected_follow_up_insurance_lead: InsuranceLead | null
  follow_up_entity: string | null
  follow_up_entity_id: number | null
  show_follow_up_create: boolean
  is_edit_follow_up_modal_open: boolean
  selected_follow_up: App.Models.FollowUp | null
  selected_follow_up_id: number | null
}

const initialState: FollowUpState = {
  is_modal_open: false,
  is_follow_up_modal_open: false,
  selected_follow_up_lead: null,
  selected_follow_up_visitor_lead: null,
  selected_follow_up_ielts_lead: null,
  selected_follow_up_insurance_lead: null,
  follow_up_entity: null,
  follow_up_entity_id: null,
  show_follow_up_create: false,
  is_edit_follow_up_modal_open: false,
  selected_follow_up: null,
  selected_follow_up_id: null,
}

export const followUpSlice = createSlice({
  name: 'followUpSlice',
  initialState,
  reducers: {
    updateIsModalOpen(state, action: PayloadAction<boolean>) {
      state.is_modal_open = action.payload
    },
    updateIsFollowUpModalOpen(state, action: PayloadAction<boolean>) {
      state.is_follow_up_modal_open = action.payload
    },
    updateSelectedFollowUpLead(state, action: PayloadAction<Lead | null>) {
      state.selected_follow_up_lead = action.payload
    },
    updateSelectedFollowUpVisitorLead(state, action: PayloadAction<VisitorLead | null>) {
      state.selected_follow_up_visitor_lead = action.payload
    },
    updateSelectedFollowUpIeltsLead(state, action: PayloadAction<IeltsLead | null>) {
      state.selected_follow_up_ielts_lead = action.payload
    },
    updateSelectedFollowUpInsuranceLead(state, action: PayloadAction<InsuranceLead | null>) {
      state.selected_follow_up_insurance_lead = action.payload
    },
    updateFollowUpEntity(state, action: PayloadAction<string | null>) {
      state.follow_up_entity = action.payload
    },
    updateFollowUpEntityId(state, action: PayloadAction<number | null>) {
      state.follow_up_entity_id = action.payload
    },
    updateShowFollowUpCreate(state, action: PayloadAction<boolean>) {
      state.show_follow_up_create = action.payload
    },
    updateIsEditFollowUpModalOpen(state, action: PayloadAction<boolean>) {
      state.is_edit_follow_up_modal_open = action.payload
    },
    updateSelectedFollowUp(state, action: PayloadAction<App.Models.FollowUp | null>) {
      state.selected_follow_up = action.payload
    },
    updateSelectedFollowUpId(state, action: PayloadAction<number | null>) {
      state.selected_follow_up_id = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateIsModalOpen,
  updateIsFollowUpModalOpen,
  updateSelectedFollowUpLead,
  updateSelectedFollowUpVisitorLead,
  updateSelectedFollowUpIeltsLead,
  updateSelectedFollowUpInsuranceLead,
  updateFollowUpEntity,
  updateFollowUpEntityId,
  updateShowFollowUpCreate,
  updateIsEditFollowUpModalOpen,
  updateSelectedFollowUp,
  updateSelectedFollowUpId,
} = followUpSlice.actions

export default followUpSlice.reducer
