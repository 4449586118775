import * as React from 'react'

function IconDrawerOpen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39648 13.8964L1.39648 0.896423L2.39648 0.896423L2.39648 13.8964H1.39648ZM10.25 3.04287L14.6036 7.39642L10.25 11.75L9.54293 11.0429L12.6894 7.89642L3.39648 7.89642V6.89642L12.6894 6.89642L9.54293 3.74998L10.25 3.04287Z"
        fill="#1D1929"
      />
    </svg>
  )
}

export default IconDrawerOpen
