import React, { useEffect, useState } from 'react'
import { Divider, HStack, Step, Steps, TabItem, Tabs, Typography, VStack, XelaColor } from '@/XelaReact'
import { Drawer } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowHubAddLeadModal } from '@/Store/Hub/hubLeadSlice'
import UploadBulkDocumentsHubForm from '@/Layouts/Hub/Forms/UploadBulkDocumentsHubForm'
import AddNewLeadFromHubForm from '@/Layouts/Hub/Forms/AddNewLeadFromHubForm'
import AddNewVisitorLeadFromHubForm from '@/Layouts/Hub/Forms/AddNewVisitorLeadFromHubForm'
import VisitorLeadUploadBulkDocumentsHubForm from '@/Layouts/Hub/Forms/VisitorLeadUploadBulkDocumentsHubForm'
import { updateShowHubAddVisitorLeadModal } from '@/Store/Hub/hubVisitorLeadSlice'

const tabs = [
  { name: 'leads', label: 'Study Leads' },
  { name: 'visitor-leads', label: 'Visitor Leads' },
]

const AddQuickHubLeadModal = () => {
  const dispatch = useDispatch()
  const hubLeadStore = useSelector((state: RootState) => state.hubLead)

  const [firstStepStatus, setFirstStepStatus] = useState<'current' | 'success'>('current')
  const [secondStepStatus, setSecondStepStatus] = useState<'default' | 'current' | 'success'>('default')
  const [tab, setTab] = useState<'leads' | 'visitor-leads'>('leads')
  const hubVisitorLeadStore = useSelector((state: RootState) => state.hubVisitorLead)
  const [visitorLeadFirstStepStatus, setVisitorLeadFirstStepStatus] = useState<'current' | 'success'>('current')
  const [visitorLeadSecondStepStatus, setVisitorLeadSecondStepStatus] = useState<'default' | 'current' | 'success'>(
    'default'
  )

  const getTabLabelByName = (name) => {
    const tab = tabs.find((tab) => tab.name === name)
    return tab ? tab.label : null // Return null or a default value if not found
  }

  useEffect(() => {
    if (hubLeadStore.lead_form_submitted) {
      setFirstStepStatus('success')
      setSecondStepStatus('current')
    }
  }, [hubLeadStore.lead_form_submitted])

  useEffect(() => {
    if (hubLeadStore.lead_documents_uploaded) {
      setSecondStepStatus('success')
    }
  }, [hubLeadStore.lead_documents_uploaded])

  useEffect(() => {
    if (hubLeadStore.show_hub_add_lead_modal) {
      setTab('leads')
      setFirstStepStatus('current')
      setSecondStepStatus('default')
    }
  }, [hubLeadStore.show_hub_add_lead_modal])

  useEffect(() => {
    if (hubVisitorLeadStore.visitor_lead_form_submitted) {
      setVisitorLeadFirstStepStatus('success')
      setVisitorLeadSecondStepStatus('current')
    }
  }, [hubVisitorLeadStore.visitor_lead_form_submitted])

  useEffect(() => {
    if (hubVisitorLeadStore.visitor_lead_documents_uploaded) {
      setVisitorLeadSecondStepStatus('success')
    }
  }, [hubVisitorLeadStore.visitor_lead_documents_uploaded])

  useEffect(() => {
    if (hubVisitorLeadStore.show_hub_add_visitor_lead_modal) {
      setTab('visitor-leads')
      setVisitorLeadFirstStepStatus('current')
      setVisitorLeadSecondStepStatus('default')
    }
  }, [hubVisitorLeadStore.show_hub_add_visitor_lead_modal])

  return (
    <Drawer
      position="left"
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      size={800}
      opened={hubLeadStore.show_hub_add_lead_modal || hubVisitorLeadStore.show_hub_add_visitor_lead_modal}
      onClose={() => {
        dispatch(updateShowHubAddLeadModal(false))
        dispatch(updateShowHubAddVisitorLeadModal(false))
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack justifyContent="space-between">
          <HStack width="30%">
            <Typography variant={'subheadline'}>Add {getTabLabelByName(tab)}</Typography>
          </HStack>
          <HStack spacing="12px" justifyContent="flex-end">
            <Tabs
              style={{
                borderRadius: '0px',
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: XelaColor.Gray12,
                justifyContent: 'flex-end',
              }}
              items={tabs.map((tab) => {
                return new TabItem(tab.name, tab.label)
              })}
              name="add_lead_modal_tabs"
              defaultValue={tab}
              onChangeHandle={(value) => {
                setTab(value)
              }}
            ></Tabs>
          </HStack>
        </HStack>
        <HStack>
          <Divider variant={'dotted'}></Divider>
        </HStack>
        {tab === 'leads' && (
          <>
            <HStack justifyContent="center">
              <Steps
                size={'medium'}
                titleCurrentColor={XelaColor.Gray3}
                captionCurrentColor={XelaColor.Gray7}
                indicatorBgSuccessColor={XelaColor.Green1}
                indicatorBgDefaultColor={XelaColor.Gray10}
                steps={[
                  new Step(1, '', 'Add Lead Details', firstStepStatus),
                  new Step(2, '', 'Upload Documents', secondStepStatus),
                ]}
                variant="horizontal"
              />
            </HStack>
            <HStack>
              <Divider />
            </HStack>
            {firstStepStatus === 'current' && <AddNewLeadFromHubForm />}
            {secondStepStatus === 'current' && <UploadBulkDocumentsHubForm />}
          </>
        )}
        {tab === 'visitor-leads' && (
          <>
            <HStack justifyContent="center">
              <Steps
                size={'medium'}
                titleCurrentColor={XelaColor.Gray3}
                captionCurrentColor={XelaColor.Gray7}
                indicatorBgSuccessColor={XelaColor.Green1}
                indicatorBgDefaultColor={XelaColor.Gray10}
                steps={[
                  new Step(1, '', 'Add Lead Details', visitorLeadFirstStepStatus),
                  new Step(2, '', 'Upload Documents', visitorLeadSecondStepStatus),
                ]}
                variant="horizontal"
              />
            </HStack>
            <HStack>
              <Divider />
            </HStack>
            {visitorLeadFirstStepStatus === 'current' && <AddNewVisitorLeadFromHubForm />}
            {visitorLeadSecondStepStatus === 'current' && <VisitorLeadUploadBulkDocumentsHubForm />}
          </>
        )}
      </VStack>
    </Drawer>
  )
}

export default AddQuickHubLeadModal
