import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Visa = App.Models.Visa
import Lead = App.Models.Lead

export interface VisaState {
  selected_visa_id: string | null
  selected_visa: Visa | null
  is_visa_scheduled: boolean
  is_visa_granted: boolean
  is_visa_denied: boolean
  is_visa_manager_changed: boolean
  visa_manager_id: string
  visa_manager_id_one: string
  visa_manager_id_two: string
  visa_manager_id_three: string
  visa_id: string
  is_ongoing_visa: boolean
  is_gic: boolean
  is_pre_lodging: boolean
  is_embassy_submission: boolean
  is_biometric_process: boolean
  is_embassy_decision: boolean
  is_medical: boolean
  is_lodged: boolean
  branch_id: string | null
  selected_visa_ids: string[]
  selected_visas: Visa[]
  quick_action_show_visa_manager_modal: boolean
  is_cas_letter: boolean
  is_vfs_scheduled: boolean
  is_visit_scheduled: boolean
  selected_lead: Lead | null
  show_insurance_confirm_details_modal: boolean
  branchIds: string[]
  visaManagerIds: string[]
  reload_all_visa_leads: boolean
  current_visa_leads_filters: {
    [key: string]: string
  }
  previous_visa_leads_filters: {
    [key: string]: string
  }
  show_all_visa_leads_filter_modal: boolean
  show_information_form: boolean
  visa_status: string | null
}

const initialState: VisaState = {
  selected_visa_id: null,
  selected_visa: null,
  is_visa_scheduled: false,
  is_visa_granted: false,
  is_visa_denied: false,
  is_visa_manager_changed: false,
  visa_manager_id: '',
  visa_manager_id_one: '',
  visa_manager_id_two: '',
  visa_manager_id_three: '',
  visa_id: '',
  is_ongoing_visa: false,
  is_gic: false,
  is_pre_lodging: false,
  is_embassy_submission: false,
  is_biometric_process: false,
  is_embassy_decision: false,
  is_medical: false,
  is_lodged: false,
  branch_id: null,
  selected_visa_ids: [],
  selected_visas: [],
  quick_action_show_visa_manager_modal: false,
  is_cas_letter: false,
  is_vfs_scheduled: false,
  is_visit_scheduled: false,
  branchIds: [],
  visaManagerIds: [],
  reload_all_visa_leads: false,
  current_visa_leads_filters: {
    branch_id: '',
    counsellor_id: '',
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
  previous_visa_leads_filters: {
    branch_id: '',
    counsellor_id: '',
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
  show_all_visa_leads_filter_modal: false,
  selected_lead: null,
  show_insurance_confirm_details_modal: false,
  show_information_form: false,
  visa_status: null,
}

export const visaSlice = createSlice({
  name: 'visaSlice',
  initialState,
  reducers: {
    updateSelectedVisaId(state, action: PayloadAction<string>) {
      state.selected_visa_id = action.payload
    },
    updateSelectedVisa(state, action: PayloadAction<Visa | null>) {
      state.selected_visa = action.payload
    },
    updateSelectedLead(state, action: PayloadAction<Lead>) {
      state.selected_lead = action.payload
    },
    updateIsVisaScheduled(state, action: PayloadAction<boolean>) {
      state.is_visa_scheduled = action.payload
    },
    updateIsVisaGranted(state, action: PayloadAction<boolean>) {
      state.is_visa_granted = action.payload
    },
    updateIsVisaDenied(state, action: PayloadAction<boolean>) {
      state.is_visa_denied = action.payload
    },
    updateIsVisaManagerChanged(
      state,
      action: PayloadAction<{
        show: boolean
        visa_manager_id: string
        visa_manager_id_one: string
        visa_manager_id_two: string
        visa_manager_id_three: string
        visa_id: string
      }>
    ) {
      state.is_visa_manager_changed = action.payload.show
      state.visa_manager_id = action.payload.visa_manager_id
      state.visa_manager_id_one = action.payload.visa_manager_id_one
      state.visa_manager_id_two = action.payload.visa_manager_id_two
      state.visa_manager_id_three = action.payload.visa_manager_id_three
      state.visa_id = action.payload.visa_id
    },
    updateIsOngoingVisa(state, action: PayloadAction<boolean>) {
      state.is_ongoing_visa = action.payload
    },
    updateIsGic(state, action: PayloadAction<boolean>) {
      state.is_gic = action.payload
    },
    updateIsPreLodging(state, action: PayloadAction<boolean>) {
      state.is_pre_lodging = action.payload
    },
    updateIsEmbassySubmission(state, action: PayloadAction<boolean>) {
      state.is_embassy_submission = action.payload
    },
    updateIsBiometricProcess(state, action: PayloadAction<boolean>) {
      state.is_biometric_process = action.payload
    },
    updateIsEmbassyDecision(state, action: PayloadAction<boolean>) {
      state.is_embassy_decision = action.payload
    },
    updateIsMedical(state, action: PayloadAction<boolean>) {
      state.is_medical = action.payload
    },
    updateIsLodged(state, action: PayloadAction<boolean>) {
      state.is_lodged = action.payload
    },
    updateVisaBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateSelectedVisaIds(state, action: PayloadAction<string[]>) {
      state.selected_visa_ids = action.payload
    },
    updateSelectedVisas(state, action: PayloadAction<Visa[]>) {
      state.selected_visas = action.payload
    },
    updateQuickActionShowVisaManagerModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
      }>
    ) {
      state.quick_action_show_visa_manager_modal = action.payload.show
      state.branch_id = action.payload.branch_id
    },
    updateIsCasLetter(state, action: PayloadAction<boolean>) {
      state.is_cas_letter = action.payload
    },
    updateIsVfsScheduled(state, action: PayloadAction<boolean>) {
      state.is_vfs_scheduled = action.payload
    },
    updateIsVisitScheduled(state, action: PayloadAction<boolean>) {
      state.is_visit_scheduled = action.payload
    },
    updateBranchIds(state, action: PayloadAction<string[]>) {
      state.branchIds = action.payload
    },
    updateVisaManagerIds(state, action: PayloadAction<string[]>) {
      state.visaManagerIds = action.payload
    },
    reloadAllVisaLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_visa_leads = action.payload
    },
    updateCurrentVisaLeadFilters(state, action: PayloadAction<{ [key: string]: string }>) {
      state.current_visa_leads_filters = action.payload
    },
    updatePreviousVisaLeadFilters(state, action: PayloadAction<{ [key: string]: string }>) {
      state.previous_visa_leads_filters = action.payload
    },
    showAllVisaLeadFilterModal(state) {
      state.show_all_visa_leads_filter_modal = true
    },
    hideAllVisaLeadFilterModal(state) {
      state.show_all_visa_leads_filter_modal = false
    },
    updateShowInsuranceConfirmDetailsModal(
      state,
      action: PayloadAction<{
        show: boolean
        lead: Lead | null
        visa: Visa | null
      }>
    ) {
      state.show_insurance_confirm_details_modal = action.payload.show
      state.selected_lead = action.payload.lead
      state.selected_visa = action.payload.visa
    },
    updateShowInformationFormDrawer(state, action: PayloadAction<boolean>) {
      state.show_information_form = action.payload
    },
    updateVisaStatus(state, action: PayloadAction<string | null>) {
      state.visa_status = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateSelectedVisaId,
  updateSelectedVisa,
  updateSelectedLead,
  updateIsVisaScheduled,
  updateIsVisaGranted,
  updateIsVisaDenied,
  updateIsVisaManagerChanged,
  updateIsOngoingVisa,
  updateIsGic,
  updateIsPreLodging,
  updateIsEmbassySubmission,
  updateIsBiometricProcess,
  updateIsEmbassyDecision,
  updateIsMedical,
  updateIsLodged,
  updateVisaBranchId,
  updateSelectedVisaIds,
  updateQuickActionShowVisaManagerModal,
  updateIsCasLetter,
  updateIsVfsScheduled,
  updateIsVisitScheduled,
  updateBranchIds,
  updateVisaManagerIds,
  updateSelectedVisas,
  reloadAllVisaLeads,
  updateCurrentVisaLeadFilters,
  updatePreviousVisaLeadFilters,
  showAllVisaLeadFilterModal,
  hideAllVisaLeadFilterModal,
  updateShowInsuranceConfirmDetailsModal,
  updateShowInformationFormDrawer,
  updateVisaStatus,
} = visaSlice.actions

export default visaSlice.reducer
