import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import { Box } from '@mantine/core'
import Notification = App.Models.Notification

const WhatsappMessageReceivedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        router.visit(route('leads.id', { id: notification.data.lead_id, show_tab: 'whatsapp' }))
      }}
    >
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small" color={XelaColor.Gray6}>
          New Whatsapp message from
        </Typography>
        <Typography variant="body-small-bold" color={XelaColor.Orange3}>
          #{notification.data.lead_name}
        </Typography>
      </HStack>
      <HStack spacing="12px" height="100%" style={{ padding: '6px 12px' }}>
        <Box
          style={{
            width: '8px',
            height: '30px',
            backgroundColor: XelaColor.Blue3,
            borderRadius: '4px',
          }}
        ></Box>
        <HStack
          spacing="3px"
          style={{
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          <VStack>
            {notification.data.message_type === 'document' ||
              (notification.data.message_type === 'image' && (
                <Typography variant="caption">
                  {notification.data.message ?? 'New ' + notification.data.message_type + ' received'}
                </Typography>
              ))}
            {notification.data.message_type !== 'document' && notification.data.message_type !== 'image' && (
              <Typography variant="caption">{notification.data.message}</Typography>
            )}
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  )
}

export default WhatsappMessageReceivedNotification
