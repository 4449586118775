import React, { FC, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

export interface BoxProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
  className?: string
  width?: string
  height?: string
  bg?: string
  borderRadius?: string
  clipped?: boolean
}

const BoxComponent = styled.div<{
  width: string
  height: string
  bg: string
  borderRadius: string
  clipped: boolean
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
  border-radius: ${(props) => props.borderRadius};
  ${(props) => props.clipped && 'overflow: hidden;'}
`

export const Box: FC<BoxProps> = ({
  children,
  className,
  width = '100%',
  height = '100%',
  bg = 'none',
  borderRadius = '0px',
  clipped = true,
  ...props
}) => {
  return (
    <BoxComponent
      width={width}
      height={height}
      bg={bg}
      borderRadius={borderRadius}
      className={`${className !== undefined ? ' ' + className : ''}`}
      clipped={clipped}
      {...props}
    >
      {children}
    </BoxComponent>
  )
}

export default Box
