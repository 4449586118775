import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Checkbox, Drawer, Group, LoadingOverlay, useMantineTheme } from '@mantine/core'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { useHotkeys } from '@mantine/hooks'
import XTextInput from '@/Mantine/XTextInput'
import { IconSearch, IconX } from '@tabler/icons-react'
import useFlash from '@/Hooks/useFlash'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowSearch } from '@/Store/globalSlice'
import { router } from '@inertiajs/react'
import SearchCard from '@/Layouts/Components/SearchCard'

interface SearchResult {
  lead_id: number
  entity: string
  entity_id: number
  lead_info?: string
  course_info?: string
  document_info?: string
  status: string
}

const GlobalSearchModal: FC<{ portal?: string }> = ({ portal = 'Agency' }) => {
  const theme = useMantineTheme()
  const dispatch = useDispatch()
  const globalState = useSelector((state: RootState) => state.global)
  const flash = useFlash()

  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [exactMatch, setExactMatch] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [searchIncludes, setSearchIncludes] = useState<string[]>(
    portal === 'Agency'
      ? ['Visa', 'Admission Application', 'Counselling', 'Lead', 'Visitor Lead', 'IELTS Lead']
      : ['Lead', 'Admission Application', 'Visa']
  )

  useEffect(() => {
    if (flash.action === 'global_search') {
      const payload = flash.payload as SearchResult[]
      setSearchResults(payload)
    }
  }, [flash.timestamp])

  useHotkeys([['mod+S', () => dispatch(updateShowSearch(!globalState.showSearch))]])

  const triggerSearch = useCallback(() => {
    if (search) {
      setLoading(true)
      const url = portal === 'Agency' || portal === 'Hub' ? '/global/search' : '/b2b/global/search'
      router.post(
        url,
        {
          exact_match: exactMatch,
          search: search,
          includes: searchIncludes,
        },
        {
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => {
            setLoading(false)
          },
          onError: () => setLoading(false),
        }
      )
    }
  }, [search, exactMatch, searchIncludes, portal])

  const handleSearchIncludesChange = useCallback(
    (value: string[]) => {
      if (value.length) {
        if (portal === 'Agency') {
          value =
            value.includes('Lead') && !value.includes('Visitor Lead') && !value.includes('IELTS Lead')
              ? [...value, 'Visitor Lead', 'IELTS Lead']
              : value.filter((item) => item !== 'Visitor Lead' && item !== 'IELTS Lead')
        }
        setSearchIncludes(value)
        triggerSearch() // Trigger search when includes change
      }
    },
    [portal, triggerSearch]
  )

  const clearSearch = useCallback(() => {
    setSearch('')
    setSearchResults([])
    setExactMatch(false)
  }, [])

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value)
  }, [])

  const handleExactMatchChange = useCallback(() => {
    setExactMatch((prev) => !prev)
    triggerSearch()
  }, [triggerSearch])

  useEffect(() => {
    if (!globalState.showSearch) {
      clearSearch()
    }
  }, [globalState.showSearch])

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          width: '36px',
          height: '36px',
          padding: 0,
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '36px',
          cursor: 'pointer',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          backgroundColor: XelaColor.White,
        }}
        onClick={() => dispatch(updateShowSearch(!globalState.showSearch))}
      >
        <IconSearch color={XelaColor.Blue3} size={18} />
      </Box>
      <Drawer
        size={700}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        withCloseButton={false}
        opened={globalState.showSearch}
        onClose={() => {
          clearSearch()
          dispatch(updateShowSearch(false))
        }}
        styles={{
          body: {
            height: '100%',
          },
        }}
      >
        <VStack height="100%" spacing="12px">
          <HStack>
            <HStack spacing="12px">
              <Typography variant={'subheadline'}>Global Search</Typography>
              <Checkbox
                checked={exactMatch}
                value="exact_match"
                label={<Typography variant="caption">Exact Match</Typography>}
                color="indigo"
                size="sm"
                onChange={handleExactMatchChange}
              />
            </HStack>
            <HStack width="initial">
              <Box
                style={{
                  padding: '4px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  clearSearch()
                  dispatch(updateShowSearch(false))
                }}
              >
                <IconX size={16} color={XelaColor.Gray7} />
              </Box>
            </HStack>
          </HStack>
          <HStack>
            <Divider variant="dotted" />
          </HStack>
          <HStack spacing={'12px'}>
            <XTextInput
              data-autofocus
              value={search}
              placeholder={'Type... & Then Enter to Search'}
              onChange={handleSearchChange}
              onKeyDown={(event) => {
                if (event.code === 'Enter') {
                  triggerSearch()
                }
              }}
              label="Search..."
              icon={<IconSearch size={20} />}
              rightSection={
                search ? (
                  <IconX size={18} color={XelaColor.Gray7} onClick={clearSearch} style={{ cursor: 'pointer' }} />
                ) : null
              }
            />
            <Button disabled={!search} label={'Search'} onClick={triggerSearch} />
          </HStack>
          <HStack justifyContent="center">
            <Checkbox.Group value={searchIncludes} onChange={handleSearchIncludesChange}>
              <Group mt="xs">
                <Checkbox value="Lead" label="Lead" color="indigo" />
                {portal === 'Agency' && <Checkbox value="Counselling" label="Counselling" color="indigo" />}
                {portal === 'Agency' && (
                  <Checkbox value="Admission Application" label="Admission Application" color="indigo" />
                )}
                {portal === 'Agency' && <Checkbox value="Visa" label="Visa" color="indigo" />}
                <Checkbox value="Document" label="Document" color="indigo" />
              </Group>
            </Checkbox.Group>
          </HStack>
          <Divider variant="dotted" />
          <VStack
            spacing="12px"
            style={{
              flex: '1 1 auto',
              height: 0,
              overflowY: 'auto',
              padding: '0 8px',
            }}
          >
            <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
            {searchResults.map((result, key) => (
              <SearchCard key={key} result={result} portal={portal} />
            ))}
          </VStack>
        </VStack>
      </Drawer>
    </>
  )
}

export default GlobalSearchModal
