import * as React from 'react'

function IconBellFill(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="13" height="14" viewBox="0 0 19 21" fill="#003CFF" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.66683 13.8333H2.16683V13.3333V7.33333C2.16683 3.55939 5.22622 0.5 9.00016 0.5C12.7741 0.5 15.8335 3.55939 15.8335 7.33333V13.3333V13.8333H16.3335H17.1668V14.1667H0.833496V13.8333H1.66683ZM6.16683 16.6667V16.5H11.8335V16.6667C11.8335 18.2315 10.565 19.5 9.00016 19.5C7.43536 19.5 6.16683 18.2315 6.16683 16.6667Z"
        stroke="#003CFF"
        strokeWidth="1.2"
      />
    </svg>
  )
}

export default IconBellFill
