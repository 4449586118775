import React, { forwardRef, ReactNode, useState } from 'react'
import { createPolymorphicComponent, createStyles, MantineSize, NumberInput, NumberInputProps } from '@mantine/core'
import { XelaColor } from '@/XelaReact'
import { omit } from 'lodash'

interface XNumberInputProps extends NumberInputProps {
  placeholder?: string
  label?: ReactNode
  size?: MantineSize
}

const useStyles = createStyles(
  (
    theme,
    params: {
      value?: number | '' | string | undefined
      placeholder?: string
      focused?: boolean
      label?: ReactNode
      size?: MantineSize
      icon?: ReactNode
    }
  ) => ({
    root: {
      fontFamily: 'Nunito Sans',
      position: 'relative',
      width: '100%',
    },

    input: {
      fontSize: '14px',
      fontWeight: 700,
      height: params.size === 'sm' ? '45px' : '56px',
      paddingLeft: params.icon ? '32px !important' : '16px !important',
      padding: params.label
        ? params.size === 'sm'
          ? '18px 22px 2px'
          : '26px 22px 6px'
        : params.size === 'sm'
        ? '2px 22px 2px'
        : '5px 22px 6px',
      border: '1px solid #E8E8EA',
      borderRadius: params.size === 'sm' ? '16px' : '18px',
      '&:hover, &:focus': {
        paddingLeft: params.icon ? '30.5px !important' : '14.5px !important',
        padding: params.label
          ? params.size === 'sm'
            ? '17px 20.5px 1px'
            : '25px 20.5px 5px'
          : params.size === 'sm'
          ? '1px 20.5px 1px'
          : '4px 20.5px 5px',
        border: '2px solid #003CFF',
      },
      '&:focus': {
        color: '#003CFF !important',
      },
      '&::placeholder': {
        color: XelaColor.Gray10,
        fontWeight: 400,
      },
    },

    label: {
      fontSize: params.focused || params.placeholder || params.value || params.value === 0 ? '10px' : '13px',
      lineHeight: '15px',
      fontWeight: 600,
      position: 'absolute',
      pointerEvents: 'none',
      paddingLeft: params.icon ? '32px !important' : '16px !important',
      transition: 'all 300ms ease',
      paddingTop: params.label
        ? `${
            params.focused || params.placeholder || params.value || params.value === 0
              ? params.size === 'sm'
                ? '8px'
                : '12px'
              : params.size === 'sm'
              ? '16px'
              : '20px'
          }`
        : '0px',
      zIndex: 1,
      color: XelaColor.Gray3,
      '&:hover, &:focus': {
        paddingLeft: '25px',
      },
    },

    dropdown: {
      fontFamily: 'Nunito Sans',
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      padding: '8px !important',
      zIndex: 9999,
    },

    item: {
      width: '90%',
      fontSize: '14px',
      fontWeight: 700,
      color: '#1D1929',
      marginBottom: '4px !important',
      padding: params.size === 'sm' ? '8px 12px !important' : '12px !important',
      '&[data-selected]': {
        '&, &:hover': {
          color: '#FFFFFF',
          background: '#003CFF !important',
          borderRadius: '12px',
        },
      },
      '&[data-focused]': {
        '&, &:hover': {
          background: '#F9F9F9',
          borderRadius: '12px',
        },
      },
      '&:hover': {
        borderRadius: '12px',
      },
    },
  })
)

// eslint-disable-next-line react/display-name
const _XNumberInput = forwardRef<
  HTMLInputElement,
  Omit<XNumberInputProps, 'defaultValue'> & {
    defaultValue: number | '' | string | undefined | null
  }
>(({ children, ...props }, ref) => {
  const [focused, setFocused] = useState(false)

  const { classes } = useStyles({
    label: props.label,
    placeholder: props.placeholder,
    icon: props.icon,
    value:
      props.defaultValue === 0 || (props.defaultValue !== undefined && props.defaultValue !== null)
        ? props.defaultValue
        : undefined,
    focused,
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const value =
    props.defaultValue === 0 ||
    (props.defaultValue !== undefined && props.defaultValue !== null && props.defaultValue !== '')
      ? typeof props.defaultValue === 'string'
        ? props.defaultValue === '' // Check for empty string here
          ? '' // Set to null if empty string
          : props.precision
          ? parseFloat(props.defaultValue)
          : parseInt(props.defaultValue, 10) // Added radix for better practice
        : props.defaultValue
      : undefined

  return (
    <NumberInput
      autoComplete={'off'}
      ref={ref}
      {...omit(props, ['defaultValue'])}
      value={value}
      type={props.type || 'number'}
      hideControls={true}
      classNames={classes}
      onFocus={() => {
        setFocused(true)
      }}
      onBlur={() => {
        setFocused(false)
      }}
    >
      {children}
    </NumberInput>
  )
})
export const XNumberInput = createPolymorphicComponent<
  'input',
  Omit<XNumberInputProps, 'defaultValue'> & {
    defaultValue: number | '' | string | undefined | null
  }
>(_XNumberInput)

export default XNumberInput
