import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import InsuranceLead = App.Models.InsuranceLead
import Document = App.Models.Document

export interface InsuranceLeadState {
  branch_id: string | null
  portal: string
  insurance_lead_id: string | null
  insurance_lead: InsuranceLead | null
  show_edit_personal_detail_modal: boolean
  show_edit_visa_passport_detail_modal: boolean
  show_edit_travel_detail_modal: boolean
  show_edit_policy_detail_modal: boolean
  show_edit_sponsor_detail_modal: boolean
  show_edit_study_detail_modal: boolean
  show_insurance_lead_assigned_modal: boolean
  show_upload_document_modal: boolean
  document_belongs_to: string
  document_entity: string
  document_entity_id: string | null
  download_documents: Document[] | null
  show_document_download_modal: boolean
  lead_manager_id: string | null
  is_lead_manager_changed: boolean
  show_update_proposal_transition_modal: boolean
  show_update_paid_transition_modal: boolean
  show_update_issued_transition_modal: boolean
  document: Document | null
  show_edit_upload_document_modal: boolean
  selected_insurance_lead_id: number | null
  is_leads_notes_opened: boolean
}

const initialState: InsuranceLeadState = {
  branch_id: null,
  portal: 'Agency',
  insurance_lead_id: null,
  insurance_lead: null,
  show_edit_personal_detail_modal: false,
  show_edit_visa_passport_detail_modal: false,
  show_edit_travel_detail_modal: false,
  show_edit_policy_detail_modal: false,
  show_edit_sponsor_detail_modal: false,
  show_edit_study_detail_modal: false,
  show_insurance_lead_assigned_modal: false,
  show_upload_document_modal: false,
  document_belongs_to: 'Applicant',
  document_entity: 'Insurance Lead',
  document_entity_id: null,
  download_documents: null,
  show_document_download_modal: false,
  lead_manager_id: null,
  is_lead_manager_changed: false,
  show_update_proposal_transition_modal: false,
  show_update_paid_transition_modal: false,
  show_update_issued_transition_modal: false,
  document: null,
  show_edit_upload_document_modal: false,
  selected_insurance_lead_id: null,
  is_leads_notes_opened: false,
}

export const insuranceLeadSlice = createSlice({
  name: 'insuranceLeadSlice',
  initialState,
  reducers: {
    updateInsuranceLeadId(state, action: PayloadAction<string | null>) {
      state.insurance_lead_id = action.payload
    },
    updateInsuranceLead(state, action: PayloadAction<InsuranceLead | null>) {
      state.insurance_lead = action.payload
    },
    updateShowEditPersonalDetailModal(
      state,
      action: PayloadAction<{ show: boolean; insuranceLead: InsuranceLead | null }>
    ) {
      state.show_edit_personal_detail_modal = action.payload.show
      state.insurance_lead = action.payload.insuranceLead
    },
    updateShowEditVisaPassportDetailModal(
      state,
      action: PayloadAction<{ show: boolean; insuranceLead: InsuranceLead | null }>
    ) {
      state.show_edit_visa_passport_detail_modal = action.payload.show
      state.insurance_lead = action.payload.insuranceLead
    },
    updateShowEditTravelDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        insuranceLead: InsuranceLead | null
      }>
    ) {
      state.show_edit_travel_detail_modal = action.payload.show
      state.insurance_lead = action.payload.insuranceLead
    },
    updateShowEditPolicyDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        insuranceLead: InsuranceLead | null
      }>
    ) {
      state.show_edit_policy_detail_modal = action.payload.show
      state.insurance_lead = action.payload.insuranceLead
    },
    updateShowEditSponsorDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        insuranceLead: InsuranceLead | null
      }>
    ) {
      state.show_edit_sponsor_detail_modal = action.payload.show
      state.insurance_lead = action.payload.insuranceLead
    },
    updateShowEditStudyDetailModal(
      state,
      action: PayloadAction<{
        show: boolean
        insuranceLead: InsuranceLead | null
      }>
    ) {
      state.show_edit_study_detail_modal = action.payload.show
      state.insurance_lead = action.payload.insuranceLead
    },
    updateInsuranceLeadBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateDocumentBelongsTo(state, action: PayloadAction<string>) {
      state.document_belongs_to = action.payload
    },
    updateShowUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        insurance_lead_id: string | null
        belongs_to: string
      }>
    ) {
      state.show_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.insurance_lead_id = action.payload.insurance_lead_id
      state.document_belongs_to = action.payload.belongs_to
    },
    updateDownloadDocumentsModal(
      state,
      action: PayloadAction<{
        show: boolean
        insurance_lead_id: string | null
        documents: Document[] | null
      }>
    ) {
      state.show_document_download_modal = action.payload.show
      state.insurance_lead_id = action.payload.insurance_lead_id
      state.download_documents = action.payload.documents
    },
    updateIsLeadManagerChanged(
      state,
      action: PayloadAction<{
        show: boolean
        insurance_lead_id: string | null
        lead_manager_id: string | null
        branch_id: string | null
      }>
    ) {
      state.is_lead_manager_changed = action.payload.show
      state.insurance_lead_id = action.payload.insurance_lead_id
      state.lead_manager_id = action.payload.lead_manager_id
      state.branch_id = action.payload.branch_id
    },
    updateShowInsuranceLeadAssignedTransactorModal(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        insurance_lead_id: string | null
      }>
    ) {
      state.show_insurance_lead_assigned_modal = action.payload.show
      state.branch_id = action.payload.branch_id
      state.insurance_lead_id = action.payload.insurance_lead_id
    },
    updateShowUpdateProposalTransitionModal(
      state,
      action: PayloadAction<{
        show: boolean
        insurance_lead: InsuranceLead | null
      }>
    ) {
      state.show_update_proposal_transition_modal = action.payload.show
      state.insurance_lead = action.payload.insurance_lead
    },
    updateShowUpdatePaidTransitionModal(
      state,
      action: PayloadAction<{
        show: boolean
        insurance_lead: InsuranceLead | null
      }>
    ) {
      state.show_update_paid_transition_modal = action.payload.show
      state.insurance_lead = action.payload.insurance_lead
    },
    updateShowUpdateIssuedTransitionModal(
      state,
      action: PayloadAction<{
        show: boolean
        insurance_lead: InsuranceLead | null
      }>
    ) {
      state.show_update_issued_transition_modal = action.payload.show
      state.insurance_lead = action.payload.insurance_lead
    },
    updateShowEditUploadDocumentModal(
      state,
      action: PayloadAction<{
        show: boolean
        document_entity: string
        document_entity_id: string | null
        insurance_lead_id: string | null
        belongs_to: string
        document: Document | null
      }>
    ) {
      state.show_edit_upload_document_modal = action.payload.show
      state.document_entity = action.payload.document_entity
      state.document_entity_id = action.payload.document_entity_id
      state.insurance_lead_id = action.payload.insurance_lead_id
      state.document_belongs_to = action.payload.belongs_to
      state.document = action.payload.document
    },
    updateIsInsuranceLeadsNotesOpened(
      state,
      action: PayloadAction<{ selected_insurance_lead_id: number | null; is_leads_notes_opened: boolean }>
    ) {
      state.selected_insurance_lead_id = action.payload.selected_insurance_lead_id
      state.is_leads_notes_opened = action.payload.is_leads_notes_opened
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateInsuranceLeadId,
  updateInsuranceLead,
  updateShowEditPersonalDetailModal,
  updateShowEditVisaPassportDetailModal,
  updateShowEditTravelDetailModal,
  updateShowEditPolicyDetailModal,
  updateShowEditSponsorDetailModal,
  updateShowEditStudyDetailModal,
  updateInsuranceLeadBranchId,
  updateShowUploadDocumentModal,
  updateDocumentBelongsTo,
  updateDownloadDocumentsModal,
  updateIsLeadManagerChanged,
  updateShowInsuranceLeadAssignedTransactorModal,
  updateShowUpdateProposalTransitionModal,
  updateShowUpdatePaidTransitionModal,
  updateShowUpdateIssuedTransitionModal,
  updateShowEditUploadDocumentModal,
  updateIsInsuranceLeadsNotesOpened,
} = insuranceLeadSlice.actions

export default insuranceLeadSlice.reducer
