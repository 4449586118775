import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Agent = App.Models.Agent

export interface HubSubAgentState {
  agent_id?: number | null
  selected_agent: Agent | null
  show_add_agent_modal: boolean
  show_agent_reset_password_modal: boolean
  show_agent_copy_login_credentials_modal: boolean
}

const initialState: HubSubAgentState = {
  agent_id: undefined,
  selected_agent: null,
  show_add_agent_modal: false,
  show_agent_reset_password_modal: false,
  show_agent_copy_login_credentials_modal: false,
}

export const hubSubAgentSlice = createSlice({
  name: 'hubSubAgentSlice',
  initialState,
  reducers: {
    updateAgentId(state, action: PayloadAction<number | undefined>) {
      state.agent_id = action.payload
    },
    updateShowAddAgentModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_add_agent_modal = true
      } else {
        state.agent_id = null
        state.selected_agent = null
        state.show_add_agent_modal = false
      }
    },
    updateShowAgentResetPasswordModal(
      state,
      action: PayloadAction<{
        show: boolean
        agent: Agent | null
        agent_id: number | null
      }>
    ) {
      state.show_agent_reset_password_modal = action.payload.show
      state.selected_agent = action.payload.agent
      state.agent_id = action.payload.agent_id
    },
    updateShowAgentCopyLoginCredentialsModal(state, action: PayloadAction<boolean>) {
      state.show_agent_copy_login_credentials_modal = action.payload
    },
    updateSelectedAgent(state, action: PayloadAction<Agent | null>) {
      state.selected_agent = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateAgentId,
  updateSelectedAgent,
  updateShowAddAgentModal,
  updateShowAgentResetPasswordModal,
  updateShowAgentCopyLoginCredentialsModal,
} = hubSubAgentSlice.actions

export default hubSubAgentSlice.reducer
