import * as React from 'react'

function IconOff(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 8.5v-8M2.618 2.499A6.963 6.963 0 00.5 7.495c0 3.864 3.135 7.005 7 7.005 3.867 0 7-3.141 7-7.005A6.968 6.968 0 0012.395 2.5"
        stroke="#003CFF"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default IconOff
