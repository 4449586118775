import React, { useEffect, useState } from 'react'
import { Divider, HStack, Step, Steps, VStack, XelaColor } from '@/XelaReact'
import { Drawer } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowHubAddVisitorLeadModal } from '@/Store/Hub/hubVisitorLeadSlice'
import AddNewVisitorLeadFromHubForm from '@/Layouts/Hub/Forms/AddNewVisitorLeadFromHubForm'
import VisitorLeadUploadBulkDocumentsHubForm from '@/Layouts/Hub/Forms/VisitorLeadUploadBulkDocumentsHubForm'

const AddQuickHubVisitorLeadModal = () => {
  const dispatch = useDispatch()
  const hubVisitorLeadStore = useSelector((state: RootState) => state.hubVisitorLead)

  const [firstStepStatus, setFirstStepStatus] = useState<'current' | 'success'>('current')
  const [secondStepStatus, setSecondStepStatus] = useState<'default' | 'current' | 'success'>('default')

  useEffect(() => {
    if (hubVisitorLeadStore.visitor_lead_form_submitted) {
      setFirstStepStatus('success')
      setSecondStepStatus('current')
    }
  }, [hubVisitorLeadStore.visitor_lead_form_submitted])

  useEffect(() => {
    if (hubVisitorLeadStore.visitor_lead_documents_uploaded) {
      setSecondStepStatus('success')
    }
  }, [hubVisitorLeadStore.visitor_lead_documents_uploaded])

  useEffect(() => {
    if (hubVisitorLeadStore.show_hub_add_visitor_lead_modal) {
      setFirstStepStatus('current')
      setSecondStepStatus('default')
    }
  }, [hubVisitorLeadStore.show_hub_add_visitor_lead_modal])

  return (
    <Drawer
      position="left"
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      size={800}
      opened={hubVisitorLeadStore.show_hub_add_visitor_lead_modal}
      onClose={() => {
        dispatch(updateShowHubAddVisitorLeadModal(false))
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack justifyContent="center">
          <Steps
            size={'medium'}
            titleCurrentColor={XelaColor.Gray3}
            captionCurrentColor={XelaColor.Gray7}
            indicatorBgSuccessColor={XelaColor.Green1}
            indicatorBgDefaultColor={XelaColor.Gray10}
            steps={[
              new Step(1, '', 'Add Lead Details', firstStepStatus),
              new Step(2, '', 'Upload Documents', secondStepStatus),
            ]}
            variant="horizontal"
          />
        </HStack>
        <HStack>
          <Divider />
        </HStack>
        {firstStepStatus === 'current' && <AddNewVisitorLeadFromHubForm />}
        {secondStepStatus === 'current' && <VisitorLeadUploadBulkDocumentsHubForm />}
      </VStack>
    </Drawer>
  )
}

export default AddQuickHubVisitorLeadModal
