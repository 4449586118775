import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import InsuranceAgencyInvoice = App.Models.InsuranceAgencyInvoice
import InsuranceOrder = App.Models.InsuranceOrder

export interface InvoiceState {
  total_invoices: number
  is_filter_applied: boolean
  is_invoice_edited: boolean
  is_selected_all: boolean
  is_invoice_need_to_refresh: boolean
  selected_invoice: InsuranceAgencyInvoice | null
  invoice_filters: {
    search: string
    status_type: string
    status_value: string
  }
  selected_invoices_id: number[]
  selected_orders: InsuranceOrder[]
  selected_status: string | null
}

const initialState: InvoiceState = {
  total_invoices: 0,
  is_filter_applied: false,
  is_invoice_edited: false,
  is_selected_all: false,
  is_invoice_need_to_refresh: false,
  selected_invoice: null,
  invoice_filters: {
    search: '',
    status_type: 'All',
    status_value: 'All',
  },
  selected_invoices_id: [],
  selected_orders: [],
  selected_status: '',
}

export const adminInsuranceAgencyInvoiceSlice = createSlice({
  name: 'adminInsuranceAgencyInvoiceSlice',
  initialState,
  reducers: {
    updateTotalAdminInsuranceAgencyInvoices(state, action: PayloadAction<number>) {
      state.total_invoices = action.payload
    },
    updateEditAdminInsuranceAgencyInvoice(
      state,
      action: PayloadAction<{
        is_invoice_edited: boolean
        selected_invoice: InsuranceAgencyInvoice | null
        selected_orders: InsuranceOrder[]
        selected_status: string | null
      }>
    ) {
      state.is_invoice_edited = action.payload.is_invoice_edited
      state.selected_invoice = action.payload.selected_invoice
      state.selected_orders = action.payload.selected_orders
      state.selected_status = action.payload.selected_status
    },
    updateIsAdminInsuranceAgencyInvoiceNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_invoice_need_to_refresh = action.payload
    },
    updateSelectedAllAdminInsuranceAgencyInvoices(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateAdminInsuranceAgencyInvoiceFilters(
      state,
      action: PayloadAction<{
        search: string
        status_type: string
        status_value: string
      }>
    ) {
      state.invoice_filters = action.payload
    },
    clearAdminInsuranceAgencyInvoiceFilters(state) {
      state.invoice_filters = {
        search: '',
        status_type: 'All',
        status_value: 'All',
      }
    },
    updateSelectedInsuranceAgencyInvoicesId: (state, action) => {
      state.selected_invoices_id = action.payload
    },
  },
})

export const {
  updateEditAdminInsuranceAgencyInvoice,
  updateTotalAdminInsuranceAgencyInvoices,
  updateIsAdminInsuranceAgencyInvoiceNeedToRefresh,
  updateSelectedAllAdminInsuranceAgencyInvoices,
  updateSelectedInsuranceAgencyInvoicesId,
  updateAdminInsuranceAgencyInvoiceFilters,
  clearAdminInsuranceAgencyInvoiceFilters,
} = adminInsuranceAgencyInvoiceSlice.actions

export default adminInsuranceAgencyInvoiceSlice.reducer
