import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled from 'styled-components'
import Box from '@/XelaReact/Box/Box'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface ToggleProps extends HTMLAttributes<HTMLInputElement> {
  name: string
  value: string
  size?: 'large' | 'medium' | 'small'
  onIcon?: ReactNode
  offIcon?: ReactNode
  deactiveBg?: string
  activeBg?: string
  isOn?: boolean
}

const ToggleComponent = styled.div<{
  size?: 'large' | 'medium' | 'small'
  activeBg?: string
  deactiveBg?: string
}>`
  display: inline-block;

  & input[type='checkbox'] {
    display: none;
  }

  & label {
    display: block;
    width: ${(props) =>
      props.size == 'large'
        ? '56px'
        : props.size == 'medium'
        ? '48px'
        : '32px'};
    height: ${(props) =>
      props.size == 'large'
        ? '32px'
        : props.size == 'medium'
        ? '24px'
        : '16px'};
    background-color: ${(props) => props.deactiveBg};
    border-radius: 100px;
    cursor: pointer;
  }

  & label:before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    width: ${(props) =>
      props.size == 'large'
        ? '24px'
        : props.size == 'medium'
        ? '20px'
        : '14px'};
    height: ${(props) =>
      props.size == 'large'
        ? '24px'
        : props.size == 'medium'
        ? '20px'
        : '14px'};
    border-radius: 100px;
    margin: ${(props) =>
      props.size == 'large' ? '4px' : props.size == 'medium' ? '2px' : '1px'};
    transform: translateX(0px);
    transition: 0.2s;
  }

  & input[type='checkbox']:checked + label {
    background-color: ${(props) => props.activeBg};
  }

  & input[type='checkbox']:checked + label:before {
    transform: translateX(
      ${(props) =>
        props.size == 'large'
          ? '24px'
          : props.size == 'medium'
          ? '24px'
          : '16px'}
    );
  }

  & #xelaOffIcon {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    position: absolute;
    width: ${(props) =>
      props.size == 'large'
        ? '16px'
        : props.size == 'medium'
        ? '15px'
        : '12px'};
    height: ${(props) =>
      props.size == 'large'
        ? '16px'
        : props.size == 'medium'
        ? '15px'
        : '12px'};
    margin: ${(props) =>
      props.size == 'large' ? '8px' : props.size == 'medium' ? '4px' : '2px'};
    color: ${(props) => props.deactiveBg};
    fill: ${(props) => props.deactiveBg};
    stroke: ${(props) => props.deactiveBg};
    overflow: hidden;
    opacity: 1;
    transition: all 0.2s ease;
  }

  & #xelaOnIcon {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    position: absolute;
    width: ${(props) =>
      props.size == 'large'
        ? '16px'
        : props.size == 'medium'
        ? '15px'
        : '12px'};
    height: ${(props) =>
      props.size == 'large'
        ? '16px'
        : props.size == 'medium'
        ? '15px'
        : '12px'};
    margin: ${(props) =>
      props.size == 'large' ? '8px' : props.size == 'medium' ? '4px' : '2px'};
    transform: translateX(
      ${(props) =>
        props.size == 'large'
          ? '24px'
          : props.size == 'medium'
          ? '24px'
          : '16px'}
    );
    color: ${(props) => props.activeBg};
    fill: ${(props) => props.activeBg};
    stroke: ${(props) => props.activeBg};
    overflow: hidden;
    opacity: 0;
    transition: all 0.2s ease;
  }

  & input[type='checkbox']:checked + label #xelaOnIcon {
    opacity: 1;
  }

  & input[type='checkbox']:checked + label #xelaOffIcon {
    opacity: 0;
  }
`

export const Toggle: FC<ToggleProps> = ({
  name,
  value,
  size = 'medium',
  onIcon,
  offIcon,
  activeBg = XelaColor.Blue3,
  deactiveBg = XelaColor.Gray11,
  isOn = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(isOn)

  const handleOnChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <ToggleComponent
      size={size}
      activeBg={activeBg}
      deactiveBg={deactiveBg}
      {...props}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        checked={isChecked}
        onChange={handleOnChange}
      />
      <label htmlFor={name}>
        <Box id="xelaOffIcon">{offIcon}</Box>
        <Box id="xelaOnIcon">{onIcon}</Box>
      </label>
    </ToggleComponent>
  )
}

export default Toggle
