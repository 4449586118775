import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled from 'styled-components'
import Typography, { TypographyProps } from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import './Button.css'

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label?: string
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  size?: 'large' | 'medium' | 'small' | 'extraSmall' | 'normal'
  variant?: 'primary' | 'secondary' | 'error'
  autoresize?: true | false
  as?: 'button' | 'submit'
  bg?: string
  hoverBg?: string
  defaultTextColor?: string
  hoverTextColor?: string
  secondaryDefaultBorderColor?: string
  disabled?: true | false
  className?: string
}

interface ButtonPropsStyled extends ButtonProps {
  isDisabled: boolean
  isMouseDown: boolean
  isHover: boolean
  defaultBackground: string
  hoverBackground: string
  defTextColor: string
  hovTextColor: string
}

function hexToRgbA(hex: string, opacity = '1') {
  let c: any = []
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }

    c = '0x' + c.join('')

    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
  }

  return 'none'
}

const ButtonComponent = styled.button<ButtonPropsStyled>`
  padding-right: ${(props) =>
    props.size == 'large'
      ? '24px'
      : props.size == 'medium'
      ? '16px'
      : props.label !== undefined && props.label != ''
      ? '14px'
      : props.size === 'extraSmall'
      ? '10px'
      : '8px'};
  padding-left: ${(props) =>
    props.size == 'large'
      ? '24px'
      : props.size == 'medium'
      ? '16px'
      : props.label !== undefined && props.label != ''
      ? '14px'
      : props.size === 'extraSmall'
      ? '10px'
      : '8px'};
  gap: ${(props) => (props.size == 'large' ? '18px' : props.size == 'medium' ? '14px' : '8px')};
  opacity: ${(props) => (props.isDisabled ? '0.4' : '1')};
  ${(props) => props.isDisabled !== undefined && 'cursor: ' + (props.isDisabled ? 'initial' : 'pointer') + ';'}
  outline: ${(props) =>
    props.variant == 'primary'
      ? props.isMouseDown
        ? '4px solid ' + hexToRgbA(props.defaultBackground, '0.4') + ' !important'
        : 'none'
      : props.isMouseDown
      ? '2px solid ' + props.defTextColor + ' !important'
      : props.isHover
      ? '1px solid ' + props.hovTextColor + ' !important'
      : '1px solid ' + props.secondaryDefaultBorderColor + ' !important'};
  background: ${(props) =>
    props.isMouseDown ? props.defaultBackground : props.isHover ? props.hoverBackground : props.defaultBackground};
  border-radius: ${(props) => (props.size == 'large' || props.size == 'medium' ? '16px' : '12px')};
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.autoresize ? 'auto' : '100%')};
  color: ${(props) =>
    props.isMouseDown ? props.defTextColor : props.isHover ? props.hovTextColor : props.defTextColor};
  fill: ${(props) =>
    props.isMouseDown ? props.defTextColor : props.isHover ? props.hovTextColor : props.defTextColor};
  stroke: ${(props) =>
    props.isMouseDown ? props.defTextColor : props.isHover ? props.hovTextColor : props.defTextColor};
  transition: background 200ms linear;
`

export const Button: FC<ButtonProps> = ({
  label,
  leftIcon,
  rightIcon,
  size = 'medium',
  variant = 'primary',
  autoresize = true,
  as = 'button',
  bg,
  hoverBg,
  defaultTextColor,
  hoverTextColor,
  secondaryDefaultBorderColor = XelaColor.Gray10,
  disabled = false,
  className,
  ...props
}) => {
  const [isHover, setHover] = useState(false)
  const [isMouseDown, setMouseDown] = useState(false)

  const labelSizeVariant = ('button-' + size) as TypographyProps['variant']

  const defaultBackground =
    bg !== undefined && bg != ''
      ? bg
      : variant === 'primary'
      ? XelaColor.Blue3
      : variant === 'error'
      ? XelaColor.Red3
      : 'none'

  const hoverBackground =
    hoverBg !== undefined && hoverBg != ''
      ? hoverBg
      : variant === 'primary'
      ? XelaColor.Blue6
      : variant === 'error'
      ? XelaColor.Red6
      : 'none'

  const defTextColor =
    defaultTextColor !== undefined && defaultTextColor !== ''
      ? defaultTextColor
      : variant === 'primary'
      ? XelaColor.White
      : variant === 'error'
      ? XelaColor.White // Assuming white text for error background
      : XelaColor.Blue3

  const hovTextColor =
    hoverTextColor !== undefined && hoverTextColor !== ''
      ? hoverTextColor
      : variant === 'primary'
      ? XelaColor.White
      : variant === 'error'
      ? XelaColor.White // Assuming white text for error hover background
      : XelaColor.Blue6

  return (
    <ButtonComponent
      size={size}
      label={label}
      variant={variant}
      isMouseDown={isMouseDown}
      isDisabled={disabled}
      defaultBackground={defaultBackground}
      defTextColor={defTextColor}
      isHover={isHover}
      hovTextColor={hovTextColor}
      secondaryDefaultBorderColor={secondaryDefaultBorderColor}
      hoverBackground={hoverBackground}
      autoresize={autoresize}
      type={as}
      disabled={disabled}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
        setMouseDown(false)
      }}
      onMouseDown={() => {
        setMouseDown(true)
      }}
      onMouseUp={() => {
        setMouseDown(false)
      }}
      className={`xela-button xela-button-${size} xela-hstack${className !== undefined ? ' ' + className : ''}`}
      {...props}
    >
      {leftIcon}
      {label !== undefined && label !== '' && (
        <Typography as="span" variant={labelSizeVariant} style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
          {label}
        </Typography>
      )}
      {rightIcon}
    </ButtonComponent>
  )
}

export default Button
