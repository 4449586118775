import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { MetaColumnOptionOptionProps, MetaColumnOptionsProps } from '@/Types/Meta'

export interface MetaState {
  show_meta_option_modal: boolean
  show_meta_add_course_modal: boolean
  selected_meta_column: MetaColumnOptionsProps | null
  is_meta_option_update: boolean
  selected_option: MetaColumnOptionOptionProps | null
  meta_selected_university: {
    university_name: string
    country: string
  } | null
  fetch_meta_options: boolean
}

const initialState: MetaState = {
  show_meta_option_modal: false,
  show_meta_add_course_modal: false,
  selected_meta_column: null,
  is_meta_option_update: false,
  selected_option: null,
  meta_selected_university: null,
  fetch_meta_options: false,
}

export const metaSlice = createSlice({
  name: 'metaSlice',
  initialState,
  reducers: {
    updateShowMetaOptionModal(state, action: PayloadAction<boolean>) {
      state.show_meta_option_modal = action.payload
    },
    updateShowMetaAddCourseModal(state, action: PayloadAction<boolean>) {
      state.show_meta_add_course_modal = action.payload
    },
    updateSelectedMetaColumn(state, action: PayloadAction<MetaColumnOptionsProps | null>) {
      state.selected_meta_column = action.payload
    },
    updateIsMetaOptionUpdate(state, action: PayloadAction<boolean>) {
      state.is_meta_option_update = action.payload
    },
    updateSelectedOption(state, action: PayloadAction<MetaColumnOptionOptionProps | null>) {
      state.selected_option = action.payload
    },
    updateMetaSelectedUniversity(
      state,
      action: PayloadAction<{
        university_name: string
        country: string
      } | null>
    ) {
      state.meta_selected_university = action.payload
    },
    updateFetchMetaOptions(state, action: PayloadAction<boolean>) {
      state.fetch_meta_options = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowMetaOptionModal,
  updateShowMetaAddCourseModal,
  updateSelectedMetaColumn,
  updateIsMetaOptionUpdate,
  updateSelectedOption,
  updateMetaSelectedUniversity,
  updateFetchMetaOptions,
} = metaSlice.actions

export default metaSlice.reducer
