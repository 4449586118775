import './FileUpload.css'
import React, { FC, HTMLAttributes, useEffect, useState } from 'react'
import styled from 'styled-components'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import Typography from '@/XelaReact/Typography/Typography'
import IconFileUpload from '@/XelaReact/FileUpload/IconFileUpload'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

export interface FileUploadProps extends HTMLAttributes<HTMLInputElement> {
  options?: DropzoneOptions
  type?: string
  name?: string
  placeholder?: string
  value: File | null
  width?: string
  height?: string
  bg?: string
  defaultTextColor?: string
  secondaryTextColor?: string
  defaultBorderColor?: string
  hoverColor?: string
  focusColor?: string
  errorColor?: string
  successColor?: string
  helperText?: string
  error?: boolean
  success?: boolean
  disabled?: boolean
  className?: string
  onChangeHandle?: (value: File) => void
  typeUploadText?: string
}

const FileUploadVStack = styled(VStack)<FileUploadComponentProps>`
  outline: ${(props) =>
    (props.isFocus ? '2px' : '1px') +
    ' solid ' +
    (props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.isFocus
      ? props.focusColor
      : props.isHover
      ? props.hoverColor
      : props.defaultBorderColor)};
  justify-content: center;
  align-items: center;
  fill: ${(props) => (props.error ? props.errorColor : props.success ? props.successColor : props.defaultTextColor)};
  stroke: ${(props) => (props.error ? props.errorColor : props.success ? props.successColor : props.defaultTextColor)};
  color: ${(props) => (props.error ? props.errorColor : props.success ? props.successColor : props.defaultTextColor)};

  ${(props) => props.disabled !== undefined && 'cursor: ' + (props.disabled ? 'initial' : 'text') + ';'}
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
`

interface FileUploadComponentProps extends FileUploadProps {
  isFocus?: boolean
  isHover?: boolean
}

export const FileUpload: FC<FileUploadProps> = ({
  options = {
    accept: {
      'image/*': [],
    },
  },
  value = null,
  width = '100%',
  height = '310px',
  bg = XelaColor.White,
  defaultTextColor = XelaColor.Gray2,
  secondaryTextColor = XelaColor.Gray7,
  defaultBorderColor = XelaColor.Gray11,
  focusColor = XelaColor.Blue3,
  errorColor = XelaColor.Red3,
  successColor = XelaColor.Green1,
  disabled = false,
  error = false,
  success = false,
  helperText,
  typeUploadText = 'SVG, PNG, JPG (max. 5 MB)',
}) => {
  const [isFocus, setFocus] = useState(false)
  const [stateFile, setDefaultValue] = useState<File | null>(value)

  const { getRootProps, getInputProps } = useDropzone(options)

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  return (
    <VStack
      width={width}
      spacing="8px"
      style={{
        opacity: disabled ? '0.4' : '1',
        height: '100%',
        padding: '2px',
      }}
    >
      <FileUploadVStack
        error={error}
        success={success}
        errorColor={errorColor}
        successColor={successColor}
        disabled={disabled}
        isFocus={isFocus}
        focusColor={focusColor}
        defaultBorderColor={defaultBorderColor}
        defaultTextColor={defaultTextColor}
        height={height}
        value={value}
        bg={bg}
        borderRadius="24px"
        className="xela-file-upload"
        onMouseEnter={() => {
          if (!disabled) {
            setFocus(true)
          }
        }}
        onMouseLeave={() => {
          if (!disabled) {
            setFocus(false)
          }
        }}
        style={{ flex: '1 1 auto' }}
      >
        <div
          style={{
            height: '100%',
            width: width,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <VStack height="100%" spacing="24px" alignItems="center" justifyContent="center">
            <VStack spacing="3px" alignItems="center" className={'p-4'}>
              {!stateFile && (
                <>
                  <IconFileUpload width={width == '200px' ? 41 : 21} height={height == '200px' ? 41 : 21} />
                  <Typography variant="body-small-bold" color={XelaColor.Gray7}>
                    <span style={{ color: XelaColor.Blue3 }}>Click to upload</span> or drag and drop
                  </Typography>
                  <Typography variant="body-small" color={XelaColor.Gray7}>
                    {typeUploadText}
                  </Typography>
                </>
              )}
              {stateFile && (
                <>
                  <Typography
                    variant="body-bold"
                    color={XelaColor.Blue3}
                    style={{ textAlign: 'center', wordBreak: 'break-all' }}
                  >
                    {stateFile.name}
                  </Typography>
                  <Typography variant="caption" color={XelaColor.Gray7}>
                    File has been uploaded successfully.
                  </Typography>
                </>
              )}
            </VStack>
          </VStack>
        </div>
      </FileUploadVStack>
      {helperText !== undefined && (
        <Typography as="p" color={error ? errorColor : success ? successColor : secondaryTextColor} variant="caption">
          <span dangerouslySetInnerHTML={{ __html: helperText }}></span>
        </Typography>
      )}
    </VStack>
  )
}

export default FileUpload
