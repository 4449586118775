export const XelaColor = {
  Gray1: '#0F0D15',
  Gray2: '#1D1929',
  Gray3: '#34303E',
  Gray4: '#4A4754',
  Gray5: '#615E69',
  Gray6: '#77757F',
  Gray7: '#8E8C94',
  Gray8: '#A5A3A9',
  Gray9: '#BBBABF',
  Gray10: '#D1D2D4',
  Gray11: '#E8E8EA',
  Gray12: '#F9F9F9',

  Blue1: '#0030CC',
  Blue2: '#0036E6',
  Blue3: '#003CFF',
  Blue4: '#1A50FF',
  Blue5: '#3363FF',
  Blue6: '#4D77FF',
  Blue7: '#668AFF',
  Blue8: '#809EFF',
  Blue9: '#99B1FF',
  Blue10: '#B3C5FF',
  Blue11: '#CCD8FF',
  Blue12: '#E6ECFF',

  Pink1: '#C80068',
  Pink2: '#E10075',
  Pink3: '#FA0082',
  Pink4: '#FB1A8F',
  Pink5: '#FB339B',
  Pink6: '#FC4DA8',
  Pink7: '#FC66B4',
  Pink8: '#FD80C1',
  Pink9: '#FD99CD',
  Pink10: '#FEB3DA',
  Pink11: '#FECCE6',
  Pink12: '#FFE6F3',

  Green1: '#18BA92',
  Green2: '#1BD2A4',
  Green3: '#1EE9B6',
  Green4: '#35EBBD',
  Green5: '#4BEDC5',
  Green6: '#62F0CC',
  Green7: '#78F2D3',
  Green8: '#8FF4DB',
  Green9: '#A5F6E2',
  Green10: '#BCF8E9',
  Green11: '#D2FBF0',
  Green12: '#E9FDF8',

  Yellow1: '#CCB243',
  Yellow2: '#E6C84C',
  Yellow3: '#FFDE54',
  Yellow4: '#FFE165',
  Yellow5: '#FFE576',
  Yellow6: '#FFE887',
  Yellow7: '#FFEB98',
  Yellow8: '#FFEFAA',
  Yellow9: '#FFF2BB',
  Yellow10: '#FFF5CC',
  Yellow11: '#FFF8DD',
  Yellow12: '#FFFCEE',

  Orange1: '#CC5801',
  Orange2: '#E66301',
  Orange3: '#FF6E01',
  Orange4: '#FF7D1A',
  Orange5: '#FF8B34',
  Orange6: '#FF9A4D',
  Orange7: '#FFA867',
  Orange8: '#FFB780',
  Orange9: '#FFC599',
  Orange10: '#FFD4B3',
  Orange11: '#FFE2CC',
  Orange12: '#FFF1E6',

  Red1: '#C32021',
  Red2: '#DC2425',
  Red3: '#F42829',
  Red4: '#F53E3E',
  Red5: '#F65354',
  Red6: '#F76969',
  Red7: '#F87E7F',
  Red8: '#FA9494',
  Red9: '#FBA9A9',
  Red10: '#FCBFBF',
  Red11: '#FDD4D4',
  Red12: '#FEEAEA',

  Purple1: '#AE59C6',
  Purple2: '#C364DF',
  Purple3: '#D96FF8',
  Purple4: '#DD7DF9',
  Purple5: '#E18CF9',
  Purple6: '#E49AFA',
  Purple7: '#E8A9FB',
  Purple8: '#ECB7FC',
  Purple9: '#F0C5FC',
  Purple10: '#F4D4FD',
  Purple11: '#F7E2FE',
  Purple12: '#FBF1FE',

  Violet1: '#9B3BCC',
  Violet2: '#A845DF',
  Violet3: '#B54FEF',
  Violet4: '#C05BFA',
  Violet5: '#CB68FB',
  Violet6: '#D576FC',
  Violet7: '#DF85FD',
  Violet8: '#E794FE',
  Violet9: '#EEA4FF',
  Violet10: '#F4B4FF',
  Violet11: '#F9C4FF',
  Violet12: '#FDE3FF',

  White: '#FFFFFF',
  Black: '#000000',
} as const

export type XelaColor = typeof XelaColor[keyof typeof XelaColor]

export const XelaGradient = (startColor: string, endColor: string, deg?: string): string => {
  if (deg === undefined) {
    deg = '45deg'
  }

  return 'linear-gradient(' + deg + ', ' + startColor + ' 8.13%, ' + endColor + ' 92.46%)'
}
