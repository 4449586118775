import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface LeadDrawer {
  show_lead_drawer: boolean
  show_visa_drawer: boolean
  selected_status: string | null
  selected_country: string | null
  selected_applied_via: string | null
}

const initialState: LeadDrawer = {
  show_lead_drawer: false,
  show_visa_drawer: false,
  selected_status: null,
  selected_country: null,
  selected_applied_via: null,
}

export const hubDashboardSlice = createSlice({
  name: 'hubDashboardSlice',
  initialState,
  reducers: {
    updateShowLeadDrawer(state, action: PayloadAction<boolean>) {
      state.show_lead_drawer = action.payload
    },
    updateShowVisaDrawer(state, action: PayloadAction<boolean>) {
      state.show_visa_drawer = action.payload
    },
    updateSelectedStatus(state, action: PayloadAction<string | null>) {
      state.selected_status = action.payload
    },
    updateSelectedCountry(state, action: PayloadAction<string | null>) {
      state.selected_country = action.payload
    },
    updateSelectedAppliedVia(state, action: PayloadAction<string | null>) {
      state.selected_applied_via = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowLeadDrawer,
  updateShowVisaDrawer,
  updateSelectedStatus,
  updateSelectedCountry,
  updateSelectedAppliedVia,
} = hubDashboardSlice.actions

export default hubDashboardSlice.reducer
