import React from 'react'
import { Button, Divider, HStack, Typography, VStack } from '@/XelaReact'
import { Box, Modal } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowDocumentViewer } from '@/Store/globalSlice'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { IconX } from '@tabler/icons-react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

const DocumentViewerModal = () => {
  const dispatch = useDispatch()
  const globalStore = useSelector((state: RootState) => state.global)

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={800}
      opened={globalStore.showDocumentViewer}
      onClose={() => {
        dispatch(updateShowDocumentViewer({ show: false }))
      }}
    >
      <VStack spacing="12px">
        <HStack justifyContent="space-between">
          <Typography variant={'subheadline'}>Document Viewer</Typography>
          <Box
            style={{
              padding: '4px',
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(updateShowDocumentViewer({ show: false }))
            }}
          >
            <IconX size={16} stroke={1.5}></IconX>
          </Box>
        </HStack>
        <HStack>
          <Divider variant={'dotted'}></Divider>
        </HStack>
        {globalStore.documentUrl && globalStore.documentMimeType === 'application/pdf' && (
          <HStack
            style={{
              padding: '12px',
              height: '100%',
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={globalStore.documentUrl} />
            </Worker>
          </HStack>
        )}
        {globalStore.documentUrl && globalStore.documentMimeType !== 'application/pdf' && (
          <HStack
            style={{
              padding: '12px',
              height: '100%',
            }}
          >
            <DocViewer
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
              }}
              pluginRenderers={DocViewerRenderers}
              documents={[
                {
                  uri: globalStore.documentUrl,
                  fileName: globalStore.documentName,
                },
              ]}
            />
          </HStack>
        )}
        <HStack>
          <Divider variant={'dotted'}></Divider>
        </HStack>
        <HStack spacing={'12px'} justifyContent="flex-end">
          <Button
            label={'Close'}
            onClick={() => {
              dispatch(updateShowDocumentViewer({ show: false }))
            }}
          ></Button>
        </HStack>
      </VStack>
    </Modal>
  )
}

export default DocumentViewerModal
