import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Course = App.Models.Course

export interface CourseState {
  filtersStatus: number
  show_course_add_modal: boolean
  show_course_edit_modal: boolean
  show_course_import_modal: boolean
  show_course_eligibility_modal: boolean
  country_filters: string[]
  old_country_filters: string[]
  course_level_filters: string[]
  old_course_level_filters: string[]
  university_filters: string[]
  old_university_filters: string[]
  course_intake_filters: string[]
  old_course_intake_filters: string[]
  apply_filter: boolean
  course: Course | null
}

const initialState: CourseState = {
  filtersStatus: -1,
  show_course_add_modal: false,
  show_course_edit_modal: false,
  show_course_import_modal: false,
  show_course_eligibility_modal: false,
  country_filters: [],
  old_country_filters: [],
  course_level_filters: [],
  old_course_level_filters: [],
  university_filters: [],
  old_university_filters: [],
  course_intake_filters: [],
  old_course_intake_filters: [],
  apply_filter: false,
  course: null,
}

export const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    clearFilters(state) {
      state.filtersStatus = 0
      state.country_filters = []
      state.old_country_filters = []
      state.course_level_filters = []
      state.old_course_level_filters = []
      state.university_filters = []
      state.old_university_filters = []
      state.course_intake_filters = []
      state.old_course_intake_filters = []
    },
    updateCurrentFilters(
      state,
      action: PayloadAction<{
        country_filters: string[]
        university_filters: string[]
        course_level_filters: string[]
        course_intake_filters: string[]
      }>
    ) {
      state.filtersStatus = 1
      if (action.payload.country_filters.length) {
        state.country_filters = action.payload.country_filters
      } else {
        state.country_filters = []
      }

      if (action.payload.university_filters.length) {
        state.university_filters = action.payload.university_filters
      } else {
        state.university_filters = []
      }

      if (action.payload.course_level_filters.length) {
        state.course_level_filters = action.payload.course_level_filters
      } else {
        state.course_level_filters = []
      }

      if (action.payload.course_intake_filters.length) {
        state.course_intake_filters = action.payload.course_intake_filters
      } else {
        state.course_intake_filters = []
      }
    },
    updateOldFilters(
      state,
      action: PayloadAction<{
        old_country_filters: string[]
        old_university_filters: string[]
        old_course_level_filters: string[]
        old_course_intake_filters: string[]
      }>
    ) {
      if (action.payload.old_country_filters.length) {
        state.old_country_filters = action.payload.old_country_filters
      } else {
        state.old_country_filters = []
      }

      if (action.payload.old_university_filters.length) {
        state.old_university_filters = action.payload.old_university_filters
      } else {
        state.old_university_filters = []
      }

      if (action.payload.old_course_level_filters.length) {
        state.old_course_level_filters = action.payload.old_course_level_filters
      } else {
        state.old_course_level_filters = []
      }

      if (action.payload.old_course_intake_filters.length) {
        state.old_course_intake_filters = action.payload.old_course_intake_filters
      } else {
        state.old_course_intake_filters = []
      }
    },
    updateCountryFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.country_filters = action.payload
      } else {
        state.country_filters = []
      }
    },
    updateOldCountryFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.old_country_filters = action.payload
      } else {
        state.old_country_filters = []
      }
    },
    updateUniversityFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.university_filters = action.payload
      } else {
        state.university_filters = []
      }
    },
    updateOldUniversityFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.old_university_filters = action.payload
      } else {
        state.old_university_filters = []
      }
    },
    updateCourseLevelFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.course_level_filters = action.payload
      } else {
        state.course_level_filters = []
      }
    },
    updateOldCourseLevelFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.old_course_level_filters = action.payload
      } else {
        state.old_course_level_filters = []
      }
    },
    updateCourseIntakeFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.course_intake_filters = action.payload
      } else {
        state.course_intake_filters = []
      }
    },
    updateOldCourseIntakeFilters(state, action: PayloadAction<string[]>) {
      if (action.payload.length) {
        state.old_course_intake_filters = action.payload
      } else {
        state.old_course_intake_filters = []
      }
    },
    updateShowCourseAddModal(state, action: PayloadAction<boolean>) {
      state.show_course_add_modal = action.payload
      if (action.payload) {
        state.filtersStatus = -1
      } else {
        state.filtersStatus = 2
      }
    },
    updateShowCourseEditModal(state, action: PayloadAction<boolean>) {
      state.show_course_edit_modal = action.payload
      if (action.payload) {
        state.filtersStatus = -1
      } else {
        state.filtersStatus = 3
      }
    },
    updateShowCourseImportModal(state, action: PayloadAction<boolean>) {
      state.show_course_import_modal = action.payload
      if (action.payload) {
        state.filtersStatus = -1
      } else {
        state.filtersStatus = 4
      }
    },
    updateShowCourseEligibilityModal(state, action: PayloadAction<boolean>) {
      state.show_course_eligibility_modal = action.payload
      if (action.payload) {
        state.filtersStatus = -1
      } else {
        state.filtersStatus = 5
      }
    },
    updateCourse(state, action: PayloadAction<Course | null>) {
      state.course = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  clearFilters,
  updateCountryFilters,
  updateOldCountryFilters,
  updateCourseLevelFilters,
  updateOldCourseLevelFilters,
  updateUniversityFilters,
  updateOldUniversityFilters,
  updateCourseIntakeFilters,
  updateOldCourseIntakeFilters,
  updateShowCourseAddModal,
  updateShowCourseEditModal,
  updateShowCourseImportModal,
  updateShowCourseEligibilityModal,
  updateCourse,
  updateCurrentFilters,
  updateOldFilters,
} = courseSlice.actions

export default courseSlice.reducer
