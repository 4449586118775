import React, { FC, ReactNode, useEffect, useState } from 'react'
import { createStyles, MantineSize, TextInput, TextInputProps } from '@mantine/core'
import { XelaColor } from '@/XelaReact'
import { IconX } from '@tabler/icons-react'

interface XInputProps {
  value: string | number | readonly string[] | undefined | null
  placeholder: string | undefined
  focused: boolean
  icon?: ReactNode
  size?: MantineSize
}

const useStyles = createStyles((theme, params: XInputProps) => ({
  root: {
    fontFamily: 'Nunito Sans',
    position: 'relative',
    width: '100%',
  },

  input: {
    fontSize: params.size === 'sm' ? '12px' : '14px',
    fontWeight: params.size === 'sm' ? 700 : 700,
    height: params.size === 'sm' ? '45px' : '56px',
    padding: params.icon
      ? params.size === 'sm'
        ? '18px 24px 2px 35px !important'
        : '26px 24px 6px 35px !important'
      : params.size === 'sm'
      ? '18px 24px 2px 16px !important'
      : '26px 24px 6px 24px !important',
    border: '1px solid #E8E8EA',
    borderRadius: '16px',
    '&:hover, &:focus': {
      padding: params.icon
        ? params.size === 'sm'
          ? '17px 23px 5px 34px !important'
          : '25px 23px 5px 34px !important'
        : params.size === 'sm'
        ? '17px 23px 1px 15px !important'
        : '25px 23px 5px 23px !important',
      border: '2px solid #003CFF',
    },
    '&:focus': {
      color: '#003CFF !important',
    },
    '&::placeholder': {
      color: XelaColor.Gray10,
      fontWeight: 400,
    },
  },

  label: {
    fontSize: params.focused || params.placeholder || params.value ? '10px' : '13px',
    lineHeight: '15px',
    fontWeight: 700,
    position: 'absolute',
    pointerEvents: 'none',
    paddingLeft: params.icon
      ? params.size == 'sm'
        ? '35px !important'
        : '35px !important'
      : params.size == 'sm'
      ? '18px !important'
      : '25px !important',
    transition: 'all 300ms ease',
    paddingTop: `${
      params.focused || params.placeholder || params.value
        ? params.size == 'sm'
          ? '6px !important'
          : '12px !important'
        : params.size == 'sm'
        ? '14px !important'
        : '20px !important'
    }`,
    zIndex: 1,
    color: XelaColor.Gray3,
    '&:hover, &:focus': {
      paddingLeft: params.icon ? '35px !important' : '25px !important',
    },
  },
}))

const XTextInput: FC<
  Omit<TextInputProps, 'value'> & {
    value?: string | number | readonly string[] | undefined | null
    onClear?: () => void
  }
> = (props) => {
  const [val, setVal] = useState<string | number | readonly string[] | undefined | null>('')

  const [focused, setFocused] = useState(false)

  const { classes } = useStyles({
    value: val,
    placeholder: props.placeholder,
    focused,
    icon: props.icon,
    size: props.size || 'md',
  })

  useEffect(() => {
    setVal(props.value)
  }, [props.value])

  const { onClear, ...rest } = props
  return (
    <TextInput
      {...rest}
      autoComplete={'off'}
      classNames={classes}
      label={props.label ? props.label : 'Input'}
      value={val || ''}
      onFocus={(event) => {
        if (!props.readOnly) {
          setFocused(true)
        }

        if (props.onFocus) {
          props.onFocus(event)
        }
      }}
      onBlur={(event) => {
        if (!props.readOnly) {
          setFocused(false)
        }

        if (props.onBlur) {
          props.onBlur(event)
        }
      }}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e)
        }

        setVal(e.target.value)
      }}
      {...(props.value && onClear
        ? {
            rightSection: (
              <IconX
                size={20}
                color={XelaColor.Gray7}
                onClick={() => {
                  setVal('')
                  if (onClear) {
                    onClear()
                  }
                }}
                style={{ cursor: 'pointer', padding: '4px' }}
              />
            ),
          }
        : {})}
    />
  )
}

export default XTextInput
