import React, { FC } from 'react'
import { Center, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { router, usePage } from '@inertiajs/react'
import { RootState } from '@/Store'
import { Box, UnstyledButton } from '@mantine/core'
import { toggleStudentSettingSidebar } from '@/Store/globalSlice'
import { IconBell, IconChevronLeft, IconQrcode, IconUser } from '@tabler/icons-react'

const StudentSettingSidebar: FC = () => {
  const page = usePage()
  const dispatch = useDispatch()
  const globalStore = useSelector((state: RootState) => state.global)

  const tabItems = []

  {
    // if (auth.role.permissions.includes('b2b_module_manage_course_settings')) {
    tabItems.push({
      label: 'Profile Details',
      route: '/settings/profile-details',
      icon: <IconUser width="18" height="18" color={XelaColor.Blue3} />,
    })
    // }

    tabItems.push({
      label: 'Notifications',
      route: '/settings/notifications',
      icon: <IconBell style={{ color: XelaColor.Blue5 }} height="18" width="18"></IconBell>,
    })
  }

  return (
    <motion.div
      className={`${
        globalStore.isStudentSettingSidebarOpen ? 'w-60 block' : 'hidden'
      } z-10 absolute bg-white min-h-screen top-0`}
      initial={{ opacity: 0.5, x: 0 }}
      animate={{ opacity: 1, x: globalStore.isSidebarOpen ? 230 : 65 }}
      transition={{ duration: 0.25 }}
    >
      <div>
        <VStack
          bg={XelaColor.White}
          spacing="12px"
          justifyContent="flex-start"
          height="100vh"
          className="shadow py-8 px-4 overflow-auto"
        >
          <HStack justifyContent={globalStore.isStudentSettingSidebarOpen ? 'space-between' : 'center'}>
            <VStack spacing="4px">
              <HStack justifyContent={globalStore.isStudentSettingSidebarOpen ? 'space-between' : 'center'}>
                <Typography variant={'subheadline'} color={XelaColor.Blue3}>
                  Settings
                </Typography>
                <UnstyledButton
                  className={'hover:bg-blue-50 p-1 rounded-full ease-in-out delay-50'}
                  onClick={() => {
                    dispatch(toggleStudentSettingSidebar(false))
                  }}
                >
                  <IconChevronLeft size={20} />
                </UnstyledButton>
              </HStack>
            </VStack>
          </HStack>
          <Divider variant="dotted" />
          <VStack spacing="8px">
            {tabItems.map((item, index) => (
              <Box
                key={index}
                onClick={() => {
                  dispatch(toggleStudentSettingSidebar(!globalStore.isStudentSettingSidebarOpen))
                  router.visit(`${item.route}`)
                }}
                style={
                  page.url === item.route
                    ? {
                        padding: '8px',
                        backgroundColor: XelaColor.Blue12,
                        borderRadius: '12px',
                        width: '100%',
                      }
                    : {
                        padding: '8px',
                      }
                }
                className="flex space-x-4 w-full px-2 py-3 cursor-pointer hover:bg-blue-12 rounded-lg"
              >
                <Center width="18px" height="18px" style={{ minWidth: '24px' }}>
                  {item.icon}
                </Center>
                <Box className="flex justify-between w-full">
                  <Typography variant="body-small-bold">{item.label}</Typography>
                </Box>
              </Box>
            ))}
          </VStack>
        </VStack>
      </div>
    </motion.div>
  )
}

export default StudentSettingSidebar
