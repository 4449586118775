import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

export class LineDataset {
  label: string
  data: Array<number>
  borderColor: string
  fill: boolean
  backgroundColor: string
  tension: number
  pointBorderColor: string
  pointBorderWidth: number
  pointBackgroundColor: string
  pointRadius: number

  constructor(
    label: string,
    data: Array<number>,
    borderColor: string,
    fill: boolean,
    backgroundColor: string,
    tension = 0,
    pointBorderColor: string,
    pointBackgroundColor: string = XelaColor.White,
    pointBorderWidth = 2,
    pointRadius = 6
  ) {
    this.label = label
    this.data = data
    this.borderColor = borderColor
    this.fill = fill
    this.backgroundColor = backgroundColor
    this.tension = tension
    this.pointBorderColor = pointBorderColor
    this.pointBorderWidth = pointBorderWidth
    this.pointBackgroundColor = pointBackgroundColor
    this.pointRadius = pointRadius
  }
}

export interface LineChartProps extends HTMLAttributes<HTMLDivElement> {
  labels: Array<string>
  datasets: Array<LineDataset>
  padding?: number
  yLabelsColor?: string
  yLabelsFontSize?: number
  yLabelsFontWeight?: string
  yBeforeLabelsText?: string
  yAfterLabelsText?: string
  xLabelsColor?: string
  xLabelsFontSize?: number
  xLabelsFontWeight?: string
  xBeforeLabelsText?: string
  xAfterLabelsText?: string
  stepSize?: number
  gridColor?: string
  width?: string
  yMinLabel?: number
}

const LineChartComponent = styled.div<{ width?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`

export const LineChart: FC<LineChartProps> = ({
  labels,
  datasets,
  padding = 16,
  yLabelsColor = XelaColor.Gray7,
  yLabelsFontSize = 12,
  yLabelsFontWeight = '600',
  yBeforeLabelsText = '',
  yAfterLabelsText = '',
  xLabelsColor = XelaColor.Gray7,
  xLabelsFontSize = 12,
  xLabelsFontWeight = '600',
  xBeforeLabelsText = '',
  xAfterLabelsText = '',
  stepSize = 10,
  gridColor = XelaColor.Gray12,
  yMinLabel = 0,
  width,
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: padding,
    },
    scales: {
      y: {
        // not 'yAxes: [{' anymore (not an array anymore)
        min: yMinLabel,
        grid: {
          color: gridColor,
          borderColor: gridColor,
        },
        ticks: {
          callback: function (
            value: string | number,
            _index: number,
            _values: any
          ) {
            return yBeforeLabelsText + value + yAfterLabelsText
          },
          color: yLabelsColor, // not 'fontColor:' anymore
          // fontSize: 18,
          font: {
            size: yLabelsFontSize, // 'size' now within object 'font {}'
            weight: yLabelsFontWeight,
          },
          stepSize: stepSize,
        },
      },
      x: {
        // not 'xAxes: [{' anymore (not an array anymore)
        grid: {
          color: gridColor,
          borderColor: gridColor,
        },
        ticks: {
          callback: function (
            value: string | number,
            _index: number,
            _values: any
          ) {
            return (
              xBeforeLabelsText + labels[value as number] + xAfterLabelsText
            )
          },
          color: xLabelsColor, // not 'fontColor:' anymore
          //fontSize: 14,
          font: {
            size: xLabelsFontSize, // 'size' now within object 'font {}'
            weight: xLabelsFontWeight,
          },
        },
      },
    },
  }

  const data = {
    labels,
    datasets: datasets,
  }

  return (
    <LineChartComponent width={width}>
      <Line options={options} data={data} />
    </LineChartComponent>
  )
}

export default LineChart
