import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Lead = App.Models.Lead
import VisitorLead = App.Models.VisitorLead
import IeltsLead = App.Models.IeltsLead
import InsuranceLead = App.Models.InsuranceLead

export interface TaskState {
  is_modal_open: boolean
  is_task_modal_open: boolean
  selected_task_lead: Lead | null
  selected_task_visitor_lead: VisitorLead | null
  selected_task_ielts_lead: IeltsLead | null
  selected_task_insurance_lead: InsuranceLead | null
  task_entity: string | null
  task_entity_id: number | null
  is_edit_task_modal_open: boolean
  selected_task: App.Models.Task | null
  selected_task_id: number | null
}

const initialState: TaskState = {
  is_modal_open: false,
  is_task_modal_open: false,
  selected_task_lead: null,
  selected_task_visitor_lead: null,
  selected_task_ielts_lead: null,
  selected_task_insurance_lead: null,
  task_entity: null,
  task_entity_id: null,
  is_edit_task_modal_open: false,
  selected_task: null,
  selected_task_id: null,
}

export const taskSlice = createSlice({
  name: 'taskSlice',
  initialState,
  reducers: {
    updateIsModalOpen(state, action: PayloadAction<boolean>) {
      state.is_modal_open = action.payload
    },
    updateIsTaskModalOpen(state, action: PayloadAction<boolean>) {
      state.is_task_modal_open = action.payload
    },
    updateSelectedTaskLead(state, action: PayloadAction<Lead | null>) {
      state.selected_task_lead = action.payload
    },
    updateSelectedTaskVisitorLead(state, action: PayloadAction<VisitorLead | null>) {
      state.selected_task_visitor_lead = action.payload
    },
    updateSelectedTaskIeltsLead(state, action: PayloadAction<IeltsLead | null>) {
      state.selected_task_ielts_lead = action.payload
    },
    updateSelectedTaskInsuranceLead(state, action: PayloadAction<InsuranceLead | null>) {
      state.selected_task_insurance_lead = action.payload
    },
    updateTaskEntity(state, action: PayloadAction<string | null>) {
      state.task_entity = action.payload
    },
    updateTaskEntityId(state, action: PayloadAction<number | null>) {
      state.task_entity_id = action.payload
    },
    updateIsEditTaskModalOpen(state, action: PayloadAction<boolean>) {
      state.is_edit_task_modal_open = action.payload
    },
    updateSelectedTask(state, action: PayloadAction<App.Models.Task | null>) {
      state.selected_task = action.payload
    },
    updateSelectedTaskId(state, action: PayloadAction<number | null>) {
      state.selected_task_id = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateIsModalOpen,
  updateIsTaskModalOpen,
  updateSelectedTaskLead,
  updateSelectedTaskVisitorLead,
  updateSelectedTaskIeltsLead,
  updateSelectedTaskInsuranceLead,
  updateTaskEntity,
  updateTaskEntityId,
  updateIsEditTaskModalOpen,
  updateSelectedTask,
  updateSelectedTaskId,
} = taskSlice.actions

export default taskSlice.reducer
