import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Chip, Drawer, Group, LoadingOverlay } from '@mantine/core'
import { useForm } from '@inertiajs/react'
import XMultiSelect from '@/Mantine/XMultiSelect'
import XTextInput from '@/Mantine/XTextInput'
import useMeta from '@/Hooks/useMeta'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import {
  updateIsEditTaskModalOpen,
  updateSelectedTaskLead,
  updateTaskEntity,
  updateTaskEntityId,
} from '@/Store/taskSlice'
import { AttachmentType } from '@/Types/TemplateTypes'
import ReactRedactorX from '@/RedactorX/ReactRedactorX'
import moment from 'moment-timezone'
import XDateTimePicker from '@/Mantine/XDateTimePicker'
import User = App.Models.User
import Lead = App.Models.Lead

const getDueDate = (due_date_time: string, custom_due_date_time: string) => {
  switch (due_date_time) {
    case 'today':
      return moment().set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'tomorrow':
      return moment().add(1, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'day_after_tomorrow':
      return moment().add(2, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'next_week':
      return moment().add(1, 'weeks').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'next_month':
      return moment().add(1, 'months').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'custom':
      if (custom_due_date_time === 'tomorrow') {
        return moment().add(1, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
      } else {
        return moment(custom_due_date_time, 'YYYY-MM-DD HH:mm:ss').toDate()
      }
  }
}

const EditTaskModal: FC = () => {
  const meta = useMeta()
  const dispatch = useDispatch()
  const taskStore = useSelector((state: RootState) => state.task)

  const lead = taskStore.selected_task_lead !== null ? taskStore.selected_task_lead : null
  const entity = taskStore.task_entity !== null ? taskStore.task_entity : null
  const entityId = taskStore.task_entity_id !== null ? taskStore.task_entity_id : null
  const [loading, setLoading] = useState(false)

  const [description, setDescription] = useState('')
  const [attachments, setAttachments] = useState<AttachmentType[]>([])

  const [counsellors, setCounsellors] = useState<{ value: string; label: string; group: string }[]>([])

  const { data, setData, post, reset, errors, clearErrors, recentlySuccessful } = useForm<{
    lead_id: number | null
    entity: string | null
    entity_id: number | null
    task: string
    description: string
    assignees: string[]
    due_date_time: string
    custom_due_date_time: string
    attachments: AttachmentType[]
  }>({
    lead_id: lead !== null ? lead.id : null,
    entity: entity !== null ? entity : null,
    entity_id: entityId !== null ? entityId : null,
    task: '',
    description: '',
    assignees: [],
    due_date_time: 'tomorrow',
    custom_due_date_time: 'tomorrow',
    attachments: [],
  })

  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        lead_id: lead ? lead.id : null,
        entity: entity ? entity : null,
        entity_id: entityId ? entityId : null,
      }
    })
  }, [lead, entity, entityId])

  const formHandler = (name: string, value: string | string[] | AttachmentType[]) => {
    setData((prev) => {
      const datum: { [key: string]: string | string[] | AttachmentType[] } = {}
      datum[name] = value

      return {
        ...prev,
        ...datum,
      }
    })
  }

  const closeHandler = () => {
    dispatch(updateSelectedTaskLead(null))
    dispatch(updateTaskEntity(null))
    dispatch(updateTaskEntityId(null))
    dispatch(updateIsEditTaskModalOpen(false))

    clearErrors()
    reset()
  }

  useEffect(() => {
    if (recentlySuccessful) {
      closeHandler()
    }
  }, [recentlySuccessful])

  const isCounsellorEnabled = (counsellor: User, lead: Lead): boolean => {
    // Check if counsellor has task management permission
    if (!counsellor.role.permissions.includes('task_module_manage_tasks')) {
      return false
    }

    const permissions = counsellor.role.permissions
    const modules = ['lead', 'counselling', 'admission', 'visa']

    for (const module of modules) {
      // Check for 'all' permission
      if (permissions.includes(`${module}_module_all_leads`)) {
        return true
      }

      // Check for 'branch' permission
      if (permissions.includes(`${module}_module_branch_leads`) && counsellor.branch_id === lead.branch_id) {
        return true
      }

      // Check for 'manage' permission
      if (permissions.includes(`${module}_module_manage_lead`) && counsellor.branch_id === lead.branch_id) {
        switch (module) {
          case 'lead':
            if (counsellor.id === lead.counsellor_id) {
              return true
            }
            break
          case 'counselling':
            if (lead.counselling && counsellor.id === lead.counselling.counsellor_id) {
              return true
            }
            break
          case 'admission':
            if (lead.admission) {
              const admissionManagerIds = [
                lead.admission.admission_manager_id,
                lead.admission.admission_manager_id_one,
                lead.admission.admission_manager_id_two,
                lead.admission.admission_manager_id_three,
                lead.admission.admission_manager_id_four,
                lead.admission.admission_manager_id_five,
              ].filter((id): id is number => id !== undefined)
              if (admissionManagerIds.includes(counsellor.id)) {
                return true
              }
            }
            break
          case 'visa':
            if (lead.visa) {
              const visaManagerIds = [
                lead.visa.visa_manager_id,
                lead.visa.visa_manager_id_one,
                lead.visa.visa_manager_id_two,
                lead.visa.visa_manager_id_three,
              ].filter((id): id is number => id !== undefined)
              if (visaManagerIds.includes(counsellor.id)) {
                return true
              }
            }
            break
        }
      }
    }

    return false
  }

  useEffect(() => {
    if (lead) {
      const taskCounsellors = meta.branchesUsers[lead.branch_id].users.map((counsellor) => ({
        value: counsellor.id.toString(),
        label: counsellor.name,
        group: counsellor.branch.branch_name,
        role: counsellor.role.role_name,
        disabled: !isCounsellorEnabled(counsellor, lead),
      }))

      setCounsellors(taskCounsellors)
    } else {
      // If there's no lead, we'll show all counsellors but disable those without task management permission
      setCounsellors(
        meta.counsellors.map((counsellor) => ({
          value: counsellor.id.toString(),
          label: counsellor.name,
          group: counsellor.branch.branch_name,
          role: counsellor.role.role_name,
          disabled: !counsellor.role.permissions.includes('task_module_manage_tasks'),
        }))
      )
    }
  }, [lead])

  useEffect(() => {
    if (taskStore.is_edit_task_modal_open && taskStore.selected_task) {
      const task = taskStore.selected_task

      setData((prev) => ({
        ...prev,
        task: task.task,
        assignees: task.assignees.map((assignee) => assignee.id.toString()),
      }))

      const localDueDateTime = moment.utc(task.due_date_time).tz(meta.timezone)

      if (localDueDateTime.clone().add(1, 'days').isSame(moment(), 'day')) {
        setData((prev) => ({
          ...prev,
          due_date_time: 'tomorrow',
          custom_due_date_time: 'tomorrow',
        }))
      } else if (localDueDateTime.isSame(moment(), 'day')) {
        setData((prev) => ({
          ...prev,
          due_date_time: 'today',
          custom_due_date_time: 'today',
        }))
      } else if (localDueDateTime.clone().subtract(1, 'days').isSame(moment(), 'day')) {
        setData((prev) => ({
          ...prev,
          due_date_time: 'day_after_tomorrow',
          custom_due_date_time: 'day_after_tomorrow',
        }))
      } else if (localDueDateTime.clone().subtract(7, 'days').isSame(moment(), 'day')) {
        setData((prev) => ({
          ...prev,
          due_date_time: 'next_week',
          custom_due_date_time: 'next_week',
        }))
      } else if (localDueDateTime.clone().subtract(30, 'days').isSame(moment(), 'day')) {
        setData((prev) => ({
          ...prev,
          due_date_time: 'next_month',
          custom_due_date_time: 'next_month',
        }))
      } else {
        setData((prev) => ({
          ...prev,
          due_date_time: 'custom',
          custom_due_date_time: localDueDateTime.format('YYYY-MM-DD HH:mm:ss'),
        }))
      }

      if (task.description) {
        setDescription(task.description)
      }

      setAttachments(task.documents)
    }
  }, [taskStore.is_edit_task_modal_open, taskStore.selected_task])

  return (
    <Drawer
      size={700}
      position={'right'}
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      opened={taskStore.is_edit_task_modal_open}
      onClose={() => {
        closeHandler()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="8px" height="100%">
        <HStack>
          <Typography variant="subheadline">Edit Task</Typography>
        </HStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        <VStack spacing="12px" style={{ position: 'relative' }} height="100%">
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack spacing="12px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto', paddingRight: '10px' }}>
            <HStack spacing="12px">
              <XTextInput
                label="Task"
                error={errors.task}
                placeholder="Add a task here..."
                value={data.task}
                onChange={(e) => {
                  formHandler('task', e.target.value)
                }}
              ></XTextInput>
            </HStack>
            <HStack>
              <ReactRedactorX
                height={'100px'}
                entity={'tasks'}
                error={errors.description}
                defaultAttachments={attachments}
                defaultValue={description}
                onChange={(content) => {
                  if (content.length > 0 && content !== '<p></p>') {
                    formHandler('description', content)
                  } else {
                    formHandler('description', '')
                  }
                }}
                onFileUpload={(attachments) => {
                  formHandler('attachments', attachments)
                }}
              ></ReactRedactorX>
            </HStack>
            <HStack>
              <XMultiSelect
                searchable
                styles={{
                  input: {
                    minHeight: '120px',
                  },
                  values: {
                    maxHeight: '100px',
                    overflowY: 'auto',
                  },
                }}
                value={data.assignees}
                data={counsellors}
                label="Select Assignees"
                onChange={(value) => {
                  if (value) {
                    formHandler('assignees', value)
                  }
                }}
                error={errors.assignees}
              />
            </HStack>
            <VStack spacing="4px" justifyContent="flex-end">
              <Typography variant="caption" color={XelaColor.Gray6} noWrap={true}>
                Due By
              </Typography>
              <HStack>
                <HStack>
                  <Chip.Group
                    value={data.due_date_time}
                    multiple={false}
                    onChange={(value) => {
                      if (value) {
                        formHandler('due_date_time', value)
                        if (value === 'custom') {
                          formHandler('custom_due_date_time', 'tomorrow')
                        }
                      }
                    }}
                  >
                    <Group spacing={8}>
                      <Chip value="today" size="sm" color="blueX" variant="outline">
                        Today
                      </Chip>
                      <Chip value="tomorrow" size="sm" color="blueX" variant="outline">
                        Tomorrow
                      </Chip>
                      <Chip value="day_after_tomorrow" size="sm" color="blueX" variant="outline">
                        Day After Tomorrow
                      </Chip>
                      <Chip value="next_week" size="sm" color="blueX" variant="outline">
                        Next Week
                      </Chip>
                      <Chip value="next_month" size="sm" color="blueX" variant="outline">
                        Next Month
                      </Chip>
                      <Chip value="custom" size="sm" color="blueX" variant="outline">
                        Custom
                      </Chip>
                    </Group>
                  </Chip.Group>
                </HStack>
                <HStack
                  style={{
                    width: '50%',
                  }}
                >
                  <XDateTimePicker
                    clearable
                    value={getDueDate(data.due_date_time, data.custom_due_date_time)}
                    valueFormat={'DD/MM/YYYY HH:mm:ss'}
                    label="Select Due Date"
                    placeholder="Due Date"
                    onChange={(value) => {
                      if (value) {
                        formHandler(
                          'custom_due_date_time',
                          moment(value).startOf('minute').format('YYYY-MM-DD HH:mm:ss')
                        )
                        formHandler('due_date_time', 'custom')
                      }
                    }}
                  />
                </HStack>
              </HStack>
            </VStack>
          </VStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack spacing="12px" justifyContent="flex-end">
            <Button
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                closeHandler()
              }}
            />
            <Button
              label={'Update'}
              onClick={() => {
                setLoading(true)
                if (taskStore.selected_task) {
                  post(route('tasks.edit', { id: taskStore.selected_task.id }), {
                    preserveState: true,
                    preserveScroll: true,
                    onFinish: () => {
                      setLoading(false)
                    },
                  })
                }
              }}
            ></Button>
          </HStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default EditTaskModal
