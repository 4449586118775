import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const CounsellingCreatedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification, portal = 'Agency' }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        router.visit(route('counsellings.id', { id: notification.data.counselling_id }))
      }}
    >
      <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        created a new Counselling
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Orange3}>
        #{notification.data.lead_name}
      </Typography>
    </HStack>
  )
}

export default CounsellingCreatedNotification
