import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Announcement = App.Models.Announcement

export interface AnnouncementState {
  show_announcement_drawer: boolean
  selected_announcement: Announcement | null
  announcement_to_update: Announcement | null
  is_announcement_update: boolean
  show_announcement_analytics_drawer: boolean
  show_view_announcement_drawer: boolean
  selected_view_announcement: Announcement | null
}

const initialState: AnnouncementState = {
  show_announcement_drawer: false,
  show_announcement_analytics_drawer: false,
  selected_announcement: null,
  announcement_to_update: null,
  is_announcement_update: false,
  show_view_announcement_drawer: false,
  selected_view_announcement: null,
}

export const announcementSlice = createSlice({
  name: 'announcementSlice',
  initialState,
  reducers: {
    updateShowAnnouncementDrawer: (state, action: PayloadAction<boolean>) => {
      state.show_announcement_drawer = action.payload
    },
    updateSelectedAnnouncement: (state, action: PayloadAction<Announcement | null>) => {
      state.selected_announcement = action.payload
    },
    UpdateAnnouncementToUpdate: (state, action: PayloadAction<Announcement | null>) => {
      state.announcement_to_update = action.payload
    },
    updateIsAnnouncementUpdate: (state, action: PayloadAction<boolean>) => {
      state.is_announcement_update = action.payload
    },
    updateShowAnnouncementAnalyticsDrawer: (state, action: PayloadAction<boolean>) => {
      state.show_announcement_analytics_drawer = action.payload
    },
    updateShowViewAnnouncementDrawer: (
      state,
      action: PayloadAction<{
        show: boolean
        announcement: Announcement | null
      }>
    ) => {
      state.show_view_announcement_drawer = action.payload.show
      state.selected_view_announcement = action.payload.announcement
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowAnnouncementDrawer,
  updateSelectedAnnouncement,
  UpdateAnnouncementToUpdate,
  updateIsAnnouncementUpdate,
  updateShowAnnouncementAnalyticsDrawer,
  updateShowViewAnnouncementDrawer,
} = announcementSlice.actions

export default announcementSlice.reducer
