import * as React from 'react'

function IconPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.5 1v13M1 7.5h13" stroke="#003CFF" />
    </svg>
  )
}

export default IconPlus
