import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Lead = App.Models.Lead

export interface HubLeadState {
  hub_lead_id?: number
  hub_lead?: Lead | undefined
  show_visa_decision_modal: boolean
  show_hub_add_lead_modal: boolean
  lead_form_submitted: boolean
  lead_documents_uploaded: boolean
}

const initialState: HubLeadState = {
  hub_lead_id: undefined,
  hub_lead: undefined,
  show_visa_decision_modal: false,
  show_hub_add_lead_modal: false,
  lead_form_submitted: false,
  lead_documents_uploaded: false,
}

export const hubLeadSlice = createSlice({
  name: 'hubLeadSlice',
  initialState,
  reducers: {
    updateHubLeadId(state, action: PayloadAction<number | undefined>) {
      state.hub_lead_id = action.payload
    },
    updateHubLead(state, action: PayloadAction<Lead | undefined>) {
      state.hub_lead = action.payload
    },
    updateShowVisaDecisionModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_visa_decision_modal = action.payload
      } else {
        state.hub_lead = undefined
        state.show_visa_decision_modal = false
      }
    },
    updateShowHubAddLeadModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_hub_add_lead_modal = true
      } else {
        state.hub_lead_id = undefined
        state.show_hub_add_lead_modal = false
        state.lead_form_submitted = false
        state.lead_documents_uploaded = false
      }
    },
    updateLeadFormSubmitted(state, action: PayloadAction<boolean>) {
      state.lead_form_submitted = action.payload
    },
    updateLeadDocumentsUploaded(state, action: PayloadAction<boolean>) {
      state.lead_documents_uploaded = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateHubLeadId,
  updateHubLead,
  updateShowVisaDecisionModal,
  updateShowHubAddLeadModal,
  updateLeadFormSubmitted,
  updateLeadDocumentsUploaded,
} = hubLeadSlice.actions

export default hubLeadSlice.reducer
