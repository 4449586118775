import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const IeltsLeadTransitionNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification, portal = 'Agency' }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        router.visit(route('ielts-leads.id', { id: notification.data.lead_id }))
      }}
    >
      <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        changed Coaching Lead Status from
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {notification.data.old}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        to
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {notification.data.new}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        for Coaching Lead
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Orange3}>
        #{notification.data.lead_name}
      </Typography>
    </HStack>
  )
}

export default IeltsLeadTransitionNotification
