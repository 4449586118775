import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface LeadDrawer {
  show_lead_drawer: boolean
  show_admission_application_drawer: boolean
  show_visa_drawer: boolean
  show_agent_drawer: boolean
  selected_status: string | null
  selected_agent: string | number | null | undefined
  selected_country: string | null
  selected_applied_via: string | null
}

const initialState: LeadDrawer = {
  show_lead_drawer: false,
  show_admission_application_drawer: false,
  show_visa_drawer: false,
  show_agent_drawer: false,
  selected_status: null,
  selected_agent: null,
  selected_country: null,
  selected_applied_via: null,
}

export const b2bDashboardSlice = createSlice({
  name: 'b2bDashboardSlice',
  initialState,
  reducers: {
    updateShowLeadDrawer(state, action: PayloadAction<boolean>) {
      state.show_lead_drawer = action.payload
    },
    updateShowAdmissionApplicationDrawer(state, action: PayloadAction<boolean>) {
      state.show_admission_application_drawer = action.payload
    },
    updateShowVisaDrawer(state, action: PayloadAction<boolean>) {
      state.show_visa_drawer = action.payload
    },
    updateShowAgentDrawer(state, action: PayloadAction<boolean>) {
      state.show_agent_drawer = action.payload
    },
    updateSelectedStatus(state, action: PayloadAction<string | null>) {
      state.selected_status = action.payload
    },
    updateSelectedCountry(state, action: PayloadAction<string | null>) {
      state.selected_country = action.payload
    },
    updateSelectedAppliedVia(state, action: PayloadAction<string | null>) {
      state.selected_applied_via = action.payload
    },
    updateSelectedAgent(state, action: PayloadAction<string | number | null | undefined>) {
      state.selected_agent = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowLeadDrawer,
  updateShowAgentDrawer,
  updateShowAdmissionApplicationDrawer,
  updateShowVisaDrawer,
  updateSelectedStatus,
  updateSelectedCountry,
  updateSelectedAgent,
  updateSelectedAppliedVia,
} = b2bDashboardSlice.actions

export default b2bDashboardSlice.reducer
