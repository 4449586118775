import React, { FC, HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface ListProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactNode | ReactNode[];
  as?: 'ul' | 'ol';
  listStyleType?: string;
}

const ListComponent = styled.ul<{ as: 'ul' | 'ol'; listStyleType: string }>`
  margin: 0;
  padding-inline-start: 30px;

  ${(props) =>
    props.as == 'ul' &&
    css`
      list-style-type: ${props.listStyleType};
    `}
  ${(props) =>
    props.as == 'ol' &&
    css`
      list-style-type: decimal;

      & > li > ol {
        list-style-type: lower-latin;
      }

      & > li > ol > li > ol {
        list-style-type: lower-roman;
      }

      & > li > ol > li > ol > li > ol {
        list-style-type: decimal;
      }
    `}
  & > ol {
    list-style-position: inside;
    padding: 0;
    list-style-type: decimal;
  }

  & > ol > li > ol {
    list-style-type: lower-latin;
    padding-left: 20px;
  }

  & > ol > li > ol > li > ol {
    list-style-type: lower-roman;
  }

  & > ol > li > ol > li > ol > li > ol {
    list-style-type: decimal;
  }

  & > ol > li > ol > li > ol > li > ol > li > ol {
    list-style-type: lower-latin;
  }
`;

export const List: FC<ListProps> = ({
  children,
  as = 'ul',
  listStyleType = 'disc',
  ...props
}) => {
  return (
    <ListComponent as={as} listStyleType={listStyleType} {...props}>
      {children}
    </ListComponent>
  );
};

export interface ListItemProps extends HTMLAttributes<HTMLLIElement> {
  children: ReactNode | ReactNode[];
}

const ListItemComponent = styled.li`
  &::marker {
    font-size: 14px;
    line-height: 16px;
  }

  & ul {
    padding-left: 20px;
  }
`;

export const ListItem: FC<ListItemProps> = ({ children, ...props }) => {
  return <ListItemComponent {...props}>{children}</ListItemComponent>;
};

export default List;
