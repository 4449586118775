import React, { useEffect } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import useAuth from '@/Hooks/useAuth'
import { IconX } from '@tabler/icons-react'

interface CallPopUpModalProps {
  lead_id: string
  lead_name: string
  lead_phone: string
}

const audioElement = new Audio('/audios/call.mp3')

const CallPopUpModal = () => {
  const auth = useAuth()
  const [opened, { open, close }] = useDisclosure(false)
  const [data, setData] = React.useState<CallPopUpModalProps | null>(null)

  const playAudio = () => {
    audioElement.volume = 0.5
    audioElement.play()
    setTimeout(() => {
      audioElement.pause()
      audioElement.currentTime = 0
      close()
    }, 10000)
  }

  useEffect(() => {
    window.Echo.private(`App.Models.User.${auth.user.id}`).listen('.call.incoming', (data: CallPopUpModalProps) => {
      if (document.visibilityState === 'hidden') {
        return
      }

      playAudio()
      setData(data)
      open()
    })

    return () => {
      window.Echo.private(`App.Models.User.${auth.user.id}`).stopListening('.call.incoming')
    }
  }, [])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={true}
      closeOnClickOutside={true}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={300}
      opened={opened}
      onClose={close}
    >
      <VStack
        spacing="16px"
        justifyContent={'center'}
        alignItems={'center'}
        style={{ padding: '16px', position: 'relative' }}
      >
        <Box
          style={{
            top: 5,
            right: 5,
            position: 'absolute',
            cursor: 'pointer',
          }}
          onClick={close}
        >
          <IconX color={XelaColor.Gray3} size={20} />
        </Box>
        <VStack justifyContent={'center'} alignItems={'center'}>
          <Typography variant={'body-bold'} style={{ fontWeight: 800 }} onClick={close}>
            {data?.lead_name}
          </Typography>
          <Typography variant={'body-small-bold'} color={XelaColor.Blue3}>
            {data?.lead_phone}
          </Typography>
          <Typography variant={'body-small'} style={{ fontWeight: 600 }}>
            Incoming IVR Call
          </Typography>
        </VStack>
        <HStack justifyContent={'center'}>
          <img
            src="/images/call_avatar.png"
            alt="call_avatar"
            style={{
              height: '88px',
            }}
          />
        </HStack>
        <HStack justifyContent={'center'}>
          <Typography variant={'body-small-bold'} style={{ fontWeight: 700 }}>
            Pick-Up call on your IVR Device/Phone
          </Typography>
        </HStack>
      </VStack>
    </Modal>
  )
}

export default CallPopUpModal
