import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import InsuranceCompanyInvoice = App.Models.InsuranceCompanyInvoice
import InsuranceOrder = App.Models.InsuranceOrder

export interface InvoiceState {
  total_invoices: number
  is_filter_applied: boolean
  is_invoice_edited: boolean
  is_selected_all: boolean
  is_invoice_need_to_refresh: boolean
  selected_invoice: InsuranceCompanyInvoice | null
  invoice_filters: {
    search: string
    status_type: string
    status_value: string
  }
  selected_invoices_id: number[]
  selected_orders: InsuranceOrder[]
  selected_status: string | null
}

const initialState: InvoiceState = {
  total_invoices: 0,
  is_filter_applied: false,
  is_invoice_edited: false,
  is_selected_all: false,
  is_invoice_need_to_refresh: false,
  selected_invoice: null,
  invoice_filters: {
    search: '',
    status_type: 'All',
    status_value: 'All',
  },
  selected_invoices_id: [],
  selected_orders: [],
  selected_status: '',
}

export const adminInsuranceCompanyInvoiceSlice = createSlice({
  name: 'adminInsuranceCompanyInvoiceSlice',
  initialState,
  reducers: {
    updateTotalAdminInsuranceCompanyInvoices(state, action: PayloadAction<number>) {
      state.total_invoices = action.payload
    },
    updateEditAdminInsuranceCompanyInvoice(
      state,
      action: PayloadAction<{
        is_invoice_edited: boolean
        selected_invoice: InsuranceCompanyInvoice | null
        selected_orders: InsuranceOrder[]
        selected_status: string | null
      }>
    ) {
      state.is_invoice_edited = action.payload.is_invoice_edited
      state.selected_invoice = action.payload.selected_invoice
      state.selected_orders = action.payload.selected_orders
      state.selected_status = action.payload.selected_status
    },
    updateIsAdminInsuranceCompanyInvoiceNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_invoice_need_to_refresh = action.payload
    },
    updateSelectedAllAdminInsuranceCompanyInvoices(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateAdminInsuranceCompanyInvoiceFilters(
      state,
      action: PayloadAction<{
        search: string
        status_type: string
        status_value: string
      }>
    ) {
      state.invoice_filters = action.payload
    },
    clearAdminInsuranceCompanyInvoiceFilters(state) {
      state.invoice_filters = {
        search: '',
        status_type: 'All',
        status_value: 'All',
      }
    },
    updateSelectedInsuranceCompanyInvoicesId: (state, action) => {
      state.selected_invoices_id = action.payload
    },
  },
})

export const {
  updateEditAdminInsuranceCompanyInvoice,
  updateTotalAdminInsuranceCompanyInvoices,
  updateIsAdminInsuranceCompanyInvoiceNeedToRefresh,
  updateSelectedAllAdminInsuranceCompanyInvoices,
  updateSelectedInsuranceCompanyInvoicesId,
  updateAdminInsuranceCompanyInvoiceFilters,
  clearAdminInsuranceCompanyInvoiceFilters,
} = adminInsuranceCompanyInvoiceSlice.actions

export default adminInsuranceCompanyInvoiceSlice.reducer
