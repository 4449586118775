import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import BankAccount = App.Models.BankAccount
import CustomerPayment = App.Models.CustomerPayment
import Invoice = App.Models.Invoice
import InvoiceTemplate = App.Models.InvoiceTemplate
import Payment = App.Models.CustomerPayment

export interface PaymentState {
  is_record_payment: boolean
  select_record_payment: CustomerPayment | null
  is_payment_reminder: boolean
  selected_payment_reminder: CustomerPayment | null
  is_generate_invoice: boolean
  selected_invoice_id?: string | null
  selected_invoice?: Invoice | null
  selected_order: Invoice | null
  selected_lead_id?: string | null
  selected_lead?: App.Models.Lead | App.Models.VisitorLead | App.Models.IeltsLead | null
  branch_id?: string | null
  is_invoice_template: boolean
  selected_invoice_template_id?: string | null
  selected_invoice_template?: InvoiceTemplate | null
  selected_bank_id: string | null
  selected_bank: BankAccount | null
  is_bank_account: boolean
  is_international_bank_account: boolean
  show_tax_detail_drawer: boolean
  selected_tax_detail: App.Models.TaxDetail | null
  is_tax_detail_update: boolean
  selected_customer_payment?: CustomerPayment | null
  show_generate_order_drawer: boolean
  show_schedule_payment_drawer: boolean
  show_tax_invoice_drawer: boolean
  show_generate_payment_link_drawer: boolean
  show_record_payment_drawer: boolean
  show_payment_requirement_drawer: boolean
  payment_requirements: {
    optionals: {
      bank_details: boolean
      tax_details: boolean
      invoice_details: boolean
      payment_gateway: boolean
      tax_invoice_settings: boolean
    }
    bank_details: boolean
    tax_details: boolean
    invoice_details: boolean
    payment_gateway: boolean
    tax_invoice_settings: boolean
  }
  selected_billed_by_detail: App.Models.BillingDetail | null
  show_billing_detail_drawer: boolean
  is_billing_detail_update: boolean
}

const initialState: PaymentState = {
  is_record_payment: false,
  select_record_payment: null,
  is_payment_reminder: false,
  selected_payment_reminder: null,
  is_generate_invoice: false,
  selected_invoice_id: null,
  selected_invoice: null,
  selected_order: null,
  selected_lead_id: null,
  selected_lead: null,
  branch_id: null,
  is_invoice_template: false,
  selected_invoice_template_id: null,
  selected_invoice_template: null,
  selected_bank_id: null,
  selected_bank: null,
  is_bank_account: false,
  is_international_bank_account: false,
  show_tax_detail_drawer: false,
  selected_tax_detail: null,
  is_tax_detail_update: false,
  show_generate_order_drawer: false,
  show_record_payment_drawer: false,
  show_schedule_payment_drawer: false,
  show_tax_invoice_drawer: false,
  show_generate_payment_link_drawer: false,
  selected_customer_payment: null,
  show_payment_requirement_drawer: false,
  payment_requirements: {
    optionals: {
      bank_details: false,
      tax_details: false,
      invoice_details: false,
      payment_gateway: false,
      tax_invoice_settings: false,
    },
    bank_details: false,
    tax_details: false,
    invoice_details: false,
    payment_gateway: false,
    tax_invoice_settings: false,
  },
  selected_billed_by_detail: null,
  show_billing_detail_drawer: false,
  is_billing_detail_update: false,
}

export const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {
    updateSelectedLeadId(state, action: PayloadAction<string | null>) {
      state.selected_lead_id = action.payload
    },
    updateSelectedLead(
      state,
      action: PayloadAction<App.Models.Lead | App.Models.VisitorLead | App.Models.IeltsLead | null>
    ) {
      state.selected_lead = action.payload
    },
    updateSelectedBankId(state, action: PayloadAction<string | null>) {
      state.selected_bank_id = action.payload
    },
    updateSelectedBank(state, action: PayloadAction<BankAccount | null>) {
      state.selected_bank = action.payload
    },
    updateIsBankAccount(state, action: PayloadAction<boolean>) {
      state.is_bank_account = action.payload
    },
    updateIsRecordPayment(state, action: PayloadAction<boolean>) {
      state.is_record_payment = action.payload
    },
    updateSelectedRecordPayment(state, action: PayloadAction<Payment | null>) {
      state.select_record_payment = action.payload
    },

    updateIsPaymentReminder(state, action: PayloadAction<boolean>) {
      state.is_payment_reminder = action.payload
    },
    updateSelectedPaymentReminder(state, action: PayloadAction<Payment | null>) {
      state.selected_payment_reminder = action.payload
    },
    updateIsGenerateInvoice(state, action: PayloadAction<boolean>) {
      state.is_generate_invoice = action.payload
    },
    updateSelectedInvoiceId(state, action: PayloadAction<string | null>) {
      state.selected_invoice_id = action.payload
    },
    updateSelectedInvoice(state, action: PayloadAction<Invoice | null>) {
      state.selected_invoice = action.payload
    },
    updateSelectedOrder(state, action: PayloadAction<Invoice | null>) {
      state.selected_order = action.payload
    },
    updateIsInvoiceTemplate(state, action: PayloadAction<boolean>) {
      state.is_invoice_template = action.payload
    },
    updateSelectedInvoiceTemplateId(state, action: PayloadAction<string | null>) {
      state.selected_invoice_template_id = action.payload
    },
    updateSelectedInvoiceTemplate(state, action: PayloadAction<InvoiceTemplate | null>) {
      state.selected_invoice_template = action.payload
    },
    updatePaymentBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateIsInternationalBankAccount(state, action: PayloadAction<boolean>) {
      state.is_international_bank_account = action.payload
    },
    updateShowTaxDetailDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        selected_tax_detail: App.Models.TaxDetail | null
        is_update: boolean
      }>
    ) {
      state.show_tax_detail_drawer = action.payload.show
      state.selected_tax_detail = action.payload.selected_tax_detail
      state.is_tax_detail_update = action.payload.is_update ?? false
    },
    updateShowGenerateOrderDrawer(state, action: PayloadAction<boolean>) {
      state.show_generate_order_drawer = action.payload
    },
    updateShowRecordPaymentDrawer(state, action: PayloadAction<boolean>) {
      state.show_record_payment_drawer = action.payload
    },
    updateShowSchedulePaymentDrawer(state, action: PayloadAction<boolean>) {
      state.show_schedule_payment_drawer = action.payload
    },
    updateShowTaxInvoiceDrawer(state, action: PayloadAction<boolean>) {
      state.show_tax_invoice_drawer = action.payload
    },
    updateShowGeneratePaymentLinkDrawer(state, action: PayloadAction<boolean>) {
      state.show_generate_payment_link_drawer = action.payload
    },
    updateSelectedCustomerPayment(state, action: PayloadAction<CustomerPayment | null>) {
      state.selected_customer_payment = action.payload
    },
    updateShowPaymentRequirementDrawer(state, action: PayloadAction<boolean>) {
      state.show_payment_requirement_drawer = action.payload
    },
    updatePaymentRequirements(
      state,
      action: PayloadAction<{
        optionals?: {
          bank_details?: boolean
          tax_details?: boolean
          invoice_details?: boolean
          payment_gateway?: boolean
          tax_invoice_settings?: boolean
        }
        bank_details: boolean
        tax_details: boolean
        invoice_details: boolean
        payment_gateway: boolean
        tax_invoice_settings: boolean
      }>
    ) {
      state.payment_requirements = {
        optionals: {
          bank_details: action.payload.optionals?.bank_details ?? false,
          tax_details: action.payload.optionals?.tax_details ?? false,
          invoice_details: action.payload.optionals?.invoice_details ?? false,
          payment_gateway: action.payload.optionals?.payment_gateway ?? false,
          tax_invoice_settings: action.payload.optionals?.tax_invoice_settings ?? false,
        },
        bank_details: action.payload.bank_details,
        tax_details: action.payload.tax_details,
        invoice_details: action.payload.invoice_details,
        payment_gateway: action.payload.payment_gateway,
        tax_invoice_settings: action.payload.tax_invoice_settings,
      }
    },
    updateShowBillingDetailDrawer: function (
      state,
      action: PayloadAction<{
        show: boolean
        selected_billed_by_detail: App.Models.BillingDetail | null
        is_update: boolean
      }>
    ) {
      state.show_billing_detail_drawer = action.payload.show
      state.selected_billed_by_detail = action.payload.selected_billed_by_detail
      state.is_billing_detail_update = action.payload.is_update ?? false
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateSelectedLeadId,
  updateSelectedLead,
  updateSelectedBankId,
  updateSelectedBank,
  updateIsBankAccount,
  updateIsRecordPayment,
  updateSelectedRecordPayment,
  updateIsPaymentReminder,
  updateSelectedPaymentReminder,
  updateIsGenerateInvoice,
  updateSelectedInvoiceId,
  updateSelectedInvoice,
  updateSelectedOrder,
  updateIsInvoiceTemplate,
  updateSelectedInvoiceTemplateId,
  updateSelectedInvoiceTemplate,
  updatePaymentBranchId,
  updateIsInternationalBankAccount,
  updateShowTaxDetailDrawer,
  updateShowGenerateOrderDrawer,
  updateShowRecordPaymentDrawer,
  updateShowSchedulePaymentDrawer,
  updateShowTaxInvoiceDrawer,
  updateSelectedCustomerPayment,
  updateShowGeneratePaymentLinkDrawer,
  updateShowPaymentRequirementDrawer,
  updatePaymentRequirements,
  updateShowBillingDetailDrawer,
} = paymentSlice.actions

export default paymentSlice.reducer
