import * as React from 'react'

function IconBox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.303 1.04a.5.5 0 01.394 0L15.5 3.956l-7 3-7-3L8.303 1.04zM1 4.83v7.67c0 .2.12.38.303.46L8 15.83v-8l-7-3zM9 7.83l7-3v7.67a.5.5 0 01-.303.46L9 15.83v-8z"
        fill="#E6ECFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7.83v8l6.697-2.87A.5.5 0 0016 12.5V4.83l-7 3zm7.5-3.866v-.008a1 1 0 00-.606-.92L9.091.122a1.5 1.5 0 00-1.182 0L1.106 3.037a1 1 0 00-.606.927.998.998 0 00-.5.866v7.67c0 .6.358 1.142.91 1.379l6.696 2.87a1 1 0 00.894-.053 1 1 0 00.894.053l6.697-2.87A1.5 1.5 0 0017 12.5V4.83a1 1 0 00-.5-.866zM8 7.83L1.106 4.875 1 4.83v7.67c0 .2.12.38.303.46L8 15.83v-8zm.394-.92l.106.046 7-3L8.697 1.04a.5.5 0 00-.394 0L1.5 3.956l6.894 2.955z"
        fill="#003CFF"
      />
    </svg>
  )
}

export default IconBox
