import React, { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: ReactNode;
  underline?: boolean;
  width?: string;
  height?: string;
}

const LinkComponent = styled.a<{
  underline: boolean;
  width?: string;
  height?: string;
}>`
  cursor: pointer !important;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}

  ${(props) =>
    props.underline
      ? css`
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        `
      : css`
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        `}
`;

export const Link: FC<LinkProps> = ({
  children,
  underline = false,
  width,
  height,
  ...props
}) => {
  return (
    <LinkComponent
      width={width}
      height={height}
      underline={underline}
      {...props}
    >
      {children}
    </LinkComponent>
  );
};

export default Link;
