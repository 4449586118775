import React, { FC, HTMLAttributes, useState } from 'react'
import styled from 'styled-components'
import HStack from '@/XelaReact/HStack/HStack'
import Input from '@/XelaReact/Input/Input'
import SvgIcon from '@/XelaReact/SvgIcon/SvgIcon'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface PaginationGoToProps extends HTMLAttributes<HTMLDivElement> {
  count: number
  defaultPage?: number
  disabled?: boolean
  title?: string
  btnText?: string
  btnColor?: string
  textColor?: string
  inputBorderColor?: string
  inputTextColor?: string
  inputBg?: string
  inputHoverColor?: string
  inputFocusColor?: string
  onGoToClickHandle?: (page: number) => void
}

const PaginationGoToComponent = styled(HStack)<{}>`
  & .paginationgoto-btn {
    cursor: pointer;
  }
`

export const PaginationGoTo: FC<PaginationGoToProps> = ({
  count,
  defaultPage = 1,
  disabled = false,
  title = 'Go to page',
  btnText = 'Go',
  btnColor = XelaColor.Blue3,
  textColor = XelaColor.Gray2,
  inputBorderColor = XelaColor.Gray11,
  inputTextColor = XelaColor.Gray2,
  inputBg = XelaColor.White,
  inputHoverColor = XelaColor.Blue3,
  inputFocusColor = XelaColor.Blue3,
  onGoToClickHandle,
  ...props
}) => {
  const [val, setValue] = useState(defaultPage)

  return (
    <PaginationGoToComponent spacing="32px" width="auto" {...props}>
      <Typography color={textColor} variant="button-medium">
        {title}
      </Typography>
      <Input
        bg={inputBg}
        defaultTextColor={inputTextColor}
        hoverColor={inputHoverColor}
        defaultBorderColor={inputBorderColor}
        focusColor={inputFocusColor}
        minNumValue={1}
        maxNumValue={count}
        type="number"
        valueTextAlign="center"
        width="82px"
        value={val.toString()}
        onChangeHandle={(value: string) => {
          setValue(parseInt(value))
        }}
      />
      <HStack
        className="paginationgoto-btn"
        width="auto"
        spacing="8px"
        onClick={() => {
          if (onGoToClickHandle) onGoToClickHandle(val)
        }}
      >
        <Typography color={btnColor} variant="button-medium">
          {btnText}
        </Typography>
        <SvgIcon fillColor={btnColor}>
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.85355 3.64648L14.2071 8.00004L9.85355 12.3536L9.14645 11.6465L12.2929 8.50004H1V7.50004H12.2929L9.14645 4.35359L9.85355 3.64648Z"
              fill="#003CFF"
            />
          </svg>
        </SvgIcon>
      </HStack>
    </PaginationGoToComponent>
  )
}

export default PaginationGoTo
