import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import InsurancePolicy = App.Models.InsurancePolicy

export interface PolicyState {
  total_policies: number
  is_filter_applied: boolean
  is_policy_edited: boolean
  is_selected_all: boolean
  is_need_to_refresh: boolean
  selected_policy: InsurancePolicy | null
  policy_filters: {
    search: string
  }
  selected_policies_id: number[]
}

const initialState: PolicyState = {
  total_policies: 0,
  is_filter_applied: false,
  is_policy_edited: false,
  is_selected_all: false,
  is_need_to_refresh: false,
  selected_policy: null,
  policy_filters: {
    search: '',
  },
  selected_policies_id: [],
}

export const adminInsurancePolicySlice = createSlice({
  name: 'insurancePolicySlice',
  initialState,
  reducers: {
    updateTotalAdminInsurancePolicies(state, action: PayloadAction<number>) {
      state.total_policies = action.payload
    },
    updateEditAdminInsurancePolicy(
      state,
      action: PayloadAction<{
        is_policy_edited: boolean
        selected_policy: InsurancePolicy | null
      }>
    ) {
      state.is_policy_edited = action.payload.is_policy_edited
      state.selected_policy = action.payload.selected_policy
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
    updateSelectedAllInsurancePolicies(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateAdminInsurancePolicyFilters(
      state,
      action: PayloadAction<{
        search: string
      }>
    ) {
      state.policy_filters = action.payload
    },
    clearAdminInsurancePolicyFilters(state) {
      state.policy_filters = {
        search: '',
      }
    },
    updateSelectedInsurancePoliciesId: (state, action) => {
      state.selected_policies_id = action.payload
    },
  },
})

export const {
  updateEditAdminInsurancePolicy,
  updateTotalAdminInsurancePolicies,
  updateIsNeedToRefresh,
  updateSelectedAllInsurancePolicies,
  updateSelectedInsurancePoliciesId,
  updateAdminInsurancePolicyFilters,
  clearAdminInsurancePolicyFilters,
} = adminInsurancePolicySlice.actions

export default adminInsurancePolicySlice.reducer
