import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import VisitorLead = App.Models.VisitorLead

export interface HubVisitorLeadState {
  hub_visitor_lead_id?: number
  hub_visitor_lead?: VisitorLead | undefined
  show_hub_add_visitor_lead_modal: boolean
  visitor_lead_form_submitted: boolean
  visitor_lead_documents_uploaded: boolean
}

const initialState: HubVisitorLeadState = {
  hub_visitor_lead_id: undefined,
  hub_visitor_lead: undefined,
  show_hub_add_visitor_lead_modal: false,
  visitor_lead_form_submitted: false,
  visitor_lead_documents_uploaded: false,
}

export const hubVisitorLeadSlice = createSlice({
  name: 'hubVisitorLeadSlice',
  initialState,
  reducers: {
    updateHubVisitorLeadId(state, action: PayloadAction<number | undefined>) {
      state.hub_visitor_lead_id = action.payload
    },
    updateHubVisitorLead(state, action: PayloadAction<VisitorLead | undefined>) {
      state.hub_visitor_lead = action.payload
    },
    updateShowHubAddVisitorLeadModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_hub_add_visitor_lead_modal = true
      } else {
        state.hub_visitor_lead_id = undefined
        state.show_hub_add_visitor_lead_modal = false
        state.visitor_lead_form_submitted = false
        state.visitor_lead_documents_uploaded = false
      }
    },
    updateVisitorLeadFormSubmitted(state, action: PayloadAction<boolean>) {
      state.visitor_lead_form_submitted = action.payload
    },
    updateVisitorLeadDocumentsUploaded(state, action: PayloadAction<boolean>) {
      state.visitor_lead_documents_uploaded = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateHubVisitorLeadId,
  updateHubVisitorLead,
  updateShowHubAddVisitorLeadModal,
  updateVisitorLeadFormSubmitted,
  updateVisitorLeadDocumentsUploaded,
} = hubVisitorLeadSlice.actions

export default hubVisitorLeadSlice.reducer
