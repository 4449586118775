import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import AdmissionApplication = App.Models.AdmissionApplication

export interface AdmissionApplicationState {
  selected_admission_application_id: string | null
  selected_admission_application: AdmissionApplication | null
  is_interview_pending: boolean
  is_admitted: boolean
  is_fee_paid: boolean
  is_visa_applied_again: boolean
  is_acceptance: boolean
  is_offer_letter: boolean
  is_ca_fee_paid: boolean
  is_partial_fee_paid: boolean
  is_conditional_letter: boolean
  is_gte_interview: boolean
  is_admission_letter: boolean
  is_no_admission: boolean
  branch_id: string | null
  is_interview_scheduled: boolean
  is_unconditional_offer: boolean
  is_uk_fee_paid: boolean
  is_i20_received: boolean
  is_usa_fee_paid: boolean
  is_transfer_to_visa: boolean
  show_upload_document_modal: boolean
  show_information_form: boolean
  admission_application_status: string | null
}

const initialState: AdmissionApplicationState = {
  selected_admission_application_id: null,
  selected_admission_application: null,
  is_interview_pending: false,
  is_admitted: false,
  is_fee_paid: false,
  is_visa_applied_again: false,
  is_acceptance: false,
  is_offer_letter: false,
  is_ca_fee_paid: false,
  is_partial_fee_paid: false,
  is_conditional_letter: false,
  is_gte_interview: false,
  is_admission_letter: false,
  is_no_admission: false,
  branch_id: null,
  is_interview_scheduled: false,
  is_unconditional_offer: false,
  is_uk_fee_paid: false,
  is_i20_received: false,
  is_usa_fee_paid: false,
  is_transfer_to_visa: false,
  show_information_form: false,
  show_upload_document_modal: false,
  admission_application_status: null,
}

export const admissionApplicationSlice = createSlice({
  name: 'admissionApplicationSlice',
  initialState,
  reducers: {
    updateSelectedAdmissionApplicationId(state, action: PayloadAction<string | null>) {
      state.selected_admission_application_id = action.payload
    },
    updateSelectedAdmissionApplication(state, action: PayloadAction<AdmissionApplication | null>) {
      state.selected_admission_application = action.payload
    },
    updateIsInterviewPending(state, action: PayloadAction<boolean>) {
      state.is_interview_pending = action.payload
    },
    updateIsAdmitted(state, action: PayloadAction<boolean>) {
      state.is_admitted = action.payload
    },
    updateIsFeePaid(state, action: PayloadAction<boolean>) {
      state.is_fee_paid = action.payload
    },
    updateIsVisaAppliedAgain(state, action: PayloadAction<boolean>) {
      state.is_visa_applied_again = action.payload
    },
    updateIsAcceptance(state, action: PayloadAction<boolean>) {
      state.is_acceptance = action.payload
    },
    updateIsOfferLetter(state, action: PayloadAction<boolean>) {
      state.is_offer_letter = action.payload
    },
    updateIsCaFeePaid(state, action: PayloadAction<boolean>) {
      state.is_ca_fee_paid = action.payload
    },
    updateIsPartialFeePaid(state, action: PayloadAction<boolean>) {
      state.is_partial_fee_paid = action.payload
    },
    updateIsConditionalLetter(state, action: PayloadAction<boolean>) {
      state.is_conditional_letter = action.payload
    },
    updateIsGteInterview(state, action: PayloadAction<boolean>) {
      state.is_gte_interview = action.payload
    },
    updateIsAdmissionLetter(state, action: PayloadAction<boolean>) {
      state.is_admission_letter = action.payload
    },
    updateIsNoAdmission(state, action: PayloadAction<boolean>) {
      state.is_no_admission = action.payload
    },
    updateAdmissionApplicationBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateIsInterviewScheduled(state, action: PayloadAction<boolean>) {
      state.is_interview_scheduled = action.payload
    },
    updateIsUnconditionalOffer(state, action: PayloadAction<boolean>) {
      state.is_unconditional_offer = action.payload
    },
    updateIsUkFeePaid(state, action: PayloadAction<boolean>) {
      state.is_uk_fee_paid = action.payload
    },
    updateIsI20Received(state, action: PayloadAction<boolean>) {
      state.is_i20_received = action.payload
    },
    updateIsUsaFeePaid(state, action: PayloadAction<boolean>) {
      state.is_usa_fee_paid = action.payload
    },
    updateIsTransferToVisa(state, action: PayloadAction<boolean>) {
      state.is_transfer_to_visa = action.payload
    },
    updateShowInformationFormDrawer(state, action: PayloadAction<boolean>) {
      state.show_information_form = action.payload
    },

    updateShowUploadDocumentDrawer(state, action: PayloadAction<boolean>) {
      state.show_upload_document_modal = action.payload
    },
    updateAdmissionApplicationStatus(state, action: PayloadAction<string | null>) {
      state.admission_application_status = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateSelectedAdmissionApplicationId,
  updateSelectedAdmissionApplication,
  updateIsInterviewPending,
  updateIsAdmitted,
  updateIsFeePaid,
  updateIsVisaAppliedAgain,
  updateIsAcceptance,
  updateIsOfferLetter,
  updateIsCaFeePaid,
  updateIsPartialFeePaid,
  updateIsConditionalLetter,
  updateIsGteInterview,
  updateIsAdmissionLetter,
  updateIsNoAdmission,
  updateAdmissionApplicationBranchId,
  updateIsInterviewScheduled,
  updateIsUnconditionalOffer,
  updateIsUkFeePaid,
  updateIsI20Received,
  updateIsUsaFeePaid,
  updateIsTransferToVisa,
  updateShowInformationFormDrawer,
  updateShowUploadDocumentDrawer,
  updateAdmissionApplicationStatus,
} = admissionApplicationSlice.actions

export default admissionApplicationSlice.reducer
