import React, { Dispatch, FC, HTMLAttributes, ReactNode, SetStateAction, useState } from 'react'
import styled, { css } from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import { getTransition } from '@/XelaReact/helpers/getTransitions'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import { IconChevronDown } from '@tabler/icons-react'
import { VStack } from '@/XelaReact'
import { useClickOutside } from '@mantine/hooks'
import useWidthResize from '@/Hooks/useWidthResize'

export class TabItem {
  label?: string
  icon?: ReactNode
  value: string
  badgeValue?: string
  error?: boolean

  constructor(value: string, label?: string, error?: boolean, icon?: ReactNode, badgeValue?: string) {
    this.label = label
    this.icon = icon
    this.error = error
    this.value = value
    this.badgeValue = badgeValue
  }
}

export interface TabsProps extends HTMLAttributes<HTMLDivElement> {
  items: TabItem[]
  name: string
  labelDefaultColor?: string
  iconDefaultColor?: string
  labelActiveColor?: string
  iconActiveColor?: string
  bg?: string
  activeItemLineColor?: string
  defaultValue?: string
  autoresize?: boolean
  itemPadding?: string
  onChangeHandle?: (checkedValue: string) => void
  badgeTextColor?: string
  badgeBg?: string
  maxLength?: number
}

const TabsComponent = styled(HStack)<{ autoresize: boolean }>`
  ${(props) =>
    props.autoresize &&
    css`
      display: inline-flex;
      width: auto;
    `}
  & input[type="radio"] {
    appearance: none;
    background: none;
    margin: 0;
    display: none;
  }
`

const TabItemContainer = styled.label<{
  checked: boolean
  activeItemLineColor: string
  iconDefaultColor: string
  iconActiveColor: string
  autoresize: boolean
  itemPadding: string
  badgeTextColor: string
}>`
  ${(props) =>
    !props.autoresize &&
    css`
      flex: 1;
    `}

  cursor: pointer;

  padding: ${(props) => props.itemPadding};
  border-bottom: 3px solid transparent;

  ${(props) =>
    props.checked
      ? css`
          border-color: ${props.activeItemLineColor};
          color: ${props.iconActiveColor};
          fill: ${props.iconActiveColor};
          stroke: ${props.iconActiveColor};
        `
      : css`
          border-color: transparent;
          color: ${props.iconDefaultColor};
          fill: ${props.iconDefaultColor};
          stroke: ${props.iconDefaultColor};
        `}
  ${getTransition(200, ['border'], 'linear')}
  & .tabs-badge {
    color: ${(props) => props.badgeTextColor};
    font-size: 12px;
    line-height: 8px;
    padding: 0 4px;
  }
`

const DropdownContainer: FC<{
  items: TabItem[]
  value: string
  badgeBg: string
  onClicked: (value: string) => void
  maxLength?: number
}> = ({ items, value, badgeBg, onClicked, maxLength = 8 }) => {
  const [opened, setOpened] = useState(false)
  const ref = useClickOutside(() => setOpened(false))

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        padding: '8px',
        borderRadius: '12px',
        background: XelaColor.Gray12,
        width: 'auto',
        position: 'relative',
        zIndex: 50,
      }}
      onClick={() => {
        setOpened(!opened)
      }}
    >
      <IconChevronDown size={20} color={XelaColor.Gray2} />
      {opened && (
        <VStack
          style={{
            height: '300px',
            width: '220px',
            padding: '8px',
            borderRadius: '12px',
            background: XelaColor.White,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            position: 'absolute',
            top: 42,
            left: -186,
            overflowY: 'auto',
          }}
        >
          {items.slice(maxLength, items.length).map((item, index) => (
            <DropdownItem
              item={item}
              value={value}
              badgeBg={badgeBg}
              key={index}
              onClicked={onClicked}
              close={setOpened}
            ></DropdownItem>
          ))}
        </VStack>
      )}
    </div>
  )
}

const DropdownItem: FC<{
  item: TabItem
  value: string
  badgeBg: string
  close: Dispatch<SetStateAction<boolean>>
  onClicked: (value: string) => void
}> = ({ item, value, badgeBg, onClicked, close }) => {
  const [hover, setHover] = useState(false)

  return (
    <HStack
      spacing={'12px'}
      style={{
        padding: '8px 12px',
        borderRadius: '12px',
        cursor: 'pointer',
        background: hover ? XelaColor.Gray12 : XelaColor.White,
        borderLeft: `3px solid ${item.value == value ? XelaColor.Blue3 : 'transparent'}`,
        borderTopLeftRadius: item.value == value ? '0px' : '12px',
        borderBottomLeftRadius: item.value == value ? '0px' : '12px',
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      onClick={() => {
        close(true)
        onClicked(item.value)
      }}
    >
      <Typography variant="body-small-bold">{item.label && item.label}</Typography>
      {item.badgeValue && (
        <Center
          width="auto"
          height="16px"
          borderRadius="6px"
          bg={badgeBg}
          style={{
            fontSize: '12px',
            lineHeight: '8px',
            padding: '0 4px',
            color: XelaColor.White,
          }}
        >
          {item.badgeValue}
        </Center>
      )}
    </HStack>
  )
}

export const Tabs: FC<TabsProps> = ({
  items,
  name,
  labelDefaultColor = XelaColor.Gray2,
  iconDefaultColor = XelaColor.Gray2,
  labelActiveColor = XelaColor.Blue3,
  iconActiveColor = XelaColor.Blue3,
  badgeBg = XelaColor.Orange3,
  badgeTextColor = XelaColor.White,
  bg,
  activeItemLineColor = XelaColor.Blue3,
  defaultValue,
  autoresize = true,
  itemPadding = '12px',
  onChangeHandle,
  maxLength = 8,
  ...props
}) => {
  const [value, setValue] = React.useState(defaultValue || items[0].value)
  const width = useWidthResize()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (onChangeHandle) onChangeHandle(event.target.value)
  }

  const onClicked = (value: string) => {
    setValue(value)
    if (onChangeHandle) onChangeHandle(value)
  }

  return (
    <TabsComponent autoresize={autoresize} bg={bg} borderRadius="12px" spacing={width ? '5px' : '8px'} {...props}>
      {items.slice(0, maxLength).map((item, index) => (
        <TabItemContainer
          badgeTextColor={badgeTextColor}
          itemPadding={itemPadding}
          autoresize={autoresize}
          activeItemLineColor={activeItemLineColor}
          iconDefaultColor={iconDefaultColor}
          iconActiveColor={iconActiveColor}
          checked={item.value == value}
          key={index}
        >
          <input type="radio" name={name} checked={item.value == value} value={item.value} onChange={handleChange} />
          <Center>
            <HStack width="auto" spacing="8px">
              {item.icon && item.icon}
              {item.label && (
                <Typography
                  color={
                    item.value == value
                      ? item.error
                        ? XelaColor.Red3
                        : labelActiveColor
                      : item.error
                      ? XelaColor.Red3
                      : labelDefaultColor
                  }
                  variant={width > 1280 ? 'button-medium' : 'caption-bold'}
                >
                  {item.label && item.label}
                </Typography>
              )}
              {item.badgeValue && (
                <Center className="tabs-badge" width="auto" height="16px" borderRadius="6px" bg={badgeBg}>
                  {item.badgeValue}
                </Center>
              )}
            </HStack>
          </Center>
        </TabItemContainer>
      ))}
      {items.length > maxLength && (
        <DropdownContainer
          items={items}
          value={value}
          badgeBg={badgeBg}
          onClicked={onClicked}
          maxLength={maxLength}
        ></DropdownContainer>
      )}
    </TabsComponent>
  )
}

export default Tabs
