import React, { FC, HTMLAttributes, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import { getTransition } from '@/XelaReact/helpers/getTransitions'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export class SegmentedControlItem {
  label?: string
  icon?: ReactNode
  value: string

  constructor(value: string, label?: string, icon?: ReactNode) {
    this.label = label
    this.icon = icon
    this.value = value
  }
}

export interface SegmentedControlProps extends HTMLAttributes<HTMLDivElement> {
  items: SegmentedControlItem[]
  name: string
  labelDefaultColor?: string
  iconDefaultColor?: string
  labelActiveColor?: string
  iconActiveColor?: string
  bg?: string
  activeItemBg?: string
  defaultValue?: string
  autoresize?: boolean
  itemPadding?: string
  onChangeHandle?: (checkedValue: string) => void
}

const SegmentedControlComponent = styled(HStack)<{ autoresize: boolean }>`
  ${(props) =>
    props.autoresize &&
    css`
      display: inline-flex;
      width: auto;
    `}
  & input[type="radio"] {
    appearance: none;
    background: none;
    margin: 0;
    display: none;
  }
`

const SegmentedControlItemContainer = styled.label<{
  checked: boolean
  activeBg: string
  iconDefaultColor: string
  iconActiveColor: string
  autoresize: boolean
  itemPadding: string
}>`
  ${(props) =>
    !props.autoresize &&
    css`
      flex: 1;
    `}

  cursor: pointer;

  padding: ${(props) => props.itemPadding};
  border-radius: 12px;
  ${(props) =>
    props.checked
      ? css`
          background: ${props.activeBg};
          color: ${props.iconActiveColor};
          fill: ${props.iconActiveColor};
          stroke: ${props.iconActiveColor};
        `
      : css`
          background: none;
          color: ${props.iconDefaultColor};
          fill: ${props.iconDefaultColor};
          stroke: ${props.iconDefaultColor};
        `}

  ${getTransition(200, ['background'], 'linear')}
`

export const SegmentedControl: FC<SegmentedControlProps> = ({
  items,
  name,
  labelDefaultColor = XelaColor.Gray2,
  iconDefaultColor = XelaColor.Gray2,
  labelActiveColor = XelaColor.White,
  iconActiveColor = XelaColor.White,
  bg,
  activeItemBg = XelaColor.Blue3,
  defaultValue,
  autoresize = true,
  itemPadding = '16px',
  onChangeHandle,
  ...props
}) => {
  const [value, setValue] = React.useState(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (onChangeHandle) onChangeHandle(event.target.value)
  }

  return (
    <SegmentedControlComponent autoresize={autoresize} bg={bg} borderRadius="12px" spacing="8px" {...props}>
      {items.map((item, index) => (
        <SegmentedControlItemContainer
          itemPadding={itemPadding}
          autoresize={autoresize}
          activeBg={activeItemBg}
          iconDefaultColor={iconDefaultColor}
          iconActiveColor={iconActiveColor}
          checked={item.value == value}
          key={index}
        >
          <input type="radio" name={name} checked={item.value == value} value={item.value} onChange={handleChange} />
          <Center>
            <HStack width="auto" spacing="8px">
              {item.icon && item.icon}
              {item.label && (
                <Typography color={item.value == value ? labelActiveColor : labelDefaultColor} variant="button-medium">
                  {item.label && item.label}
                </Typography>
              )}
            </HStack>
          </Center>
        </SegmentedControlItemContainer>
      ))}
    </SegmentedControlComponent>
  )
}

export default SegmentedControl
