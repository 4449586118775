import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import { Box } from '@mantine/core'
import Notification = App.Models.Notification

const TaskAssignedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        router.visit(route('tasks', { period: 'all', task_id: notification.data.task_id }))
      }}
    >
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          assigned a new{notification.data.task_due_today ? ' URGENT' : ''} task
        </Typography>
      </HStack>
      <HStack spacing="12px" height="100%" style={{ padding: '6px 12px' }}>
        <Box
          style={{
            width: '8px',
            height: '30px',
            backgroundColor: XelaColor.Blue3,
            borderRadius: '4px',
          }}
        ></Box>
        <HStack
          spacing="3px"
          style={{
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          <Typography variant="body-small-bold">{notification.data.task}</Typography>
        </HStack>
      </HStack>
    </VStack>
  )
}

export default TaskAssignedNotification
