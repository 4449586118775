import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Checkbox, Drawer, Loader } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { useForm } from '@inertiajs/react'
import { updateDownloadDocumentsModal } from '@/Store/leadSlice'
import { IconDragDrop } from '@tabler/icons-react'
import XSelect from '@/Mantine/XSelect'
import axios, { AxiosResponse } from 'axios'
import { DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Document = App.Models.Document

interface BlobResponse extends AxiosResponse {
  data: Blob
}

interface DragItem {
  index: number
  id: number
  type: string
}

const ITEM_TYPE = 'document'

interface DraggableDocumentProps {
  index: number
  document: Document
  moveItem: (dragIndex: number, hoverIndex: number) => void
  documentIds: number[]
  setDocumentIds: React.Dispatch<React.SetStateAction<number[]>>
}

const DraggableDocument: FC<DraggableDocumentProps> = ({ index, document, moveItem, documentIds, setDocumentIds }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally, it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: () => {
      return { id: document.id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        gap: 12,
        alignItems: 'center',
        opacity: isDragging ? 0.5 : 1,
        width: '100%',
      }}
    >
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {index + 1}.
      </Typography>
      <HStack
        key={index}
        spacing="8px"
        style={{
          padding: '8px 16px',
          border: `1px solid ${XelaColor.Gray11}`,
          borderRadius: '16px',
        }}
      >
        <IconDragDrop size={20} stroke={1} />
        <VStack>
          <Typography
            variant={'body-small-bold'}
            color={XelaColor.Gray3}
            style={{
              wordBreak: 'break-all',
            }}
          >
            {document.name}
          </Typography>
          <Typography variant={'caption'} color={XelaColor.Gray6}>
            {document.type}{' '}
          </Typography>
        </VStack>
        <Checkbox
          size={'xs'}
          color={'blueX'}
          onChange={() => {
            if (documentIds.includes(document.id)) {
              setDocumentIds((prev) => {
                return prev.filter((id) => id !== document.id)
              })
            } else {
              setDocumentIds((prev) => {
                return [...prev, document.id]
              })
            }
          }}
          checked={documentIds.includes(document.id)}
        />
      </HStack>
    </div>
  )
}

const DownloadDocumentsModal = () => {
  const dispatch = useDispatch()
  const leadStore = useSelector((state: RootState) => state.lead)
  const [downloading, setDownloading] = useState(false)

  const { data, setData, reset } = useForm<{
    lead_id: string | null
    documents: Document[]
    download_type: string
    document_belongs_to: string
  }>({
    lead_id: null,
    documents: [],
    download_type: 'ZIP',
    document_belongs_to: 'Applicant',
  })

  useEffect(() => {
    if (leadStore.lead_id !== null) {
      setData((prev) => {
        const datum: { [key: string]: string | number | null | Document[] } = {}
        datum['lead_id'] = leadStore.lead_id
        datum['documents'] = leadStore.download_documents ?? []
        datum['document_belongs_to'] = leadStore.document_belongs_to

        return {
          ...prev,
          ...datum,
        }
      })
    }
  }, [leadStore.lead_id, leadStore.download_documents, leadStore.document_belongs_to])

  const [documentIds, setDocumentIds] = useState<number[]>([])
  const [selectAll, setSelectAll] = useState(false)

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = data.documents[dragIndex]
      setData((prevState) => {
        const copiedData = [...prevState.documents]
        copiedData.splice(dragIndex, 1)
        copiedData.splice(hoverIndex, 0, dragItem)
        return { ...prevState, documents: copiedData }
      })
    },
    [data.documents]
  )

  return (
    <Drawer
      position={'right'}
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      size={650}
      opened={leadStore.show_document_download_modal}
      onClose={() => {
        dispatch(
          updateDownloadDocumentsModal({
            show: false,
            lead_id: null,
            documents: [],
          })
        )
        reset()
        setDocumentIds([])
        setSelectAll(false)
      }}
      styles={{
        body: {
          height: '100vh !important',
          overflowY: 'hidden',
        },
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <VStack spacing="12px" height="100%">
          <HStack justifyContent="space-between">
            <HStack
              style={{
                padding: '16px',
                background: XelaColor.Blue12,
                borderRadius: '12px',
                gap: '16px',
              }}
            >
              <Typography
                variant={'subheadline'}
                style={{
                  color: XelaColor.Blue3,
                }}
              >
                Download
              </Typography>
            </HStack>
          </HStack>
          <HStack
            spacing="8px"
            justifyContent={'space-between'}
            style={{
              padding: '0 10px',
            }}
          >
            <HStack>
              <XSelect
                size={'sm'}
                style={{ width: '180px', borderRadius: '16px' }}
                value={data.download_type ?? 'ZIP'}
                data={[
                  { value: 'ZIP', label: 'ZIP' },
                  { value: 'PDF', label: 'PDF' },
                ]}
                onChange={(value) => {
                  if (value) {
                    setData((prev) => {
                      return {
                        ...prev,
                        download_type: value,
                      }
                    })
                  }
                }}
              />
            </HStack>
            <HStack justifyContent="flex-end">
              <Checkbox
                size={'xs'}
                style={{ cursor: 'pointer' }}
                label={
                  <Typography variant={'body-small-bold'} noWrap>
                    {' '}
                    Select All
                  </Typography>
                }
                labelPosition="left"
                color={'blueX'}
                checked={selectAll}
                onChange={() => {
                  if (selectAll) {
                    setDocumentIds([])
                  } else {
                    setDocumentIds(data.documents.map((document) => document.id))
                  }
                  setSelectAll((prev) => !prev)
                }}
              />
            </HStack>
          </HStack>
          <HStack>
            <Divider variant={'dotted'}></Divider>
          </HStack>
          <VStack
            style={{
              padding: '12px',
              width: '100%',
              gap: '12px',
              flexGrow: 1,
              overflowY: 'auto',
            }}
          >
            {data.documents.map((document: Document, index: number) => {
              return (
                <DraggableDocument
                  index={index}
                  key={document.id}
                  moveItem={moveItem}
                  document={document}
                  documentIds={documentIds}
                  setDocumentIds={setDocumentIds}
                />
              )
            })}
          </VStack>
          <HStack
            justifyContent="flex-end"
            spacing="12px"
            style={{
              flexShrink: 0,
            }}
          >
            <Button
              size={'medium'}
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                dispatch(
                  updateDownloadDocumentsModal({
                    show: false,
                    lead_id: null,
                    documents: [],
                  })
                )
                reset()
                setDocumentIds([])
                setSelectAll(false)
              }}
            />
            <Button
              disabled={documentIds.length === 0 || data.documents.length === 0 || downloading}
              label={'Download'}
              size={'medium'}
              rightIcon={downloading && <Loader color={'blueX'} size={16} />}
              onClick={() => {
                setDownloading(true)

                axios({
                  url: `/documents/${data.lead_id}/download-documents`,
                  method: 'POST',
                  data: {
                    document_ids: data.documents
                      .filter((document) => documentIds.includes(document.id))
                      .map((document) => document.id),
                    download_type: data.download_type,
                    document_belongs_to: data.document_belongs_to,
                  },
                  responseType: 'blob', // important
                })
                  .then((response: BlobResponse) => {
                    const contentDisposition = response.headers['content-disposition']
                    let filename = 'files.zip'

                    if (contentDisposition) {
                      const match = contentDisposition.match(/filename=(.+)/)
                      filename = match ? match[1] : 'files.zip'
                    }

                    const downloadLink = document.createElement('a')
                    downloadLink.href = URL.createObjectURL(response.data)
                    downloadLink.download = filename
                    downloadLink.click()
                  })
                  .finally(() => {
                    setDownloading(false)
                    dispatch(
                      updateDownloadDocumentsModal({
                        show: false,
                        lead_id: null,
                        documents: [],
                      })
                    )
                    reset()

                    setDocumentIds([])
                    setSelectAll(false)
                  })
              }}
            ></Button>
          </HStack>
        </VStack>
      </DndProvider>
    </Drawer>
  )
}

export default DownloadDocumentsModal
