import { createContext, useContext } from 'react';
import { Option } from './Select';

const SelectContext = createContext<{
  selectedOption?: Option;
  changeSelectedOption: (option: Option) => void;
}>({
  selectedOption: undefined,
  changeSelectedOption: () => {},
});

const useSelectContext = () => {
  const context = useContext(SelectContext);
  if (!context) {
    throw new Error('Error in creating the context');
  }
  return context;
};

export { useSelectContext, SelectContext };
