import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface LeadDrawer {
  show_lead_drawer: boolean
  show_visitor_lead_drawer: boolean
  show_ielts_lead_drawer: boolean
  show_counselling_drawer: boolean
  show_admission_drawer: boolean
  show_admission_application_drawer: boolean
  show_visa_drawer: boolean
  show_notes_drawer: boolean
  show_calls_drawer: boolean
  show_documents_drawer: boolean
  show_emails_drawer: boolean
  show_tasks_drawer: boolean
  show_follow_ups_drawer: boolean
  selected_status: string | null
  selected_mode: string | null
  selected_country: string | null
  selected_source: string | null
  selected_applied_via: string | null
  selected_intake: string | null
  selected_manager: string | number | null | undefined
  selected_employee: string | number | null | undefined
  selected_university: string | null
  show_pending_amount: boolean
  show_paid_amount: boolean
  show_payments_drawer: boolean
  show_dashboard_items_drawer: boolean
}

const initialState: LeadDrawer = {
  show_lead_drawer: false,
  show_visitor_lead_drawer: false,
  show_ielts_lead_drawer: false,
  show_counselling_drawer: false,
  show_admission_drawer: false,
  show_admission_application_drawer: false,
  show_visa_drawer: false,
  show_notes_drawer: false,
  show_calls_drawer: false,
  show_documents_drawer: false,
  show_emails_drawer: false,
  show_tasks_drawer: false,
  show_follow_ups_drawer: false,
  selected_status: null,
  selected_mode: null,
  selected_country: null,
  selected_source: null,
  selected_applied_via: null,
  selected_intake: null,
  selected_manager: null,
  selected_employee: null,
  show_pending_amount: false,
  show_paid_amount: false,
  selected_university: null,
  show_payments_drawer: false,
  show_dashboard_items_drawer: false,
}

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    updateShowLeadDrawer(state, action: PayloadAction<boolean>) {
      state.show_lead_drawer = action.payload
    },
    updateShowVisitorLeadDrawer(state, action: PayloadAction<boolean>) {
      state.show_visitor_lead_drawer = action.payload
    },
    updateShowIeltsLeadDrawer(state, action: PayloadAction<boolean>) {
      state.show_ielts_lead_drawer = action.payload
    },
    updateShowCounsellingDrawer(state, action: PayloadAction<boolean>) {
      state.show_counselling_drawer = action.payload
    },
    updateShowAdmissionDrawer(state, action: PayloadAction<boolean>) {
      state.show_admission_drawer = action.payload
    },
    updateShowAdmissionApplicationDrawer(state, action: PayloadAction<boolean>) {
      state.show_admission_application_drawer = action.payload
    },
    updateShowVisaDrawer(state, action: PayloadAction<boolean>) {
      state.show_visa_drawer = action.payload
    },
    updateShowNotesDrawer(state, action: PayloadAction<boolean>) {
      state.show_notes_drawer = action.payload
    },
    updateShowCallsDrawer(state, action: PayloadAction<boolean>) {
      state.show_calls_drawer = action.payload
    },
    updateShowDocumentsDrawer(state, action: PayloadAction<boolean>) {
      state.show_documents_drawer = action.payload
    },
    updateShowEmailsDrawer(state, action: PayloadAction<boolean>) {
      state.show_emails_drawer = action.payload
    },
    updateShowTasksDrawer(state, action: PayloadAction<boolean>) {
      state.show_tasks_drawer = action.payload
    },
    updateShowFollowUpsDrawer(state, action: PayloadAction<boolean>) {
      state.show_follow_ups_drawer = action.payload
    },
    updateSelectedStatus(state, action: PayloadAction<string | null>) {
      state.selected_status = action.payload
    },
    updateSelectedCountry(state, action: PayloadAction<string | null>) {
      state.selected_country = action.payload
    },
    updateSelectedSource(state, action: PayloadAction<string | null>) {
      state.selected_source = action.payload
    },
    updateSelectedAppliedVia(state, action: PayloadAction<string | null>) {
      state.selected_applied_via = action.payload
    },
    updateSelectedIntake(state, action: PayloadAction<string | null>) {
      state.selected_intake = action.payload
    },
    updateSelectedManager(state, action: PayloadAction<string | number | null | undefined>) {
      state.selected_manager = action.payload
    },
    updateSelectedEmployee(state, action: PayloadAction<string | number | null | undefined>) {
      state.selected_employee = action.payload
    },
    updateSelectedUniversity(state, action: PayloadAction<string | null>) {
      state.selected_university = action.payload
    },
    updateShowPaymentsDrawer(state, action: PayloadAction<boolean>) {
      state.show_payments_drawer = action.payload
    },
    updateSelectedMode(state, action: PayloadAction<string | null>) {
      state.selected_mode = action.payload
    },
    updateShowPendingAmount(state, action: PayloadAction<boolean>) {
      state.show_pending_amount = action.payload
    },
    updateShowPaidAmount(state, action: PayloadAction<boolean>) {
      state.show_paid_amount = action.payload
    },
    updateShowDashboardItemsDrawer(state, action: PayloadAction<boolean>) {
      state.show_dashboard_items_drawer = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowLeadDrawer,
  updateShowVisitorLeadDrawer,
  updateShowIeltsLeadDrawer,
  updateShowCounsellingDrawer,
  updateShowAdmissionDrawer,
  updateShowAdmissionApplicationDrawer,
  updateShowVisaDrawer,
  updateShowNotesDrawer,
  updateShowCallsDrawer,
  updateShowDocumentsDrawer,
  updateShowEmailsDrawer,
  updateShowTasksDrawer,
  updateShowFollowUpsDrawer,
  updateSelectedStatus,
  updateSelectedCountry,
  updateSelectedSource,
  updateSelectedAppliedVia,
  updateSelectedIntake,
  updateSelectedManager,
  updateSelectedEmployee,
  updateSelectedUniversity,
  updateShowPaymentsDrawer,
  updateShowPendingAmount,
  updateShowPaidAmount,
  updateSelectedMode,
  updateShowDashboardItemsDrawer,
} = dashboardSlice.actions

export default dashboardSlice.reducer
