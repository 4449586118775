import React, { FC, HTMLAttributes, ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export class Radio {
  value: string
  label?: string
  caption?: string
  additional?: ReactNode
  disabled?: boolean

  constructor(
    value: string,
    label?: string,
    caption?: string,
    additional?: ReactNode,
    disabled?: boolean
  ) {
    this.value = value
    this.label = label
    this.caption = caption
    this.additional = additional
    this.disabled = disabled
  }
}

export interface RadioButtonProps extends HTMLAttributes<HTMLInputElement> {
  radios: Radio[]
  name: string
  size?: 'large' | 'medium' | 'small'
  labelColor?: string
  captionColor?: string
  defaultColor?: string
  activeColor?: string
  border?: boolean
  direction?: 'horizontal' | 'vertical'
  checkedValue?: string
  width?: string
}

interface RadioItemProps extends RadioButtonProps {
  radio: Radio
  checked: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioItemComponent = styled(HStack)<{
  defaultColor?: string
  activeColor?: string
  size?: 'large' | 'medium' | 'small'
  border?: boolean
  checked?: boolean
  disabled?: boolean
}>`
  ${(props) =>
    props.border &&
    'border: 1px solid ' +
      props.defaultColor +
      '; border-radius: 18px; padding: 16px;'}
  ${(props) =>
    props.border && props.checked && 'border-color: ' + props.activeColor + ';'}
  ${(props) =>
    props.border &&
    !props.disabled &&
    '&:hover {border-color: ' + props.activeColor + ';}'}
  ${(props) => props.disabled && 'opacity: 0.4;'}
  & {
    cursor: pointer;
  }
  & input[type='radio'] {
    cursor: pointer;
    appearance: none;
    background: none;
    margin: 0;

    font: inherit;
    color: ${(props) => props.defaultColor};
    width: ${(props) =>
      props.size == 'large'
        ? '32px'
        : props.size == 'medium'
        ? '24px'
        : '20px'};
    height: ${(props) =>
      props.size == 'large'
        ? '32px'
        : props.size == 'medium'
        ? '24px'
        : '20px'};
    border: 2px solid ${(props) => props.defaultColor};
    border-radius: 100%;

    display: grid;
    place-content: center;
    min-width: ${(props) =>
      props.size == 'large'
        ? '32px'
        : props.size == 'medium'
        ? '24px'
        : '20px'};
  }

  & input[type='radio']:checked,
  & input[type='radio']:checked:hover {
    border: ${(props) =>
        props.size == 'large' ? '8px' : props.size == 'medium' ? '6px' : '5px'}
      solid ${(props) => props.activeColor};
  }

  & input[type='radio']:hover {
    border: 2px solid
      ${(props) => (props.disabled ? props.defaultColor : props.activeColor)};
  }
`

const RadioItem: FC<RadioItemProps> = ({
  radio,
  labelColor,
  size,
  captionColor,
  name,
  defaultColor,
  activeColor,
  border,
  checked,
  direction,
  width,
  handleChange,
}) => {
  return (
    <label>
      <RadioItemComponent
        width={direction == 'horizontal' ? width : 'auto'}
        spacing="12px"
        activeColor={activeColor}
        defaultColor={defaultColor}
        checked={checked}
        size={size}
        border={border}
        disabled={radio.disabled}
      >
        <input
          type="radio"
          disabled={radio.disabled}
          checked={checked}
          name={name}
          onChange={handleChange}
          value={radio.value}
        />
        <VStack>
          {radio.label && (
            <Typography variant="button-medium" as="span" color={labelColor}>
              {radio.label}
            </Typography>
          )}
          {radio.caption && (
            <Typography variant="caption" as="span" color={captionColor}>
              {radio.caption}
            </Typography>
          )}
        </VStack>
        {radio.additional && radio.additional}
      </RadioItemComponent>
    </label>
  )
}

export const RadioButton: FC<RadioButtonProps> = ({
  radios,
  name,
  size = 'medium',
  labelColor = XelaColor.Gray2,
  captionColor = XelaColor.Gray7,
  defaultColor = XelaColor.Gray11,
  activeColor = XelaColor.Blue3,
  border = false,
  direction = 'vertical',
  checkedValue,
  width = 'auto',
  ...props
}) => {
  const [value, setValue] = React.useState(checkedValue)

  useEffect(() => {
    setValue(checkedValue)
  }, [checkedValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  if (direction == 'horizontal') {
    return (
      <HStack
        spacing={
          border
            ? '16px'
            : size == 'large'
            ? '24px'
            : size == 'medium'
            ? '20px'
            : '16px'
        }
        width={width}
        {...props}
      >
        {radios.map((radio, index) => (
          <RadioItem
            key={index}
            radio={radio}
            radios={radios}
            name={name}
            size={size}
            labelColor={labelColor}
            captionColor={captionColor}
            defaultColor={defaultColor}
            activeColor={activeColor}
            border={border}
            width={width}
            direction={direction}
            checked={radio.value == value}
            handleChange={handleChange}
          />
        ))}
      </HStack>
    )
  }

  return (
    <VStack
      spacing={
        border
          ? '16px'
          : size == 'large'
          ? '24px'
          : size == 'medium'
          ? '20px'
          : '16px'
      }
      width={width}
      {...props}
    >
      {radios.map((radio, index) => (
        <RadioItem
          key={index}
          radio={radio}
          radios={radios}
          name={name}
          size={size}
          labelColor={labelColor}
          captionColor={captionColor}
          defaultColor={defaultColor}
          activeColor={activeColor}
          border={border}
          direction={direction}
          checked={radio.value == value}
          handleChange={handleChange}
        />
      ))}
    </VStack>
  )
}

export default RadioButton
