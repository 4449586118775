import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Drawer, LoadingOverlay } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowB2BAddAgentModal } from '@/Store/B2B/b2bAgentSlice'
import { useForm } from '@inertiajs/react'
import XTextInput from '@/Mantine/XTextInput'
import XSelect from '@/Mantine/XSelect'
import useMeta from '@/Hooks/useMeta'
import useLocation from '@/Hooks/useLocation'
import XPhoneInput from '@/Mantine/XPhoneInput'
import useAuth from '@/Hooks/useAuth'
import INDIAN_STATES from '@/Constants/IndianStates'
import XDatePicker from '@/Mantine/XDatePicker'
import moment from 'moment/moment'
import XNumberInput from '@/Mantine/XNumberInput'
import { XTextarea } from '@/Mantine/XTextarea'

const AddB2BAgentModal = () => {
  const auth = useAuth()
  const meta = useMeta()
  const dispatch = useDispatch()
  const location = useLocation()

  const b2bAgentStore = useSelector((state: RootState) => state.b2bAgent)

  const [loading, setLoading] = useState(false)

  const branchId =
    meta.branches.find((branch) => {
      return branch.is_default
    })?.id ?? null

  const { data, setData, post, errors, reset, clearErrors, recentlySuccessful } = useForm<{
    agency_name: string
    first_name: string
    last_name: string
    email: string
    country: string
    country_code: string
    country_calling_code: string
    phone: string
    address: string
    area: string
    zone: string
    state: string
    city: string
    associate_type: string
    association_date: string
    no_of_renewals: string
    agreement_start_date: string
    agreement_end_date: string
    remarks: string
    relationship_manager_id: string
    relationship_manager_id_one: string
    relationship_manager_id_two: string
    relationship_manager_id_three: string
    relationship_manager_id_four: string
    relationship_manager_id_five: string
    relationship_manager_id_six: string
    relationship_manager_id_seven: string
  }>({
    agency_name: '',
    first_name: '',
    last_name: '',
    email: '',
    country: 'India',
    country_code: 'IN',
    country_calling_code: '+91',
    phone: '',
    address: '',
    area: '',
    zone: '',
    state: '',
    city: '',
    associate_type: '',
    association_date: '',
    no_of_renewals: '',
    agreement_start_date: '',
    agreement_end_date: '',
    remarks: '',
    relationship_manager_id: '',
    relationship_manager_id_one: '',
    relationship_manager_id_two: '',
    relationship_manager_id_three: '',
    relationship_manager_id_four: '',
    relationship_manager_id_five: '',
    relationship_manager_id_six: '',
    relationship_manager_id_seven: '',
  })

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(updateShowB2BAddAgentModal(false))
      clearErrors()
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (location.country_code) {
      setData((prevData) => ({
        ...prevData,
        country: location.country_name,
        country_code: location.country_code,
        country_calling_code: location.country_calling_code,
      }))
    }
  }, [location])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      size={750}
      opened={b2bAgentStore.show_b2b_add_agent_modal}
      onClose={() => {
        dispatch(updateShowB2BAddAgentModal(false))
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack spacing="12px">
          <Typography variant="subheadline">Invite Agent</Typography>
        </HStack>
        <HStack spacing="12px">
          <Divider variant="dotted"></Divider>
        </HStack>
        <VStack spacing="12px" style={{ position: 'relative' }} height="100%">
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack spacing="12px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto', paddingRight: '10px' }}>
            <HStack>
              <Typography variant="body-small-bold" color={XelaColor.Blue3}>
                Add Details
              </Typography>
            </HStack>
            <VStack spacing="30px">
              <VStack spacing="16px">
                <HStack spacing="12px">
                  <XTextInput
                    data-autofocus
                    autoFocus
                    required
                    label={'Agency Name'}
                    error={errors.agency_name}
                    value={data.agency_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        agency_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XTextInput
                    required
                    label={'First Name'}
                    error={errors.first_name}
                    value={data.first_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        first_name: event.target.value,
                      }))
                    }}
                  />
                  <XTextInput
                    required
                    label={'Last Name'}
                    error={errors.last_name}
                    value={data.last_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        last_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XTextInput
                    required
                    label={'Email'}
                    error={errors.email}
                    value={data.email}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        email: event.target.value,
                      }))
                    }}
                  />
                  <XPhoneInput
                    error={errors.phone}
                    dialCode={data.country_calling_code}
                    countryCode={data.country_code}
                    initialValue={data.phone}
                    onChange={(number, dialCode, countryCode, country) => {
                      setData((prevData) => ({
                        ...prevData,
                        phone: number,
                        country_calling_code: dialCode,
                        country_code: countryCode,
                        country: country,
                      }))
                    }}
                  ></XPhoneInput>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  {data.country_code === 'IN' && (
                    <XSelect
                      searchable
                      required
                      label={'State'}
                      error={errors.state}
                      value={data.state}
                      data={INDIAN_STATES}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            state: value,
                          }))
                        }
                      }}
                    />
                  )}
                  {data.country_code !== 'IN' && (
                    <XTextInput
                      required
                      label={'State'}
                      error={errors.state}
                      value={data.state}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          state: event.target.value,
                        }))
                      }}
                    />
                  )}
                  <XTextInput
                    required
                    label={'City'}
                    error={errors.city}
                    value={data.city}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        city: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XTextInput
                    label={'Address'}
                    error={errors.address}
                    value={data.address}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        address: event.target.value,
                      }))
                    }}
                  ></XTextInput>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XTextInput
                    label={'Area'}
                    error={errors.area}
                    value={data.area}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        area: event.target.value,
                      }))
                    }}
                  />
                  <XTextInput
                    label={'Zone'}
                    error={errors.zone}
                    value={data.zone}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        zone: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </VStack>
              <HStack>
                <Divider variant="dotted"></Divider>
              </HStack>
              <VStack spacing="16px">
                <HStack>
                  <Typography variant="body-small-bold" color={XelaColor.Blue3}>
                    Assign Relationship Manager
                  </Typography>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XSelect
                    required
                    searchable
                    value={data.relationship_manager_id}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Main Relationship Manager"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id}
                  />
                  <XSelect
                    searchable
                    value={data.relationship_manager_id_one}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Relationship Manager One (Op)"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id_one: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id_one}
                  />
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XSelect
                    searchable
                    value={data.relationship_manager_id_two}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Relationship Manager Two (Op)"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id_two: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id_two}
                  />
                  <XSelect
                    searchable
                    value={data.relationship_manager_id_three}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Relationship Manager Three (Op)"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id_three: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id_three}
                  />
                </HStack>
                {auth.agency.id === 1111178 && (
                  <>
                    <HStack spacing="12px" alignItems="flex-start">
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_four}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Four (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_four: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_four}
                      />
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_five}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Five (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_five: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_five}
                      />
                    </HStack>
                    <HStack spacing="12px" alignItems="flex-start">
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_six}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Six (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_six: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_six}
                      />
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_seven}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Seven (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_seven: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_seven}
                      />
                    </HStack>
                  </>
                )}
              </VStack>
              <HStack spacing={'16px'}>
                <Divider variant="dotted"></Divider>
              </HStack>
              <VStack spacing="16px" className={'pb-2'}>
                <HStack>
                  <Typography variant="body-small-bold" color={XelaColor.Blue3}>
                    Association/Agreement Details
                  </Typography>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XSelect
                    error={errors.associate_type}
                    label="Associate Type"
                    data={['General', 'Premium', 'Freelancer', 'Franchise']}
                    value={data.associate_type}
                    onChange={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        associate_type: value ? value.toString() : '',
                      }))
                    }}
                  ></XSelect>
                  <XDatePicker
                    label={'Association Date'}
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={data.association_date ? moment(data.association_date, 'YYYY-MM-DD').toDate() : null}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          association_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          association_date: '',
                        }))
                      }
                    }}
                  ></XDatePicker>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XDatePicker
                    label={'Agreement Start Date'}
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={data.agreement_start_date ? moment(data.agreement_start_date, 'YYYY-MM-DD').toDate() : null}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_start_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_start_date: '',
                        }))
                      }
                    }}
                  ></XDatePicker>
                  <XDatePicker
                    label={'Agreement End Date'}
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={data.agreement_end_date ? moment(data.agreement_end_date, 'YYYY-MM-DD').toDate() : null}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_end_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_end_date: '',
                        }))
                      }
                    }}
                  ></XDatePicker>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XNumberInput
                    type={'number'}
                    error={errors.no_of_renewals}
                    label="No of Renewals"
                    value={data.no_of_renewals ? parseInt(data.no_of_renewals) : 0}
                    defaultValue={data.no_of_renewals}
                    onChange={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        no_of_renewals: value ? value.toString() : '',
                      }))
                    }}
                  ></XNumberInput>
                  <XTextarea
                    styles={{
                      input: {
                        height: '50px',
                      },
                    }}
                    label={'Remarks, if any'}
                    error={errors.remarks}
                    value={data.remarks}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        remarks: event.target.value,
                      }))
                    }}
                  ></XTextarea>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack justifyContent="flex-start" spacing="12px">
            <HStack spacing="12px">
              <Button
                label="Invite"
                variant="primary"
                onClick={() => {
                  setLoading(true)
                  post(route('b2b.invites', { _query: { invite_type: 'Invite' } }), {
                    onFinish: () => {
                      setLoading(false)
                    },
                  })
                }}
              />
            </HStack>
            <HStack spacing="12px" justifyContent="flex-end">
              <Button
                label="Cancel"
                variant="secondary"
                onClick={() => {
                  dispatch(updateShowB2BAddAgentModal(false))
                }}
              />
              <Button
                label="Activate"
                variant="primary"
                onClick={() => {
                  setLoading(true)
                  post(route('b2b.invite-and-activate-agent', { _query: { invite_type: 'Activate' } }), {
                    onFinish: () => {
                      setLoading(false)
                    },
                  })
                }}
              />
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default AddB2BAgentModal
