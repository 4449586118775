import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import AdminAgency = App.Models.Agency
import User = App.Models.User
import Branch = App.Models.Branch

export interface AgencyState {
  total_agencies: number
  total_agencies_users: number
  total_agencies_branch: number
  total_users: number
  is_filter_applied: boolean
  is_agency_edited: boolean
  is_selected_all: boolean
  is_need_to_refresh: boolean
  selected_agency: AdminAgency | null
  agency_filters: {
    search: string
    subscription_plan: string[]
    subscription_status: string[]
  }
  agency_users_filters: {
    search: string
  }
  agency_branch_filters: {
    search: string
  }
  selected_agencies_id: number[]
  is_show_agency_user: boolean
  selected_user_agency_id: number | null
  is_user_edited: boolean
  selected_user: User | null
  is_show_agency_branch: boolean
  selected_branch_agency_id: number | null
  is_branch_edited: boolean
  selected_branch: Branch | null
}

const initialState: AgencyState = {
  total_agencies: 0,
  total_agencies_users: 0,
  total_agencies_branch: 0,
  total_users: 0,
  is_filter_applied: false,
  is_agency_edited: false,
  is_selected_all: false,
  is_need_to_refresh: false,
  selected_agency: null,
  agency_filters: {
    search: '',
    subscription_plan: [],
    subscription_status: [],
  },
  agency_users_filters: {
    search: '',
    // subscription_plan: [],
    // subscription_status: [],
  },
  agency_branch_filters: {
    search: '',
  },
  selected_agencies_id: [],
  is_show_agency_user: false,
  selected_user_agency_id: null,
  is_user_edited: false,
  selected_user: null,
  is_show_agency_branch: false,
  selected_branch_agency_id: null,
  is_branch_edited: false,
  selected_branch: null,
}

export const adminAgencySlice = createSlice({
  name: 'agencySlice',
  initialState,
  reducers: {
    updateTotalAdminAgencies(state, action: PayloadAction<number>) {
      state.total_agencies = action.payload
    },
    updateTotalAdminAgenciesUsers(state, action: PayloadAction<number>) {
      state.total_agencies_users = action.payload
    },
    updateTotalAdminAgenciesBranch(state, action: PayloadAction<number>) {
      state.total_agencies_branch = action.payload
    },
    updateEditAdminAgency(
      state,
      action: PayloadAction<{
        is_agency_edited: boolean
        selected_agency: AdminAgency | null
      }>
    ) {
      state.is_agency_edited = action.payload.is_agency_edited
      state.selected_agency = action.payload.selected_agency
    },
    showAgencyUsersList(
      state,
      action: PayloadAction<{
        is_show_agency_user: boolean
        selected_user_agency_id: number | null
      }>
    ) {
      state.is_show_agency_user = action.payload.is_show_agency_user
      state.selected_user_agency_id = action.payload.selected_user_agency_id
    },

    showAgencyBranchList(
      state,
      action: PayloadAction<{
        is_show_agency_branch: boolean
        selected_branch_agency_id: number | null
      }>
    ) {
      state.is_show_agency_branch = action.payload.is_show_agency_branch
      state.selected_branch_agency_id = action.payload.selected_branch_agency_id
    },
    updateEditAdminAgencyUser(
      state,
      action: PayloadAction<{
        is_user_edited: boolean
        selected_user: User | null
      }>
    ) {
      state.is_user_edited = action.payload.is_user_edited
      state.selected_user = action.payload.selected_user
    },
    updateEditAdminAgencyBranch(
      state,
      action: PayloadAction<{
        is_branch_edited: boolean
        selected_branch: Branch | null
      }>
    ) {
      state.is_branch_edited = action.payload.is_branch_edited
      state.selected_branch = action.payload.selected_branch
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
    updateSelectedAllAgencies(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateAdminAgencyFilters(
      state,
      action: PayloadAction<{
        search: string
        subscription_plan: string[]
        subscription_status: string[]
      }>
    ) {
      state.agency_filters = action.payload
    },
    clearAdminAgencyFilters(state) {
      state.agency_filters = {
        search: '',
        subscription_plan: [],
        subscription_status: [],
      }
    },
    updateAdminAgencyUsersFilters(
      state,
      action: PayloadAction<{
        search: string
        // subscription_plan: string[]
        // subscription_status: string[]
      }>
    ) {
      state.agency_users_filters = action.payload
    },
    clearAdminAgencyUsersFilters(state) {
      state.agency_users_filters = {
        search: '',
        // subscription_plan: [],
        // subscription_status: [],
      }
    },
    updateAdminAgencyBranchFilters(
      state,
      action: PayloadAction<{
        search: string
        // subscription_plan: string[]
        // subscription_status: string[]
      }>
    ) {
      state.agency_branch_filters = action.payload
    },
    clearAdminAgencyBranchFilters(state) {
      state.agency_branch_filters = {
        search: '',
        // subscription_plan: [],
        // subscription_status: [],
      }
    },
    updateSelectedAgenciesId: (state, action) => {
      state.selected_agencies_id = action.payload
    },
  },
})

export const {
  updateEditAdminAgency,
  updateTotalAdminAgencies,
  updateTotalAdminAgenciesUsers,
  updateTotalAdminAgenciesBranch,
  updateIsNeedToRefresh,
  updateSelectedAllAgencies,
  updateSelectedAgenciesId,
  updateAdminAgencyFilters,
  updateAdminAgencyUsersFilters,
  updateAdminAgencyBranchFilters,
  clearAdminAgencyFilters,
  clearAdminAgencyUsersFilters,
  showAgencyUsersList,
  updateEditAdminAgencyUser,
  showAgencyBranchList,
  updateEditAdminAgencyBranch,
} = adminAgencySlice.actions

export default adminAgencySlice.reducer
