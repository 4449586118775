import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import B2BAnnouncement = App.Models.B2BAnnouncement

export interface B2BAnnouncementState {
  show_b2b_announcement_drawer: boolean
  b2b_selected_announcement: B2BAnnouncement | null
  b2b_announcement_to_update: B2BAnnouncement | null
  is_announcement_update: boolean
  show_b2b_announcement_analytics_drawer: boolean
}

const initialState: B2BAnnouncementState = {
  show_b2b_announcement_drawer: false,
  show_b2b_announcement_analytics_drawer: false,
  b2b_selected_announcement: null,
  b2b_announcement_to_update: null,
  is_announcement_update: false,
}

export const b2bAnnouncementSlice = createSlice({
  name: 'b2bAnnouncementSlice',
  initialState,
  reducers: {
    updateShowB2BAnnouncementDrawer: (state, action: PayloadAction<boolean>) => {
      state.show_b2b_announcement_drawer = action.payload
    },
    updateB2BSelectedAnnouncement: (state, action: PayloadAction<B2BAnnouncement | null>) => {
      state.b2b_selected_announcement = action.payload
    },
    UpdateB2BAnnouncementToUpdate: (state, action: PayloadAction<B2BAnnouncement | null>) => {
      state.b2b_announcement_to_update = action.payload
    },
    updateIsAnnouncementUpdate: (state, action: PayloadAction<boolean>) => {
      state.is_announcement_update = action.payload
    },
    updateShowB2BAnnouncementAnalyticsDrawer: (state, action: PayloadAction<boolean>) => {
      state.show_b2b_announcement_analytics_drawer = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateShowB2BAnnouncementDrawer,
  updateB2BSelectedAnnouncement,
  UpdateB2BAnnouncementToUpdate,
  updateIsAnnouncementUpdate,
  updateShowB2BAnnouncementAnalyticsDrawer,
} = b2bAnnouncementSlice.actions

export default b2bAnnouncementSlice.reducer
