import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export class BarDataset {
  label: string
  data: Array<number>
  backgroundColor: string
  borderRadius: number

  constructor(
    label: string,
    data: Array<number>,
    backgroundColor: string,
    borderRadius = 6
  ) {
    this.label = label
    this.data = data
    this.borderRadius = borderRadius
    this.backgroundColor = backgroundColor
  }
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export interface BarChartProps extends HTMLAttributes<HTMLDivElement> {
  labels: Array<string>
  datasets: Array<BarDataset>
  padding?: number
  yLabelsColor?: string
  yLabelsFontSize?: number
  yLabelsFontWeight?: string
  yBeforeLabelsText?: string
  yAfterLabelsText?: string
  xLabelsColor?: string
  xLabelsFontSize?: number
  xLabelsFontWeight?: string
  xBeforeLabelsText?: string
  xAfterLabelsText?: string
  stepSize?: number
  beginAtZero?: boolean
  gridColor?: string
  width?: string
  borderRadius?: number
  yMinLabel?: number
}

const BarChartComponent = styled.div<{ width?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`

export const BarChart: FC<BarChartProps> = ({
  labels,
  datasets,
  padding = 16,
  yLabelsColor = XelaColor.Gray7,
  yLabelsFontSize = 12,
  yLabelsFontWeight = '600',
  yBeforeLabelsText = '',
  yAfterLabelsText = '',
  xLabelsColor = XelaColor.Gray7,
  xLabelsFontSize = 12,
  xLabelsFontWeight = '600',
  xBeforeLabelsText = '',
  xAfterLabelsText = '',
  stepSize = 10,
  beginAtZero = true,
  gridColor = XelaColor.Gray12,
  width,
  borderRadius = 6,
  yMinLabel = 0,
}) => {
  const plugins = [
    {
      id: 'customPlugin',
      beforeDatasetsDraw: function (chart: any) {
        if (chart.chartArea) {
          const ctx = chart.ctx
          const chartArea = chart.chartArea
          for (let j = 0; j < chart.data.datasets.length; j++) {
            const barArray = chart.getDatasetMeta(j).data

            ctx.fillStyle = gridColor

            for (let i = 0; i < barArray.length; i++) {
              const { x, width } = barArray[i]

              const _x = x - width / 2
              const _y = chartArea.top
              const _width = width
              const _height = chartArea.bottom - chartArea.top
              let radius = borderRadius

              if (_width < 2 * radius) radius = _width / 2
              if (_height < 2 * radius) radius = _height / 2
              ctx.beginPath()
              ctx.moveTo(_x + radius, _y)
              ctx.arcTo(_x + _width, _y, _x + _width, _y + _height, radius)
              ctx.arcTo(_x + _width, _y + _height, _x, _y + _height, radius)
              ctx.arcTo(_x, _y + _height, _x, _y, radius)
              ctx.arcTo(_x, _y, x + _width, _y, radius)
              ctx.closePath()
              ctx.fill()
            }
          }
        }
      },
    },
  ]

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: padding,
    },
    scales: {
      y: {
        // not 'yAxes: [{' anymore (not an array anymore)
        min: yMinLabel,
        grid: {
          color: gridColor,
          borderColor: gridColor,
        },
        ticks: {
          callback: function (
            value: string | number,
            _index: number,
            _values: any
          ) {
            return yBeforeLabelsText + value + yAfterLabelsText
          },
          color: yLabelsColor, // not 'fontColor:' anymore
          // fontSize: 18,
          font: {
            size: yLabelsFontSize, // 'size' now within object 'font {}'
            weight: yLabelsFontWeight,
          },
          stepSize: stepSize,
          beginAtZero: beginAtZero,
        },
      },
      x: {
        // not 'xAxes: [{' anymore (not an array anymore)
        grid: {
          color: gridColor,
          borderColor: gridColor,
        },
        ticks: {
          callback: function (
            value: string | number,
            _index: number,
            _values: any
          ) {
            return (
              xBeforeLabelsText + labels[value as number] + xAfterLabelsText
            )
          },
          color: xLabelsColor, // not 'fontColor:' anymore
          //fontSize: 14,
          font: {
            size: xLabelsFontSize, // 'size' now within object 'font {}'
            weight: xLabelsFontWeight,
          },
        },
      },
    },
  }

  const data = {
    labels,
    datasets: datasets,
  }

  return (
    <BarChartComponent width={width}>
      <Bar options={options} data={data} plugins={plugins} />
    </BarChartComponent>
  )
}

export default BarChart
