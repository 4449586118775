import * as React from 'react'

function IconMessage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 0.5C1.61911 0.5 0.5 1.61714 0.5 2.9991V14.9913C0.5 16.3733 1.61911 17.4904 3 17.4904H4.5V20.3211C4.5 20.763 4.7496 21.1669 5.14475 21.3645C5.53991 21.5622 6.01282 21.5197 6.36637 21.2547L11.3887 17.4904H19C20.3809 17.4904 21.5 16.3733 21.5 14.9913V2.9991C21.5 1.61714 20.3809 0.5 19 0.5H3ZM6.83333 6.82983H15.1667V7.16317H6.83333V6.82983ZM6.83333 10.8272H12.5V11.1606H6.83333V10.8272Z"
        fill="#E6ECFF"
        stroke="#003CFF"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default IconMessage
