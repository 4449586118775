import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const InvoiceStatusTransitionNotification: FC<{
  notification: Notification
}> = ({ notification }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        router.visit('hub/commission/invoices')
      }}
    >
      <Typography variant="body-small-bold" color={XelaColor.Blue3}>
        {notification.data.event_by}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        changed University Commission Invoice Status from
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {notification.data.old_status}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        to
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {notification.data.new_status}
      </Typography>
    </HStack>
  )
}

export default InvoiceStatusTransitionNotification
