import * as React from 'react'

function IconDrawerClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1036 1.39648H14.1036L14.1036 14.3965H13.1036L13.1036 1.39648ZM2.8107 8.39648L5.95714 11.5429L5.25004 12.25L0.896484 7.89648L5.25004 3.54293L5.95714 4.25004L2.8107 7.39648L12.1036 7.39648V8.39648L2.8107 8.39648Z"
        fill="#1D1929"
      />
    </svg>
  )
}

export default IconDrawerClose
