import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import EntityForm = App.Models.EntityForm

export interface EntityState {
  show_update_lead_form_drawer: boolean
  selected_form: EntityForm
  is_need_to_refresh: boolean
}

const initialState: EntityState = {
  show_update_lead_form_drawer: false,
  selected_form: {} as EntityForm,
  is_need_to_refresh: false,
}

export const entityFormSlice = createSlice({
  name: 'entitySlice',
  initialState,
  reducers: {
    updateShowEntityLeadFormDrawer(state, action: PayloadAction<boolean>) {
      state.show_update_lead_form_drawer = action.payload
    },
    updateSelectedForm(state, action: PayloadAction<EntityForm>) {
      state.selected_form = action.payload
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
  },
})
export const { updateShowEntityLeadFormDrawer, updateSelectedForm, updateIsNeedToRefresh } = entityFormSlice.actions

export default entityFormSlice.reducer
