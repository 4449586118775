import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface CustomTooltipProps {
  children?: ReactNode
  content?: ReactNode
  width?: string
  height?: string
  bg?: string
  position?: 'top' | 'bottom' | 'left' | 'right'
}

type TooltipProps = CustomTooltipProps & HTMLAttributes<HTMLDivElement>

const TooltipComponent = styled.div<{
  width: string
  height: string
  bg: string
  tooltipPosition: string
}>`
  position: relative;
  display: inline-block;

  & .xela-tooltip {
    position: absolute;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background: ${(props) => props.bg};
    z-index: 100;
    border-radius: 10px;
    padding: 8px 16px;
    ${(props) =>
      props.tooltipPosition == 'bottom' &&
      css`
        left: 50%;
        top: 100%;
        transform: translate(-50%, 12px);
      `}
    ${(props) =>
      props.tooltipPosition == 'right' &&
      css`
        left: 100%;
        top: 50%;
        transform: translate(12px, -50%);
      `}
    ${(props) =>
      props.tooltipPosition == 'left' &&
      css`
        left: 0;
        top: 50%;
        transform: translate(calc(-100% - 11.5px), -50%);
      `}
    ${(props) =>
      props.tooltipPosition == 'top' &&
      css`
        left: 50%;
        top: 0;
        transform: translate(-50%, calc(-100% - 12px));
      `}
  }

  &:hover:before {
    content: '';
    position: absolute;
    border: 8px solid ${(props) => props.bg};
    ${(props) =>
      props.tooltipPosition == 'left'
        ? 'border-color: transparent transparent transparent ' + props.bg + ';'
        : props.tooltipPosition == 'right'
        ? 'border-color: transparent  ' + props.bg + ' transparent transparent;'
        : props.tooltipPosition == 'top'
        ? 'border-color: ' + props.bg + ' transparent transparent transparent;'
        : 'border-color:  transparent transparent ' +
          props.bg +
          ' transparent;'}
    ${(props) =>
      props.tooltipPosition == 'bottom' &&
      css`
        top: 100%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 4px));
      `}

    ${(props) =>
      props.tooltipPosition == 'right' &&
      css`
        top: 50%;
        left: 100%;
        transform: translate(calc(-50% + 4px), -50%);
      `}

    ${(props) =>
      props.tooltipPosition == 'left' &&
      css`
        top: 50%;
        left: 0;
        transform: translate(calc(-50% - 4px), -50%);
      `}

    ${(props) =>
      props.tooltipPosition == 'top' &&
      css`
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-100% + 4px));
      `}
  }
`

export const Tooltip: FC<TooltipProps> = ({
  children,
  width = 'auto',
  height = 'auto',
  bg = XelaColor.Gray3,
  content,
  position = 'bottom',
  ...props
}) => {
  const [active, setActive] = useState(false)

  const className = 'xela-tooltip ' + position

  return (
    <TooltipComponent
      width={width}
      height={height}
      bg={bg}
      onMouseEnter={() => {
        setActive(true)
      }}
      onMouseLeave={() => {
        setActive(false)
      }}
      tooltipPosition={position}
      {...props}
    >
      {children}
      {active && <div className={className}>{content}</div>}
    </TooltipComponent>
  )
}

export default Tooltip
