import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import { Box } from '@mantine/core'
import Notification = App.Models.Notification

const TaskAssignedWithLeadNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        if (notification.data.lead_id === null) return
        if (notification.data.lead_type === 'App\\Models\\VisitorLead') {
          router.visit(
            route('visitor-leads.id', {
              id: notification.data.lead_id,
              show_tab: 'tasks',
            })
          )
        } else if (notification.data.lead_type === 'App\\Models\\IeltsLead') {
          router.visit(
            route('ielts-leads.id', {
              id: notification.data.lead_id,
              show_tab: 'tasks',
            })
          )
        } else if (notification.data.agent_id) {
          router.visit(
            route('b2b.leads.show', {
              id: notification.data.lead_id,
              show_tab: 'tasks',
            })
          )
        } else {
          router.visit(
            route('leads.id', {
              id: notification.data.lead_id,
              show_tab: 'tasks',
            })
          )
        }
      }}
    >
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          assigned a new{notification.data.task_due_today ? ' URGENT' : ''} task
        </Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          in Lead
        </Typography>
        {notification.data.agent_id !== null && (
          <Typography variant="body-small-bold" color={XelaColor.Pink3}>
            B2B Lead
          </Typography>
        )}
        <Typography variant="body-small-bold" color={XelaColor.Orange3}>
          #{notification.data.lead_name}
        </Typography>
      </HStack>
      <HStack spacing="12px" height="100%" style={{ padding: '6px 12px' }}>
        <Box
          style={{
            width: '8px',
            height: '30px',
            backgroundColor: XelaColor.Blue3,
            borderRadius: '4px',
          }}
        ></Box>
        <HStack
          spacing="3px"
          style={{
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          <Typography variant="body-small-bold">{notification.data.task}</Typography>
        </HStack>
      </HStack>
    </VStack>
  )
}

export default TaskAssignedWithLeadNotification
