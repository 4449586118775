import * as React from 'react'

function IconFileUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={41}
      height={40}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.334 7.045v21.788h-1.667V6.012l-.854.853-5.37 5.37-1.178-1.178 8.212-8.212 8.888 8.204-1.13 1.224-6.062-5.595-.84-.775v1.142zm-16 27.622v.5h30.333v-16h1.667v17.666H3.667V19.167h1.667v15.5z"
        fill="#E6ECFF"
        stroke="#003CFF"
      />
    </svg>
  )
}

export default IconFileUpload
