import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled from 'styled-components'
import Button from '@/XelaReact/Button/Button'
import HStack from '@/XelaReact/HStack/HStack'
import SvgIcon from '@/XelaReact/SvgIcon/SvgIcon'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface NumberInputProps extends HTMLAttributes<HTMLInputElement> {
  placeholder?: string
  value?: string
  bg?: string
  defaultTextColor?: string
  secondaryTextColor?: string
  defaultBorderColor: string
  hoverColor?: string
  focusColor?: string
  errorColor?: string
  successColor?: string
  helperText?: string
  error?: true | false
  success?: true | false
  disabled?: true | false
  increaseIcon?: ReactNode
  decreaseIcon?: ReactNode
  controls?: 'left' | 'right' | 'leftright'
  minValue?: number
  maxValue?: number
}

interface NumberInputPropsComponent extends NumberInputProps {
  isHover?: boolean
}

const NumberInputComponent = styled(HStack)<NumberInputPropsComponent>`
  outline: 1px solid
    ${(props) =>
      props.isHover
        ? props.hoverColor
        : props.error
        ? props.errorColor
        : props.success
        ? props.successColor
        : props.defaultBorderColor};
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};
`

const ControlButton = styled(Button)`
  outline: none;
  height: 24px;
  padding: 0px 14px;
`

const NumberInputInput = styled.input<{ color: string; align: string }>`
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: ${(props) => props.color};
  font-family: inherit;
  text-align: ${(props) => props.align};
  background: none;
`

export const NumberInput: FC<NumberInputProps> = ({
  placeholder,
  value = '1',
  bg = XelaColor.White,
  defaultTextColor = XelaColor.Gray2,
  secondaryTextColor = XelaColor.Gray7,
  defaultBorderColor = XelaColor.Gray11,
  hoverColor = XelaColor.Blue5,
  errorColor = XelaColor.Red3,
  successColor = XelaColor.Green1,
  error = false,
  success = false,
  disabled = false,
  helperText,
  controls = 'leftright',
  increaseIcon = (
    <SvgIcon isInherit={false}>
      <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 7.5V1.5H8V7.5H14V8.5H8V14.5H7V8.5H1V7.5H7Z"
          fill="#1D1929"
        />
      </svg>
    </SvgIcon>
  ),
  decreaseIcon = (
    <SvgIcon isInherit={false}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 8H1V7H14V8Z"
          fill="#1D1929"
        />
      </svg>
    </SvgIcon>
  ),
  maxValue = 999,
  minValue = 1,
  ...props
}) => {
  const [isHover, setHover] = useState(false)
  const [val, setVal] = useState(value)

  return (
    <div
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <VStack justifyContent="center" spacing="8px">
        <NumberInputComponent
          defaultTextColor={defaultTextColor}
          isHover={disabled ? false : isHover}
          hoverColor={hoverColor}
          error={error}
          success={success}
          errorColor={errorColor}
          successColor={successColor}
          defaultBorderColor={defaultBorderColor}
          spacing="0px"
          justifyContent="center"
          bg={bg}
          width="158px"
          height="54px"
          disabled={disabled}
          borderRadius="18px"
        >
          {(controls == 'leftright' || controls == 'left') && (
            <VStack width="64px" justifyContent="center">
              {controls == 'leftright' && (
                <ControlButton
                  disabled={disabled}
                  onClick={() => {
                    const nVal = parseInt(val) - 1
                    if (nVal >= minValue) {
                      setVal(String(nVal))
                    }
                  }}
                  size="small"
                  leftIcon={decreaseIcon}
                  bg="none"
                  variant="secondary"
                />
              )}
              {controls == 'left' && (
                <ControlButton
                  disabled={disabled}
                  onClick={() => {
                    const nVal = parseInt(val) + 1
                    if (nVal <= maxValue) {
                      setVal(String(nVal))
                    }
                  }}
                  size="small"
                  leftIcon={increaseIcon}
                  bg="none"
                  variant="secondary"
                />
              )}
              {controls == 'left' && (
                <ControlButton
                  disabled={disabled}
                  onClick={() => {
                    const nVal = parseInt(val) - 1
                    if (nVal >= minValue) {
                      setVal(String(nVal))
                    }
                  }}
                  size="small"
                  leftIcon={decreaseIcon}
                  bg="none"
                  variant="secondary"
                />
              )}
            </VStack>
          )}
          <VStack
            alignItems="center"
            spacing="4px"
            justifyContent="center"
            style={{
              marginRight: controls == 'left' ? '16px' : '0px',
              marginLeft: controls == 'right' ? '16px' : '0px',
            }}
          >
            {placeholder !== undefined && (
              <Typography
                as="p"
                align={
                  controls == 'leftright'
                    ? 'center'
                    : controls == 'left'
                    ? 'right'
                    : 'left'
                }
                variant="body-small"
                color={secondaryTextColor}
              >
                {placeholder}
              </Typography>
            )}
            <NumberInputInput
              value={val}
              align={
                controls == 'leftright'
                  ? 'center'
                  : controls == 'left'
                  ? 'right'
                  : 'left'
              }
              color={defaultTextColor}
              readOnly={!disabled}
              {...props}
            />
          </VStack>
          {(controls == 'leftright' || controls == 'right') && (
            <VStack width="64px" justifyContent="center">
              {controls == 'leftright' && (
                <ControlButton
                  disabled={disabled}
                  size="small"
                  onClick={() => {
                    const nVal = parseInt(val) + 1
                    if (nVal <= maxValue) {
                      setVal(String(nVal))
                    }
                  }}
                  leftIcon={increaseIcon}
                  bg="none"
                  variant="secondary"
                />
              )}
              {controls == 'right' && (
                <ControlButton
                  disabled={disabled}
                  onClick={() => {
                    const nVal = parseInt(val) + 1
                    if (nVal <= maxValue) {
                      setVal(String(nVal))
                    }
                  }}
                  size="small"
                  leftIcon={increaseIcon}
                  bg="none"
                  variant="secondary"
                />
              )}
              {controls == 'right' && (
                <ControlButton
                  disabled={disabled}
                  onClick={() => {
                    const nVal = parseInt(val) - 1
                    if (nVal >= minValue) {
                      setVal(String(nVal))
                    }
                  }}
                  size="small"
                  leftIcon={decreaseIcon}
                  bg="none"
                  variant="secondary"
                />
              )}
            </VStack>
          )}
        </NumberInputComponent>
        {helperText !== undefined && (
          <Typography
            as="p"
            color={
              error ? errorColor : success ? successColor : secondaryTextColor
            }
            variant="caption"
          >
            {helperText}
          </Typography>
        )}
      </VStack>
    </div>
  )
}

export default NumberInput
