import * as React from 'react'

function IconUserSquare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0A1.5 1.5 0 000 1.5v12A1.5 1.5 0 001.5 15h12a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0013.5 0h-12zm5 9A3.5 3.5 0 003 12.5v1a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-1A3.5 3.5 0 008.5 9h-2zM5 5.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
        fill="#E6ECFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h.585A1.498 1.498 0 012 13.5v-1c0-2.05 1.37-3.78 3.245-4.323a3.5 3.5 0 114.51 0A4.501 4.501 0 0113 12.5V13.5c0 .175-.03.344-.085.5h.585a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5h-12zm12 14a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0013.5 0h-12A1.5 1.5 0 000 1.5v12A1.5 1.5 0 001.5 15h12zM3 12.5A3.5 3.5 0 016.5 9h2a3.5 3.5 0 013.5 3.5v1a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-1zM7.5 3a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        fill="#003CFF"
      />
    </svg>
  )
}

export default IconUserSquare
