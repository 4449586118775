import React, { FC, HTMLAttributes, ReactElement } from 'react'
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser'

export interface SvgIconProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactElement
  width?: string
  height?: string
  fillColor?: string
  strokeColor?: string
  isInherit?: true | false
}

export const SvgIcon: FC<SvgIconProps> = ({
  children,
  width,
  height,
  fillColor,
  strokeColor,
  isInherit = false,
}): React.JSX.Element => {
  let icon = renderToString(children)

  const widthReg = / width(\s+|)=(\s+|)(["'])(.*?)(["'])/g
  if (icon.match(widthReg)) {
    if (width !== undefined && width !== '') {
      icon = icon.replace(widthReg, ' width="' + width + '"')
    }
  }

  const heightReg = / height(\s+|)=(\s+|)(["'])(.*?)(["'])/g
  if (icon.match(heightReg)) {
    if (height !== undefined && height !== '') {
      icon = icon.replace(heightReg, ' height="' + height + '"')
    }
  }

  const fillReg = /fill(\s+|)=(\s+|)(["'])(.*?)(["'])/g

  if (icon.match(fillReg)) {
    if (fillColor !== undefined && fillColor !== '') {
      icon = icon.replace(fillReg, 'fill="' + fillColor + '"')
    } else {
      if (isInherit) {
        icon = icon.replace(fillReg, '')
      }
    }
  } else {
    icon = icon.replace(/<svg/, '$& fill="none"')
  }

  const strokeReg = /stroke(\s+|)=(\s+|)(["'])(.*?)(["'])/g
  if (icon.match(strokeReg)) {
    if (strokeColor !== undefined && strokeColor !== '') {
      icon = icon.replace(strokeReg, 'stroke="' + strokeColor + '"')
    } else {
      if (isInherit) {
        icon = icon.replace(strokeReg, '')
      }
    }
  } else {
    icon = icon.replace(/<svg/, '$& stroke="none"')
  }
  return <>{parse(icon)}</>
}

export default SvgIcon
