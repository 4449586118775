import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Drawer, LoadingOverlay } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateB2BSelectedAgent, updateShowB2BAgentModal } from '@/Store/B2B/b2bAgentSlice'
import { useForm } from '@inertiajs/react'
import XTextInput from '@/Mantine/XTextInput'
import XSelect from '@/Mantine/XSelect'
import INDIAN_STATES from '@/Constants/IndianStates'
import useAuth from '@/Hooks/useAuth'
import useMeta from '@/Hooks/useMeta'
import XDatePicker from '@/Mantine/XDatePicker'
import moment from 'moment'
import XNumberInput from '@/Mantine/XNumberInput'
import { XTextarea } from '@/Mantine/XTextarea'

const EditB2BAgentModal = () => {
  const auth = useAuth()
  const meta = useMeta()
  const dispatch = useDispatch()

  const b2bAgentStore = useSelector((state: RootState) => state.b2bAgent)

  const [loading, setLoading] = useState(false)

  const branchId =
    meta.branches.find((branch) => {
      return branch.is_default
    })?.id ?? null

  const { data, setData, post, errors, reset, clearErrors, recentlySuccessful } = useForm<{
    agency_name: string
    first_name: string
    last_name: string
    country_code: string
    state: string
    city: string
    address: string
    area: string
    zone: string
    associate_type: string
    association_date: string
    no_of_renewals: string
    agreement_start_date: string
    agreement_end_date: string
    remarks: string
    relationship_manager_id: string
    relationship_manager_id_one: string
    relationship_manager_id_two: string
    relationship_manager_id_three: string
    relationship_manager_id_four: string
    relationship_manager_id_five: string
    relationship_manager_id_six: string
    relationship_manager_id_seven: string
  }>({
    agency_name: '',
    first_name: '',
    last_name: '',
    country_code: '',
    address: '',
    area: '',
    zone: '',
    state: '',
    city: '',
    associate_type: '',
    association_date: '',
    no_of_renewals: '',
    agreement_start_date: '',
    agreement_end_date: '',
    remarks: '',
    relationship_manager_id: '',
    relationship_manager_id_one: '',
    relationship_manager_id_two: '',
    relationship_manager_id_three: '',
    relationship_manager_id_four: '',
    relationship_manager_id_five: '',
    relationship_manager_id_six: '',
    relationship_manager_id_seven: '',
  })

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(updateShowB2BAgentModal(false))
      dispatch(dispatch(updateB2BSelectedAgent(null)))
      clearErrors()
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (b2bAgentStore.b2b_selected_agent !== null) {
      setData((prevData) => ({
        ...prevData,
        agency_name: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.agency_name : '',
        first_name: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.first_name : '',
        last_name: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.last_name : '',
        country_code: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.country_code : '',
        state: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.state : '',
        city: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.city : '',
        address: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.address : '',
        area: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.area : '',
        zone: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.zone : '',
        associate_type:
          b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.associate_type : '',
        association_date:
          b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.association_date : '',
        no_of_renewals:
          b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.no_of_renewals : '',
        agreement_start_date:
          b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.agreement_start_date : '',
        agreement_end_date:
          b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.agreement_end_date : '',
        remarks: b2bAgentStore.b2b_selected_agent !== null ? b2bAgentStore.b2b_selected_agent.pivot.remarks : '',
        relationship_manager_id: b2bAgentStore.b2b_selected_agent?.relationship_manager?.id?.toString() ?? '',
        relationship_manager_id_one: b2bAgentStore.b2b_selected_agent?.relationship_manager_one?.id?.toString() ?? '',
        relationship_manager_id_two: b2bAgentStore.b2b_selected_agent?.relationship_manager_two?.id?.toString() ?? '',
        relationship_manager_id_three:
          b2bAgentStore.b2b_selected_agent?.relationship_manager_three?.id?.toString() ?? '',
        relationship_manager_id_four: b2bAgentStore.b2b_selected_agent?.relationship_manager_four?.id?.toString() ?? '',
        relationship_manager_id_five: b2bAgentStore.b2b_selected_agent?.relationship_manager_five?.id?.toString() ?? '',
        relationship_manager_id_six: b2bAgentStore.b2b_selected_agent?.relationship_manager_six?.id?.toString() ?? '',
        relationship_manager_id_seven:
          b2bAgentStore.b2b_selected_agent?.relationship_manager_seven?.id?.toString() ?? '',
      }))
    }
  }, [b2bAgentStore.b2b_selected_agent])

  return (
    <Drawer
      size={800}
      position={'right'}
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      opened={b2bAgentStore.show_b2b_agent_modal}
      onClose={() => {
        dispatch(updateShowB2BAgentModal(false))
        dispatch(dispatch(updateB2BSelectedAgent(null)))
        clearErrors()
        reset()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack spacing="12px">
          <Typography variant="subheadline">Edit Agent</Typography>
        </HStack>
        <HStack spacing="12px">
          <Divider></Divider>
        </HStack>
        <VStack spacing="12px" style={{ position: 'relative' }} height="100%">
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack spacing="12px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto', paddingRight: '10px' }}>
            <HStack>
              <Typography variant="body-small-bold" color={XelaColor.Blue3}>
                Agent Details
              </Typography>
            </HStack>
            <VStack spacing="20px">
              <VStack spacing="16px">
                <HStack spacing="12px" alignItems="flex-start">
                  <XTextInput
                    data-autofocus
                    required
                    label={'First Name'}
                    error={errors.first_name}
                    value={data.first_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        first_name: event.target.value,
                      }))
                    }}
                  />
                  <XTextInput
                    required
                    label={'Last Name'}
                    error={errors.last_name}
                    value={data.last_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        last_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </VStack>
              <HStack>
                <Divider variant="dotted"></Divider>
              </HStack>
              <VStack spacing="16px">
                <HStack>
                  <Typography variant="body-small-bold" color={XelaColor.Blue3}>
                    Agency Details
                  </Typography>
                </HStack>
                <HStack>
                  <XTextInput
                    required
                    label={'Agency Name'}
                    error={errors.agency_name}
                    value={data.agency_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        agency_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  {data.country_code === 'IN' && (
                    <XSelect
                      searchable
                      required
                      label={'State'}
                      error={errors.state}
                      value={data.state}
                      data={INDIAN_STATES}
                      onChange={(value) => {
                        if (value) {
                          setData((prevData) => ({
                            ...prevData,
                            state: value,
                          }))
                        }
                      }}
                    />
                  )}
                  {data.country_code !== 'IN' && (
                    <XTextInput
                      required
                      label={'State'}
                      error={errors.state}
                      value={data.state}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          state: event.target.value,
                        }))
                      }}
                    />
                  )}
                  <XTextInput
                    required
                    label={'City'}
                    error={errors.city}
                    value={data.city}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        city: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XTextInput
                    label={'Address'}
                    error={errors.address}
                    value={data.address}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        address: event.target.value,
                      }))
                    }}
                  ></XTextInput>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XTextInput
                    label={'Area'}
                    error={errors.area}
                    value={data.area}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        area: event.target.value,
                      }))
                    }}
                  />
                  <XTextInput
                    label={'Zone'}
                    error={errors.zone}
                    value={data.zone}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        zone: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </VStack>
              <HStack>
                <Divider variant="dotted"></Divider>
              </HStack>
              <VStack spacing="16px">
                <HStack>
                  <Typography variant="body-small-bold" color={XelaColor.Blue3}>
                    Relationship Manager Details
                  </Typography>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XSelect
                    required
                    searchable
                    value={data.relationship_manager_id}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Main Relationship Manager"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id}
                  />
                  <XSelect
                    searchable
                    value={data.relationship_manager_id_one}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Relationship Manager One (Op)"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id_one: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id_one}
                  />
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XSelect
                    searchable
                    value={data.relationship_manager_id_two}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Relationship Manager Two (Op)"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id_two: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id_two}
                  />
                  <XSelect
                    searchable
                    value={data.relationship_manager_id_three}
                    data={
                      branchId
                        ? meta.branchesUsers[branchId].users.map((counsellor) => {
                            let isCounsellorEnabled = false

                            if (counsellor.role.permissions.includes('b2b_module_access')) {
                              isCounsellorEnabled = true
                            }

                            return {
                              value: counsellor.id.toString(),
                              label: counsellor.name,
                              group: counsellor.branch.branch_name,
                              disabled: !isCounsellorEnabled,
                            }
                          })
                        : []
                    }
                    label="Select Relationship Manager Three (Op)"
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          relationship_manager_id_three: value,
                        }))
                      }
                    }}
                    error={errors.relationship_manager_id_three}
                  />
                </HStack>
                {auth.agency.id === 1111178 && (
                  <>
                    <HStack spacing="12px" alignItems="flex-start">
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_four}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Four (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_four: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_four}
                      />
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_five}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Five (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_five: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_five}
                      />
                    </HStack>
                    <HStack spacing="12px" alignItems="flex-start">
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_six}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Six (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_six: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_six}
                      />
                      <XSelect
                        searchable
                        value={data.relationship_manager_id_seven}
                        data={
                          branchId
                            ? meta.branchesUsers[branchId].users.map((counsellor) => {
                                let isCounsellorEnabled = false

                                if (counsellor.role.permissions.includes('b2b_module_access')) {
                                  isCounsellorEnabled = true
                                }

                                return {
                                  value: counsellor.id.toString(),
                                  label: counsellor.name,
                                  group: counsellor.branch.branch_name,
                                  disabled: !isCounsellorEnabled,
                                }
                              })
                            : []
                        }
                        label="Select Relationship Manager Seven (Op)"
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              relationship_manager_id_seven: value,
                            }))
                          }
                        }}
                        error={errors.relationship_manager_id_seven}
                      />
                    </HStack>
                  </>
                )}
              </VStack>
              <HStack spacing={'16px'}>
                <Divider variant="dotted"></Divider>
              </HStack>
              <VStack spacing="16px" className={'pb-2'}>
                <HStack>
                  <Typography variant="body-small-bold" color={XelaColor.Blue3}>
                    Association/Agreement Details
                  </Typography>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XSelect
                    error={errors.associate_type}
                    label="Associate Type"
                    data={['General', 'Premium', 'Freelancer', 'Franchise']}
                    value={data.associate_type}
                    onChange={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        associate_type: value ? value.toString() : '',
                      }))
                    }}
                  ></XSelect>
                  <XDatePicker
                    label={'Association Date'}
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={data.association_date ? moment(data.association_date, 'YYYY-MM-DD').toDate() : null}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          association_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          association_date: '',
                        }))
                      }
                    }}
                  ></XDatePicker>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XDatePicker
                    label={'Agreement Start Date'}
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={data.agreement_start_date ? moment(data.agreement_start_date, 'YYYY-MM-DD').toDate() : null}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_start_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_start_date: '',
                        }))
                      }
                    }}
                  ></XDatePicker>
                  <XDatePicker
                    label={'Agreement End Date'}
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={data.agreement_end_date ? moment(data.agreement_end_date, 'YYYY-MM-DD').toDate() : null}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_end_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          agreement_end_date: '',
                        }))
                      }
                    }}
                  ></XDatePicker>
                </HStack>
                <HStack spacing="12px" alignItems="flex-start">
                  <XNumberInput
                    type={'number'}
                    error={errors.no_of_renewals}
                    label="No of Renewals"
                    value={data.no_of_renewals ? parseInt(data.no_of_renewals) : 0}
                    defaultValue={data.no_of_renewals}
                    onChange={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        no_of_renewals: value ? value.toString() : '',
                      }))
                    }}
                  ></XNumberInput>
                  <XTextarea
                    styles={{
                      input: {
                        height: '50px',
                      },
                    }}
                    label={'Remarks, if any'}
                    error={errors.remarks}
                    value={data.remarks}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        remarks: event.target.value,
                      }))
                    }}
                  ></XTextarea>
                </HStack>
              </VStack>
            </VStack>
          </VStack>
          <HStack spacing={'16px'}>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack justifyContent="flex-end" spacing="12px">
            <Button
              label="Cancel"
              variant="secondary"
              onClick={() => {
                dispatch(updateShowB2BAgentModal(false))
                dispatch(dispatch(updateB2BSelectedAgent(null)))
                clearErrors()
                reset()
              }}
            />
            <Button
              label="Save"
              variant="primary"
              onClick={() => {
                if (b2bAgentStore.b2b_selected_agent !== null) {
                  setLoading(true)
                  post(
                    route('b2b.agents.edit', {
                      id: b2bAgentStore.b2b_selected_agent.id,
                    }),
                    {
                      onFinish: () => {
                        setLoading(false)
                      },
                    }
                  )
                }
              }}
            />
          </HStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default EditB2BAgentModal
