import React, { FC, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import './VStack.css'

export interface VStackProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  className?: string
  spacing?: string
  width?: string
  height?: string
  bg?: string
  borderRadius?: string
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
}

const VStackComponent = styled.div<VStackProps>`
  gap: ${(props) => props.spacing};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
  border-radius: ${(props) => props.borderRadius};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`

export const VStack: FC<VStackProps> = ({
  children,
  className,
  spacing = '0px',
  width = '100%',
  height = 'initial',
  bg = 'none',
  borderRadius = '0px',
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  ...props
}) => {
  return (
    <VStackComponent
      spacing={spacing}
      width={width}
      height={height}
      bg={bg}
      justifyContent={justifyContent}
      borderRadius={borderRadius}
      alignItems={alignItems}
      className={`xela-vstack${className !== undefined ? ' ' + className : ''}`}
      {...props}
    >
      {children}
    </VStackComponent>
  )
}

export default VStack
