import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { WorkflowStep } from '@/Types/WorkflowTypes'
import Workflow = App.Models.Workflow

export interface WorkflowState {
  workflow: Workflow | null
  workflow_steps: WorkflowStep[]
  show_workflow_information_form_drawer: boolean
}

const initialState: WorkflowState = {
  workflow: null,
  workflow_steps: [],
  show_workflow_information_form_drawer: false,
}

export const workflowSlice = createSlice({
  name: 'workflowSlice',
  initialState,
  reducers: {
    updateWorkflow(state, action: PayloadAction<Workflow | null>) {
      state.workflow = action.payload
    },
    updateWorkflowSteps(state, action: PayloadAction<WorkflowStep[]>) {
      state.workflow_steps = action.payload
    },
    updateShowWorkflowInformationFormDrawer(state, action: PayloadAction<boolean>) {
      state.show_workflow_information_form_drawer = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateWorkflow, updateWorkflowSteps, updateShowWorkflowInformationFormDrawer } = workflowSlice.actions

export default workflowSlice.reducer
