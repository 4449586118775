import React from 'react'
import { XelaColor } from '@/XelaReact'
import { Box } from '@mantine/core'
import { router } from '@inertiajs/react'
import reactStringReplace from 'react-string-replace'
import moment from 'moment'
import { clone } from 'lodash'
import { updateShowDocumentEditor } from '@/Store/globalSlice'
import { useDispatch } from 'react-redux'
import { EntityFormItem, EntitySectionData } from '@/Types/EntitySubTypes'
import Branch = App.Models.Branch

export const getSwimlaneHeadingTextColor = (color: string): string => {
  switch (color) {
    case 'blue':
      return XelaColor.Blue3
    case 'pink':
      return XelaColor.Pink3
    case 'yellow':
      return XelaColor.Yellow3
    case 'orange':
      return XelaColor.Orange3
    case 'green':
      return XelaColor.Green3
    case 'red':
      return XelaColor.Red3
  }

  return XelaColor.Blue3
}

export const getSwimlaneHeadingBackgroundColor = (color: string): string => {
  switch (color) {
    case 'blue':
      return XelaColor.Blue12
    case 'pink':
      return XelaColor.Pink12
    case 'yellow':
      return XelaColor.Yellow12
    case 'orange':
      return XelaColor.Orange12
    case 'green':
      return XelaColor.Green12
    case 'red':
      return XelaColor.Red12
  }

  return XelaColor.Blue12
}

export const greetingTime = () => {
  const currentHour: number = (moment().format('HH') as unknown) as number

  if (currentHour >= 3 && currentHour < 12) {
    return 'Good Morning'
  } else if (currentHour >= 12 && currentHour < 15) {
    return 'Good Afternoon'
  } else if (currentHour >= 15 && currentHour < 20) {
    return 'Good Evening'
  } else if (currentHour >= 20 && currentHour < 3) {
    return 'Good Night'
  } else {
    return 'Hello'
  }
}

export const getRouteFromEntity = (
  entity: string,
  entityId: number,
  agentId: number | null = null,
  showTab: string | null = null
) => {
  switch (entity) {
    case 'Lead':
      if (agentId != null) {
        return showTab ? `/b2b/leads/${entityId}?via=leads&show_tab=${showTab}` : `/b2b/leads/${entityId}?via=leads`
      }

      return showTab ? `/leads/${entityId}?show_tab=${showTab}` : `/leads/${entityId}`
    case 'Counselling':
      return showTab ? `/counsellings/${entityId}?show_tab=${showTab}` : `/counsellings/${entityId}`
    case 'Admission':
      return showTab ? `/admissions/${entityId}?show_tab=${showTab}` : `/admissions/${entityId}`
    case 'Visa':
      return showTab ? `/visas/${entityId}?show_tab=${showTab}` : `/visas/${entityId}`
    case 'IELTS Lead':
      return showTab ? `/ielts-leads/${entityId}?show_tab=${showTab}` : `/ielts-leads/${entityId}`
    case 'Visitor Lead':
      return showTab ? `/visitor-leads/${entityId}?show_tab=${showTab}` : `/visitor-leads/${entityId}`
  }

  return showTab ? `/leads/${entityId}?show_tab=${showTab}` : `/leads/${entityId}`
}

export const replaceVariablesForMentions = (string: string) => {
  const dispatch = useDispatch()

  let replacedText

  // Match URLs
  replacedText = reactStringReplace(string, /(https?:\/\/\S+)/g, (match, i) => (
    <a key={match + i} href={match}>
      {match}
    </a>
  ))

  // Match @mentions
  replacedText = reactStringReplace(replacedText, /(@\[[\w\s]+]\(user:\d+\))/g, (match, i) => {
    const results = /@\[([\w\s]+)]\(user:(\d+)\)/g.exec(match)

    if (results && results[1] && results[2])
      return (
        <Box
          key={match + i}
          component="span"
          style={{
            color: XelaColor.Blue3,
            marginRight: '1px',
          }}
        >
          {`@${results[1]}`}
        </Box>
      )

    return match
  })

  let leadId: string | undefined

  // Match #mentions
  replacedText = reactStringReplace(replacedText, /(#\[[\w\s]+\(\d+\)]\(lead:\d+\))/g, (match, i) => {
    const results = /#\[([\w\s]+)\((\d+)\)]\(lead:(\d+)\)/g.exec(match)

    if (results && results[1] && results[2] && results[3]) {
      leadId = clone(results[3])
      return (
        <Box
          key={match + i}
          component="span"
          style={{
            color: XelaColor.Orange3,
            cursor: 'pointer',
            marginRight: '1px',
          }}
          onClick={() => {
            router.visit(route('leads.id', { id: results[3] }))
          }}
        >
          {`#${results[1]} (${results[2]})`}
        </Box>
      )
    }

    return match
  })

  // Match !mentions
  replacedText = reactStringReplace(replacedText, /(!\[[\w\s]+]\(document:[\w\s]+\))/g, (match, i) => {
    const results = /!\[([\w\s]+)]\(document:([\w\s]+)\)/g.exec(match)

    if (results && results[1] && results[2])
      return (
        <Box
          key={match + i}
          component="span"
          style={{
            color: XelaColor.Purple3,
            cursor: 'pointer',
            marginRight: '1px',
          }}
          onClick={() => {
            dispatch(
              updateShowDocumentEditor({
                show: true,
                documentType: results[2],
                documentLeadId: leadId,
              })
            )
          }}
        >
          {`!${results[1]}`}
        </Box>
      )

    return match
  })

  return replacedText
}

export const getInitials = (firstName: string, lastName: string): string => {
  if (lastName === undefined || lastName === null || lastName === '' || lastName === '.') {
    return firstName.charAt(0).toUpperCase().slice(0, 2)
  }

  return firstName.slice(0, 1).toUpperCase() + lastName.slice(0, 1).toUpperCase()
}

export const maskPhone = (phone: string, country_calling_code: string) => {
  if (!phone) {
    return ''
  } else {
    const visibleDigits = 2
    const phoneLength = phone.length

    if (phoneLength <= visibleDigits) {
      return (country_calling_code ?? '') + phone
    }

    const hiddenPart = '*'.repeat(phoneLength - visibleDigits)
    return (country_calling_code ?? '') + hiddenPart + phone.slice(-visibleDigits)
  }
}

export const maskEmail = (email: string) => {
  if (email === undefined || email === null || email === '') {
    return ''
  } else {
    const [username, domain] = email.split('@')
    return '*'.repeat(username.length) + '@' + domain
  }
}

export const getOrderOfFieldByLabel = (
  fieldsData: EntitySectionData | undefined,
  section: string,
  value: string
): number => {
  const sectionData = fieldsData?.[section]

  if (sectionData) {
    const field = sectionData.find((field: EntityFormItem) => field.value === value)
    return field ? field.order : 0
  }

  return 0
}

export const getSelectedOfFieldByLabel = (
  fieldsData: EntitySectionData | undefined,
  section: string,
  value: string
): boolean => {
  const sectionData = fieldsData?.[section]

  if (sectionData) {
    const field = fieldsData?.[section].find((field: EntityFormItem) => field.value === value)
    return field ? field.selected : false
  }

  return false
}

export const getDateDifference = (startDate: Date | null, endDate: Date | null) => {
  if (startDate && endDate) {
    const differenceInTime = endDate.getTime() - startDate.getTime()
    const days = Math.floor(differenceInTime / (1000 * 3600 * 24))

    return days + 1
  }
}

export const formatWhatsAppContent = (content: string) => {
  let formattedContent

  // Match URLs
  formattedContent = reactStringReplace(content, /(https?:\/\/\S+)/g, (match, i) => (
    <a
      key={match + i}
      href={match}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        color: XelaColor.Blue3,
      }}
    >
      {match}
    </a>
  ))

  // Match bold text
  formattedContent = reactStringReplace(formattedContent, /\*(\w+(\s\w+)*)\*/g, (match, i) => (
    <strong
      key={match + i}
      style={{
        color: XelaColor.Gray4,
        fontWeight: 700,
      }}
    >
      {match}
    </strong>
  ))

  // Match italic text
  formattedContent = reactStringReplace(formattedContent, /_(\w+(\s\w+)*)_/g, (match, i) => (
    <em key={match + i} color={XelaColor.Gray4}>
      {match}
    </em>
  ))

  // Match strikethrough text
  formattedContent = reactStringReplace(formattedContent, /~(\w+(\s\w+)*)~/g, (match, i) => (
    <del key={match + i}>{match}</del>
  ))

  // Match monospace text
  formattedContent = reactStringReplace(formattedContent, /```([\s\S]+?)```/g, (match, i) => (
    <code key={match + i} style={{ whiteSpace: 'pre-wrap' }}>
      {match}
    </code>
  ))

  return formattedContent
}

export const convertSecondsToReadableFormat = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  const hrsDisplay = hrs > 0 ? `${hrs}h` : ''
  const minsDisplay = mins > 0 ? `${mins}m` : ''
  const secsDisplay = secs > 0 ? `${secs}s` : ''

  return `${hrsDisplay} ${minsDisplay} ${secsDisplay}`.trim()
}

export const capitalizeWords = (str: string) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const getMultipleBranchesUsers = (
  branchIds: string[],
  meta: { branchesUsers: { [branchId: string]: Branch } },
  allPermission: string,
  branchPermission: string,
  managePermission: string
) => {
  if (branchIds.length > 0) {
    const uniqueUsers: string[] = []
    let usersData
    usersData = branchIds.flatMap((branchId) => {
      const usersInBranch = meta.branchesUsers[branchId]?.users || []

      const filteredUsers = usersInBranch.filter(
        (item) =>
          item.role.permissions.includes(managePermission) ||
          item.role.permissions.includes(branchPermission) ||
          item.role.permissions.includes(allPermission)
      )

      return filteredUsers
        .filter((counsellor) => {
          if (uniqueUsers.includes(counsellor.id.toString())) {
            return false
          }
          uniqueUsers.push(counsellor.id.toString())
          return true
        })
        .map((counsellor) => ({
          value: counsellor.id.toString(),
          label: counsellor.name,
          group: counsellor.branch.branch_name,
          role: counsellor.role.role_name,
        }))
    })
    return usersData
  } else {
    return []
  }
}
