import React, {FC} from 'react'
import {HStack, Typography, XelaColor} from '@/XelaReact'
import {router} from '@inertiajs/react'
import Notification = App.Models.Notification

const ShareInfopediaNotification: FC<{
  notification: Notification
}> = ({notification}) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        router.visit(notification.data.route_path)
      }}
    >
      <Typography variant="body-small-bold" color={XelaColor.Orange3}>
        #{notification.data.event_by}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray6}>
        shared a {notification.data.type} with you in Infopedia
      </Typography>
      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
        {notification.data.type} name : {notification.data.infopedia_name}
      </Typography>
    </HStack>
  )
}

export default ShareInfopediaNotification
