import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const DocumentUploadedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification, portal = 'Agency' }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        if (notification.data.lead_type === 'App\\Models\\VisitorLead') {
          router.visit(route('visitor-leads.id', { id: notification.data.lead_id }))
        } else {
          if (notification.data.lead_type === 'App\\Models\\IeltsLead') {
            router.visit(route('ielts-leads.id', { id: notification.data.lead_id }))
          } else {
            router.visit(route('leads.id', { id: notification.data.lead_id }))
          }
        }
      }}
    >
      {notification.data.event_by ? (
        <>
          <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
          <Typography variant="body-small" color={XelaColor.Gray6}>
            uploaded a document in Lead
          </Typography>
        </>
      ) : (
        <Typography variant="body-small" color={XelaColor.Gray6}>
          Document Uploaded in Lead
        </Typography>
      )}
      <Typography variant="body-small-bold" color={XelaColor.Orange3}>
        #{notification.data.lead_name}
      </Typography>
    </HStack>
  )
}

export default DocumentUploadedNotification
