import './Input.css'
import React, {
  FC,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import VStack from '@/XelaReact/VStack/VStack'
import { getTransition } from '@/XelaReact/helpers/getTransitions'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
  inputRef?: MutableRefObject<HTMLInputElement | null>
  type?: string
  name?: string
  placeholder?: string
  value?: string
  width?: string
  bg?: string
  defaultTextColor?: string
  secondaryTextColor?: string
  defaultBorderColor?: string
  hoverColor?: string
  focusColor?: string
  errorColor?: string
  successColor?: string
  helperText?: string
  error?: boolean
  success?: boolean
  disabled?: boolean
  readOnly?: boolean
  className?: string
  leftIcon?: ReactNode
  rightElement?: ReactNode
  onChangeHandle?: (value: string) => void
  onFocusHandle?: (value: boolean) => void
  onBlurHandle?: (value: boolean) => void
  minNumValue?: number
  maxNumValue?: number
  valueTextAlign?: string
}

const InputSubVStack = styled(VStack)`
  justify-content: center;
`

const InputVStack = styled(VStack)<InputComponentProps>`
  outline: ${(props) =>
    (props.isFocus ? '2px' : '1px') +
    ' solid ' +
    (props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.isFocus
      ? props.focusColor
      : props.isHover
      ? props.hoverColor
      : props.defaultBorderColor)};
  justify-content: center;
  fill: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};
  stroke: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};
  color: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};

  ${(props) =>
    props.disabled !== undefined &&
    'cursor: ' + (props.disabled ? 'initial' : 'text') + ';'}
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
`

interface InputComponentProps extends InputProps {
  isFocus?: boolean
  isHover?: boolean
  val: string
}

const InputComponent = styled.input<InputComponentProps>`
  color: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  width: inherit;

  ${getTransition(200, ['opacity', 'height', 'margin-top'], 'linear')}

  opacity: ${(props) => (props.isFocus || props.val.length > 0 ? '1' : '0')};
  height: ${(props) =>
    props.isFocus || props.val.length > 0 ? '16px' : '0px'};
  margin: ${(props) => (props.isFocus || props.val.length > 0 ? '4px' : '0px')}
    0 0;

  ${(props) =>
    props.valueTextAlign &&
    css`
      text-align: ${props.valueTextAlign};
    `}
`

export const Input: FC<InputProps> = ({
  inputRef,
  placeholder,
  type = 'text',
  name,
  value = '',
  width = '344px',
  bg = XelaColor.White,
  defaultTextColor = XelaColor.Gray2,
  secondaryTextColor = XelaColor.Gray7,
  defaultBorderColor = XelaColor.Gray11,
  hoverColor = XelaColor.Blue5,
  focusColor = XelaColor.Blue3,
  errorColor = XelaColor.Red3,
  successColor = XelaColor.Green1,
  disabled = false,
  readOnly = false,
  error = false,
  success = false,
  leftIcon,
  rightElement,
  helperText,
  onChangeHandle,
  onFocusHandle,
  onBlurHandle,
  minNumValue,
  maxNumValue,
  valueTextAlign,
  ...props
}) => {
  const [isHover, setHover] = useState(false)
  const [isFocus, setFocus] = useState(false)
  const [val, setValue] = useState(value || '')

  if (!inputRef) {
    inputRef = React.useRef<HTMLInputElement>(null)
  }

  useEffect(() => {
    setValue(value || '')
  }, [value])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Tab') {
        setFocus(false)
        inputRef?.current?.blur()
        if (onBlurHandle) {
          onBlurHandle(false)
        }
      }
    }

    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  return (
    <VStack
      width={width}
      spacing="8px"
      style={{ opacity: disabled ? '0.4' : '1' }}
    >
      <InputVStack
        error={error}
        success={success}
        errorColor={errorColor}
        successColor={successColor}
        disabled={disabled}
        isFocus={isFocus}
        focusColor={focusColor}
        isHover={isHover}
        hoverColor={hoverColor}
        defaultBorderColor={defaultBorderColor}
        defaultTextColor={defaultTextColor}
        val={val}
        height="56px"
        bg={bg}
        borderRadius="18px"
        className="xela-input"
        onMouseEnter={() => {
          if (!disabled) {
            setHover(true)
          }
        }}
        onMouseLeave={() => {
          if (!disabled) {
            setHover(false)
          }
        }}
        onClick={() => {
          if (!disabled) {
            setFocus(true)
            inputRef?.current?.focus()
            if (onFocusHandle) {
              onFocusHandle(true)
            }
          }
        }}
        onFocus={() => {
          if (!disabled) {
            setFocus(true)
            inputRef?.current?.focus()
            if (onFocusHandle) {
              onFocusHandle(true)
            }
          }
        }}
      >
        <HStack spacing="12px">
          <HStack spacing="12px">
            {leftIcon !== undefined && leftIcon}
            <InputSubVStack>
              <Typography
                as="span"
                color={secondaryTextColor}
                variant="body-small"
                style={{ lineHeight: '15px' }}
              >
                {placeholder}
              </Typography>

              <InputComponent
                error={error}
                success={success}
                errorColor={errorColor}
                successColor={successColor}
                defaultTextColor={defaultTextColor}
                isFocus={isFocus}
                val={val}
                autoFocus={isFocus}
                value={val}
                ref={inputRef}
                valueTextAlign={valueTextAlign}
                onChange={(target: React.FormEvent<HTMLInputElement>) => {
                  if (type == 'number') {
                    if (minNumValue) {
                      if (parseInt(target.currentTarget.value) < minNumValue) {
                        if (onChangeHandle) {
                          onChangeHandle(minNumValue.toString())
                        }
                        return
                      }
                    }

                    if (maxNumValue) {
                      if (parseInt(target.currentTarget.value) > maxNumValue) {
                        if (onChangeHandle) {
                          onChangeHandle(maxNumValue.toString())
                        }
                        return
                      }
                    }
                  }

                  if (onChangeHandle) {
                    onChangeHandle(target.currentTarget.value)
                  }

                  if (setValue) {
                    setValue(target.currentTarget.value)
                  }
                }}
                disabled={disabled}
                readOnly={readOnly}
                onBlur={() => {
                  setFocus(false)
                }}
                type={type}
                name={name}
                {...props}
              />
            </InputSubVStack>
          </HStack>

          {rightElement !== undefined && rightElement}
        </HStack>
      </InputVStack>
      {helperText !== undefined && (
        <Typography
          as="p"
          color={
            error ? errorColor : success ? successColor : secondaryTextColor
          }
          variant="caption"
        >
          {helperText}
        </Typography>
      )}
    </VStack>
  )
}

export default Input
