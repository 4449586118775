import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { MetaCourseFiltersProps } from '@/Types/CourseTypes'
import MetaCourse = App.Models.MetaCourse

export interface CourseState {
  total_meta_courses: number
  is_filter_applied: boolean
  is_course_edited: boolean
  is_selected_all: boolean
  is_need_to_refresh: boolean
  selected_course: MetaCourse | null
  show_assign_to_agency_modal: boolean
  show_assign_courses_modal: boolean
  show_course_import_modal: boolean
  meta_course_filters: MetaCourseFiltersProps
  selected_courses_id: number[]
}

const initialState: CourseState = {
  total_meta_courses: 0,
  is_filter_applied: false,
  is_course_edited: false,
  is_selected_all: false,
  is_need_to_refresh: false,
  selected_course: null,
  show_assign_to_agency_modal: false,
  show_assign_courses_modal: false,
  show_course_import_modal: false,
  meta_course_filters: {
    search: '',
    university_names: [],
    course_names: [],
    country_names: [],
    course_levels: [],
  },
  selected_courses_id: [],
}

export const adminMetaCourseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    updateTotalMetaCourses(state, action: PayloadAction<number>) {
      state.total_meta_courses = action.payload
    },
    updateEditMetaCourse(
      state,
      action: PayloadAction<{
        is_course_edited: boolean
        selected_course: MetaCourse | null
      }>
    ) {
      state.is_course_edited = action.payload.is_course_edited
      state.selected_course = action.payload.selected_course
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
    updateSelectedAllMetaCourses(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateShowAssignToAgencyModal(state, action: PayloadAction<boolean>) {
      state.show_assign_to_agency_modal = action.payload
    },
    updateShowAssignCoursesModal(state, action: PayloadAction<boolean>) {
      state.show_assign_courses_modal = action.payload
    },
    updateShowCourseImportModal(state, action: PayloadAction<boolean>) {
      state.show_course_import_modal = action.payload
    },
    updateMetaCourseFilters(state, action: PayloadAction<MetaCourseFiltersProps>) {
      state.meta_course_filters = action.payload
    },
    clearMetaCourseFilters(state) {
      state.meta_course_filters = {
        search: '',
        university_names: [],
        course_names: [],
        country_names: [],
        course_levels: [],
      }
    },
    updateSelectedCoursesId: (state, action) => {
      state.selected_courses_id = action.payload
    },
  },
})

export const {
  updateEditMetaCourse,
  updateTotalMetaCourses,
  updateIsNeedToRefresh,
  updateSelectedAllMetaCourses,
  updateShowAssignToAgencyModal,
  updateShowAssignCoursesModal,
  updateShowCourseImportModal,
  updateSelectedCoursesId,
  updateMetaCourseFilters,
  clearMetaCourseFilters,
} = adminMetaCourseSlice.actions

export default adminMetaCourseSlice.reducer
