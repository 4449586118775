import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { IconListDetails } from '@tabler/icons-react'
import IconTextDocumentAlt from '@/Icons/IconTextDocumentAlt'
import IconUserSquare from '@/Icons/IconUserSquare'
import IconDocuments from '@/Icons/IconDocuments'
import IconVisa from '@/Icons/IconVisa'
import { updateShowSearch } from '@/Store/globalSlice'
import { router } from '@inertiajs/react'
import { useDispatch } from 'react-redux'

interface SearchResult {
  lead_id: number
  entity: string
  entity_id: number
  lead_info?: string
  course_info?: string
  document_info?: string
  status: string
}
const SearchCard: FC<{ result: SearchResult; portal?: string }> = ({ result, portal = 'Agency' }) => {
  const dispatch = useDispatch()
  const [hover, setHover] = React.useState(false)

  return (
    <HStack
      alignItems="center"
      style={{
        cursor: 'pointer',
        backgroundColor: hover ? XelaColor.Gray12 : XelaColor.White,
        borderRadius: '12px',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        switch (result.entity) {
          case 'Visitor Lead':
            router.visit(route('visitor-leads.id', { id: result.entity_id }))
            break
          case 'IELTS Lead':
            router.visit(route('ielts-leads.id', { id: result.entity_id }))
            break
          case 'Lead':
            portal === 'Agency'
              ? router.visit(route('leads.id', { id: result.entity_id }))
              : portal === 'B2B'
              ? router.visit('/b2b/leads/' + result.lead_id)
              : router.visit('/leads/' + result.lead_id)
            break
          case 'Counselling':
            router.visit(route('counsellings.id', { id: result.entity_id }))
            break
          case 'Admission Application':
            portal === 'Agency'
              ? router.visit(
                  route('admissions.id', {
                    id: result.entity_id,
                    _query: { via: 'Search' },
                  })
                )
              : portal === 'B2B'
              ? router.visit('/b2b/leads/' + result.lead_id)
              : router.visit('/leads/' + result.lead_id)
            break
          case 'Visa':
            portal === 'Agency'
              ? router.visit(route('visas.id', { id: result.entity_id }))
              : portal === 'B2B'
              ? router.visit('/b2b/leads/' + result.lead_id)
              : router.visit('/leads/' + result.lead_id)
            break
          case 'Document':
            portal === 'Agency'
              ? router.visit(
                  route('leads.id', {
                    id: result.lead_id,
                    _query: { show_tab: 'documents' },
                  })
                )
              : portal === 'B2B'
              ? router.visit('/b2b/leads/' + result.lead_id + '?show_tab=documents')
              : router.visit('/leads/' + result.lead_id + '?show_tab=documents')
            break
        }
        dispatch(updateShowSearch(false))
      }}
    >
      <HStack justifyContent="space-between" style={{ padding: '4px 12px' }}>
        <HStack spacing="12px">
          {(result.entity === 'Lead' || result.entity === 'Visitor Lead' || result.entity === 'IELTS Lead') && (
            <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />
          )}
          {result.entity === 'Counselling' && (
            <IconUserSquare height="18" width="18" viewBox="0 0 17 17"></IconUserSquare>
          )}
          {result.entity === 'Admission Application' && (
            <IconDocuments height="18" width="18" viewBox="0 0 16 16"></IconDocuments>
          )}
          {result.entity === 'Visa' && <IconVisa height="18" width="18" viewBox="0 0 16 16"></IconVisa>}
          {result.entity === 'Document' && <IconListDetails color={XelaColor.Blue3}></IconListDetails>}
          <VStack spacing="2px">
            <Typography variant={'body-small-bold'} color={XelaColor.Gray3}>
              {result.lead_info}
            </Typography>
            {result.course_info && (
              <Typography variant={'caption'} color={XelaColor.Gray3}>
                {result.course_info}
              </Typography>
            )}
            <Typography variant="sub-caption" color={XelaColor.Gray6}>
              {result.entity} - {result.status}
            </Typography>
          </VStack>
        </HStack>
      </HStack>
    </HStack>
  )
}

export default SearchCard
