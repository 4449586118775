import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import VisitorLead = App.Models.VisitorLead
import Agent = App.Models.Agent
import Visa = App.Models.Visa

export interface B2BVisitorLeadState {
  b2b_lead: VisitorLead | null
  show_b2b_accept_visitor_lead_modal: boolean
  b2b_branch_id: string | null
  b2b_select_agent: Agent | null
  b2b_visitor_lead_id?: number
  visitor_lead_form_submitted: boolean
  visitor_lead_documents_uploaded: boolean
  show_b2b_add_visitor_lead_modal: boolean
  selected_visitor_lead_id: number | null
  is_visitor_leads_notes_opened: boolean
  selected_visitor_lead_ids: string[]
  show_all_visitor_leads_filter_modal: boolean
  selected_leads: Visa[]
  reload_all_leads: boolean
  current_b2b_visitor_lead_filters: {
    agent_id?: string
    created_at?: string
    city?: string
    state?: string
    from_date?: string
    to_date?: string
  }
  previous_b2b_visitor_lead_filters: {
    agent_id?: string
    created_at?: string
    city?: string
    state?: string
    from_date?: string
    to_date?: string
  }
}

const initialState: B2BVisitorLeadState = {
  b2b_lead: null,
  show_b2b_accept_visitor_lead_modal: false,
  b2b_select_agent: null,
  b2b_branch_id: null,
  b2b_visitor_lead_id: undefined,
  visitor_lead_form_submitted: false,
  visitor_lead_documents_uploaded: false,
  show_b2b_add_visitor_lead_modal: false,
  selected_visitor_lead_id: null,
  is_visitor_leads_notes_opened: false,
  selected_visitor_lead_ids: [],
  reload_all_leads: false,
  show_all_visitor_leads_filter_modal: false,
  selected_leads: [],
  current_b2b_visitor_lead_filters: {
    agent_id: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
  previous_b2b_visitor_lead_filters: {
    agent_id: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
}

export const b2bVisitorLeadSlice = createSlice({
  name: 'b2bVisitorLeadSlice',
  initialState,
  reducers: {
    updateB2BVisitorLead(state, action: PayloadAction<VisitorLead | null>) {
      state.b2b_lead = action.payload
    },
    updateShowB2BAcceptVisitorLeadModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_accept_visitor_lead_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_accept_visitor_lead_modal = false
      }
    },
    updateB2BSelectAgent(state, action: PayloadAction<Agent | null>) {
      state.b2b_select_agent = action.payload
    },
    updateB2BBranchId(state, action: PayloadAction<string | null>) {
      state.b2b_branch_id = action.payload
    },
    updateB2BVisitorLeadId(state, action: PayloadAction<number | undefined>) {
      state.b2b_visitor_lead_id = action.payload
    },
    updateVisitorLeadFormSubmitted(state, action: PayloadAction<boolean>) {
      state.visitor_lead_form_submitted = action.payload
    },
    updateVisitorLeadDocumentsUploaded(state, action: PayloadAction<boolean>) {
      state.visitor_lead_documents_uploaded = action.payload
    },
    updateShowB2BAddVisitorLeadModal(state, action: PayloadAction<boolean>) {
      state.show_b2b_add_visitor_lead_modal = action.payload
      if (action.payload) {
        state.show_b2b_add_visitor_lead_modal = true
      } else {
        state.b2b_visitor_lead_id = undefined
        state.show_b2b_add_visitor_lead_modal = false
        state.visitor_lead_form_submitted = false
        state.visitor_lead_documents_uploaded = false
      }
    },
    updateIsLeadsNotesOpened(
      state,
      action: PayloadAction<{ selected_lead_id: number | null; is_leads_notes_opened: boolean }>
    ) {
      state.selected_visitor_lead_id = action.payload.selected_lead_id
      state.is_visitor_leads_notes_opened = action.payload.is_leads_notes_opened
    },
    updateSelectedLeadIds(state, action: PayloadAction<string[]>) {
      state.selected_visitor_lead_ids = action.payload
    },
    updateSelectedLeads(state, action: PayloadAction<Visa[]>) {
      state.selected_leads = action.payload
    },
    showAllLeadFilterModal(state) {
      state.show_all_visitor_leads_filter_modal = true
    },
    hideAllLeadFilterModal(state) {
      state.show_all_visitor_leads_filter_modal = false
    },
    updateCurrentB2BLeadFilters(
      state,
      action: PayloadAction<{
        agent_id?: string
        city?: string
        state?: string
        from_date?: string
        to_date?: string
      }>
    ) {
      state.current_b2b_visitor_lead_filters = action.payload
    },
    updatePreviousB2BLeadFilters(
      state,
      action: PayloadAction<{
        agent_id?: string
        city?: string
        state?: string
        from_date?: string
        to_date?: string
      }>
    ) {
      state.previous_b2b_visitor_lead_filters = action.payload
    },
    reloadAllLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_leads = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateB2BVisitorLead,
  updateShowB2BAcceptVisitorLeadModal,
  updateB2BSelectAgent,
  updateB2BBranchId,
  updateB2BVisitorLeadId,
  updateVisitorLeadFormSubmitted,
  updateVisitorLeadDocumentsUploaded,
  updateShowB2BAddVisitorLeadModal,
  updateIsLeadsNotesOpened,
  updateSelectedLeadIds,
  showAllLeadFilterModal,
  hideAllLeadFilterModal,
  updateSelectedLeads,
  updatePreviousB2BLeadFilters,
  updateCurrentB2BLeadFilters,
  reloadAllLeads,
} = b2bVisitorLeadSlice.actions

export default b2bVisitorLeadSlice.reducer
