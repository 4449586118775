import React, { FC, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import { getTransition } from '@/XelaReact/helpers/getTransitions'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  children?: ReactNode | ReactNode[]
}

const TableRowComponent = styled.tr<TableRowProps>``

export const TableRow: FC<TableRowProps> = ({ children, ...props }) => {
  return <TableRowComponent {...props}>{children}</TableRowComponent>
}

export interface TableHeadProps extends HTMLAttributes<HTMLTableSectionElement> {
  children?: ReactNode | ReactNode[]
}

const TableHeadComponent = styled.thead<TableHeadProps>``

export const TableHead: FC<TableHeadProps> = ({ children, ...props }) => {
  return <TableHeadComponent {...props}>{children}</TableHeadComponent>
}

export interface TableBodyProps extends HTMLAttributes<HTMLTableSectionElement> {
  children?: ReactNode | ReactNode[]
}

const TableBodyComponent = styled.tbody<TableBodyProps>``

export const TableBody: FC<TableBodyProps> = ({ children, ...props }) => {
  return <TableBodyComponent {...props}>{children}</TableBodyComponent>
}

export interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  children?: ReactNode | ReactNode[]
  colSpan?: number
}

const TableCellComponent = styled.td<TableCellProps>``

export const TableCell: FC<TableCellProps> = ({ children, colSpan, ...props }) => {
  return (
    <TableCellComponent colSpan={colSpan} {...props}>
      {children}
    </TableCellComponent>
  )
}

export interface TableProps extends HTMLAttributes<HTMLTableElement> {
  children?: ReactNode | ReactNode[]
  width?: string
  theadHeight?: string
  theadBottomMargin?: string
  theadBg?: string
  theadTextColor?: string
  tbodyHeight?: string
}

const TableComponent = styled.table<TableProps>`
  border: none;
  border-spacing: 0;
  width: ${(props) => props.width};

  & tbody:before {
    content: '@';
    display: block;
    line-height: ${(props) => props.theadBottomMargin};
    text-indent: -99999px;
  }

  & thead > tr {
    height: ${(props) => props.theadHeight};
    background: ${(props) => props.theadBg};
    color: ${(props) => props.theadTextColor};
  }

  & thead > tr > td {
    padding: 10px 5px;
  }

  & thead > tr > td:first-child {
    padding-left: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  & thead > tr > td:last-child {
    padding-right: 16px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
  }

  & tbody > tr {
    height: ${(props) => props.tbodyHeight};
    padding: 4px 8px;
  }

  & tbody > tr:hover {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    ${getTransition(300, ['box-shadow'])}
  }

  & tbody > tr > td {
    padding: 10px 5px;
  }

  & tbody > tr > td:first-child {
    padding-left: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  & tbody > tr > td:last-child {
    padding-right: 16px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
  }
`

export const Table: FC<TableProps> = ({
  children,
  width = '100%',
  theadBg = XelaColor.Gray12,
  theadTextColor = XelaColor.Gray6,
  theadBottomMargin = '16px',
  theadHeight = '40px',
  tbodyHeight = '56px',
  ...props
}) => {
  return (
    <TableComponent
      width={width}
      theadBg={theadBg}
      theadBottomMargin={theadBottomMargin}
      theadHeight={theadHeight}
      theadTextColor={theadTextColor}
      tbodyHeight={tbodyHeight}
      {...props}
    >
      {children}
    </TableComponent>
  )
}

export default Table
