import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

export class PieDataset {
  label: string;
  data: Array<number>;
  backgroundColor: Array<string>;
  borderColor?: Array<string>;
  borderWidth: number;
  hoverOffset: number;

  constructor(
    label: string,
    data: Array<number>,
    backgroundColor: Array<string>,
    borderColor?: Array<string>,
    borderWidth = 0,
    hoverOffset = 8
  ) {
    this.label = label;
    this.data = data;
    this.backgroundColor = backgroundColor;
    this.borderColor = borderColor;
    this.borderWidth = borderWidth;
    this.hoverOffset = hoverOffset;
  }
}

ChartJS.register(ArcElement, Tooltip, Legend);

export interface PieChartProps extends HTMLAttributes<HTMLDivElement> {
  labels: Array<string>;
  datasets: Array<PieDataset>;
  padding?: number;
  width?: string;
}

const PieChartComponent = styled.div<{ width?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

export const PieChart: FC<PieChartProps> = ({
  labels,
  datasets,
  padding = 16,
  width,
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: padding,
    },
  };

  const ds = [];
  for (let i = 0; i < datasets.length; i++) {
    ds.push(datasets[i]);
    if (i < datasets.length - 1) {
      ds.push({ label: undefined, data: undefined, weight: 0.05 });
    }
  }

  const data = {
    labels,
    datasets: ds,
  };

  return (
    <PieChartComponent width={width}>
      <Pie options={options} data={data} />
    </PieChartComponent>
  );
};

export default PieChart;
