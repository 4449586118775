import React, { FC, HTMLAttributes, ReactNode } from 'react'
import './Typography.css'
import styled from 'styled-components'

export interface TypographyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  variant?:
    | 'title1'
    | 'title2'
    | 'title3'
    | 'headline'
    | 'subheadline'
    | 'body'
    | 'body-bold'
    | 'body-small'
    | 'body-small-bold'
    | 'caption'
    | 'caption-bold'
    | 'sub-caption'
    | 'sub-caption-bold'
    | 'button-large'
    | 'button-medium'
    | 'button-small'
    | 'sub-link'
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  noWrap?: boolean
  gutterBottom?: boolean
  color?: string
  className?: string
}

const CustomTag = styled.p<TypographyProps>`
  color: ${(props) => props.color};
  ${(props) => props.gutterBottom && 'margin-bottom: 1em;'}
  ${(props) => props.noWrap && 'white-space: nowrap;'}
`

export const Typography: FC<TypographyProps> = ({
  children,
  className,
  as,
  variant = 'body',
  color = 'inherit',
  align = 'inherit',
  noWrap = false,
  gutterBottom = false,
  ...props
}) => {
  if (as === undefined) {
    if (variant == 'title1') {
      as = 'h1'
    } else if (variant == 'title2') {
      as = 'h1'
    } else if (variant == 'title3') {
      as = 'h1'
    } else if (variant == 'headline') {
      as = 'h2'
    } else if (variant == 'subheadline') {
      as = 'h3'
    } else {
      as = 'span'
    }
  }

  return (
    <CustomTag
      as={as}
      className={`xela-typography-${variant} xela-typography-align-${align}${
        className !== undefined ? ' ' + className : ''
      }`}
      color={color}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      {...props}
    >
      {children}
    </CustomTag>
  )
}

export default Typography
