import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { LeadForm } from '@/Types/LeadTypes'
import LEAD_FIELDS from '@/Constants/LeadFieldMeta'
import Lead = App.Models.Lead

export interface LeadDrawerState {
  showDrawer: boolean
  lead_form: LeadForm
}

type LeadKey = keyof Lead

const initialState: LeadDrawerState = {
  showDrawer: false,
  lead_form: {
    id: '-1',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone: '',
    alternate_phone: '',
    father_name: '',
    mother_name: '',
    lead_source: 'Walk In',
    gender: '',
    date_of_birth: '1995-01-01',
    martial_status: 'Not Married',
    pin_code: '',
    city: '',
    state: '',
    country: 'India',
    country_code: 'IN',
    address: '',
    preferred_country: 'Sweden',
    preferred_country_code: 'SE',
    preferred_course_type: '',
    preferred_course_name: '',
    language_test_given: 'No',
    language_test_score: '',
    language_test_date: '',
    aptitude_test_given: 'No',
    aptitude_test_score: '',
    aptitude_test_date: '',
    tenth_year: '',
    tenth_board: '',
    tenth_percentage: '',
    twelfth_equivalent_year: '',
    twelfth_equivalent_board: '',
    twelfth_equivalent_percentage: '',
    graduation_year: '',
    graduation_university_college: '',
    graduation_degree_name: '',
    graduation_percentage: '',
    graduation_number_of_reappears: '',
    is_still_working: false,
    recent_work_experience_start_date: '',
    recent_work_experience_end_date: '',
    recent_work_experience_organisation_name: '',
    recent_work_experience_job_title: '',
    is_visa_rejected_in_past: false,
    past_visa_rejected_country: '',
    past_visa_rejected_year: '',
    passport_number: '',
    passport_issue_date: '',
    passport_expiry_date: '',
    passport_nationality: '',
    past_experiences: [],
  },
}

LEAD_FIELDS.map((leadGroup) => {
  leadGroup.fields.map((field) => {
    if (field.field_name) {
      initialState.lead_form[field.field_name] = field.field_default
    }
  })
})

export const leadDrawerSlice = createSlice({
  name: 'leadDrawerSlice',
  initialState,
  reducers: {
    updateShowDrawer(state, action: PayloadAction<boolean>) {
      state.showDrawer = action.payload
    },
    updateLeadForm(state, action: PayloadAction<Lead>) {
      Object.keys(state.lead_form).map((key) => {
        if (action.payload[key as LeadKey]) {
          state.lead_form[key] = action.payload[key as LeadKey]
        }
      })

      state.showDrawer = true
    },
    resetLeadForm(state) {
      LEAD_FIELDS.map((leadGroup) => {
        leadGroup.fields.map((field) => {
          if (field.field_name) {
            state.lead_form[field.field_name] = field.field_default
          }
        })
      })

      state.lead_form['id'] = '-1'

      state.showDrawer = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateShowDrawer, updateLeadForm, resetLeadForm } = leadDrawerSlice.actions

export default leadDrawerSlice.reducer
