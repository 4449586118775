import { useEffect, useState } from 'react'

const useWidthResize = () => {
  // State for window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // Effect to update window width on resize and check initial width
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty dependency array means this useEffect will run once after mount

  return windowWidth
}

export default useWidthResize
