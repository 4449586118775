import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import InsuranceCompany = App.Models.InsuranceCompany

export interface CompanyState {
  total_companies: number
  is_filter_applied: boolean
  is_company_edited: boolean
  is_selected_all: boolean
  is_need_to_refresh: boolean
  selected_company: InsuranceCompany | null
  company_filters: {
    search: string
    status: string[]
  }
  selected_companies_id: number[]
}

const initialState: CompanyState = {
  total_companies: 0,
  is_filter_applied: false,
  is_company_edited: false,
  is_selected_all: false,
  is_need_to_refresh: false,
  selected_company: null,
  company_filters: {
    search: '',
    status: [],
  },
  selected_companies_id: [],
}

export const adminInsuranceCompanySlice = createSlice({
  name: 'insuranceCompanySlice',
  initialState,
  reducers: {
    updateTotalAdminInsuranceCompanies(state, action: PayloadAction<number>) {
      state.total_companies = action.payload
    },
    updateEditAdminInsuranceCompany(
      state,
      action: PayloadAction<{
        is_company_edited: boolean
        selected_company: InsuranceCompany | null
      }>
    ) {
      state.is_company_edited = action.payload.is_company_edited
      state.selected_company = action.payload.selected_company
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
    updateSelectedAllInsuranceCompanies(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateAdminInsuranceCompanyFilters(
      state,
      action: PayloadAction<{
        search: string
        status: string[]
      }>
    ) {
      state.company_filters = action.payload
    },
    clearAdminInsuranceCompanyFilters(state) {
      state.company_filters = {
        search: '',
        status: [],
      }
    },
    updateSelectedInsuranceCompaniesId: (state, action) => {
      state.selected_companies_id = action.payload
    },
  },
})

export const {
  updateEditAdminInsuranceCompany,
  updateTotalAdminInsuranceCompanies,
  updateIsNeedToRefresh,
  updateSelectedAllInsuranceCompanies,
  updateSelectedInsuranceCompaniesId,
  updateAdminInsuranceCompanyFilters,
  clearAdminInsuranceCompanyFilters,
} = adminInsuranceCompanySlice.actions

export default adminInsuranceCompanySlice.reducer
