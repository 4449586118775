import React, { FC, useEffect, useState } from 'react'
import { Box, createStyles } from '@mantine/core'
import { DateInput, DateInputProps } from '@mantine/dates'
import { XelaColor } from '@/XelaReact'
import moment from 'moment'
import { IconX } from '@tabler/icons-react'
import { omit } from 'lodash'

interface XDatePickerProps {
  value: Date | null | undefined
  placeholder: string | undefined
  size?: 'sm' | 'md' | 'lg' | string
}

const useStyles = createStyles((theme, params: XDatePickerProps) => ({
  root: {
    fontFamily: 'Nunito Sans',
    position: 'relative',
    width: '100%',
  },

  input: {
    fontSize: params.size === 'sm' ? '10px' : '13px',
    fontWeight: 700,
    height: params.size === 'sm' ? '48px' : '56px',
    padding: params.size === 'sm' ? '13px 12px 3px' : '26px 24px 6px',
    border: '1px solid #E8E8EA',
    borderRadius: params.size === 'sm' ? '12px' : '18px',
    '&:hover, &:focus': {
      padding: params.size === 'sm' ? '11.5px 10.5px 1.5px' : '25px 23px 5px',
      border: '2px solid #003CFF',
    },
    '&:focus': {
      color: '#003CFF !important',
    },
    '&::placeholder': {
      color: XelaColor.Gray10,
      fontWeight: 400,
    },
  },

  label: {
    fontSize:
      params.size === 'sm'
        ? params.placeholder || params.value
          ? '8px'
          : '11px'
        : params.placeholder || params.value
        ? '10px'
        : '13px',
    lineHeight: '15px',
    fontWeight: 600,
    position: 'absolute',
    pointerEvents: 'none',
    paddingLeft: params.size === 'sm' ? '12.5px' : '25px',
    transition: 'all 300ms ease',
    paddingTop:
      params.size === 'sm'
        ? `${params.placeholder || params.value ? '8px' : '12px'}`
        : `${params.placeholder || params.value ? '12px' : '20px'}`,
    zIndex: 1,
    color: XelaColor.Gray3,
    '&:hover, &:focus': {
      paddingLeft: params.size === 'sm' ? '12.5px' : '25px',
    },
  },

  calendarHeader: {
    paddingBottom: '12px',
    borderBottom: '2px dotted #E8E8EA',
  },

  calendarHeaderControl: {
    border: '1px solid #E8E8EA',
    borderRadius: '12px',
  },

  calendarHeaderLevel: {
    borderRadius: '12px',
    margin: '0 10px',
  },

  weekday: {
    fontFamily: 'Nunito Sans',
  },

  weekdayCell: {
    width: '32px',
    height: '32px',
    color: '#8E8C94',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
  },

  day: {
    fontFamily: 'Nunito Sans',
    width: '32px',
    height: '32px',
    color: '#34303E',
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 600,
    '&[data-weekend]:not([data-outside])': {
      color: '#34303E',
    },
    '&[data-outside]': {
      fontWeight: 600,
    },
    '&[data-selected]': {
      '&, &:hover': {
        border: '2px solid #003CFF',
        color: '#FFFFFF !important',
        backgroundColor: '#003CFF !important',
      },
    },
    '&[data-today]': {
      border: '1px solid #003CFF',
    },
  },

  monthPickerControlActive: {
    '&, &:hover': {
      border: '2px solid #003CFF',
      color: '#003CFF',
      background: '#ffffff',
    },
  },

  yearPickerControlActive: {
    '&, &:hover': {
      border: '2px solid #003CFF',
      color: '#003CFF',
      background: '#ffffff',
    },
  },
}))

const XDatePicker: FC<DateInputProps & React.RefAttributes<HTMLInputElement>> = (props) => {
  const [val, setVal] = useState(props.value)

  const { classes } = useStyles({
    value: val,
    placeholder: props.placeholder,
    size: props.size ? props.size : 'md',
  })

  useEffect(() => {
    setVal(props.value)
  }, [props.value])

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <DateInput
        {...omit(props, ['clearable'])}
        classNames={classes}
        label={props.label ? props.label : 'Date'}
        value={val}
        minDate={props.minDate ?? moment().subtract(125, 'years').toDate()}
        maxDate={props.maxDate ?? moment().add(25, 'years').toDate()}
        onChange={(date) => {
          if (props.onChange) {
            props.onChange(date)
          }

          setVal(date)
        }}
      />
      {props.clearable && (
        <Box
          style={{ position: 'absolute', right: '12px', top: props.size === 'sm' ? '14px' : '22px', cursor: 'pointer' }}
          onClick={() => {
            setVal(null)
            if (props.onChange) {
              props.onChange(null)
            }
          }}
        >
          <IconX size={13} color={XelaColor.Gray7}></IconX>
        </Box>
      )}
    </Box>
  )
}

export default XDatePicker
