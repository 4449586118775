import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { SelectItem } from '@mantine/core'

export interface AutomationState {
  selected_automation: App.Models.Automation | null
  selected_automation_id: number | null
  show_automation_modal: boolean
  show_map_variables_modal: boolean
  selected_action: {
    event: string
    template_id: string
    mappings: {
      [key: string]: string
    }
  } | null
  selected_action_template: SelectItem | undefined | null
  is_automation_mapping_saved: boolean
  selected_automation_mappings: {
    [key: string]: string
  }
}

const initialState: AutomationState = {
  selected_automation: null,
  selected_automation_id: null,
  show_automation_modal: false,
  show_map_variables_modal: false,
  selected_action: null,
  selected_action_template: null,
  is_automation_mapping_saved: false,
  selected_automation_mappings: {},
}
export const automationSlice = createSlice({
  name: 'automationSlice',
  initialState,
  reducers: {
    updateSelectedAutomation(state, action: PayloadAction<App.Models.Automation | null>) {
      state.selected_automation = action.payload
    },
    updateSelectedAutomationId(state, action: PayloadAction<number | null>) {
      state.selected_automation_id = action.payload
    },
    updateShowAutomationModal(state, action: PayloadAction<boolean>) {
      state.show_automation_modal = action.payload
    },
    updateShowMapVariablesModal(state, action: PayloadAction<boolean>) {
      state.show_map_variables_modal = action.payload
    },
    updateIsAutomationMappingSaved(state, action: PayloadAction<boolean>) {
      state.is_automation_mapping_saved = action.payload
    },
    updateSelectedAutomationMappings(
      state,
      action: PayloadAction<{
        [key: string]: string
      }>
    ) {
      state.selected_automation_mappings = action.payload
    },
    updateSelectedAction(
      state,
      action: PayloadAction<{
        event: string
        template_id: string
        mappings: {
          [key: string]: string
        }
      } | null>
    ) {
      state.selected_action = action.payload
    },
    updateSelectedActionTemplate(state, action: PayloadAction<SelectItem | undefined | null>) {
      state.selected_action_template = action.payload
    },
  },
})

export const {
  updateSelectedAutomation,
  updateSelectedAutomationId,
  updateShowAutomationModal,
  updateShowMapVariablesModal,
  updateSelectedAutomationMappings,
  updateSelectedAction,
  updateSelectedActionTemplate,
  updateIsAutomationMappingSaved,
} = automationSlice.actions

export default automationSlice.reducer
