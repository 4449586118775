import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { AgencyCourseFiltersProps } from '@/Types/CourseTypes'
import Course = App.Models.Course
import Agency = App.Models.Agency

export interface CourseState {
  total_meta_courses: number
  is_filter_applied: boolean
  is_course_edited: boolean
  is_selected_all: boolean
  is_need_to_refresh: boolean
  selected_course: Course | null
  show_select_agency_modal: boolean
  selected_agency: Agency | null
  agency_course_filters: AgencyCourseFiltersProps
  selected_courses_id: number[]
}

const initialState: CourseState = {
  total_meta_courses: 0,
  is_filter_applied: false,
  is_course_edited: false,
  is_selected_all: false,
  is_need_to_refresh: false,
  selected_course: null,
  show_select_agency_modal: false,
  selected_agency: null,
  agency_course_filters: {
    search: '',
    university_names: [],
    course_names: [],
    country_names: [],
    course_levels: [],
  },
  selected_courses_id: [],
}

export const adminAgencyCourseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    updateTotalAgencyCourses(state, action: PayloadAction<number>) {
      state.total_meta_courses = action.payload
    },
    updateEditAgencyCourse(
      state,
      action: PayloadAction<{
        is_course_edited: boolean
        selected_course: Course | null
      }>
    ) {
      state.is_course_edited = action.payload.is_course_edited
      state.selected_course = action.payload.selected_course
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
    updateSelectedAllAgencyCourses(state, action: PayloadAction<boolean>) {
      state.is_selected_all = action.payload
    },
    updateShowSelectAgencyModal(state, action: PayloadAction<boolean>) {
      state.show_select_agency_modal = action.payload
    },
    updateSelectedAgency(state, action: PayloadAction<Agency | null>) {
      state.selected_agency = action.payload
    },
    updateAgencyCourseFilters(state, action: PayloadAction<AgencyCourseFiltersProps>) {
      state.agency_course_filters = action.payload
    },
    clearAgencyCourseFilters(state) {
      state.agency_course_filters = {
        search: '',
        university_names: [],
        course_names: [],
        country_names: [],
        course_levels: [],
      }
    },
    updateSelectedCoursesId: (state, action) => {
      state.selected_courses_id = action.payload
    },
  },
})

export const {
  updateEditAgencyCourse,
  updateTotalAgencyCourses,
  updateIsNeedToRefresh,
  updateSelectedAllAgencyCourses,
  updateShowSelectAgencyModal,
  updateSelectedAgency,
  updateSelectedCoursesId,
  updateAgencyCourseFilters,
  clearAgencyCourseFilters,
} = adminAgencyCourseSlice.actions

export default adminAgencyCourseSlice.reducer
