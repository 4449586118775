import React, { useEffect, useState } from 'react'
import { Drawer } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowAddQuickLeadModal } from '@/Store/leadSlice'
import CreateQuickStudyLead from '@/Layouts/Modals/Forms/CreateQuickStudyLead'
import { Divider, HStack, TabItem, Tabs, Typography, VStack, XelaColor } from '@/XelaReact'
import CreateQuickVisitorLead from '@/Layouts/Modals/Forms/CreateQuickVisitorLead'
import CreateQuickIeltsLead from '@/Layouts/Modals/Forms/CreateQuickIeltsLead'
import useAuth from '@/Hooks/useAuth'
import CreateQuickInsuranceLead from '@/Layouts/Modals/Forms/CreateQuickInsuranceLead'

const AddQuickLeadModal = () => {
  const auth = useAuth()
  const dispatch = useDispatch()
  const leadStore = useSelector((state: RootState) => state.lead)
  const [tab, setTab] = useState(
    auth.agency.subscription_features.includes('lead')
      ? 'study'
      : auth.agency.subscription_features.includes('visitor_lead')
      ? 'visitor'
      : auth.agency.subscription_features.includes('ielts_lead')
      ? 'ielts'
      : auth.agency.subscription_features.includes('insurance_lead')
      ? 'insurance'
      : 'empty'
  )

  const tabItems: TabItem[] = []
  if (auth.agency.subscription_features.includes('lead')) tabItems.push(new TabItem('study', 'Study Lead'))
  if (auth.agency.subscription_features.includes('visitor_lead')) tabItems.push(new TabItem('visitor', 'Visitor Lead'))
  if (auth.agency.subscription_features.includes('ielts_lead')) tabItems.push(new TabItem('ielts', 'Coaching Lead'))
  if (auth.agency.subscription_features.includes('insurance_lead'))
    tabItems.push(new TabItem('insurance', 'Insurance Lead'))

  useEffect(() => {
    if (leadStore.show_add_quick_lead_modal) {
      setTab(
        auth.agency.subscription_features.includes('lead')
          ? 'study'
          : auth.agency.subscription_features.includes('visitor_lead')
          ? 'visitor'
          : auth.agency.subscription_features.includes('ielts_lead')
          ? 'ielts'
          : auth.agency.subscription_features.includes('insurance_lead')
          ? 'insurance'
          : 'empty'
      )
    }
  }, [leadStore.show_add_quick_lead_modal])

  return (
    <Drawer
      position="left"
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      size={800}
      opened={leadStore.show_add_quick_lead_modal}
      onClose={() => {
        setTab('Study')
        dispatch(updateShowAddQuickLeadModal(false))
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack
        spacing="12px"
        style={{
          height: '100%',
        }}
      >
        <HStack justifyContent="space-between">
          <HStack width="30%">
            <Typography variant={'subheadline'}>Add Lead</Typography>
          </HStack>
          <HStack spacing="12px" justifyContent="flex-end">
            <Tabs
              style={{
                borderRadius: '0px',
                borderBottomWidth: '2px',
                borderBottomStyle: 'solid',
                borderBottomColor: XelaColor.Gray12,
                justifyContent: 'flex-end',
              }}
              items={tabItems}
              name="add_lead_modal_tabs"
              defaultValue={tab}
              onChangeHandle={(value) => {
                setTab(value)
              }}
            ></Tabs>
          </HStack>
        </HStack>
        <HStack>
          <Divider variant={'dotted'}></Divider>
        </HStack>
        {tab == 'empty' && (
          <VStack height="100%" alignItems="center" justifyContent="center">
            <Typography variant={'body-bold'} color={XelaColor.Gray6}>
              You {"don't"} have Access to Add Any Kind of Lead
            </Typography>
          </VStack>
        )}
        {tab == 'study' && <CreateQuickStudyLead />}
        {tab == 'visitor' && <CreateQuickVisitorLead />}
        {tab == 'ielts' && <CreateQuickIeltsLead />}
        {tab == 'insurance' && <CreateQuickInsuranceLead />}
      </VStack>
    </Drawer>
  )
}

export default AddQuickLeadModal
