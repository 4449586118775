import React, { FC, HTMLAttributes, useState } from 'react'
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider'
import {
  RangeHandle as Handle,
  RangeTick as Tick,
  RangeTrack as Track,
} from './RangeSliderComponents'
import styled from 'styled-components'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import { getTransition } from '@/XelaReact/helpers/getTransitions'

export interface RangeSliderInputProps extends HTMLAttributes<HTMLDivElement> {
  values: number[]
  step?: number
  min?: number
  max?: number
  deactiveColor?: string
  activeColor?: string
  handleBg?: string
  handleBorderColor?: string
  tooltip?: boolean
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom'
  tooltipBg?: string
  tooltipTextColor?: string
  tick?: boolean
  tickStep?: number
  tickTextColor?: string
  tickColor?: string
  disabled?: boolean
  onChangeHandle?: (values: ReadonlyArray<number>) => void
}

const SliderContainer = styled.div<{
  activeColor: string
  deactiveColor: string
  handleBorderColor: string
  handleBg: string
  isDragged: boolean
  tooltipPosition: 'left' | 'right' | 'top' | 'bottom'
  tooltipBg: string
  tooltiopTextColor: string
  tickTextColor: string
  tickColor: string
  tick: boolean
  tooltip: boolean
  disabled: boolean
}>`
  #overflow-x: clip;
  height: ${(props) => (props.tick ? '48px' : '20px')};
  margin-top: 14px;

  ${(props) => props.disabled && 'opacity: 0.4;'}
  & .xela-slider {
    margin: 14px;
    position: relative;
    width: calc(100% - 28px);
  }

  & .xela-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 100px;
    cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
    background: ${(props) => props.deactiveColor};
  }

  & .xela-slider-handles > * {
    position: absolute;
    z-index: 2;
    width: 24px;
    height: 24px;
    cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08),
      0px 4px 24px rgba(0, 0, 0, 0.08);
    border: ${(props) => (props.isDragged ? '2px' : '1px')} solid
      ${(props) =>
        props.isDragged ? props.activeColor : props.handleBorderColor};
    background: ${(props) => props.handleBg};
    transform: translate(calc(-50% + 0.5px), calc(-50% + 1px));
    fill: ${(props) => props.deactiveColor};
  }

  & .xela-slider-handles > *:before {
    content: attr(aria-valuenow);
    position: absolute;

    padding: 8px 16px;
    border-radius: 10px;
    background: ${(props) => props.tooltipBg};
    color: ${(props) => props.tooltiopTextColor};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;

    ${(props) =>
      props.tooltipPosition == 'left'
        ? 'transform: translate(calc(-100% - 11px), calc(-50% + 13px));'
        : props.tooltipPosition == 'right'
        ? 'transform: translate(calc(35px), calc(-50% + 13px));'
        : props.tooltipPosition == 'top'
        ? 'transform: translate(calc(-50% + 12px), -44px);'
        : 'transform: translate(calc(-50% + 12px), 36px);'}
    display: ${(props) =>
      props.tooltip && props.isDragged ? 'block' : 'none'};
  }

  & .xela-slider-handles > *:hover:before,
  & .xela-slider-handles > *:hover:after {
    display: ${(props) => (props.tooltip ? 'block' : 'none')};
  }

  & .xela-slider-handles > *:after {
    content: '';
    position: absolute;
    border: 8px solid ${(props) => props.tooltipBg};
    ${(props) =>
      props.tooltipPosition == 'left'
        ? 'border-color: transparent transparent transparent ' +
          props.tooltipBg +
          ';transform: translate(-12px, -19px);'
        : props.tooltipPosition == 'right'
        ? 'border-color: transparent  ' +
          props.tooltipBg +
          ' transparent transparent;transform: translate(20px, -19px);'
        : props.tooltipPosition == 'top'
        ? 'border-color: ' +
          props.tooltipBg +
          ' transparent transparent transparent;transform: translate(calc(-50% + 12px), -36px);'
        : 'border-color:  transparent transparent ' +
          props.tooltipBg +
          ' transparent;transform: translate(calc(-50% + 12px), -4px);'}
    display: ${(props) =>
      props.tooltip && props.isDragged ? 'block' : 'none'};
  }

  & .xela-slider-handles > *:hover {
    fill: ${(props) =>
      props.disabled ? props.deactiveColor : props.activeColor};
  }

  & .xela-slider-tracks > * {
    position: absolute;
    margin-top: ${(props) => (props.isDragged ? '-2px' : '0')};
    height: ${(props) => (props.isDragged ? '8px' : '4px')};
    z-index: 1;
    background-color: ${(props) => props.activeColor};
    border-radius: 100px;
    cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
    ${getTransition(200, ['height', 'margin-top'])}
  }

  & .xela-slider-tick-text {
    position: absolute;
    margin-top: 26px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
    color: ${(props) => props.tickTextColor};
    text-align: center;
  }

  & .xela-slider-tick {
    position: absolute;
    margin-top: 18px;
    width: 1px;
    height: 8px;
    background-color: ${(props) => props.tickColor};
  }
`

const DivContainer = styled.div`
  width: 100%;
`

export const RangeSliderInput: FC<RangeSliderInputProps> = ({
  values,
  onChangeHandle,
  step = 1,
  min = 0,
  max = 100,
  deactiveColor = XelaColor.Gray11,
  activeColor = XelaColor.Blue3,
  handleBg = XelaColor.White,
  handleBorderColor = XelaColor.Gray12,
  tooltipBg = XelaColor.Gray3,
  tooltipPosition = 'bottom',
  tooltipTextColor = XelaColor.White,
  tickColor = XelaColor.Gray11,
  tickTextColor = XelaColor.Gray6,
  tickStep = 10,
  tick = false,
  tooltip = false,
  disabled = false,
}) => {
  const domain = [min, max]

  const [isDragged, setDragged] = useState(false)

  return (
    <DivContainer>
      <SliderContainer
        activeColor={activeColor}
        deactiveColor={deactiveColor}
        handleBg={handleBg}
        handleBorderColor={handleBorderColor}
        isDragged={isDragged}
        tooltiopTextColor={tooltipTextColor}
        tooltipBg={tooltipBg}
        tooltipPosition={tooltipPosition}
        tickColor={tickColor}
        tickTextColor={tickTextColor}
        tick={tick}
        tooltip={tooltip}
        disabled={disabled}
      >
        <Slider
          mode={2}
          step={step}
          domain={domain}
          onChange={onChangeHandle}
          values={values}
          className="xela-slider"
          onSlideStart={() => {
            setDragged(true)
          }}
          onSlideEnd={() => {
            setDragged(false)
          }}
          disabled={disabled}
        >
          <Rail>
            {({ getRailProps }) => (
              <div className="xela-slider-rail" {...getRailProps()} />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="xela-slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="xela-slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          {tick && (
            <Ticks count={max / step}>
              {({ ticks }) => (
                <div className="xela-slider-ticks">
                  {ticks.map((tick) => (
                    <Tick
                      key={tick.id}
                      tick={tick}
                      count={ticks.length}
                      tickStep={tickStep}
                    />
                  ))}
                </div>
              )}
            </Ticks>
          )}
        </Slider>
      </SliderContainer>
    </DivContainer>
  )
}

export default RangeSliderInput
