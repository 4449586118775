import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import Button from '@/XelaReact/Button/Button'
import Center from '@/XelaReact/Center/Center'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import Input from '../Input/Input'
import moment from 'moment/moment'

export interface DatepickerProps extends HTMLAttributes<HTMLDivElement> {
  error?: boolean
  helperText?: string
  value?: string
  placeholder?: string
  width?: string
  monthNames?: string[]
  weekdayNames?: string[]
  bg?: string
  borderRadius?: string
  padding?: string
  prevMonthButtonView?: ReactNode
  nextMonthButtonView?: ReactNode
  monthYearColor?: string
  weekdaysColor?: string
  daysColor?: string
  disabledDaysColor?: string
  monthOffset?: number
  minDate?: Date
  maxDate?: Date
  daySelectedBg?: string
  daySelectedColor?: string
  dayRangeBg?: string
  dayRangeColor?: string
  disabledDates?: Array<Date>
  selectedDates?: Array<Date>
  selectedDate?: Date
  startDate?: Date
  endDate?: Date
  mode?: 'date' | 'dates' | 'range'
  onChangeDatesHandle?: (dates?: Array<Date>) => void
  onChangeDateHandle?: (date?: Date) => void
  onChangeStartEndDateHandle?: (startDate?: Date, endDate?: Date) => void
}

const DatepickerComponent = styled(VStack)<{
  padding: string
  daySelectedBg: string
  daySelectedColor: string
  dayRangeBg: string
  dayRangeColor: string
}>`
  z-index: 9999;
  padding: ${(props) => props.padding};
  display: inline-flex;

  & .xela-datepicker-day-btn:hover {
    background: ${(props) => props.daySelectedBg};
    cursor: pointer;
  }

  & .xela-datepicker-day-btn:hover > span {
    color: ${(props) => props.daySelectedColor} !important;
    cursor: pointer;
  }

  & .xela-datepicker-day-btn-today {
    box-sizing: border-box;
    border: 2px solid ${(props) => props.daySelectedBg};
  }

  & .xela-datepicker-day-btn-today > span {
    color: ${(props) => props.daySelectedBg};
  }

  & .xela-datepicker-day-selected {
    background: ${(props) => props.daySelectedBg};
  }

  & .xela-datepicker-day-selected > span {
    color: ${(props) => props.daySelectedColor} !important;
  }

  & .xela-datepicker-day-ranged > span {
    width: 100%;
    line-height: 30px;
    text-align: center;
    background: ${(props) => props.dayRangeBg};
    color: ${(props) => props.dayRangeColor} !important;
  }

  & .xela-datepicker-day-ranged:hover > span {
    background: ${(props) => props.daySelectedBg};
  }
`

export const Datepicker: FC<DatepickerProps> = ({
  error = false,
  helperText,
  value = '',
  placeholder = '',
  width = '334px',
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  weekdayNames = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  bg = XelaColor.Gray12,
  borderRadius = '32px',
  padding = '32px',
  prevMonthButtonView,
  nextMonthButtonView,
  monthYearColor = XelaColor.Gray2,
  weekdaysColor = XelaColor.Gray7,
  daysColor = XelaColor.Gray3,
  disabledDaysColor = XelaColor.Gray9,
  monthOffset = 0,
  minDate = moment().subtract(50, 'years').toDate(),
  maxDate = moment().add(50, 'years').toDate(),
  daySelectedBg = XelaColor.Blue3,
  daySelectedColor = XelaColor.White,
  selectedDate = moment().toDate(),
  selectedDates,
  disabledDates,
  startDate,
  endDate,
  mode = 'date',
  onChangeDateHandle,
  onChangeDatesHandle,
  onChangeStartEndDateHandle,
  dayRangeBg = XelaColor.Blue8,
  dayRangeColor = XelaColor.Gray3,
}) => {
  const [show, setShow] = useState(false)
  const [defaultValue, setDefaultValue] = useState('')

  useEffect(() => {
    if (moment(value, 'DD/MM/YYYY').isValid()) {
      setDefaultValue(moment(value).format('DD/MM/YYYY'))
    } else {
      setDefaultValue(moment().format('DD/MM/YYYY'))
    }
  }, [value])

  const [currentYearMonth, setCurrentYearMonth] = useState(
    new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + monthOffset,
      selectedDate.getDate()
    )
  )

  const [selDate, setSelectedDate] = useState(selectedDate)
  const [selDates, setSelectedDates] = useState(selectedDates)
  const [sDate, setStartDate] = useState(startDate)
  const [eDate, setEndDate] = useState(endDate)

  const daysPerWeek = 7
  const lastMonthDay = new Date(
    currentYearMonth.getFullYear(),
    currentYearMonth.getMonth() + 1,
    0
  ).getDate()
  let firstDayOfWeek = new Date(
    currentYearMonth.getFullYear(),
    currentYearMonth.getMonth(),
    1
  ).getDay()
  firstDayOfWeek = firstDayOfWeek == 0 ? 7 : firstDayOfWeek
  const rows =
    ~~((lastMonthDay + firstDayOfWeek - 1) / daysPerWeek) +
    ((lastMonthDay + firstDayOfWeek - 1) % daysPerWeek > 0 ? 1 : 0)

  const days: Date[] = []

  const prevMonth = new Date(
    currentYearMonth.getFullYear(),
    currentYearMonth.getMonth() - 1,
    1
  )
  const lastPrevMonthDay = new Date(
    prevMonth.getFullYear(),
    prevMonth.getMonth() + 1,
    0
  ).getDate()
  const nextMonth = new Date(
    currentYearMonth.getFullYear(),
    currentYearMonth.getMonth() + 1,
    1
  )

  let i
  let day

  for (let r = 0; r < rows; r++) {
    for (let cell = 1; cell <= daysPerWeek; cell++) {
      i = r * daysPerWeek + cell + 1
      if (firstDayOfWeek > i) {
        day = lastPrevMonthDay - (firstDayOfWeek - i)
        days.push(new Date(prevMonth.getFullYear(), prevMonth.getMonth(), day))
      } else {
        day = i - firstDayOfWeek
        if (lastMonthDay < day) {
          day -= lastMonthDay
          days.push(
            new Date(nextMonth.getFullYear(), nextMonth.getMonth(), day)
          )
        } else {
          days.push(
            new Date(
              currentYearMonth.getFullYear(),
              currentYearMonth.getMonth(),
              day
            )
          )
        }
      }
    }
  }

  let ri = 0
  const rowsItems = []
  while (++ri <= rows) rowsItems.push(ri)

  return (
    <VStack style={{ position: 'relative' }}>
      <Input
        error={error}
        helperText={helperText}
        value={defaultValue}
        width={width}
        placeholder={placeholder}
        onChangeHandle={(value) => {
          setShow(true)
          if (value.length === 10 && moment(value, 'DD/MM/YYYY').isValid()) {
            setCurrentYearMonth(moment(value, 'DD/MM/YYYY').toDate())
            setSelectedDate(moment(value, 'DD/MM/YYYY').toDate())

            setTimeout(() => {
              setShow(false)
            }, 500)
          }
        }}
        onFocusHandle={(value) => {
          setShow(value)
        }}
        onBlurHandle={(value) => {
          setShow(value)
        }}
      />
      <DatepickerComponent
        style={
          show
            ? { display: 'initial', position: 'absolute', top: '64px' }
            : { display: 'none', position: 'absolute', top: '64px' }
        }
        width="auto"
        bg={bg}
        borderRadius={borderRadius}
        padding={padding}
        spacing="12px"
        daySelectedBg={daySelectedBg}
        daySelectedColor={daySelectedColor}
        dayRangeBg={dayRangeBg}
        dayRangeColor={dayRangeColor}
      >
        <HStack justifyContent="space-between">
          <div
            onClick={() => {
              setCurrentYearMonth(
                new Date(
                  currentYearMonth.setMonth(currentYearMonth.getMonth() - 1)
                )
              )
            }}
          >
            {prevMonthButtonView ? (
              prevMonthButtonView
            ) : (
              <Button
                size="small"
                variant="secondary"
                leftIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 8.5L5 12.5M1 8.5L5 4.5M1 8.5L13.5 8.5"
                      stroke="#1D1929"
                    />
                  </svg>
                }
              />
            )}
          </div>
          <Typography color={monthYearColor} variant="subheadline">
            {monthNames[currentYearMonth.getMonth()]}{' '}
            {currentYearMonth.getFullYear()}
          </Typography>
          <div
            onClick={() => {
              setCurrentYearMonth(
                new Date(
                  currentYearMonth.setMonth(currentYearMonth.getMonth() + 1)
                )
              )
            }}
          >
            {nextMonthButtonView ? (
              nextMonthButtonView
            ) : (
              <Button
                size="small"
                variant="secondary"
                leftIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 8.5L9.5 4.5M13.5 8.5L9.5 12.5M13.5 8.5H1"
                      stroke="#1D1929"
                    />
                  </svg>
                }
              />
            )}
          </div>
        </HStack>
        <VStack>
          <HStack>
            {weekdayNames.map((weekday, index) => (
              <Center key={index} width="40px" height="40px">
                <Typography color={weekdaysColor} variant="caption">
                  {weekday}
                </Typography>
              </Center>
            ))}
          </HStack>
          <VStack>
            {rowsItems.map((_r, index) => (
              <HStack key={index}>
                {weekdayNames.map((_cell, cellIndex) => {
                  const day = days[index * daysPerWeek + cellIndex]
                  let isEnabled =
                    day.getMonth() == currentYearMonth.getMonth() &&
                    day.getFullYear() == currentYearMonth.getFullYear()
                  if (
                    day.getTime() > maxDate.getTime() ||
                    day.getTime() < minDate.getTime()
                  ) {
                    isEnabled = false
                  }
                  if (disabledDates) {
                    for (const el of disabledDates) {
                      if (el.getTime() == day.getTime()) {
                        isEnabled = false
                        break
                      }
                    }
                  }
                  const isToday =
                    day.getDate() == selDate.getDate() &&
                    day.getMonth() == selDate.getMonth() &&
                    day.getFullYear() == selDate.getFullYear()
                  let isSelected = false
                  let isRange = false

                  if (mode == 'date') {
                    isSelected = selDate?.getTime() == day.getTime()
                  }

                  if (selDates && mode == 'dates') {
                    for (const el of selDates) {
                      if (el.getTime() == day.getTime()) {
                        isSelected = true
                        break
                      }
                    }
                  }

                  if (mode == 'range') {
                    isSelected =
                      sDate?.getTime() == day.getTime() ||
                      eDate?.getTime() == day.getTime()

                    if (sDate && eDate) {
                      isRange =
                        day.getTime() < eDate.getTime() &&
                        day.getTime() > sDate.getTime()
                    }
                  }

                  return (
                    <Center
                      key={Math.random()}
                      onClick={() => {
                        if (mode == 'date') {
                          setSelectedDate(day)
                          setDefaultValue(moment(day).format('DD/MM/YYYY'))
                          setShow(false)
                          onChangeDateHandle && onChangeDateHandle(day)
                        }
                        if (mode == 'dates') {
                          if (selDates) {
                            const newArr = []
                            let isContains = false
                            for (const el of selDates) {
                              if (el.getTime() != day.getTime()) {
                                newArr.push(el)
                              } else {
                                isContains = true
                              }
                            }
                            !isContains && newArr.push(day)
                            setSelectedDates(newArr)
                            onChangeDatesHandle && onChangeDatesHandle(newArr)
                          } else {
                            setSelectedDates([day])
                            onChangeDatesHandle && onChangeDatesHandle([day])
                          }
                        }
                        if (mode == 'range') {
                          if (sDate === undefined && eDate === undefined) {
                            setStartDate(day)
                            onChangeStartEndDateHandle &&
                              onChangeStartEndDateHandle(day, undefined)
                          } else if (sDate?.getTime() == day.getTime()) {
                            setStartDate(undefined)
                            if (eDate) {
                              setStartDate(eDate)
                              setEndDate(undefined)
                              onChangeStartEndDateHandle &&
                                onChangeStartEndDateHandle(eDate, undefined)
                            } else {
                              onChangeStartEndDateHandle &&
                                onChangeStartEndDateHandle(undefined, undefined)
                            }
                          } else if (eDate?.getTime() == day.getTime()) {
                            setEndDate(undefined)
                            onChangeStartEndDateHandle &&
                              onChangeStartEndDateHandle(sDate, undefined)
                          } else if (sDate && eDate === undefined) {
                            if (day.getTime() > sDate.getTime()) {
                              setEndDate(day)
                              onChangeStartEndDateHandle &&
                                onChangeStartEndDateHandle(sDate, day)
                            } else {
                              setEndDate(sDate)
                              setStartDate(day)
                              onChangeStartEndDateHandle &&
                                onChangeStartEndDateHandle(day, sDate)
                            }
                          } else {
                            if (sDate && day.getTime() < sDate?.getTime()) {
                              setStartDate(day)
                              onChangeStartEndDateHandle &&
                                onChangeStartEndDateHandle(day, eDate)
                            } else if (
                              eDate &&
                              day.getTime() > eDate?.getTime()
                            ) {
                              setEndDate(day)
                              onChangeStartEndDateHandle &&
                                onChangeStartEndDateHandle(sDate, day)
                            } else {
                              setStartDate(undefined)
                              setEndDate(undefined)
                              onChangeStartEndDateHandle &&
                                onChangeStartEndDateHandle(undefined, undefined)
                            }
                          }
                        }
                      }}
                      borderRadius="8px"
                      className={
                        isEnabled
                          ? 'xela-datepicker-day-btn' +
                            (isToday ? ' xela-datepicker-day-btn-today' : '') +
                            (isSelected
                              ? ' xela-datepicker-day-selected'
                              : '') +
                            (isRange ? ' xela-datepicker-day-ranged' : '')
                          : ''
                      }
                      width="40px"
                      height="40px"
                    >
                      <Typography
                        variant="button-medium"
                        color={isEnabled ? daysColor : disabledDaysColor}
                      >
                        {day.getDate()}
                      </Typography>
                    </Center>
                  )
                })}
              </HStack>
            ))}
          </VStack>
        </VStack>
      </DatepickerComponent>
    </VStack>
  )
}

export default Datepicker
