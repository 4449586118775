import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import InsuranceOrder = App.Models.InsuranceOrder

export interface OrderState {
  total_orders: number
  is_filter_applied: boolean
  is_need_to_refresh: boolean
  selected_order: InsuranceOrder | null
  filters: {
    days: string
    status_type: string
    status_value: string
  }
}

const initialState: OrderState = {
  total_orders: 0,
  is_filter_applied: false,
  is_need_to_refresh: false,
  selected_order: null,
  filters: {
    days: '',
    status_type: 'All',
    status_value: 'All',
  },
}

export const insuranceCommissionSlice = createSlice({
  name: 'insuranceCommissionSlice',
  initialState,
  reducers: {
    updateTotalInsuranceCommissions(state, action: PayloadAction<number>) {
      state.total_orders = action.payload
    },
    updateIsNeedToRefresh(state, action: PayloadAction<boolean>) {
      state.is_need_to_refresh = action.payload
    },
    updateFilters(
      state,
      action: PayloadAction<{
        days: string
        status_type: string
        status_value: string
      }>
    ) {
      state.filters = action.payload
    },
    clearFilters(state) {
      state.filters = {
        days: '30 days',
        status_type: 'All',
        status_value: 'All',
      }
    },
  },
})

export const {
  updateTotalInsuranceCommissions,
  updateFilters,
  updateIsNeedToRefresh,
} = insuranceCommissionSlice.actions

export default insuranceCommissionSlice.reducer
