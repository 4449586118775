import './Textarea.css'
import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'

export interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string
  value?: string
  width?: string
  height?: string
  bg?: string
  defaultTextColor?: string
  secondaryTextColor?: string
  defaultBorderColor?: string
  hoverColor?: string
  focusColor?: string
  errorColor?: string
  successColor?: string
  helperText?: string
  error?: boolean
  success?: boolean
  disabled?: boolean
  className?: string
  leftIcon?: ReactNode
  rightElement?: ReactNode
  maxCount?: number
  onChangeHandle?: (value: string) => void
}

const TextareaSubVStack = styled(VStack)`
  justify-content: flex-start;
`

const TextareaVStack = styled(VStack)<TextareaComponentProps>`
  outline: ${(props) =>
    (props.isFocus ? '2px' : '1px') +
    ' solid ' +
    (props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.isFocus
      ? props.focusColor
      : props.isHover
      ? props.hoverColor
      : props.defaultBorderColor)};
  justify-content: flex-start;
  fill: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};
  stroke: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};
  color: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};

  ${(props) =>
    props.disabled !== undefined &&
    'cursor: ' + (props.disabled ? 'initial' : 'text') + ';'}
`

interface TextareaComponentProps extends TextareaProps {
  isFocus?: boolean
  isHover?: boolean
  val: string
}

const TextareaComponent = styled.textarea<TextareaComponentProps>`
  color: ${(props) =>
    props.error
      ? props.errorColor
      : props.success
      ? props.successColor
      : props.defaultTextColor};
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  ${(props) =>
    props.isFocus || props.val.length > 0
      ? css`
          margin-top: 4px;
        `
      : css`
          margin-top: 0;
        `}
`

export const Textarea: FC<TextareaProps> = ({
  placeholder,
  value = '',
  width = '100%',
  height = '100px',
  bg = XelaColor.White,
  defaultTextColor = XelaColor.Gray2,
  secondaryTextColor = XelaColor.Gray3,
  defaultBorderColor = XelaColor.Gray11,
  hoverColor = XelaColor.Blue5,
  focusColor = XelaColor.Blue3,
  errorColor = XelaColor.Red3,
  successColor = XelaColor.Green1,
  disabled = false,
  error = false,
  success = false,
  leftIcon,
  rightElement,
  helperText,
  maxCount,
  onChangeHandle,
  ...props
}) => {
  const [isHover, setHover] = useState(false)
  const [isFocus, setFocus] = useState(false)
  const [val, setValue] = useState(value || '')
  const inputRef = React.useRef<HTMLTextAreaElement>(null)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setValue(value || '')
  }, [value])

  const handleChange = (maxCount: number | undefined) => (
    target: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const valueCount = target.currentTarget.value.length
    if (maxCount !== undefined) {
      if (valueCount <= maxCount) {
        setValue(target.currentTarget.value)
        setCount(target.currentTarget.value.length)
        if (onChangeHandle) {
          onChangeHandle(target.currentTarget.value)
        }
      }
    } else {
      setValue(target.currentTarget.value)
      setCount(target.currentTarget.value.length)
      if (onChangeHandle) {
        onChangeHandle(target.currentTarget.value)
      }
    }
  }

  return (
    <VStack spacing="8px" style={{ opacity: disabled ? '0.4' : '1' }}>
      <TextareaVStack
        error={error}
        success={success}
        errorColor={errorColor}
        successColor={successColor}
        disabled={disabled}
        isFocus={isFocus}
        focusColor={focusColor}
        isHover={isHover}
        hoverColor={hoverColor}
        defaultBorderColor={defaultBorderColor}
        defaultTextColor={defaultTextColor}
        width={width}
        val={val}
        height={height}
        bg={bg}
        borderRadius="18px"
        className="xela-textarea"
        onMouseEnter={() => {
          if (!disabled) {
            setHover(true)
          }
        }}
        onMouseLeave={() => {
          if (!disabled) {
            setHover(false)
          }
        }}
        onClick={() => {
          if (!disabled) {
            setFocus(true)
            inputRef.current?.focus()
          }
        }}
        onFocus={() => {
          if (!disabled) {
            setFocus(true)
            inputRef.current?.focus()
          }
        }}
      >
        <HStack spacing="12px">
          <HStack spacing="12px">
            {leftIcon !== undefined && leftIcon}
            <TextareaSubVStack height={`calc(${height} - 30px)`}>
              <HStack height="auto" justifyContent="space-between">
                <Typography
                  as="span"
                  color={secondaryTextColor}
                  variant="body-small"
                  style={{
                    lineHeight: '16px',
                    fontWeight: 600,
                    fontSize: '13px',
                  }}
                >
                  {placeholder}
                </Typography>
                {maxCount !== undefined && (
                  <Typography
                    as="span"
                    color={secondaryTextColor}
                    variant="body-small"
                    style={{ lineHeight: '15px' }}
                  >
                    {count}/{maxCount}
                  </Typography>
                )}
              </HStack>

              <TextareaComponent
                className="xela-typography-button-medium"
                error={error}
                success={success}
                errorColor={errorColor}
                successColor={successColor}
                defaultTextColor={defaultTextColor}
                isFocus={isFocus}
                val={val}
                autoFocus={isFocus}
                value={val}
                ref={inputRef}
                onChange={handleChange(maxCount)}
                disabled={disabled}
                onBlur={() => {
                  setFocus(false)
                }}
                {...props}
              />
            </TextareaSubVStack>
          </HStack>

          {rightElement !== undefined && rightElement}
        </HStack>
      </TextareaVStack>
      {helperText !== undefined && (
        <Typography
          as="p"
          color={
            error ? errorColor : success ? successColor : secondaryTextColor
          }
          variant="caption"
        >
          {helperText}
        </Typography>
      )}
    </VStack>
  )
}

export default Textarea
