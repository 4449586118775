import React, { FC } from 'react'
import { HStack, Typography, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import Notification = App.Models.Notification

const AdmissionManagerAssignedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification, portal = 'Agency' }) => {
  return (
    <HStack
      spacing="3px"
      style={{
        flexWrap: 'wrap',
        cursor: 'pointer',
        padding: '12px',
      }}
      onClick={() => {
        if (portal === 'Agency') {
          router.visit(`/admissions/${notification.data.admission_id}`)
        } else {
          if (portal === 'B2B') {
            router.visit(`/b2b/leads/${notification.data.lead_id}`)
          } else {
            router.visit(`/leads/${notification.data.lead_id}`)
          }
        }
      }}
    >
      {portal === 'Agency' && (
        <>
          <Typography variant="body-small-bold">{notification.data.event_by}</Typography>
          <Typography variant="body-small" color={XelaColor.Gray6}>
            assigned {notification.data.title} for Client
          </Typography>
          <Typography variant="body-small-bold" color={XelaColor.Orange3}>
            #{notification.data.lead_name}
          </Typography>
        </>
      )}
      {(portal === 'B2B' || portal === 'Hub') && (
        <>
          <Typography variant="body-small-bold">{notification.data.user_name}</Typography>
          <Typography variant="body-small" color={XelaColor.Gray6}>
            assigned {notification.data.title} for Client
          </Typography>
          <Typography variant="body-small-bold" color={XelaColor.Orange3}>
            #{notification.data.lead_name}
          </Typography>
        </>
      )}
    </HStack>
  )
}

export default AdmissionManagerAssignedNotification
