import { usePage } from '@inertiajs/react'
import { EntitySectionData } from '@/Types/EntitySubTypes'
import User = App.Models.User
import EntityStatus = App.Models.EntityStatus
import Branch = App.Models.Branch

interface MetaProps {
  appName: string
  branches: Branch[]
  branchesUsers: { [branchId: string]: Branch }
  counsellors: User[]
  leadStatuses: EntityStatus[]
  visitorLeadStatuses: EntityStatus[]
  ieltsLeadStatuses: EntityStatus[]
  insuranceLeadStatuses: EntityStatus[]
  counsellingStatuses: EntityStatus[]
  admissionStatuses: EntityStatus[]
  admissionApplicationStatuses: EntityStatus[]
  visaStatuses: EntityStatus[]
  agentLeadStatuses: string[]
  agentVisaStatuses: string[]
  entityForms: { [key: string]: EntitySectionData }
  filters: {
    countries: string[]
    courseLevels: string[]
    courseUniversities: string[]
  }
  counts: {
    tasks: number
    followUps: number
    b2bTasks: number
    b2bFollowUps: number
    b2bLeads: number
  }
  timezone: string
  countryWiseWorkflow: {
    [agency: string]: {
      [country: string]: string
    }
  }
}

const useMeta = () => {
  const props = usePage().props

  return props.meta as MetaProps
}

export default useMeta
