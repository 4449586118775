import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, TabItem, Tabs, Typography, VStack, XelaColor } from '@/XelaReact'
import { Checkbox, LoadingOverlay, SelectItem } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { useForm } from '@inertiajs/react'
import { updateShowAddQuickLeadModal } from '@/Store/leadSlice'
import XSelect from '@/Mantine/XSelect'
import XTextInput from '@/Mantine/XTextInput'
import useLocation from '@/Hooks/useLocation'
import useFlash from '@/Hooks/useFlash'
import useMeta from '@/Hooks/useMeta'
import axios from 'axios'
import useAuth from '@/Hooks/useAuth'
import XPhoneInput from '@/Mantine/XPhoneInput'
import XDatePicker from '@/Mantine/XDatePicker'
import moment from 'moment'
import { getOrderOfFieldByLabel, getSelectedOfFieldByLabel } from '@/helpers'
import { EntitySectionData } from '@/Types/EntitySubTypes'
import COUNTRY_FORMATS from '@/Constants/CountryFormats'
import XNumberInput from '@/Mantine/XNumberInput'
import XSelectWithRole from '@/Mantine/XSelectWithRole'
import User = App.Models.User

const CreateQuickVisitorLead = () => {
  const auth = useAuth()
  const meta = useMeta()
  const flash = useFlash()
  const dispatch = useDispatch()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [familyTab, setFamilyTab] = React.useState('')
  const [incomeTab, setIncomeTab] = React.useState('')
  const [countries, setCountries] = useState<SelectItem[]>([])
  const [travelCountries, setTravelCountries] = useState<SelectItem[]>([])
  const [fieldsData] = useState<EntitySectionData>(meta.entityForms['visitor_lead_form'])
  const [categoryData, setCategoryData] = useState<Record<string, boolean>>({})

  const { data, setData, post, errors, reset } = useForm<{
    lead_source: string
    sub_lead_source: string
    given_name: string
    surname: string
    phone: string
    email: string
    country: string
    country_code: string
    country_calling_code: string
    travel_country: string
    travel_country_code: string
    travel_start_date: string
    branch_id: string
    lead_manager_id: string
    service_type: string
    alternate_phone: string
    alternate_country: string
    alternate_country_code: string
    alternate_country_calling_code: string
    date_of_birth: string
    gender: string
    marital_status: string
    visa_type: string
    address: string
    city: string
    state: string
    pin_code: string
    travel_end_date: string
    travel_purpose: string
    personal_identification_type: string
    personal_identification_number: string
    tax_account_number: string
    father_name: string
    father_dob: string
    father_occupation: string
    father_address: string
    father_aadhaar_uid: string
    mother_name: string
    mother_dob: string
    mother_occupation: string
    mother_address: string
    mother_aadhaar_uid: string
    passport_number: string
    passport_nationality: string
    passport_issue_date: string
    passport_expiry_date: string
    is_visa_rejected_in_past: boolean
    visa_rejection_one_country: string
    visa_rejection_one_year: number | ''
    visa_rejection_one_reason: string
    work_experience_one_company_name: string
    work_experience_one_job_title: string
    work_experience_one_is_still_working: boolean
    work_experience_one_start_date: string
    work_experience_one_end_date: string
    work_experience_salary_one_description: string
    itr_returns_one_financial_year: string
    itr_returns_one_financial_year_amount: string
    property_details_one_property_address: string
    property_details_one_property_value: string
  }>({
    lead_source: 'Walk In',
    sub_lead_source: '',
    given_name: '',
    surname: '',
    phone: '',
    email: '',
    country: 'India',
    country_code: 'IN',
    country_calling_code: '+91',
    travel_country: '',
    travel_country_code: '',
    travel_start_date: '',
    branch_id: auth.branch.id.toString(),
    lead_manager_id: '',
    service_type: '',
    alternate_phone: '',
    alternate_country: 'India',
    alternate_country_code: 'IN',
    alternate_country_calling_code: '+91',
    date_of_birth: '',
    gender: 'Male',
    marital_status: 'Not Married',
    visa_type: '',
    address: '',
    city: '',
    state: '',
    pin_code: '',
    travel_end_date: '',
    travel_purpose: '',
    personal_identification_type: '',
    personal_identification_number: '',
    tax_account_number: '',
    father_name: '',
    father_dob: '',
    father_occupation: '',
    father_address: '',
    father_aadhaar_uid: '',
    mother_name: '',
    mother_dob: '',
    mother_occupation: '',
    mother_address: '',
    mother_aadhaar_uid: '',
    passport_number: '',
    passport_nationality: '',
    passport_issue_date: '',
    passport_expiry_date: '',
    is_visa_rejected_in_past: false,
    visa_rejection_one_country: '',
    visa_rejection_one_year: '',
    visa_rejection_one_reason: '',
    work_experience_one_company_name: '',
    work_experience_one_job_title: '',
    work_experience_one_is_still_working: false,
    work_experience_one_start_date: '',
    work_experience_one_end_date: '',
    work_experience_salary_one_description: '',
    itr_returns_one_financial_year: '',
    itr_returns_one_financial_year_amount: '',
    property_details_one_property_address: '',
    property_details_one_property_value: '',
  })

  useEffect(() => {
    if (flash.action === 'visitor-lead:created') {
      const payload = flash.payload as {
        visitor_lead_id: number | string
      } | null

      if (payload) {
        const leadManagerId = data.lead_manager_id
        dispatch(updateShowAddQuickLeadModal(false))
        reset()

        if (
          (auth.role.permissions.includes('visitor_lead_module_manage_lead') ||
            auth.role.permissions.includes('visitor_lead_module_branch_leads') ||
            auth.role.permissions.includes('visitor_lead_module_all_leads')) &&
          leadManagerId === auth.user.id.toString()
        ) {
          window.open((route('visitor-leads.id', { id: payload.visitor_lead_id }) as unknown) as string, '_self')
        }
      }
    }
  }, [flash.timestamp])

  useEffect(() => {
    if (location.country_code) {
      setData((prevData) => ({
        ...prevData,
        country: location.country_name,
        country_code: location.country_code,
        country_calling_code: location.country_calling_code,
      }))
    }
  }, [location])

  useEffect(() => {
    axios
      .post<Record<string, string>>('/select/meta-options', {
        entity_name: 'countries',
        column_name: 'country',
        with_key: true,
      })
      .then((response) => {
        const countryList = Object.keys(response.data).map((key) => {
          return {
            value: key,
            label: response.data[key],
          }
        })

        setCountries(countryList)
        setTravelCountries(countryList)
      })
      .catch((error) => {
        console.error('Error fetching countries:', error)
      })
  }, [])

  const [counsellorData, setCounsellorData] = useState(
    meta.branchesUsers[auth.branch.id].users.map((counsellor) => {
      let isCounsellorEnabled = false
      if (
        counsellor.role.permissions.includes('visitor_lead_module_manage_lead') ||
        counsellor.role.permissions.includes('visitor_lead_module_branch_leads') ||
        counsellor.role.permissions.includes('visitor_lead_module_all_leads')
      ) {
        isCounsellorEnabled = true
      }
      return {
        value: counsellor.id.toString(),
        label: counsellor.name,
        group: counsellor.branch.branch_name,
        role: counsellor.role.role_name,
        disabled: !isCounsellorEnabled,
      }
    })
  )

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      lead_manager_id: '',
    }))

    if (data.branch_id) {
      setCounsellorData(
        meta.branchesUsers[data.branch_id].users.map((counsellor) => {
          let isCounsellorEnabled = false

          if (
            counsellor.role.permissions.includes('visitor_lead_module_manage_lead') ||
            counsellor.role.permissions.includes('visitor_lead_module_branch_leads') ||
            counsellor.role.permissions.includes('visitor_lead_module_all_leads')
          ) {
            isCounsellorEnabled = true
          }
          return {
            value: counsellor.id.toString(),
            label: counsellor.name,
            group: counsellor.branch.branch_name,
            role: counsellor.role.role_name,
            disabled: !isCounsellorEnabled,
          }
        })
      )
    }
  }, [data.branch_id])

  useEffect(() => {
    const branchLeadPermission = auth.role.permissions.includes('visitor_lead_module_branch_leads')
    const allPermission = auth.role.permissions.includes('visitor_lead_module_all_leads')
    const leadPermission = auth.role.permissions.includes('visitor_lead_module_manage_lead')

    if (data.branch_id) {
      const users = meta.branchesUsers[data.branch_id]?.users || []

      const filterAndMapCounsellors = (users: User[]) =>
        users.map((counsellor) => {
          const isCounsellorEnabled = [
            'visitor_lead_module_manage_lead',
            'visitor_lead_module_branch_leads',
            'visitor_lead_module_all_leads',
          ].some((permission) => counsellor.role.permissions.includes(permission))

          return {
            value: counsellor.id.toString(),
            label: counsellor.name,
            group: counsellor.branch.branch_name,
            role: counsellor.role.role_name,
            disabled: !isCounsellorEnabled,
          }
        })

      if (allPermission) {
        setCounsellorData(filterAndMapCounsellors(users))
      } else if (branchLeadPermission && data.branch_id === auth.user.branch_id.toString()) {
        const branchUsers = users.filter((user) => user.branch.branch_name === auth.user.branch.branch_name)
        setCounsellorData(filterAndMapCounsellors(branchUsers))
      } else if (leadPermission) {
        const filteredCounsellors = meta.counsellors.filter((counsellor) => counsellor.id === auth.user.id)
        setCounsellorData(filterAndMapCounsellors(filteredCounsellors))
      } else {
        const filteredCounsellors = meta.counsellors.filter((counsellor) => counsellor.id === auth.user.id)
        setCounsellorData(filterAndMapCounsellors(filteredCounsellors))
      }
    }
  }, [data.branch_id])

  useEffect(() => {
    if (fieldsData) {
      const categoryData: Record<string, boolean> = {}
      Object.entries(fieldsData).forEach(([category, formItems]) => {
        categoryData[category] = formItems.some((formItem) => formItem.selected)
      })
      setCategoryData(categoryData)
    }
  }, [fieldsData])

  const familyTabArrangement = [
    {
      label: 'Father',
      value: 'Father',
      fieldName: 'father_details',
      order: getOrderOfFieldByLabel(fieldsData, 'family_details', 'father_details'),
    },
    {
      label: 'Mother',
      value: 'Mother',
      fieldName: 'mother_details',
      order: getOrderOfFieldByLabel(fieldsData, 'family_details', 'mother_details'),
    },
  ]

  familyTabArrangement.sort((a, b) => a.order - b.order)

  useEffect(() => {
    if (familyTabArrangement.length > 0) {
      setFamilyTab(familyTabArrangement[0].label)
    }
  }, [])

  const incomeTabArrangement = [
    {
      label: 'ITR Returns',
      value: 'ITR Returns',
      fieldName: 'itr_returns',
      order: getOrderOfFieldByLabel(fieldsData, 'income_details', 'itr_returns'),
    },
    {
      label: 'Property Details',
      value: 'Property Details',
      fieldName: 'property_details',
      order: getOrderOfFieldByLabel(fieldsData, 'income_details', 'property_details'),
    },
  ]

  incomeTabArrangement.sort((a, b) => a.order - b.order)

  useEffect(() => {
    if (incomeTabArrangement.length > 0) {
      setIncomeTab(incomeTabArrangement[0].label)
    }
  }, [])

  return (
    <VStack
      spacing="12px"
      style={{
        height: '100%',
        position: 'relative',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
      <VStack
        className={'pr-2 overflow-y-auto '}
        spacing={'16px'}
        style={{
          flex: '1 1 0',
          height: 0,
        }}
      >
        <VStack spacing={'12px'}>
          <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
            Personal Details
          </Typography>
          <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'given_name') }}>
              <XTextInput
                data-autofocus
                required
                label={'Given Name'}
                error={errors.given_name}
                value={data.given_name}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    given_name: event.target.value,
                  }))
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'surname') }}>
              <XTextInput
                label={'Surname'}
                error={errors.surname}
                value={data.surname}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    surname: event.target.value,
                  }))
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'phone') }}>
              <XPhoneInput
                error={errors.phone}
                dialCode={data.country_calling_code}
                countryCode={data.country_code}
                initialValue={data.phone}
                onChange={(number, dialCode, countryCode, country) => {
                  setData((prevData) => ({
                    ...prevData,
                    phone: number,
                    country_calling_code: dialCode,
                    country_code: countryCode,
                    country: country,
                  }))
                }}
              ></XPhoneInput>
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'email') }}>
              <XTextInput
                label={'Email'}
                error={errors.email}
                value={data.email}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    email: event.target.value,
                  }))
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'branch') }}>
              <XSelect
                searchable
                required
                label="Select Branch"
                value={data.branch_id}
                error={errors.branch_id}
                data={auth.user.branches.map((branch) => {
                  return {
                    value: branch.id.toString(),
                    label: branch.branch_name,
                  }
                })}
                onChange={(value) => {
                  if (value) {
                    setData((prevData) => ({
                      ...prevData,
                      branch_id: value,
                    }))
                  }
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'lead_manager_id') }}>
              <XSelectWithRole
                searchable
                required
                label="Select Lead Manager"
                value={data.lead_manager_id}
                error={errors.lead_manager_id}
                data={counsellorData}
                onChange={(value) => {
                  if (value) {
                    setData((prevData) => ({
                      ...prevData,
                      lead_manager_id: value,
                    }))
                  }
                }}
              />
            </HStack>
            <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'lead_source') }}>
              <XSelect
                searchable
                required
                label={'Lead Source'}
                error={errors.lead_source}
                value={data.lead_source}
                onInit={(callback) => {
                  axios
                    .post('/select/meta-options', {
                      entity_name: 'visitor_leads',
                      column_name: 'lead_source',
                    })
                    .then((response) => {
                      callback(response.data)
                    })
                    .catch(() => {
                      callback([])
                    })
                }}
                onChange={(value) => {
                  if (value) {
                    setData((prevData) => ({
                      ...prevData,
                      lead_source: value,
                    }))
                  }
                }}
              />
            </HStack>
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'sub_lead_source') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'sub_lead_source') }}>
                <XTextInput
                  label={'Sub Lead Source'}
                  error={errors.sub_lead_source}
                  value={data.sub_lead_source}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      sub_lead_source: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'service_type') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'service_type') }}>
                <XSelect
                  searchable
                  label={'Service Type'}
                  error={errors.service_type}
                  value={data.service_type}
                  onInit={(callback) => {
                    axios
                      .post('/select/meta-options', {
                        entity_name: 'visitor_leads',
                        column_name: 'service_type',
                      })
                      .then((response) => {
                        callback(response.data)
                      })
                      .catch(() => {
                        callback([])
                      })
                  }}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        service_type: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'alternate_phone') }}>
                <XPhoneInput
                  label={'Alternate Phone'}
                  error={errors.alternate_phone}
                  dialCode={data.alternate_country_calling_code}
                  countryCode={data.alternate_country_code}
                  initialValue={data.alternate_phone}
                  onChange={(number, dialCode, countryCode, country) => {
                    setData((prevData) => ({
                      ...prevData,
                      alternate_phone: number,
                      alternate_country_calling_code: dialCode,
                      alternate_country_code: countryCode,
                      alternate_country: country,
                    }))
                  }}
                ></XPhoneInput>
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'date_of_birth') }}>
                <XDatePicker
                  clearable
                  dateParser={(dateString) => {
                    return moment(dateString, 'DD/MM/YYYY').toDate()
                  }}
                  value={data.date_of_birth ? moment(data.date_of_birth as string, 'YYYY-MM-DD').toDate() : null}
                  width="100%"
                  placeholder="DD/MM/YYYY"
                  valueFormat={'DD/MM/YYYY'}
                  maxDate={new Date()}
                  label={'Date of Birth'}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        date_of_birth: moment(value).format('YYYY-MM-DD'),
                      }))
                    } else {
                      setData((prevData) => ({
                        ...prevData,
                        date_of_birth: '',
                      }))
                    }
                  }}
                  error={errors.date_of_birth}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'marital_status') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'marital_status') }}>
                <XSelect
                  searchable
                  label={'Marital Status'}
                  error={errors.marital_status}
                  value={data.marital_status}
                  data={['Married', 'Not Married']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        marital_status: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'gender') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'gender') }}>
                <XSelect
                  searchable
                  label={'Gender'}
                  error={errors.gender}
                  value={data.gender}
                  data={['Male', 'Female', 'Others']}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        gender: value,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'address') && (
              <HStack
                className={'col-span-2'}
                style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'address') }}
              >
                <XTextInput
                  label={'Address'}
                  error={errors.address}
                  value={data.address}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      address: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'country') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'country') }}>
                <XSelect
                  searchable
                  label={'Country'}
                  error={errors.country}
                  value={data.country_code}
                  data={countries}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        country_code: value,
                        country: countries.find((country) => country.value === value)?.label as string,
                      }))
                    }
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'state') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'state') }}>
                <XTextInput
                  label={'State'}
                  error={errors.state}
                  value={data.state}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      state: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'city') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'city') }}>
                <XTextInput
                  label={'City'}
                  error={errors.city}
                  value={data.city}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      city: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
            {getSelectedOfFieldByLabel(fieldsData, 'personal_details', 'pincode') && (
              <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'personal_details', 'pincode') }}>
                <XTextInput
                  label={'Pincode'}
                  error={errors.pin_code}
                  value={data.pin_code}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      pin_code: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
          </div>
        </VStack>
        {categoryData['travel_details'] && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Travel Details
            </Typography>
            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'travel_country') && (
                <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'travel_country') }}>
                  <XSelect
                    searchable
                    label={'Travel Country'}
                    error={errors.travel_country}
                    value={data.travel_country_code}
                    data={travelCountries}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          travel_country_code: value,
                          travel_country: countries.find((country) => country.value === value)?.label as string,
                        }))
                      }
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'travel_start_date') && (
                <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'travel_start_date') }}>
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.travel_start_date ? moment(data.travel_start_date as string, 'YYYY-MM-DD').toDate() : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Travel Start Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          travel_start_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          travel_start_date: '',
                        }))
                      }
                    }}
                    error={errors.travel_start_date}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'travel_end_date') && (
                <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'travel_end_date') }}>
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={data.travel_end_date ? moment(data.travel_end_date as string, 'YYYY-MM-DD').toDate() : null}
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Travel End Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          travel_end_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          travel_end_date: '',
                        }))
                      }
                    }}
                    error={errors.travel_end_date}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'travel_purpose') && (
                <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'travel_purpose') }}>
                  <XTextInput
                    data-autofocus
                    label={'Travel Purpose'}
                    error={errors.travel_purpose}
                    value={data.travel_purpose}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        travel_purpose: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'personal_identification_type') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'personal_identification_type'),
                  }}
                >
                  <XSelect
                    searchable
                    label={'Personal Identification Type'}
                    error={errors.personal_identification_type}
                    value={data.personal_identification_type}
                    data={['Aadhaar', 'Passport', 'Driving License']}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          personal_identification_type: value,
                        }))
                      }
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'personal_identification_number') && (
                <HStack
                  style={{
                    order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'personal_identification_number'),
                  }}
                >
                  <XTextInput
                    label={'Personal Identification Number'}
                    error={errors.personal_identification_number}
                    value={data.personal_identification_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        personal_identification_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'travel_details', 'tax_account_number') && (
                <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'travel_details', 'tax_account_number') }}>
                  <XTextInput
                    label={'PAN Card Number'}
                    error={errors.tax_account_number}
                    value={data.tax_account_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        tax_account_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {categoryData['income_details'] && (
          <>
            <VStack spacing={'12px'}>
              <Divider variant={'dotted'} />
              <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
                Income Details
              </Typography>
              <Tabs
                style={{
                  width: '100%',
                  borderBottomWidth: '2px',
                  borderBottomStyle: 'solid',
                  borderBottomColor: XelaColor.Gray12,
                }}
                items={incomeTabArrangement
                  .filter((tab) => getSelectedOfFieldByLabel(fieldsData, 'income_details', tab.fieldName))
                  .map((tab) => new TabItem(tab.label, tab.value))}
                name="income_details_edit_modal_tabs"
                defaultValue={incomeTab}
                onChangeHandle={(value) => {
                  setIncomeTab(value)
                }}
              ></Tabs>
              {incomeTab === 'ITR Returns' && (
                <VStack spacing="12px">
                  <HStack spacing="12px" alignItems="flex-start">
                    <HStack>
                      <XSelect
                        searchable
                        label={'ITR Returns Year'}
                        error={errors.itr_returns_one_financial_year}
                        value={data.itr_returns_one_financial_year}
                        data={['FY 2023-24', 'FY 2022-23', 'FY 2021-22', 'FY 2021-20']}
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              itr_returns_one_financial_year: value,
                            }))
                          }
                        }}
                      />
                    </HStack>
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Amount'}
                        error={errors.itr_returns_one_financial_year_amount}
                        value={data.itr_returns_one_financial_year_amount}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            itr_returns_one_financial_year_amount: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                  </HStack>
                </VStack>
              )}
              {incomeTab === 'Property Details' && (
                <VStack spacing="12px">
                  <HStack spacing="12px" alignItems="flex-start">
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Property Address'}
                        error={errors.property_details_one_property_address}
                        value={data.property_details_one_property_address}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            property_details_one_property_address: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Property Value'}
                        error={errors.property_details_one_property_value}
                        value={data.property_details_one_property_value}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            property_details_one_property_value: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                  </HStack>
                </VStack>
              )}
            </VStack>
          </>
        )}
        {categoryData['family_details'] && (
          <>
            <VStack spacing={'12px'}>
              <Divider variant={'dotted'} />
              <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
                Family Details
              </Typography>
              <Tabs
                style={{
                  width: '100%',
                  borderBottomWidth: '2px',
                  borderBottomStyle: 'solid',
                  borderBottomColor: XelaColor.Gray12,
                }}
                items={familyTabArrangement
                  .filter((tab) => getSelectedOfFieldByLabel(fieldsData, 'family_details', tab.fieldName))
                  .map((tab) => new TabItem(tab.label, tab.value))}
                name="family_details_edit_modal_tabs"
                defaultValue={familyTab}
                onChangeHandle={(value) => {
                  setFamilyTab(value)
                }}
              ></Tabs>
              {familyTab === 'Father' && (
                <VStack spacing="12px">
                  <HStack spacing="12px" alignItems="flex-start">
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Father Name'}
                        error={errors.father_name}
                        value={data.father_name}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            father_name: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                    <HStack>
                      <XDatePicker
                        clearable
                        maxDate={new Date()}
                        dateParser={(dateString) => {
                          return moment(dateString, 'DD/MM/YYYY').toDate()
                        }}
                        value={data.father_dob ? moment(data.father_dob as string, 'YYYY-MM-DD').toDate() : null}
                        width="100%"
                        placeholder="DD/MM/YYYY"
                        valueFormat={'DD/MM/YYYY'}
                        label={'Father DOB'}
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              father_dob: moment(value).format('YYYY-MM-DD'),
                            }))
                          } else {
                            setData((prevData) => ({
                              ...prevData,
                              father_dob: '',
                            }))
                          }
                        }}
                        error={errors.father_dob}
                      />
                    </HStack>
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Father Occupation'}
                        error={errors.father_occupation}
                        value={data.father_occupation}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            father_occupation: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                  </HStack>
                  <HStack spacing="12px" alignItems="flex-start">
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Father Aadhaar UID'}
                        error={errors.father_aadhaar_uid}
                        value={data.father_aadhaar_uid}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            father_aadhaar_uid: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                    <HStack width="200%">
                      <XTextInput
                        data-autofocus
                        label={'Father Address'}
                        error={errors.father_address}
                        value={data.father_address}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            father_address: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                  </HStack>
                </VStack>
              )}
              {familyTab === 'Mother' && (
                <VStack spacing="12px">
                  <HStack spacing="12px" alignItems="flex-start">
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Mother Name'}
                        error={errors.mother_name}
                        value={data.mother_name}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            mother_name: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                    <HStack>
                      <XDatePicker
                        clearable
                        maxDate={new Date()}
                        dateParser={(dateString) => {
                          return moment(dateString, 'DD/MM/YYYY').toDate()
                        }}
                        value={data.mother_dob ? moment(data.mother_dob as string, 'YYYY-MM-DD').toDate() : null}
                        width="100%"
                        placeholder="DD/MM/YYYY"
                        valueFormat={'DD/MM/YYYY'}
                        label={'Mother DOB'}
                        onChange={(value) => {
                          if (value) {
                            setData((prevData) => ({
                              ...prevData,
                              mother_dob: moment(value).format('YYYY-MM-DD'),
                            }))
                          } else {
                            setData((prevData) => ({
                              ...prevData,
                              mother_dob: '',
                            }))
                          }
                        }}
                        error={errors.mother_dob}
                      />
                    </HStack>
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Mother Occupation'}
                        error={errors.mother_occupation}
                        value={data.mother_occupation}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            mother_occupation: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                  </HStack>
                  <HStack spacing="12px" alignItems="flex-start">
                    <HStack>
                      <XTextInput
                        data-autofocus
                        label={'Mother Aadhaar UID'}
                        error={errors.mother_aadhaar_uid}
                        value={data.mother_aadhaar_uid}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            mother_aadhaar_uid: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                    <HStack width="200%">
                      <XTextInput
                        data-autofocus
                        label={'Mother Address'}
                        error={errors.mother_address}
                        value={data.mother_address}
                        onChange={(event) => {
                          setData((prevData) => ({
                            ...prevData,
                            mother_address: event.target.value,
                          }))
                        }}
                      />
                    </HStack>
                  </HStack>
                </VStack>
              )}
            </VStack>
          </>
        )}
        {categoryData['passport_details'] && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Passport Details
            </Typography>
            <div className="grid grid-cols-2 w-full gap-4 items-start p-[1px]">
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_number') && (
                <HStack style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_number') }}>
                  <XTextInput
                    data-autofocus
                    label={'Passport Number'}
                    error={errors.passport_number}
                    value={data.passport_number}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_number: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_nationality') }}
                >
                  <XTextInput
                    label={'Passport Nationality'}
                    error={errors.passport_nationality}
                    value={data.passport_nationality}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        passport_nationality: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_expiry_date') }}
                >
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_expiry_date
                        ? moment(data.passport_expiry_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Expiry Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_expiry_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          passport_expiry_date: '',
                        }))
                      }
                    }}
                    error={errors.passport_expiry_date}
                  />
                </HStack>
              )}
              {getSelectedOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date') && (
                <HStack
                  style={{ order: getOrderOfFieldByLabel(fieldsData, 'passport_details', 'passport_issue_date') }}
                >
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'DD/MM/YYYY').toDate()
                    }}
                    value={
                      data.passport_issue_date
                        ? moment(data.passport_issue_date as string, 'YYYY-MM-DD').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="DD/MM/YYYY"
                    valueFormat={'DD/MM/YYYY'}
                    label={'Passport Issue Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          passport_issue_date: moment(value).format('YYYY-MM-DD'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          passport_issue_date: '',
                        }))
                      }
                    }}
                    error={errors.passport_issue_date}
                  />
                </HStack>
              )}
            </div>
          </VStack>
        )}
        {getSelectedOfFieldByLabel(fieldsData, 'visa_details', 'is_visa_rejected_in_past') && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Visa Details
            </Typography>
            <HStack>
              <Checkbox
                label={'Is Visa Rejected in Past'}
                color={'blueX'}
                checked={data.is_visa_rejected_in_past}
                onChange={(event) => {
                  setData((prevData) => ({
                    ...prevData,
                    is_visa_rejected_in_past: event.target.checked,
                  }))
                }}
              />
            </HStack>
            {data.is_visa_rejected_in_past && (
              <HStack spacing={'12px'}>
                <XSelect
                  searchable
                  data={Object.values(COUNTRY_FORMATS)}
                  label={'Rejection Country'}
                  value={data.visa_rejection_one_country}
                  error={errors.visa_rejection_one_country}
                  onChange={(value) => {
                    if (value) {
                      setData((prevData) => ({
                        ...prevData,
                        visa_rejection_one_country: value,
                      }))
                    }
                  }}
                />
                <XNumberInput
                  type={'number'}
                  label={'Rejection Year'}
                  error={errors.visa_rejection_one_year}
                  defaultValue={data.visa_rejection_one_year}
                  onChange={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      visa_rejection_one_year: value,
                    }))
                  }}
                />
                <XTextInput
                  label={'Rejection Reason'}
                  error={errors.visa_rejection_one_reason}
                  value={data.visa_rejection_one_reason}
                  onChange={(event) => {
                    setData((prevData) => ({
                      ...prevData,
                      visa_rejection_one_reason: event.target.value,
                    }))
                  }}
                />
              </HStack>
            )}
          </VStack>
        )}
        {getSelectedOfFieldByLabel(fieldsData, 'work_experience', 'work_experience_details') && (
          <VStack spacing={'12px'}>
            <Divider variant={'dotted'} />
            <Typography color={XelaColor.Blue3} variant={'body-small-bold'}>
              Work Experiences
            </Typography>
            <VStack spacing="12px">
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XTextInput
                    data-autofocus
                    label={'Company Name'}
                    error={errors.work_experience_one_company_name}
                    value={data.work_experience_one_company_name}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_company_name: event.target.value,
                      }))
                    }}
                  />
                </HStack>
                <HStack>
                  <XTextInput
                    label={'Job Title'}
                    error={errors.work_experience_one_job_title}
                    value={data.work_experience_one_job_title}
                    onChange={(event) => {
                      setData((prevData) => ({
                        ...prevData,
                        work_experience_one_job_title: event.target.value,
                      }))
                    }}
                  />
                </HStack>
              </HStack>
              <VStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <Divider variant={'dotted'} color={XelaColor.Gray11}></Divider>
                </HStack>
                <HStack>
                  <HStack>
                    <Checkbox
                      label={'I am currently working in this role.'}
                      color={'blueX'}
                      checked={data.work_experience_one_is_still_working}
                      onChange={(event) => {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_is_still_working: event.target.checked,
                        }))
                      }}
                    />
                  </HStack>
                </HStack>
              </VStack>
              <HStack spacing="12px" alignItems="flex-start">
                <HStack>
                  <XDatePicker
                    clearable
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_start_date
                        ? moment(data.work_experience_one_start_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'Start Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_start_date: moment(value).format('YYYY-MM'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_start_date: '',
                        }))
                      }
                    }}
                    error={errors.work_experience_one_start_date}
                  />
                </HStack>
                <HStack>
                  <XDatePicker
                    clearable
                    disabled={data.work_experience_one_is_still_working}
                    dateParser={(dateString) => {
                      return moment(dateString, 'MM/YYYY').toDate()
                    }}
                    value={
                      data.work_experience_one_end_date
                        ? moment(data.work_experience_one_end_date as string, 'YYYY-MM').toDate()
                        : null
                    }
                    width="100%"
                    placeholder="MM/YYYY"
                    valueFormat={'MM/YYYY'}
                    label={'End Date'}
                    onChange={(value) => {
                      if (value) {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_end_date: moment(value).format('YYYY-MM'),
                        }))
                      } else {
                        setData((prevData) => ({
                          ...prevData,
                          work_experience_one_end_date: '',
                        }))
                      }
                    }}
                    error={errors.work_experience_one_end_date}
                  />
                </HStack>
              </HStack>
            </VStack>
          </VStack>
        )}
      </VStack>
      <HStack>
        <Divider variant={'dotted'}></Divider>
      </HStack>
      <HStack spacing={'12px'} justifyContent="flex-end">
        <Button
          label={'Cancel'}
          variant={'secondary'}
          onClick={() => {
            dispatch(updateShowAddQuickLeadModal(false))
            reset()
          }}
        ></Button>
        <Button
          label={'Create'}
          onClick={() => {
            setLoading(true)
            post(route('visitor-leads.quick-create'), {
              preserveState: true,
              preserveScroll: true,
              onFinish: () => {
                setLoading(false)
              },
            })
          }}
        ></Button>
      </HStack>
    </VStack>
  )
}

export default CreateQuickVisitorLead
