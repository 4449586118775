import React, { FC, useEffect, useState } from 'react'
import { Avatar, Box, Center, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router, usePage } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import IconOff from '@/Icons/IconOff'
import IconDrawerClose from '@/Icons/IconDrawerClose'
import IconDrawerOpen from '@/Icons/IconDrawerOpen'
import { useDispatch, useSelector } from 'react-redux'
import { updateAvatar } from '@/Store/userSlice'
import { RootState } from '@/Store'
import IconHome from '@/Icons/IconHome'
import IconTextDocumentAlt from '@/Icons/IconTextDocumentAlt'
import useDimension from '@/Hooks/useWidthResize'
import { IconChevronDown, IconChevronUp, IconFileCertificate, IconPlane } from '@tabler/icons-react'

interface SidebarItemProps {
  item: SidebarItemType
  isOpen: boolean
  nested?: boolean
}

interface SidebarItemType {
  type?: string
  label?: string
  route?: string
  icon?: React.JSX.Element
  roles?: string[]
  children?: SidebarItemType[]
}

export const SIDEBAR_ITEMS = [
  {
    type: 'item',
    label: 'Dashboard',
    route: '/dashboard',
    icon: <IconHome width="18" height="18" viewBox="0 0 16 16" />,
    roles: ['*'],
  },
  {
    type: 'divider',
  },
  {
    type: 'item',
    label: 'Agencies',
    route: '/agencies',
    icon: <IconPlane fontSize={24} stroke={1.4} color={XelaColor.Blue3} fill={XelaColor.Blue12} />,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Courses',
    route: '/courses',
    icon: <IconTextDocumentAlt width="18" height="19" viewBox="0 0 16 17" />,
    roles: ['*'],
    children: [
      {
        label: 'Meta Courses',
        route: '/courses/meta-courses',
      },
      {
        label: 'Agency Courses',
        route: '/courses/agency-courses',
      },
    ],
  },
  {
    type: 'item',
    label: 'Insurances',
    route: '/insurances',
    icon: <IconFileCertificate fontSize={24} stroke={1.4} color={XelaColor.Blue3} fill={XelaColor.Blue12} />,
    roles: ['*'],
    children: [
      {
        label: 'Companies',
        route: '/insurance/companies',
      },
      {
        label: 'Policies',
        route: '/insurance/policies',
      },
      {
        label: 'Orders',
        route: '/insurance/orders',
      },
      {
        label: 'Agency Invoices',
        route: '/insurance/agency/invoices',
      },
      {
        label: 'Company Invoices',
        route: '/insurance/company/invoices',
      },
    ],
  },
  {
    type: 'grow',
  },
]

const SidebarItem: React.FC<SidebarItemProps> = ({ item, isOpen, nested }) => {
  const { url } = usePage()

  const [hover, setHover] = useState(false)
  const [showChildren, setShowChildren] = useState(item.route && url.startsWith(item.route))

  const handleClick = () => {
    if (item.children) {
      setShowChildren(!showChildren)
    } else {
      if (item.route) {
        router.visit(item.route)
      }
    }
  }

  return (
    <>
      <HStack
        width={nested ? 'calc(100% - 28px)' : '100%'}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
        spacing="12px"
        style={{
          padding: nested ? '8px 12px' : '8px 16px',
          paddingLeft: nested ? '12px' : '16px',
          backgroundColor: hover
            ? item.route && url === item.route
              ? XelaColor.Blue12
              : XelaColor.Gray11
            : item.route && url === item.route
            ? XelaColor.Blue12
            : XelaColor.White,
          borderRadius: '12px',
          marginLeft: nested ? '40px' : 0,
        }}
        className="sidebar-icon-btn"
      >
        {!nested && (
          <Center width="18px" height="18px" style={{ minWidth: '18px' }}>
            {item.icon}
          </Center>
        )}
        {isOpen && (
          <HStack spacing="12px" justifyContent="space-between" width="initial">
            <Typography
              variant={nested ? 'caption-bold' : 'body-small-bold'}
              color={nested ? XelaColor.Gray4 : XelaColor.Gray3}
            >
              {item.label}
            </Typography>
            {item.children &&
              (showChildren ? (
                <IconChevronUp color={XelaColor.Gray3} size={16}></IconChevronUp>
              ) : (
                <IconChevronDown color={XelaColor.Gray3} size={16}></IconChevronDown>
              ))}
          </HStack>
        )}
      </HStack>
      {item.children && showChildren && (
        <VStack spacing="4px" width="initial">
          {isOpen &&
            item.children &&
            item.children.map((child, index) => <SidebarItem key={index} item={child} isOpen={isOpen} nested={true} />)}
        </VStack>
      )}
    </>
  )
}

const AdminSidebar: FC = () => {
  const dimension = useDimension()
  const dispatch = useDispatch()

  const admin = useAuth().admin
  const agentRole = useAuth().role

  const [isOpen, setOpen] = useState(true)
  const [showElement, setShowElement] = useState(true)

  // Check width and perform desired action
  useEffect(() => {
    if (dimension < 1200) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [dimension])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowElement(true)
      }, 150)
    } else {
      setShowElement(false)
    }
  }, [isOpen])

  useEffect(() => {
    dispatch(updateAvatar(admin.avatar))
  }, [])

  const avatar = useSelector((state: RootState) => state.user.avatar)

  return (
    <div style={{ position: 'relative', paddingRight: '10px' }}>
      <Center
        style={{
          background: XelaColor.White,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '12px',
          position: 'absolute',
          right: '-5px',
          top: '30px',
          zIndex: 9,
        }}
        className="sidebar-icon-btn"
        onClick={() => {
          setOpen(!isOpen)
        }}
        width="32px"
        height="32px"
      >
        {isOpen ? (
          <IconDrawerClose width="15" height="16" viewBox="0 0 15 16" />
        ) : (
          <IconDrawerOpen width="15" height="16" viewBox="0 0 15 16" />
        )}
      </Center>
      <VStack
        width={isOpen ? '265px' : '105px'}
        className="sidebar"
        bg={XelaColor.White}
        spacing="12px"
        justifyContent="flex-start"
        height="100vh"
        style={{
          borderLeft: `5px solid ${XelaColor.Green1}`,
          overflowX: !isOpen ? 'visible' : 'hidden',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <HStack justifyContent={isOpen ? 'space-between' : 'flex-end'}>
          <VStack spacing="4px">
            <HStack spacing={isOpen ? '20px' : '0px'} justifyContent="center">
              {showElement && (
                <HStack spacing="4px">
                  <Typography variant={'subheadline'} color={XelaColor.Blue1}>
                    Super
                  </Typography>
                  <Typography variant={'subheadline'} color={XelaColor.Green1}>
                    Admin
                  </Typography>
                </HStack>
              )}
              {!showElement && (
                <HStack spacing="1px" justifyContent="center">
                  <Typography variant={'subheadline'} color={XelaColor.Blue1}>
                    S
                  </Typography>
                  <Typography variant={'subheadline'} color={XelaColor.Green1}>
                    A
                  </Typography>
                </HStack>
              )}
            </HStack>
          </VStack>
        </HStack>
        <Divider variant="dotted" />
        <VStack spacing="12px" className="grow">
          {SIDEBAR_ITEMS.filter((item) => {
            if (item.type !== 'item') {
              return true
            }

            if (item.roles && item.roles.includes('*')) {
              return true
            }

            return item.roles && agentRole && item.roles.includes(agentRole.role_name)
          }).map((item, index) => {
            if (item.type === 'divider') {
              return <Divider key={index} variant="dotted" />
            }

            if (item.type === 'grow') {
              return <Box key={index} className="grow"></Box>
            }

            return <SidebarItem key={index} item={item} isOpen={isOpen} />
          })}
        </VStack>
        <HStack spacing="12px" justifyContent="center">
          <Avatar size="small" image={<img src={avatar || admin.avatar} alt="avatar" />} />
          {isOpen && showElement && (
            <>
              <VStack
                style={{
                  minWidth: dimension > 1440 ? '150px' : '130px',
                  wordBreak: 'break-all',
                }}
              >
                <Typography variant="body-small-bold" color={XelaColor.Gray2}>
                  {admin.name}
                </Typography>
                <Typography variant="caption" color={XelaColor.Gray6}>
                  {admin.email}
                </Typography>
              </VStack>
              <Center width="16px" style={{ minWidth: '16px' }} className="sidebar-icon-btn">
                <IconOff
                  width="16"
                  height="16"
                  viewBox="0 0 15 15"
                  onClick={() => {
                    router.post(route('admin.logout'))
                  }}
                ></IconOff>
              </Center>
            </>
          )}
        </HStack>
      </VStack>
    </div>
  )
}

export default AdminSidebar
