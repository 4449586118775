import { SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement>

const IconCreditCard = (props: Props) => (
  <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M14.5 11.5V5.5H0.5L0.5 11.5C0.5 12.0523 0.947716 12.5 1.5 12.5L13.5 12.5C14.0523 12.5 14.5 12.0523 14.5 11.5Z"
        fill="#E6ECFF"
      />
      <path
        d="M0.5 11.5L0.5 3.5C0.5 2.94772 0.947716 2.5 1.5 2.5L13.5 2.5C14.0523 2.5 14.5 2.94772 14.5 3.5V11.5M0.5 11.5C0.5 12.0523 0.947716 12.5 1.5 12.5L13.5 12.5C14.0523 12.5 14.5 12.0523 14.5 11.5M0.5 11.5L0.5 5.5H14.5V11.5M2 9.5H8M10 9.5H13"
        stroke="#003CFF"
      />
    </g>
  </svg>
)

export { IconCreditCard }
