import React, { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import HStack from '@/XelaReact/HStack/HStack'
import SvgIcon from '@/XelaReact/SvgIcon/SvgIcon'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export class Step {
  num: number
  title?: string
  caption?: React.ReactNode | string
  status: 'current' | 'default' | 'error' | 'success' | 'pending'
  progressIndicator?: React.ReactNode
  constructor(
    num: number,
    title?: string,
    caption?: React.ReactNode | string,
    status: 'current' | 'default' | 'error' | 'pending' | 'success' = 'default',
    progressIndicator?: React.ReactNode
  ) {
    this.num = num
    this.title = title
    this.caption = caption
    this.status = status
    this.progressIndicator = progressIndicator
  }
}

export interface StepsProps extends HTMLAttributes<HTMLDivElement> {
  steps: Step[]
  size?: 'small' | 'medium' | 'large'
  variant?: 'horizontal' | 'vertical'
  indicatorBgErrorColor?: string
  indicatorBgDefaultColor?: string
  indicatorBgSuccessColor?: string
  indicatorBgPendingColor?: string
  indicatorBgCurrentColor?: string
  indicatorContentErrorColor?: string
  indicatorContentDefaultColor?: string
  indicatorContentSuccessColor?: string
  indicatorContentCurrentColor?: string
  titleErrorColor?: string
  titleDefaultColor?: string
  titleSuccessColor?: string
  titleCurrentColor?: string
  captionErrorColor?: string
  captionDefaultColor?: string
  captionSuccessColor?: string
  captionCurrentColor?: string
  width?: string
}

const StepsComponent = styled.div<{ width: string; size: string }>`
  width: ${(props) => props.width};

  & .xela-step-indicator {
    min-width: ${(props) => (props.size === 'small' ? '32px' : props.size === 'medium' ? '48px' : '56px')};
  }
`

const IndicatorVBorder = styled.div<{ color: string }>`
  display: block;
  border-left: 2px dashed ${(props) => props.color};
  width: 2px;
  height: 8px;
`

const IndicatorHBorder = styled.div<{ color: string }>`
  display: block;
  border-top: 2px dashed ${(props) => props.color};
  width: 100%;
  height: 2px;
`

export const Steps: FC<StepsProps> = ({
  steps,
  size = 'medium',
  variant = 'vertical',
  indicatorBgErrorColor = XelaColor.Red3,
  indicatorBgDefaultColor = XelaColor.Gray11,
  indicatorBgSuccessColor = XelaColor.Blue3,
  indicatorBgPendingColor = XelaColor.Yellow3,
  indicatorBgCurrentColor = XelaColor.Blue11,
  indicatorContentErrorColor = XelaColor.White,
  indicatorContentDefaultColor = XelaColor.Gray3,
  indicatorContentSuccessColor = XelaColor.White,
  indicatorContentCurrentColor = XelaColor.Blue3,
  titleErrorColor = XelaColor.Gray3,
  titleDefaultColor = XelaColor.Gray3,
  titleSuccessColor = XelaColor.Gray3,
  titleCurrentColor = XelaColor.Blue3,
  captionErrorColor = XelaColor.Gray7,
  captionDefaultColor = XelaColor.Gray7,
  captionSuccessColor = XelaColor.Gray7,
  captionCurrentColor = XelaColor.Blue7,
  width = '100%',
  ...props
}) => {
  if (variant == 'horizontal') {
    return (
      <StepsComponent width={width} size={size} {...props}>
        <HStack alignItems="flex-start">
          {steps.map((step, index) => {
            const progressIndicator = step.progressIndicator ? (
              step.progressIndicator
            ) : (
              <SvgIcon strokeColor={indicatorContentSuccessColor}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.06641 7.46662L5.86641 12.2666L14.9331 3.19995" stroke="white" strokeLinecap="square" />
                </svg>
              </SvgIcon>
            )
            return (
              <VStack spacing="12px" key={index} alignItems="center">
                <HStack spacing="4px">
                  <IndicatorHBorder
                    style={{
                      opacity: index == 0 ? '0' : '1',
                      marginLeft: '2px',
                    }}
                    color={
                      step.status == 'current'
                        ? indicatorContentCurrentColor
                        : step.status == 'error'
                        ? indicatorBgErrorColor
                        : step.status == 'pending'
                        ? indicatorBgPendingColor
                        : step.status == 'success'
                        ? indicatorBgSuccessColor
                        : indicatorBgDefaultColor
                    }
                  />
                  <Center
                    className="xela-step-indicator"
                    bg={
                      step.status == 'current'
                        ? indicatorBgCurrentColor
                        : step.status == 'error'
                        ? indicatorBgErrorColor
                        : step.status == 'pending'
                        ? indicatorBgPendingColor
                        : step.status == 'success'
                        ? indicatorBgSuccessColor
                        : indicatorBgDefaultColor
                    }
                    width={size == 'medium' ? '48px' : '32px'}
                    height={size == 'medium' ? '48px' : '32px'}
                    borderRadius="12px"
                  >
                    {step.status == 'error' ? (
                      <SvgIcon strokeColor={indicatorContentErrorColor}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.59961 1.6001L14.3996 14.4001M1.59961 14.4001L14.3996 1.6001" stroke="white" />
                        </svg>
                      </SvgIcon>
                    ) : step.status == 'success' ? (
                      progressIndicator
                    ) : step.status == 'pending' ? (
                      progressIndicator
                    ) : (
                      <Typography
                        color={step.status == 'current' ? indicatorContentCurrentColor : indicatorContentDefaultColor}
                        variant="button-medium"
                      >
                        {step.num}
                      </Typography>
                    )}
                  </Center>
                  <IndicatorHBorder
                    style={{
                      opacity: index == steps.length - 1 ? '0' : '1',
                      marginRight: '2px',
                    }}
                    color={
                      step.status == 'current'
                        ? indicatorContentCurrentColor
                        : step.status == 'error'
                        ? indicatorBgErrorColor
                        : step.status == 'pending'
                        ? indicatorBgPendingColor
                        : step.status == 'success'
                        ? indicatorBgSuccessColor
                        : indicatorBgDefaultColor
                    }
                  />
                </HStack>
                <VStack
                  spacing="12px"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    minHeight: step.caption && typeof step.caption !== 'string' ? '80px' : 'auto',
                  }}
                >
                  {step.title && (
                    <Typography
                      color={
                        step.status == 'current'
                          ? titleCurrentColor
                          : step.status == 'error'
                          ? titleErrorColor
                          : step.status == 'success'
                          ? titleSuccessColor
                          : titleDefaultColor
                      }
                      variant="body-small-bold"
                      style={{
                        width: '100px',
                        textAlign: 'center',
                      }}
                    >
                      {step.title}
                    </Typography>
                  )}
                  {step.caption && typeof step.caption === 'string' && (
                    <Typography
                      color={
                        step.status == 'current'
                          ? captionCurrentColor
                          : step.status == 'error'
                          ? captionErrorColor
                          : step.status == 'success'
                          ? captionSuccessColor
                          : captionDefaultColor
                      }
                      variant="caption"
                    >
                      {step.caption}
                    </Typography>
                  )}
                  {step.caption && typeof step.caption !== 'string' && step.caption}
                </VStack>
              </VStack>
            )
          })}
        </HStack>
      </StepsComponent>
    )
  }

  return (
    <StepsComponent width={width} size={size} {...props}>
      <VStack>
        {steps.map((step, index) => (
          <HStack spacing="12px" key={index}>
            <VStack width="auto" spacing="4px" alignItems="center">
              {index != 0 && (
                <IndicatorVBorder
                  style={{ marginTop: '2px' }}
                  color={
                    step.status == 'current'
                      ? indicatorContentCurrentColor
                      : step.status == 'error'
                      ? indicatorBgErrorColor
                      : step.status == 'pending'
                      ? indicatorBgPendingColor
                      : step.status == 'success'
                      ? indicatorBgSuccessColor
                      : indicatorBgDefaultColor
                  }
                />
              )}
              <Center
                bg={
                  step.status == 'current'
                    ? indicatorBgCurrentColor
                    : step.status == 'error'
                    ? indicatorBgErrorColor
                    : step.status == 'pending'
                    ? indicatorBgPendingColor
                    : step.status == 'success'
                    ? indicatorBgSuccessColor
                    : indicatorBgDefaultColor
                }
                width={size == 'medium' ? '48px' : '32px'}
                height={size == 'medium' ? '48px' : '32px'}
                borderRadius="12px"
              >
                {step.status == 'error' ? (
                  <SvgIcon strokeColor={indicatorContentErrorColor}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.59961 1.6001L14.3996 14.4001M1.59961 14.4001L14.3996 1.6001" stroke="white" />
                    </svg>
                  </SvgIcon>
                ) : step.status == 'success' ? (
                  <SvgIcon strokeColor={indicatorContentSuccessColor}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.06641 7.46662L5.86641 12.2666L14.9331 3.19995"
                        stroke="white"
                        strokeLinecap="square"
                      />
                    </svg>
                  </SvgIcon>
                ) : (
                  <Typography
                    color={step.status == 'current' ? indicatorContentCurrentColor : indicatorContentDefaultColor}
                    variant="button-medium"
                  >
                    {step.num}
                  </Typography>
                )}
              </Center>
              {index !== steps.length - 1 && (
                <IndicatorVBorder
                  style={{ marginBottom: '2px' }}
                  color={
                    step.status == 'current'
                      ? indicatorContentCurrentColor
                      : step.status == 'error'
                      ? indicatorBgErrorColor
                      : step.status == 'pending'
                      ? indicatorBgPendingColor
                      : step.status == 'success'
                      ? indicatorBgSuccessColor
                      : indicatorBgDefaultColor
                  }
                />
              )}
            </VStack>
            <VStack
              style={{
                marginTop: index == 0 ? '-12px' : index == steps.length - 1 ? '12px' : '0',
              }}
            >
              {step.title && (
                <Typography
                  color={
                    step.status == 'current'
                      ? titleCurrentColor
                      : step.status == 'error'
                      ? titleErrorColor
                      : step.status == 'success'
                      ? titleSuccessColor
                      : titleDefaultColor
                  }
                  variant="button-medium"
                >
                  {step.title}
                </Typography>
              )}
              {step.caption && (
                <Typography
                  color={
                    step.status == 'current'
                      ? captionCurrentColor
                      : step.status == 'error'
                      ? captionErrorColor
                      : step.status == 'success'
                      ? captionSuccessColor
                      : captionDefaultColor
                  }
                  variant="caption"
                >
                  {step.caption}
                </Typography>
              )}
            </VStack>
          </HStack>
        ))}
      </VStack>
    </StepsComponent>
  )
}

export default Steps
