import _ from 'lodash'
import axios from 'axios'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { initializeApp } from 'firebase/app'
import { showNotification } from '@mantine/notifications'
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react'

window._ = _

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// Need to write interceptor for axios
window.axios.interceptors.response.use(
  (response) => {
    if (response.status === 203 && response.data?.message) {
      showNotification({
        autoClose: 5000,
        title: 'Success',
        message: response.data.message,
        color: 'green',
        icon: <IconCheck />,
      })
    }

    return response
  },
  (error) => {
    if (error.response) {
      if (error.response?.status === 423 && error.response?.data?.error) {
        showNotification({
          autoClose: 5000,
          title: 'Error',
          message: error.response.data.error,
          color: 'red',
          icon: <IconAlertTriangle />,
        })
      }
    }

    return Promise.reject(error)
  }
)

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web admissions.
 */
window.Pusher = Pusher

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_ABLY_PUBLIC_KEY,
  wsHost: 'realtime-pusher.ably.io',
  wsPort: 443,
  disableStats: true,
  encrypted: true,
  cluster: 'eu',
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post('/broadcasting/auth', {
            socket_id: socketId,
            channel_name:
              window.location.hostname === 'hub.swiftams.com' ||
              window.location.hostname === 'hub.staging.swiftams.com' ||
              window.location.hostname === 'hub.demo.swiftams.com' ||
              window.location.hostname === 'b2b.swiftams.com' ||
              window.location.hostname === 'b2b.crosslands.in' ||
              window.location.hostname === 'b2b.zealandimmigration.net' ||
              window.location.hostname === 'hub.edufinn.org' ||
              window.location.hostname === 'b2b.bicm.edu.np' ||
              window.location.hostname === 'b2bvunihub.valmikigroup.com'
                ? channel.name.replace('User', 'Agent')
                : channel.name,
          })
          .then((response) => {
            callback(null, response.data)
          })
          .catch((error) => {
            callback(error)
          })
      },
    }
  },
})

const firebaseConfig = {
  apiKey: 'AIzaSyCvjNekC3OhiZz-JLMGohcjqI6dhnIUQy4',
  authDomain: 'swiftcounsellorapp.firebaseapp.com',
  projectId: 'swiftcounsellorapp',
  storageBucket: 'swiftcounsellorapp.appspot.com',
  messagingSenderId: '847414185796',
  appId: '1:847414185796:web:264eace86b697889771111',
  measurementId: 'G-B3BZD2X82Q',
}

// Initialize Firebase
window.FirebaseApp = initializeApp(firebaseConfig)
