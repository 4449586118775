import * as React from 'react'

function IconReport(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M.5 7.5a7 7 0 0011.95 4.95L7.5 7.5v-7a7 7 0 00-7 7z" fill="#E6ECFF" />
      <path
        d="M7.5 7.5H7a.5.5 0 00.146.354L7.5 7.5zm0 6.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zm0-13A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM7 0v7.5h1V0H7zm.724 7.947l6-3-.448-.894-6 3 .448.894zM7.5 15c2.07 0 3.947-.84 5.303-2.197l-.707-.707A6.478 6.478 0 017.5 14v1zm5.303-2.904l-4.95-4.95-.707.708 4.95 4.95.707-.708zM8 7.5v-7H7v7h1zm4.096 5.303l.05.05.708-.707-.05-.05-.708.707zM14 7.5c0 1.795-.727 3.42-1.904 4.596l.707.707A7.478 7.478 0 0015 7.5h-1z"
        fill="#003CFF"
      />
    </svg>
  )
}

export default IconReport
