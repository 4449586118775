import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Lead = App.Models.Lead
import AdmissionApplication = App.Models.AdmissionApplication
import Agent = App.Models.Agent
import Visa = App.Models.Visa

export interface B2BLeadState {
  b2b_lead: Lead | null
  b2b_application: AdmissionApplication | null
  show_b2b_accept_lead_modal: boolean
  show_b2b_fee_paid_modal: boolean
  show_b2b_ca_fee_paid_modal: boolean
  show_b2b_visa_applied_again_modal: boolean
  b2b_branch_id: string | null
  b2b_select_agent: Agent | null
  b2b_lead_id?: number
  lead_form_submitted: boolean
  lead_documents_uploaded: boolean
  show_b2b_add_lead_modal: boolean
  show_b2b_uk_fee_paid_modal: boolean
  show_b2b_usa_fee_paid_modal: boolean
  show_b2b_transfer_to_visa_modal: boolean
  selected_lead_id: number | null
  is_leads_notes_opened: boolean
  selected_lead_ids: string[]
  show_all_leads_filter_modal: boolean
  show_create_new_invoice_drawer: boolean
  selected_leads: Visa[]
  reload_all_leads: boolean
  current_b2b_lead_filters: {
    agent_id?: string
    created_at?: string
    city?: string
    state?: string
    from_date?: string
    to_date?: string
    preferred_countries?: string[]
  }
  previous_b2b_lead_filters: {
    agent_id?: string
    created_at?: string
    city?: string
    state?: string
    from_date?: string
    to_date?: string
    preferred_countries?: string[]
  }
}

const initialState: B2BLeadState = {
  b2b_lead: null,
  b2b_application: null,
  show_b2b_accept_lead_modal: false,
  show_b2b_fee_paid_modal: false,
  show_b2b_ca_fee_paid_modal: false,
  show_b2b_visa_applied_again_modal: false,
  b2b_select_agent: null,
  b2b_branch_id: null,
  b2b_lead_id: undefined,
  lead_form_submitted: false,
  lead_documents_uploaded: false,
  show_b2b_add_lead_modal: false,
  show_b2b_uk_fee_paid_modal: false,
  show_b2b_usa_fee_paid_modal: false,
  show_b2b_transfer_to_visa_modal: false,
  selected_lead_id: null,
  is_leads_notes_opened: false,
  selected_lead_ids: [],
  reload_all_leads: false,
  show_all_leads_filter_modal: false,
  show_create_new_invoice_drawer: false,
  selected_leads: [],
  current_b2b_lead_filters: {
    agent_id: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
    preferred_countries: [],
  },
  previous_b2b_lead_filters: {
    agent_id: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
    preferred_countries: [],
  },
}

export const b2bLeadSlice = createSlice({
  name: 'b2bLeadSlice',
  initialState,
  reducers: {
    updateB2BLead(state, action: PayloadAction<Lead | null>) {
      state.b2b_lead = action.payload
    },
    updateB2BApplication(state, action: PayloadAction<AdmissionApplication | null>) {
      state.b2b_application = action.payload
    },
    updateShowB2BAcceptLeadModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_accept_lead_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_accept_lead_modal = false
      }
    },
    updateShowB2BFeePaidModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_fee_paid_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_fee_paid_modal = false
      }
    },
    updateShowB2BCaFeePaidModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_ca_fee_paid_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_ca_fee_paid_modal = false
      }
    },
    updateShowB2BVisaAppliedAgainModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_visa_applied_again_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_visa_applied_again_modal = false
      }
    },
    updateB2BSelectAgent(state, action: PayloadAction<Agent | null>) {
      state.b2b_select_agent = action.payload
    },
    updateB2BBranchId(state, action: PayloadAction<string | null>) {
      state.b2b_branch_id = action.payload
    },
    updateB2BLeadId(state, action: PayloadAction<number | undefined>) {
      state.b2b_lead_id = action.payload
    },
    updateLeadFormSubmitted(state, action: PayloadAction<boolean>) {
      state.lead_form_submitted = action.payload
    },
    updateLeadDocumentsUploaded(state, action: PayloadAction<boolean>) {
      state.lead_documents_uploaded = action.payload
    },
    updateShowB2BAddLeadModal(state, action: PayloadAction<boolean>) {
      state.show_b2b_add_lead_modal = action.payload
      if (action.payload) {
        state.show_b2b_add_lead_modal = true
      } else {
        state.b2b_lead_id = undefined
        state.show_b2b_add_lead_modal = false
        state.lead_form_submitted = false
        state.lead_documents_uploaded = false
      }
    },
    updateShowB2BUkFeePaidModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_uk_fee_paid_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_uk_fee_paid_modal = false
      }
    },
    updateShowB2BUsaFeePaidModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_usa_fee_paid_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_usa_fee_paid_modal = false
      }
    },
    updateShowB2BTransferToVisaModal(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        state.show_b2b_transfer_to_visa_modal = true
      } else {
        state.b2b_lead = null
        state.show_b2b_transfer_to_visa_modal = false
      }
    },
    updateIsLeadsNotesOpened(
      state,
      action: PayloadAction<{ selected_lead_id: number | null; is_leads_notes_opened: boolean }>
    ) {
      state.selected_lead_id = action.payload.selected_lead_id
      state.is_leads_notes_opened = action.payload.is_leads_notes_opened
    },
    updateSelectedLeadIds(state, action: PayloadAction<string[]>) {
      state.selected_lead_ids = action.payload
    },
    updateSelectedLeads(state, action: PayloadAction<Visa[]>) {
      state.selected_leads = action.payload
    },
    showAllLeadFilterModal(state) {
      state.show_all_leads_filter_modal = true
    },
    hideAllLeadFilterModal(state) {
      state.show_all_leads_filter_modal = false
    },

    showCreateNewInvoiceDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        // branch_id: string | null
      }>
    ) {
      state.show_create_new_invoice_drawer = action.payload.show
      // state.branch_id = action.payload.branch_id
    },
    updateCurrentB2BLeadFilters(
      state,
      action: PayloadAction<{
        agent_id?: string
        city?: string
        state?: string
        from_date?: string
        to_date?: string
        preferred_countries?: string[]
      }>
    ) {
      state.current_b2b_lead_filters = action.payload
    },
    updatePreviousB2BLeadFilters(
      state,
      action: PayloadAction<{
        agent_id?: string
        city?: string
        state?: string
        from_date?: string
        to_date?: string
        preferred_countries?: string[]
      }>
    ) {
      state.previous_b2b_lead_filters = action.payload
    },
    reloadAllLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_leads = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateB2BLead,
  updateB2BApplication,
  updateShowB2BAcceptLeadModal,
  updateShowB2BFeePaidModal,
  updateShowB2BCaFeePaidModal,
  updateShowB2BVisaAppliedAgainModal,
  updateB2BSelectAgent,
  updateB2BBranchId,
  updateB2BLeadId,
  updateLeadFormSubmitted,
  updateLeadDocumentsUploaded,
  updateShowB2BAddLeadModal,
  updateShowB2BUkFeePaidModal,
  updateShowB2BUsaFeePaidModal,
  updateShowB2BTransferToVisaModal,
  updateIsLeadsNotesOpened,
  updateSelectedLeadIds,
  showAllLeadFilterModal,
  hideAllLeadFilterModal,
  showCreateNewInvoiceDrawer,
  updateSelectedLeads,
  updatePreviousB2BLeadFilters,
  updateCurrentB2BLeadFilters,
  reloadAllLeads,
} = b2bLeadSlice.actions

export default b2bLeadSlice.reducer
