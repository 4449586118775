import React, { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  textColor?: string
  bg?: string
}

const BadgeComponent = styled.div<{ textColor: string; bg: string }>`
  background: ${(props) => props.bg};
  color: ${(props) => props.textColor};
  border-radius: 10px;
  padding: 4px 8px;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
`

export const Badge: FC<BadgeProps> = ({
  label,
  textColor = XelaColor.White,
  bg = XelaColor.Blue3,
  ...props
}) => {
  return (
    <BadgeComponent bg={bg} textColor={textColor} {...props}>
      <Typography variant="caption" as="span">
        {label}
      </Typography>
    </BadgeComponent>
  )
}

export default Badge
