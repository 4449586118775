import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Divider,
  HStack,
  TabItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  VStack,
  XelaColor,
} from '@/XelaReact'
import { Drawer, SimpleGrid } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateB2BSelectedAgent, updateShowB2BAgentDetailsDrawer } from '@/Store/B2B/b2bAgentSlice'
import useAuth from '@/Hooks/useAuth'
import moment from 'moment/moment'
import axios from 'axios'
import PaginationData from '@/Types/Pagination'
import Agent = App.Models.Agent

let scrollWeight = false

interface AgentProps extends PaginationData {
  data: Agent[]
}

const B2BAgentDetailsDrawer = () => {
  const auth = useAuth()
  const [tab, setTab] = useState('details')
  const dispatch = useDispatch()

  const b2bAgentStore = useSelector((state: RootState) => state.b2bAgent)
  const [teams, setTeams] = useState<AgentProps>({} as AgentProps)

  const tabs = [
    { name: 'details', label: 'Details' },
    { name: 'teams', label: 'Team' },
  ]

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const scrollListener = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current
      if (scrollTop + clientHeight >= scrollHeight - 200) {
        if (!scrollWeight) {
          scrollWeight = true

          if (b2bAgentStore.b2b_selected_agent !== null) {
            axios
              .post<AgentProps>(route('b2b.agents.teams', { id: b2bAgentStore.b2b_selected_agent.id }), {
                page: teams ? teams.current_page + 1 : 1,
              })
              .then((response) => {
                setTeams((prevState) => ({
                  ...prevState,
                  data: [...prevState.data, ...response.data.data],
                  current_page: response.data.current_page,
                }))
              })
              .finally(() => {
                setTimeout(() => {
                  scrollWeight = false
                }, 250)
              })
          }
        }
      }
    }
  }, [teams])

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', scrollListener)
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', scrollListener)
      }
    }
  }, [teams])

  const fetchTeams = () => {
    if (b2bAgentStore.b2b_selected_agent !== null) {
      axios
        .post<AgentProps>(route('b2b.agents.teams', { id: b2bAgentStore.b2b_selected_agent.id }))
        .then((response) => {
          setTeams(response.data)
        })
        .catch((error) => {
          console.error('Error fetching B2B Agent Teams:', error)
        })
        .finally(() => {})
      if (scrollContainerRef) {
        scrollContainerRef.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  useEffect(() => {
    fetchTeams()
  }, [b2bAgentStore.b2b_selected_agent !== null && b2bAgentStore.show_b2b_agent_details_drawer])

  const closeHandler = () => {
    dispatch(updateShowB2BAgentDetailsDrawer(false))
    dispatch(dispatch(updateB2BSelectedAgent(null)))
    setTab('details')
  }

  return (
    <Drawer
      size={900}
      position={'right'}
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      opened={b2bAgentStore.show_b2b_agent_details_drawer}
      onClose={() => {
        closeHandler()
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" style={{ height: '100%' }}>
        <HStack>
          <HStack spacing="12px">
            <Typography variant="headline" color={XelaColor.Gray3}>
              {auth.agency.agency_name}
            </Typography>
          </HStack>
          <HStack spacing="12px" justifyContent={'flex-end'}>
            <Button
              size={'small'}
              label="Close"
              variant="secondary"
              onClick={() => {
                closeHandler()
              }}
            />
          </HStack>
        </HStack>
        <HStack spacing="12px">
          <Divider variant="dotted"></Divider>
        </HStack>
        <HStack>
          <Tabs
            labelActiveColor={XelaColor.Pink3}
            iconActiveColor={XelaColor.Pink3}
            activeItemLineColor={XelaColor.Pink3}
            style={{
              width: '100%',
              borderBottomWidth: '2px',
              borderBottomStyle: 'solid',
              borderBottomColor: XelaColor.Gray12,
            }}
            items={tabs.map((tab) => {
              return new TabItem(tab.name, tab.label)
            })}
            name="b2b_detail_tabs"
            defaultValue={'details'}
            onChangeHandle={(value) => setTab(value)}
          ></Tabs>
        </HStack>
        {tab === 'details' && (
          <>
            <HStack spacing="12px">
              <HStack bg={XelaColor.Blue12} borderRadius="16px" style={{ padding: '16px' }}>
                <VStack>
                  <Typography variant="caption" color={XelaColor.Gray3}>
                    Total Leads
                  </Typography>
                  <Typography variant="headline" color={XelaColor.Blue3}>
                    {b2bAgentStore.b2b_selected_agent?.leads_count ?? 0}
                  </Typography>
                </VStack>
              </HStack>
              <HStack bg={XelaColor.Green12} borderRadius="16px" style={{ padding: '16px' }}>
                <VStack width="auto">
                  <Typography variant="caption" color={XelaColor.Gray3}>
                    Login Status
                  </Typography>
                  <Typography variant="headline" color={XelaColor.Green1}>
                    {b2bAgentStore.b2b_selected_agent?.pivot.has_login_access ? 'Enabled' : 'Disabled'}
                  </Typography>
                </VStack>
              </HStack>
              <HStack bg={XelaColor.Yellow12} borderRadius="16px" style={{ padding: '16px' }}>
                <VStack width="auto">
                  <Typography variant="caption" color={XelaColor.Gray3}>
                    Status
                  </Typography>
                  <Typography variant="headline" color={XelaColor.Yellow3}>
                    {b2bAgentStore.b2b_selected_agent?.pivot.status}
                  </Typography>
                </VStack>
              </HStack>
            </HStack>
            <VStack spacing="12px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto', paddingRight: '10px' }}>
              <HStack>
                <Typography variant="body-bold" color={XelaColor.Gray4}>
                  Agent Details
                </Typography>
              </HStack>
              <VStack spacing="16px">
                <SimpleGrid
                  style={{ width: '100%' }}
                  breakpoints={[
                    { maxWidth: 4000, cols: 4 },
                    { maxWidth: 1900, cols: 4 },
                    { maxWidth: 1500, cols: 3 },
                    { maxWidth: 1200, cols: 3 },
                    { maxWidth: 768, cols: 2 },
                  ]}
                >
                  <VStack>
                    <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                      First Name
                    </Typography>
                    <Typography variant="caption-bold" color={XelaColor.Gray5}>
                      {b2bAgentStore.b2b_selected_agent?.first_name ?? ''}
                    </Typography>
                  </VStack>
                  <VStack>
                    <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                      Last Name
                    </Typography>
                    <Typography variant="caption-bold" color={XelaColor.Gray5}>
                      {b2bAgentStore.b2b_selected_agent?.last_name ?? ''}
                    </Typography>
                  </VStack>
                </SimpleGrid>
                <HStack>
                  <Divider variant="dotted"></Divider>
                </HStack>
                <VStack spacing="16px">
                  <HStack>
                    <Typography variant="body-bold" color={XelaColor.Gray4}>
                      Agency Details
                    </Typography>
                  </HStack>
                  <SimpleGrid
                    style={{ width: '100%' }}
                    breakpoints={[
                      { maxWidth: 2500, cols: 3 },
                      { maxWidth: 1900, cols: 3 },
                      { maxWidth: 1500, cols: 2 },
                      { maxWidth: 1200, cols: 2 },
                      { maxWidth: 768, cols: 2 },
                      { maxWidth: 576, cols: 1 },
                    ]}
                  >
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Agency Name
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.agency_name ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        State
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.state ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        City
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.city ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Address
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.address ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Area
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.area ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Zone
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.zone ?? ''}
                      </Typography>
                    </VStack>
                  </SimpleGrid>
                </VStack>
                <HStack>
                  <Divider variant="dotted"></Divider>
                </HStack>
                <VStack spacing="16px">
                  <HStack>
                    <Typography variant="body-bold" color={XelaColor.Gray4}>
                      Relationship Manager Details
                    </Typography>
                  </HStack>
                  <SimpleGrid
                    style={{ width: '100%' }}
                    breakpoints={[
                      { maxWidth: 2500, cols: 3 },
                      { maxWidth: 1900, cols: 3 },
                      { maxWidth: 1500, cols: 2 },
                      { maxWidth: 1200, cols: 2 },
                      { maxWidth: 768, cols: 2 },
                      { maxWidth: 576, cols: 1 },
                    ]}
                  >
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Relationship Manager
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray3}>
                        {b2bAgentStore.b2b_selected_agent?.relationship_manager?.name ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Relationship Manager One
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.relationship_manager_one?.name ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Relationship Manager Two
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.relationship_manager_two?.name ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Relationship Manager Three
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.relationship_manager_three?.name ?? ''}
                      </Typography>
                    </VStack>
                    {auth.agency.id === 1111178 && (
                      <>
                        <VStack>
                          <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                            Relationship Manager Four
                          </Typography>
                          <Typography variant="caption-bold" color={XelaColor.Gray5}>
                            {b2bAgentStore.b2b_selected_agent?.relationship_manager_four?.name ?? ''}
                          </Typography>
                        </VStack>
                        <VStack>
                          <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                            Relationship Manager Five
                          </Typography>
                          <Typography variant="caption-bold" color={XelaColor.Gray5}>
                            {b2bAgentStore.b2b_selected_agent?.relationship_manager_five?.name ?? ''}
                          </Typography>
                        </VStack>
                        <VStack>
                          <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                            Relationship Manager Six
                          </Typography>
                          <Typography variant="caption-bold" color={XelaColor.Gray5}>
                            {b2bAgentStore.b2b_selected_agent?.relationship_manager_six?.name ?? ''}
                          </Typography>
                        </VStack>
                        <VStack>
                          <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                            Relationship Manager Seven
                          </Typography>
                          <Typography variant="caption-bold" color={XelaColor.Gray5}>
                            {b2bAgentStore.b2b_selected_agent?.relationship_manager_seven?.name ?? ''}
                          </Typography>
                        </VStack>
                      </>
                    )}
                  </SimpleGrid>
                </VStack>
                <HStack spacing={'16px'}>
                  <Divider variant="dotted"></Divider>
                </HStack>
                <VStack spacing="16px" className={'pb-2'}>
                  <HStack>
                    <Typography variant="body-bold" color={XelaColor.Gray4}>
                      Association/Agreement Details
                    </Typography>
                  </HStack>
                  <SimpleGrid
                    style={{ width: '100%' }}
                    breakpoints={[
                      { maxWidth: 2500, cols: 3 },
                      { maxWidth: 1900, cols: 3 },
                      { maxWidth: 1500, cols: 2 },
                      { maxWidth: 1200, cols: 2 },
                      { maxWidth: 768, cols: 2 },
                      { maxWidth: 576, cols: 1 },
                    ]}
                  >
                    <VStack>
                      <Typography variant="caption" color={XelaColor.Gray8}>
                        Associate Type
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray3}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.associate_type ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Association Date
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent &&
                        b2bAgentStore.b2b_selected_agent.pivot &&
                        b2bAgentStore.b2b_selected_agent.pivot.association_date
                          ? moment(b2bAgentStore.b2b_selected_agent.pivot.association_date).format('DD/MM/YYYY')
                          : ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Agreement Start Date
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent &&
                        b2bAgentStore.b2b_selected_agent.pivot &&
                        b2bAgentStore.b2b_selected_agent.pivot.agreement_start_date
                          ? moment(b2bAgentStore.b2b_selected_agent.pivot.agreement_start_date).format('DD/MM/YYYY')
                          : ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Agreement End Date
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent &&
                        b2bAgentStore.b2b_selected_agent.pivot &&
                        b2bAgentStore.b2b_selected_agent.pivot.agreement_end_date
                          ? moment(b2bAgentStore.b2b_selected_agent.pivot.agreement_end_date).format('DD/MM/YYYY')
                          : ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        No of Renewals
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.no_of_renewals ?? ''}
                      </Typography>
                    </VStack>
                    <VStack>
                      <Typography variant="sub-caption-bold" color={XelaColor.Gray8}>
                        Remarks
                      </Typography>
                      <Typography variant="caption-bold" color={XelaColor.Gray5}>
                        {b2bAgentStore.b2b_selected_agent?.pivot.remarks ?? ''}
                      </Typography>
                    </VStack>
                  </SimpleGrid>
                </VStack>
              </VStack>
            </VStack>
            <HStack spacing={'16px'}>
              <Divider variant="dotted"></Divider>
            </HStack>
            <HStack justifyContent="flex-end" spacing="12px">
              <Button
                label="Close"
                variant="secondary"
                onClick={() => {
                  closeHandler()
                }}
              />
            </HStack>
          </>
        )}
        {tab === 'teams' && (
          <VStack spacing="12px" style={{ flex: '1 1 auto', height: 0, overflowY: 'auto', paddingRight: '10px' }}>
            <Table>
              <TableHead>
                <TableRow style={{ height: '54px' }}>
                  <TableCell>
                    <Typography variant="body-small-bold">Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body-small-bold">Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body-small-bold">Email</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body-small-bold">Role</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body-small-bold">Status</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body-small-bold">Location</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body-small-bold">Login Status</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams && teams.data.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                      <Typography variant="body-small-bold" color={XelaColor.Gray3}>
                        No Team Members Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {teams &&
                  teams.data &&
                  teams.data.length > 0 &&
                  teams.data.map((agent, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body-small-bold">
                            {moment(agent.created_at).format('DD MMM YYYY')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <VStack spacing="4px">
                            <Typography variant="body-small-bold">{agent.name}</Typography>
                            <Typography variant="caption">
                              {agent.country_calling_code}
                              {agent.phone}
                            </Typography>
                          </VStack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body-small-bold">{agent.email}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body-small-bold">{agent.role?.role_name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body-small-bold">{agent.pivot.status}</Typography>
                        </TableCell>
                        <TableCell>
                          <VStack spacing="2px">
                            <Typography
                              variant={'body-small-bold'}
                              color={XelaColor.Gray3}
                              style={{
                                wordBreak: 'break-all',
                              }}
                            >
                              {agent.pivot.state}
                            </Typography>
                            <Typography
                              color={XelaColor.Gray6}
                              variant="caption"
                              style={{
                                wordBreak: 'break-all',
                              }}
                            >
                              {agent.pivot.city}
                            </Typography>
                          </VStack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body-small-bold">
                            {agent.pivot.has_login_access ? 'Enabled' : 'Disabled'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </VStack>
        )}
      </VStack>
    </Drawer>
  )
}

export default B2BAgentDetailsDrawer
