import React, { FC, useEffect, useState } from 'react'
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
import { Box, Text } from '@mantine/core'
import { Typography, VStack, XelaColor } from '@/XelaReact'
import axios from 'axios'

interface MobileValidationErrorsProps {
  check: string
  message: string
  lead_id: string
}

const XPhoneInput: FC<{
  tabIndex?: number
  initialValue?: string
  dialCode?: string
  countryCode?: string
  onChange?: (number: string, dialCode: string, countryCode: string, country: string) => void
  error?: string
  label?: string
  disabled?: boolean
  checkMobileRemoteValidation?: boolean
  required?: boolean
  phoneProps?: PhoneInputProps
}> = ({
  initialValue,
  dialCode,
  countryCode,
  onChange,
  error,
  label = 'Phone',
  checkMobileRemoteValidation = false,
  disabled = false,
  required = false,
  tabIndex,
  phoneProps,
}) => {
  const [value, setValue] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [hover, setHover] = useState<boolean>(false)
  const [mobileValidationErrors, setMobileValidationErrors] = useState<MobileValidationErrorsProps[]>([])

  useEffect(() => {
    if (countryCode && countryCode != '') {
      setCountry(countryCode.toLowerCase())
    }
  }, [countryCode])

  useEffect(() => {
    let phone = ''
    if (dialCode && dialCode != '' && initialValue && initialValue != '') {
      dialCode = dialCode.replace('+', '')
      phone = dialCode + initialValue
    }

    if (phone !== '') {
      setTimeout(() => {
        setValue(phone)
      }, 10)
    } else {
      setTimeout(() => {
        if (dialCode && dialCode != '') {
          setValue(dialCode)
        }
      }, 10)
    }
  }, [dialCode, initialValue])

  useEffect(() => {}, [phoneProps, mobileValidationErrors])

  return (
    <VStack
      spacing={'2px'}
      style={{
        background: '#FFFFFF',
      }}
    >
      <Box
        {...(tabIndex && { tabIndex: tabIndex })}
        style={{
          outline: hover ? `1px solid ${XelaColor.Blue3}` : 'none',
          border: hover ? `1px solid ${XelaColor.Blue3}` : '1px solid #E0E0E0',
          borderRadius: '16px',
          padding: '15px 9px 3px 9px',
          width: '100%',
          position: 'relative',
        }}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: '1px',
            left: '23px',
            zIndex: 10,
          }}
        >
          <Typography variant={'sub-caption'}>{label}</Typography>
          {required && (
            <Typography variant={'sub-caption'} color={XelaColor.Red3}>
              {' '}
              *
            </Typography>
          )}
        </Box>
        <PhoneInput
          disabled={disabled}
          value={value}
          enableSearch
          disableCountryGuess={true}
          onFocus={() => {
            setHover(true)
          }}
          onBlur={(event) => {
            setHover(false)

            if (checkMobileRemoteValidation) {
              const number = event.target.value

              const countryCallingCode = number.split(' ')[0]

              const phone = number.replace(countryCallingCode, '')

              if (number && number !== '') {
                axios
                  .post('/validation/mobile-number', { country_calling_code: countryCallingCode, phone: phone })
                  .then((response) => {
                    if (response.data && response.data.status === 'success') {
                      setMobileValidationErrors([])
                    }

                    if (response.data && response.data.status === 'error') {
                      setMobileValidationErrors(response.data.errors)
                    }
                  })
                  .catch((error) => {
                    console.error('Validation error:', error)
                  })
              }
            }
          }}
          dropdownStyle={{
            borderRadius: '8px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            border: 'none',
            padding: '5px 0',
          }}
          inputStyle={{
            border: 'none',
            width: '99%',
            fontSize: '13px',
            fontWeight: 600,
            fontFamily: 'Nunito Sans, sans-serif',
          }}
          buttonStyle={{
            border: 'none',
            marginLeft: '6px',
          }}
          searchStyle={{
            padding: '20px',
            fontSize: '13px',
            fontWeight: 600,
            fontFamily: 'Nunito Sans, sans-serif',
            textTransform: 'capitalize',
          }}
          onChange={(value, data) => {
            if (onChange) {
              if ('dialCode' in data && 'countryCode' in data && 'name' in data) {
                if (value.startsWith(data.dialCode)) {
                  // Ensure the dial code is at the beginning of the string
                  const dialCodeRegex = new RegExp('^' + data.dialCode)
                  const number = value.replace(dialCodeRegex, '')

                  onChange(number, '+' + data.dialCode, data.countryCode.toUpperCase(), data.name)
                }
              }
            }
          }}
          country={country}
          {...phoneProps}
        ></PhoneInput>
        {/*<Box*/}
        {/*  onClick={() => {*/}
        {/*    setValue('')*/}
        {/*    if (onChange) onChange('', dialCode ?? '', countryCode ?? '', country)*/}
        {/*  }}*/}
        {/*  style={{*/}
        {/*    position: 'absolute',*/}
        {/*    right: '12px',*/}
        {/*    top: '18px',*/}
        {/*    cursor: 'pointer',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <IconX size={12} color={XelaColor.Gray6}></IconX>*/}
        {/*</Box>*/}
      </Box>

      {mobileValidationErrors.length > 0 && (
        <VStack spacing={'2px'}>
          {mobileValidationErrors.map((error, index) => {
            let message = ''
            let url = ''
            let linkName = ''

            switch (error.check) {
              case 'lead':
                url = `/leads/${error.lead_id}`
                message = 'Phone number already exists in the '
                linkName = 'Lead'

                break
              case 'lead_alternate':
                url = `/leads/${error.lead_id}`
                message = 'Phone number already exists as alternate number in '
                linkName = 'Lead'

                break
              case 'agent_lead':
                url = `b2b/leads/${error.lead_id}`
                message = 'Phone number already exists in the '
                linkName = 'Agent Lead'

                break
              case 'agent_lead_alternate':
                url = `b2b/leads/${error.lead_id}`
                message = 'Phone number already exists as alternate number in the '
                linkName = 'Agent Lead'

                break
              case 'visitor_lead':
                url = `/visitor-leads/${error.lead_id}`
                message = 'Phone number already exists in the '
                linkName = 'Visitor Lead'
                break
              case 'visitor_lead_alternate':
                url = `/visitor-leads/${error.lead_id}`
                message = 'Phone number already exists as alternate number in the '
                linkName = 'Visitor Lead'
                break
              case 'ielts_lead':
                url = `/ielts-leads/${error.lead_id}`
                message = 'Phone number already exists in the '
                linkName = 'Coaching Lead'
                break
              case 'ielts_lead_alternate':
                url = `/ielts-leads/${error.lead_id}`
                message = 'Phone number already exists as alternate number in the '
                linkName = 'Coaching Lead'

                break
            }

            return (
              <Text size={'xs'} color={XelaColor.Red4} key={index}>
                {'*'} {message}
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: XelaColor.Blue3,
                  }}
                >
                  {linkName}
                </a>
              </Text>
            )
          })}
        </VStack>
      )}

      {error && (
        <Text size={'xs'} color={XelaColor.Red4}>
          {error}
        </Text>
      )}
    </VStack>
  )
}

export default XPhoneInput
