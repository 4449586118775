import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import LoanAccount = App.Models.LoanAccount

export interface LoanState {
  selected_loan_id: string | null
  selected_loan: LoanAccount | null
  show_loan_drawer: boolean
}

const initialState: LoanState = {
  selected_loan_id: null,
  selected_loan: null,
  show_loan_drawer: false,
}

export const loanSlice = createSlice({
  name: 'loanSlice',
  initialState,
  reducers: {
    updateSelectedLoanId(state, action: PayloadAction<string | null>) {
      state.selected_loan_id = action.payload
    },
    updateSelectedLoan(state, action: PayloadAction<LoanAccount | null>) {
      state.selected_loan = action.payload
    },
    updateShowLoanDrawer(state, action: PayloadAction<boolean>) {
      state.show_loan_drawer = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateSelectedLoanId, updateSelectedLoan, updateShowLoanDrawer } = loanSlice.actions

export default loanSlice.reducer
