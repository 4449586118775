import * as React from 'react'

function IconTextDocumentAlt(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2.5A1.5 1.5 0 012.5 1h8.207L14 4.293V14.5a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 011 14.5v-12zm3 2.497h7v1H4v-1zm7 2.998H4v1h7v-1zM11 11H4v1h7v-1z"
        fill="#E6ECFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.121 0L15 3.879V14.5a2.5 2.5 0 01-2.5 2.5h-10A2.5 2.5 0 010 14.5v-12A2.5 2.5 0 012.5 0h8.621zM2.5 1A1.5 1.5 0 001 2.5v12A1.5 1.5 0 002.5 16h10a1.5 1.5 0 001.5-1.5V4.293L10.707 1H2.5zM4 4.997v1h7v-1H4zm7 2.998v1H4v-1h7zM11 11v1H4v-1h7z"
        fill="#003CFF"
      />
    </svg>
  )
}

export default IconTextDocumentAlt
