import React, { FC, HTMLAttributes, ReactNode, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import useOnClickOutside from '@/XelaReact/Hooks/useOnClickOutside'
import HStack from '@/XelaReact/HStack/HStack'
import SvgIcon from '@/XelaReact/SvgIcon/SvgIcon'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import { SelectContext } from './SelectContext'
import './Select.css'
import Box from '@/XelaReact/Box/Box'

export class Option {
  value: string
  title: string
  subtitle?: string
  avatar?: ReactNode
  icon?: ReactNode

  constructor(
    value: string,
    title: string,
    subtitle?: string,
    avatar?: ReactNode,
    icon?: ReactNode
  ) {
    this.value = value
    this.title = title
    this.subtitle = subtitle
    this.avatar = avatar
    this.icon = icon
  }
}

const OptionVStack = styled(VStack)`
  justify-content: center;
`

const OptionContainer = styled.div<{
  selected: boolean
  focusColor: string
  isHover: boolean
  hoverOptionBg: string
  leftPadding: boolean
}>`
  background: ${(props) =>
    props.selected
      ? props.focusColor
      : props.isHover
      ? props.hoverOptionBg
      : 'none'};
  ${(props) => !props.leftPadding && 'padding-left: 16px;'}
`

const OptionHStack = styled(HStack)<{
  selected: boolean
  selectedOptionContentColor: string
  primaryTextColor: string
}>`
  fill: ${(props) =>
    props.selected ? props.selectedOptionContentColor : props.primaryTextColor};
  stroke: ${(props) =>
    props.selected ? props.selectedOptionContentColor : props.primaryTextColor};
`

export const OptionItem: FC<{
  option: Option
  focusColor?: string
  primaryTextColor?: string
  secondaryTextColor?: string
  hoverOptionBg?: string
  selectedOptionContentColor?: string
  selected: true | false
}> = ({
  option,
  selected = false,
  focusColor = XelaColor.Blue3,
  primaryTextColor = XelaColor.Gray2,
  secondaryTextColor = XelaColor.Gray7,
  hoverOptionBg = XelaColor.Gray11,
  selectedOptionContentColor = XelaColor.White,
}) => {
  const [isHover, setHover] = useState(false)

  return (
    <OptionContainer
      selected={selected}
      focusColor={focusColor}
      isHover={isHover}
      hoverOptionBg={hoverOptionBg}
      className="xela-select-container-li-container"
      leftPadding={option.avatar !== undefined}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <OptionHStack
        height="100%"
        selected={selected}
        selectedOptionContentColor={selectedOptionContentColor}
        primaryTextColor={primaryTextColor}
        spacing={option.icon !== undefined ? '16px' : '8px'}
      >
        {option.icon !== undefined && (
          <SelectIconBox width="16px" height="16px" clipped={true}>
            {option.icon}
          </SelectIconBox>
        )}
        {option.avatar !== undefined && option.avatar}
        <OptionVStack>
          <Typography
            as="p"
            variant="button-medium"
            color={selected ? selectedOptionContentColor : primaryTextColor}
          >
            {option.title}
          </Typography>
          {option.subtitle !== undefined && (
            <Typography
              as="p"
              variant="caption"
              color={selected ? selectedOptionContentColor : secondaryTextColor}
            >
              {option.subtitle}
            </Typography>
          )}
        </OptionVStack>
        {selected && (
          <SelectIconBox width="16px" height="16px">
            <SvgIcon isInherit={false}>
              <svg
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7.5L5.5 12L14 3.5"
                  stroke="white"
                  strokeLinecap="square"
                />
              </svg>
            </SvgIcon>
          </SelectIconBox>
        )}
      </OptionHStack>
    </OptionContainer>
  )
}

export interface SelectProps extends HTMLAttributes<HTMLInputElement> {
  options: Option[]
  defaultOption?: Option
  placeholder?: string
  defaultIcon?: ReactNode
  defaultAvatar?: ReactNode
  width?: string
  bg?: string
  defaultBorderColor?: string
  hoverBorderColor?: string
  focusColor?: string
  primaryTextColor?: string
  secondaryTextColor?: string
  hoverOptionBg?: string
  selectedOptionContentColor?: string
  disabled?: true | false
  name?: string
  onChangeHandle?: (option?: Option) => void
  shadow?: boolean
  bgDropDown?: string
  isChanging?: boolean
}

const SelectDiv = styled.div<{
  shadow: boolean
  width: string
  bg: string
  bgDropDown: string
  showDropdown: boolean
  focusColor: string
  isHover: boolean
  hoverBorderColor: string
  defaultBorderColor: string
  disabled: boolean
}>`
  width: ${(props) => props.width};
  background: ${(props) => props.bg};
  outline-color: ${(props) =>
    props.showDropdown
      ? props.focusColor
      : props.isHover
      ? props.hoverBorderColor
      : props.defaultBorderColor};
  outline-width: ${(props) => (props.showDropdown ? '2px' : '1px')};

  ${(props) =>
    props.disabled !== undefined &&
    'cursor: ' + (props.disabled ? 'initial' : 'pointer') + ';'}
  ${(props) => (props.disabled ? 'opacity: 0.4;' : 'opacity: 1;')}
  & ul {
    background: ${(props) => props.bgDropDown};
    ${(props) =>
      props.shadow &&
      css`
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08),
          0 4px 48px rgba(0, 0, 0, 0.08);
      `}
    z-index: 100;
  }
`

const SelectSubDivHStack = styled(HStack)<{
  showDropdown: boolean
  focusColor: string
  primaryTextColor: string
}>`
  fill: ${(props) =>
    props.showDropdown ? props.focusColor : props.primaryTextColor};
  stroke: ${(props) =>
    props.showDropdown ? props.focusColor : props.primaryTextColor};
`

const SelectSubDiv = styled.div<{
  selectedOption?: Option
  defaultAvatar?: ReactNode
  defaultIcon?: ReactNode
}>`
  padding-left: ${(props) =>
    props.selectedOption?.avatar !== undefined ||
    props.defaultAvatar !== undefined
      ? '12px'
      : props.selectedOption?.icon !== undefined ||
        props.defaultIcon !== undefined
      ? '16px'
      : '24px'};
`

const SelectIconBox = styled(Box)`
  flex-basis: 16px;
  flex-grow: 0;
  flex-shrink: 0;
`

export const Select: FC<SelectProps> = ({
  options,
  defaultOption,
  placeholder,
  defaultIcon,
  defaultAvatar,
  width = '344px',
  bg = XelaColor.White,
  defaultBorderColor = XelaColor.Gray11,
  hoverBorderColor = XelaColor.Blue5,
  focusColor = XelaColor.Blue3,
  primaryTextColor = XelaColor.Gray2,
  secondaryTextColor = XelaColor.Gray7,
  hoverOptionBg = XelaColor.Gray11,
  selectedOptionContentColor = XelaColor.White,
  disabled = false,
  name,
  onChangeHandle,
  shadow = true,
  bgDropDown = XelaColor.White,
  isChanging = true,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [query, setQuery] = useState(defaultOption?.title || '')
  const [inputSelected, setInputSelected] = useState(defaultOption?.title || '')
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [showDropdown, setShowDropdown] = useState(false)
  const selectPlaceholder = placeholder || 'Choose an option'
  const selectContainerRef = useRef(null)

  const showDropdownHandler = () => {
    if (inputRef) {
      inputRef.current?.focus()
    }
    setQuery('')
    setShowDropdown(!showDropdown)
  }

  const clickOutsideHandler = () => {
    setQuery(inputSelected)
    setShowDropdown(false)
  }

  useOnClickOutside(selectContainerRef, clickOutsideHandler)

  const updateSelectedOption = (option: Option) => {
    if (isChanging) setSelectedOption(option)
    onChangeHandle && onChangeHandle(option)
    setShowDropdown(false)
    setQuery(option.title)
    setInputSelected(option.title)
  }

  const [isHover, setHover] = useState(false)

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <SelectContext.Provider
      value={{ selectedOption, changeSelectedOption: updateSelectedOption }}
    >
      <SelectDiv
        width={width}
        bgDropDown={bgDropDown}
        shadow={shadow}
        bg={bg}
        disabled={disabled}
        showDropdown={showDropdown}
        focusColor={focusColor}
        isHover={isHover}
        hoverBorderColor={hoverBorderColor}
        defaultBorderColor={defaultBorderColor}
        className="xela-select-container"
        ref={selectContainerRef}
      >
        <SelectSubDiv
          selectedOption={selectedOption}
          defaultAvatar={defaultAvatar}
          defaultIcon={defaultIcon}
          className={
            showDropdown ? 'xela-selected-text active' : 'xela-selected-text'
          }
          onClick={() => {
            if (!disabled) showDropdownHandler()
          }}
          onMouseEnter={() => {
            if (!disabled) setHover(true)
          }}
          onMouseLeave={() => {
            if (!disabled) setHover(false)
          }}
        >
          <Center height="100%">
            <SelectSubDivHStack
              showDropdown={showDropdown}
              focusColor={focusColor}
              primaryTextColor={primaryTextColor}
              spacing="12px"
            >
              {selectedOption?.icon !== undefined ? (
                <SelectIconBox width="16px" height="16px" clipped={true}>
                  {selectedOption.icon}
                </SelectIconBox>
              ) : (
                defaultIcon !== undefined &&
                selectedOption?.avatar === undefined &&
                defaultAvatar === undefined && (
                  <SelectIconBox width="16px" height="16px" clipped={true}>
                    {defaultIcon}
                  </SelectIconBox>
                )
              )}
              {selectedOption?.avatar !== undefined
                ? selectedOption.avatar
                : defaultAvatar !== undefined && defaultAvatar}
              <VStack spacing="4px" height="auto">
                <Typography
                  as="p"
                  variant="body-small"
                  color={secondaryTextColor}
                >
                  {selectPlaceholder}
                </Typography>
                {selectedOption !== undefined && (
                  <input
                    ref={inputRef}
                    value={inputSelected}
                    onChange={(e) => {
                      setInputSelected(e.target.value)
                      setQuery(e.target.value)
                      if (!showDropdown) {
                        setShowDropdown(true)
                      }
                    }}
                    type="text"
                    className="xela-selected-input"
                    style={{
                      color: showDropdown ? focusColor : primaryTextColor,
                    }}
                  />
                )}
              </VStack>
              <SelectIconBox width="16px" height="16px">
                {!showDropdown ? (
                  <SvgIcon
                    strokeColor={showDropdown ? focusColor : primaryTextColor}
                  >
                    <svg
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 5L7.5 12L1 5"
                        stroke="#1D1929"
                        strokeLinecap="square"
                      />
                    </svg>
                  </SvgIcon>
                ) : (
                  <SvgIcon
                    strokeColor={showDropdown ? focusColor : primaryTextColor}
                  >
                    <svg
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 11L7.5 4L14 11"
                        stroke="#1D1929"
                        strokeLinecap="square"
                      />
                    </svg>
                  </SvgIcon>
                )}
              </SelectIconBox>
            </SelectSubDivHStack>
          </Center>
        </SelectSubDiv>
        <input
          type="hidden"
          name={name}
          disabled={disabled}
          value={
            selectedOption !== undefined
              ? selectedOption?.value
              : defaultOption?.value || ''
          }
          {...props}
        />
        <ul
          className={
            showDropdown
              ? 'xela-select-options xela-show-dropdown-options'
              : 'xela-select-options xela-hide-dropdown-options'
          }
        >
          {filteredOptions.map((optionItem) => (
            <li
              key={Math.random()}
              className="xela-select-option"
              onClick={() => updateSelectedOption(optionItem)}
            >
              <OptionItem
                option={optionItem}
                selected={optionItem.value == selectedOption?.value}
                focusColor={focusColor}
                primaryTextColor={primaryTextColor}
                secondaryTextColor={secondaryTextColor}
                hoverOptionBg={hoverOptionBg}
                selectedOptionContentColor={selectedOptionContentColor}
              />
            </li>
          ))}
          {filteredOptions.length === 0 && (
            <li
              key={Math.random()}
              className="xela-select-option"
              style={{
                padding: '12px',
                fontSize: '14px',
                color: XelaColor.Gray3,
              }}
            >
              No Item Found
            </li>
          )}
        </ul>
      </SelectDiv>
    </SelectContext.Provider>
  )
}

export default Select
