import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface ChipProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  name?: string
  value?: string
  size?: 'large' | 'medium' | 'small' | 'extraSmall'
  activeIcon?: ReactNode
  deactiveIcon?: ReactNode
  activeAction?: ReactNode
  deactiveAction?: ReactNode
  isOn?: boolean
  disabled?: boolean
  activeBg?: string
  deactiveBg?: string
  activeLabelColor?: string
  deactiveLabelColor?: string
  activeBorder?: string
  deactiveBorder?: string
  onChangeHandle?: (isOn: boolean) => void
}

const ChipComponent = styled(HStack)<{
  active: boolean
  activeBorder?: string
  disabled: boolean
  deactiveBorder?: string
  size: 'large' | 'medium' | 'small' | 'extraSmall'
  activeIcon?: ReactNode
  deactiveIcon?: ReactNode
  activeAction?: ReactNode
  deactiveAction?: ReactNode
}>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}

  user-select: none;
  display: inline-flex;
  padding: ${(props) =>
      props.size === 'large' ? '12px' : props.size === 'medium' ? '8px' : props.size === 'small' ? '4px' : '2px'}
    12px;

  ${(props) =>
    props.active
      ? css`
          ${props.activeBorder && 'border: ' + props.activeBorder + ';'}
        `
      : css`
          ${props.deactiveBorder && 'border: ' + props.deactiveBorder + ';'}
        `}

  transition: background 200ms;
`

export const ChipX: FC<ChipProps> = ({
  label,
  name,
  value,
  activeIcon,
  onChangeHandle,
  deactiveIcon,
  activeLabelColor = XelaColor.Orange12,
  deactiveLabelColor = XelaColor.Orange5,
  size = 'small',
  activeAction,
  deactiveAction,
  activeBg = XelaColor.Orange9,
  deactiveBg = XelaColor.Orange11,
  disabled = false,
  isOn = false,
  ...props
}) => {
  const [active, setActive] = useState(isOn)

  return (
    <ChipComponent
      size={size}
      active={active}
      width="auto"
      bg={active ? activeBg : deactiveBg}
      borderRadius="99px"
      spacing="10px"
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          if (onChangeHandle) onChangeHandle(!active)
          setActive(!active)
        }
      }}
      {...props}
    >
      {activeIcon !== undefined && deactiveIcon !== undefined && (
        <Center width="16px" height="16px">
          {active ? activeIcon : deactiveIcon}
        </Center>
      )}
      <Typography
        className={'whitespace-pre'}
        color={active ? activeLabelColor : deactiveLabelColor}
        variant={size === 'small' ? 'button-small' : size === 'extraSmall' ? 'sub-caption-bold' : 'button-medium'} // Adjusted variant for 'extraSmall' size
      >
        {label}
      </Typography>
      {activeAction !== undefined && deactiveAction !== undefined && (
        <Center width="16px" height="16px">
          {active ? activeAction : deactiveAction}
        </Center>
      )}
      {active && <input type="hidden" disabled={disabled} name={name} value={value} />}
    </ChipComponent>
  )
}

export default ChipX
