import React, { FC, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import Center from '@/XelaReact/Center/Center'
import SvgIcon from '@/XelaReact/SvgIcon/SvgIcon'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor, XelaGradient } from '@/XelaReact/XelaColor/XelaColor'

export interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode
  initials?: string
  image?: ReactNode
  bg?: string
  initialsColor?: string
  size?: 'large' | 'medium' | 'small'
  variant?: 'rectangle' | 'circle'
  decorationPosition?: 'top' | 'bottom'
  decoration?: 'badge' | 'plus' | 'indicator' | 'none' | 'border'
  badgeText?: string
  badgeBg?: string
  badgeTextColor?: string
  plusIconColor?: string
  plusBg?: string
  indicatorColor?: string
  borderColor?: string
  decorationBorderColor?: string
  className?: string
}

const AvatarCenter = styled(Center)<AvatarProps>`
  padding: ${(props) =>
    props.icon !== undefined || props.initials !== undefined
      ? props.size == 'large'
        ? '16px'
        : props.size == 'medium'
        ? '12px'
        : '8px'
      : '0px'};
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
`

const AvatarBadge = styled(Center)<AvatarProps>`
  position: absolute;
  margin-top: ${(props) =>
    props.decorationPosition == 'bottom'
      ? props.size == 'small'
        ? '-14px'
        : props.size == 'medium'
        ? '-18px'
        : '-20px'
      : props.size == 'small'
      ? '-38px'
      : props.size == 'medium'
      ? '-52px'
      : '-66px'};
  margin-left: ${(props) =>
    props.size == 'large' ? '52px' : props.size == 'medium' ? '38px' : '26px'};
`

const AvatarIndicator = styled(Center)<AvatarProps>`
  position: absolute;
  margin-top: ${(props) =>
    props.decorationPosition == 'bottom'
      ? props.size == 'small'
        ? '-12px'
        : props.size == 'medium'
        ? '-14px'
        : '-16px'
      : props.size == 'small'
      ? '-38px'
      : props.size == 'medium'
      ? '-52px'
      : '-66px'};
  margin-left: ${(props) =>
    props.size == 'large' ? '54px' : props.size == 'medium' ? '42px' : '28px'};
  outline: ${(props) =>
    (props.size == 'small' ? '1px' : props.size == 'medium' ? '2px' : '3px') +
    ' solid ' +
    props.decorationBorderColor};
`

const AvatarPlus = styled(Center)<AvatarProps>`
  position: absolute;
  margin-top: ${(props) =>
    props.decorationPosition == 'bottom'
      ? props.size == 'small'
        ? '-15px'
        : props.size == 'medium'
        ? '-20px'
        : '-22px'
      : props.size == 'small'
      ? '-38px'
      : props.size == 'medium'
      ? '-52px'
      : '-66px'};
  margin-left: ${(props) =>
    props.size == 'large' ? '50px' : props.size == 'medium' ? '38px' : '25px'};
  outline: ${(props) =>
    (props.size == 'small' ? '2px' : props.size == 'medium' ? '3px' : '3px') +
    ' solid ' +
    props.decorationBorderColor};
`

const AvatarContainer = styled.div`
  margin-left: -4px;
  position: relative;
`

export const Avatar: FC<AvatarProps> = ({
  icon,
  initials,
  image,
  bg = XelaColor.Blue7,
  initialsColor = XelaColor.White,
  size = 'medium',
  variant = 'rectangle',
  decorationPosition = 'bottom',
  decoration = 'none',
  badgeText,
  badgeBg = XelaColor.Red3,
  badgeTextColor = XelaColor.White,
  plusIconColor = XelaColor.White,
  plusBg = XelaColor.Blue3,
  indicatorColor = XelaColor.Green2,
  borderColor = XelaGradient(XelaColor.Pink3, XelaColor.Blue3),
  decorationBorderColor = XelaColor.White,
  className,
  ...props
}) => {
  return (
    <AvatarContainer className={className} {...props}>
      <Center
        bg={decoration == 'border' ? borderColor : 'none'}
        borderRadius={
          variant == 'circle'
            ? '100px'
            : size == 'large'
            ? '26px'
            : size == 'medium'
            ? '20px'
            : '14px'
        }
        width={size == 'large' ? '72px' : size == 'medium' ? '56px' : '40px'}
        height={size == 'large' ? '72px' : size == 'medium' ? '56px' : '40px'}
      >
        <Center
          bg={decoration == 'border' ? decorationBorderColor : 'none'}
          borderRadius={
            variant == 'circle'
              ? '100px'
              : size == 'large'
              ? '25px'
              : size == 'medium'
              ? '19px'
              : '13px'
          }
          width={size == 'large' ? '68px' : size == 'medium' ? '52px' : '36px'}
          height={size == 'large' ? '68px' : size == 'medium' ? '52px' : '36px'}
        >
          <AvatarCenter
            size={size}
            initials={initials}
            icon={icon}
            bg={bg}
            borderRadius={
              variant == 'circle'
                ? '100px'
                : size == 'large'
                ? '24px'
                : size == 'medium'
                ? '18px'
                : '12px'
            }
            width={
              image === undefined
                ? size == 'large'
                  ? '32px'
                  : size == 'medium'
                  ? '24px'
                  : '16px'
                : size == 'large'
                ? '64px'
                : size == 'medium'
                ? '48px'
                : '32px'
            }
            height={
              image === undefined
                ? size == 'large'
                  ? '32px'
                  : size == 'medium'
                  ? '24px'
                  : '16px'
                : size == 'large'
                ? '64px'
                : size == 'medium'
                ? '48px'
                : '32px'
            }
          >
            {icon !== undefined && icon}
            {initials !== undefined && (
              <Typography
                as="p"
                variant={
                  size == 'large'
                    ? 'headline'
                    : size == 'medium'
                    ? 'subheadline'
                    : 'button-medium'
                }
                color={initialsColor}
              >
                {initials}
              </Typography>
            )}
            {image !== undefined && image}
          </AvatarCenter>
        </Center>
      </Center>

      {decoration == 'badge' && badgeText !== undefined && (
        <AvatarBadge
          decorationPosition={decorationPosition}
          size={size}
          width={size == 'small' ? '12px' : '16px'}
          height={size == 'small' ? '12px' : '16px'}
          bg={badgeBg}
          borderRadius="100px"
        >
          <Typography
            as="span"
            noWrap={true}
            variant="caption"
            color={badgeTextColor}
            style={{ fontSize: '8px', lineHeight: '1px' }}
          >
            {badgeText}
          </Typography>
        </AvatarBadge>
      )}
      {decoration == 'indicator' && (
        <AvatarIndicator
          decorationPosition={decorationPosition}
          size={size}
          decorationBorderColor={decorationBorderColor}
          width={size == 'small' ? '8px' : size == 'medium' ? '10px' : '12px'}
          height={size == 'small' ? '8px' : size == 'medium' ? '10px' : '12px'}
          bg={indicatorColor}
          borderRadius="100px"
        />
      )}
      {decoration == 'plus' && (
        <AvatarPlus
          decorationPosition={decorationPosition}
          size={size}
          decorationBorderColor={decorationBorderColor}
          width={size == 'small' ? '13px' : size == 'medium' ? '17px' : '19px'}
          height={size == 'small' ? '13px' : size == 'medium' ? '17px' : '19px'}
          bg={plusBg}
          borderRadius="100px"
        >
          <SvgIcon strokeColor={plusIconColor}>
            <svg
              width="7"
              height="7"
              viewBox="0 0 7 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.5 0V7M0 3.5H7" stroke="white" />
            </svg>
          </SvgIcon>
        </AvatarPlus>
      )}
    </AvatarContainer>
  )
}

export default Avatar
