import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Chip, Drawer, Group, LoadingOverlay } from '@mantine/core'
import { router, useForm, usePage } from '@inertiajs/react'
import { XTextarea } from '@/Mantine/XTextarea'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import {
  updateFollowUpEntity,
  updateFollowUpEntityId,
  updateIsFollowUpModalOpen,
  updateSelectedFollowUpIeltsLead,
  updateSelectedFollowUpInsuranceLead,
  updateSelectedFollowUpLead,
  updateSelectedFollowUpVisitorLead,
  updateShowFollowUpCreate,
} from '@/Store/followUpSlice'
import XDateTimePicker from '@/Mantine/XDateTimePicker'

const getDueDate = (due_date_time: string, custom_due_date_time: string) => {
  switch (due_date_time) {
    case 'today':
      return moment().set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'tomorrow':
      return moment().add(1, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'day_after_tomorrow':
      return moment().add(2, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'next_week':
      return moment().add(1, 'weeks').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'next_month':
      return moment().add(1, 'months').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
    case 'custom':
      if (custom_due_date_time === 'tomorrow') {
        return moment().add(1, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toDate()
      } else {
        return moment(custom_due_date_time, 'YYYY-MM-DD HH:mm:ss').toDate()
      }
  }
}

const CreateFollowUpModal: FC = () => {
  const followUpStore = useSelector((state: RootState) => state.followUp)
  const dispatch = useDispatch()
  const page = usePage()
  const [loading, setLoading] = useState(false)

  const lead = followUpStore.selected_follow_up_lead !== null ? followUpStore.selected_follow_up_lead : null
  const visitorLead =
    followUpStore.selected_follow_up_visitor_lead !== null ? followUpStore.selected_follow_up_visitor_lead : null
  const ieltsLead =
    followUpStore.selected_follow_up_ielts_lead !== null ? followUpStore.selected_follow_up_ielts_lead : null
  const insuranceLead =
    followUpStore.selected_follow_up_insurance_lead !== null ? followUpStore.selected_follow_up_insurance_lead : null
  const entity = followUpStore.follow_up_entity !== null ? followUpStore.follow_up_entity : null
  const entityId = followUpStore.follow_up_entity_id !== null ? followUpStore.follow_up_entity_id : null

  const showCreate = followUpStore.show_follow_up_create

  const { data, setData, post, reset, errors, clearErrors, recentlySuccessful } = useForm<{
    lead_id: number | null
    lead_type: string | null
    entity: string | null
    entity_id: number | null
    follow_up: string
    due_date_time: string
    custom_due_date_time: string
  }>({
    lead_id: lead
      ? lead.id
      : visitorLead
      ? visitorLead.id
      : ieltsLead
      ? ieltsLead.id
      : insuranceLead
      ? insuranceLead.id
      : null,
    lead_type: lead
      ? `Lead`
      : visitorLead
      ? `Visitor Lead`
      : ieltsLead
      ? `IELTS Lead`
      : insuranceLead
      ? `Insurance Lead`
      : null,
    entity: entity !== null ? entity : null,
    entity_id: entityId !== null ? entityId : null,
    follow_up: '',
    due_date_time: 'tomorrow',
    custom_due_date_time: 'tomorrow',
  })

  useEffect(() => {
    setData((prev) => {
      return {
        ...prev,
        lead_id: lead
          ? lead.id
          : visitorLead
          ? visitorLead.id
          : ieltsLead
          ? ieltsLead.id
          : insuranceLead
          ? insuranceLead.id
          : null,
        lead_type: lead
          ? `Lead`
          : visitorLead
          ? `Visitor Lead`
          : ieltsLead
          ? `IELTS Lead`
          : insuranceLead
          ? `Insurance Lead`
          : null,
        entity: entity ? entity : null,
        entity_id: entityId ? entityId : null,
      }
    })
  }, [lead, visitorLead, ieltsLead, entity, entityId])

  const formHandler = (name: string, value: string) => {
    setData((prev) => {
      const datum: { [key: string]: string } = {}
      datum[name] = value

      return {
        ...prev,
        ...datum,
      }
    })
  }

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(updateSelectedFollowUpLead(null))
      dispatch(updateSelectedFollowUpVisitorLead(null))
      dispatch(updateSelectedFollowUpIeltsLead(null))
      dispatch(updateSelectedFollowUpInsuranceLead(null))
      dispatch(updateFollowUpEntity(null))
      dispatch(updateFollowUpEntityId(null))
      dispatch(updateIsFollowUpModalOpen(!followUpStore.is_follow_up_modal_open))
      router.visit(page.url, { preserveScroll: true })
      clearErrors()
      reset()
    }
  }, [recentlySuccessful])

  return (
    <Drawer
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      position={'right'}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      size={650}
      opened={followUpStore.is_follow_up_modal_open}
      onClose={() => {
        dispatch(updateSelectedFollowUpLead(null))
        dispatch(updateSelectedFollowUpVisitorLead(null))
        dispatch(updateSelectedFollowUpIeltsLead(null))
        dispatch(updateSelectedFollowUpInsuranceLead(null))
        dispatch(updateFollowUpEntity(null))
        dispatch(updateFollowUpEntityId(null))
        dispatch(updateIsFollowUpModalOpen(!followUpStore.is_follow_up_modal_open))
        router.visit(page.url, { preserveScroll: true })
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height={'100%'}>
        <HStack>
          <Typography variant="subheadline">Follow Up 1</Typography>
        </HStack>
        <HStack>
          <Divider variant="dotted"></Divider>
        </HStack>
        <VStack spacing={'12px'} height={'100%'}>
          <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
          <VStack
            className={'pr-2'}
            spacing={'12px'}
            style={{
              flex: '1 1 0',
              height: 0,
              overflowY: 'scroll',
            }}
          >
            <HStack spacing="12px">
              <XTextarea
                error={errors.follow_up}
                placeholder="Add a follow up here..."
                minRows={11}
                value={data.follow_up}
                onChange={(e) => {
                  formHandler('follow_up', e.target.value)
                }}
              ></XTextarea>
            </HStack>
            <VStack spacing="4px" justifyContent="flex-end">
              <Typography variant="caption" color={XelaColor.Gray6} noWrap={true}>
                Due By
              </Typography>
              <HStack>
                <HStack>
                  <Chip.Group
                    value={data.due_date_time}
                    multiple={false}
                    onChange={(value) => {
                      if (value) {
                        formHandler('due_date_time', value)
                        if (value === 'custom') {
                          formHandler('custom_due_date_time', 'tomorrow')
                        }
                      }
                    }}
                  >
                    <Group spacing={8}>
                      <Chip value="today" size="sm" color="blueX" variant="outline">
                        Today
                      </Chip>
                      <Chip value="tomorrow" size="sm" color="blueX" variant="outline">
                        Tomorrow
                      </Chip>
                      <Chip value="day_after_tomorrow" size="sm" color="blueX" variant="outline">
                        Day After Tomorrow
                      </Chip>
                      <Chip value="next_week" size="sm" color="blueX" variant="outline">
                        Next Week
                      </Chip>
                      <Chip value="next_month" size="sm" color="blueX" variant="outline">
                        Next Month
                      </Chip>
                      <Chip value="custom" size="sm" color="blueX" variant="outline">
                        Custom
                      </Chip>
                    </Group>
                  </Chip.Group>
                </HStack>
                <HStack
                  style={{
                    width: '50%',
                  }}
                >
                  <XDateTimePicker
                    clearable
                    value={getDueDate(data.due_date_time, data.custom_due_date_time)}
                    valueFormat={'DD/MM/YYYY HH:mm:ss'}
                    label="Select Due Date"
                    placeholder="Due Date"
                    minDate={new Date()}
                    maxDate={moment().add(24, 'months').toDate()}
                    onChange={(value) => {
                      if (value) {
                        formHandler(
                          'custom_due_date_time',
                          moment(value).startOf('minute').format('YYYY-MM-DD HH:mm:ss')
                        )
                        formHandler('due_date_time', 'custom')
                      }
                    }}
                  ></XDateTimePicker>
                </HStack>
              </HStack>
            </VStack>
          </VStack>
          <HStack>
            <Divider variant="dotted"></Divider>
          </HStack>
          <HStack spacing="12px" justifyContent="flex-end">
            <Button
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                dispatch(updateSelectedFollowUpLead(null))
                dispatch(updateSelectedFollowUpVisitorLead(null))
                dispatch(updateSelectedFollowUpIeltsLead(null))
                dispatch(updateSelectedFollowUpInsuranceLead(null))
                dispatch(updateFollowUpEntity(null))
                dispatch(updateFollowUpEntityId(null))
                dispatch(updateIsFollowUpModalOpen(!followUpStore.is_follow_up_modal_open))
                router.visit(page.url, { preserveScroll: true })
                clearErrors()
                reset()
              }}
            />
            <Button
              label={'Create'}
              onClick={() => {
                setLoading(true)
                post(route('follow-ups.create'), {
                  preserveState: true,
                  preserveScroll: true,
                  onFinish: () => {
                    setLoading(false)
                    // if (setShowCreate) setShowCreate(false)
                    if (showCreate) {
                      updateShowFollowUpCreate(false)
                    }
                  },
                })
              }}
            ></Button>
          </HStack>
        </VStack>
      </VStack>
    </Drawer>
  )
}

export default CreateFollowUpModal
