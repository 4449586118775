import React, { useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, Modal } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { updateShowB2BAgentCopyLoginCredentialsModal } from '@/Store/B2B/b2bAgentSlice'
import useFlash from '@/Hooks/useFlash'
import { useClipboard } from '@mantine/hooks'

const CopyLoginCredentialsAgentModal = () => {
  const flash = useFlash()
  const dispatch = useDispatch()
  const b2bAgentStore = useSelector((state: RootState) => state.b2bAgent)

  const clipboard = useClipboard({
    timeout: 500,
  })

  const [credentials, setCredentials] = useState({
    login_url: '',
    email: '',
    password: '',
  })

  useEffect(() => {
    if (flash.action === 'b2b:agent:show_credentials') {
      setCredentials(flash.payload as { login_url: string; email: string; password: string })
      dispatch(updateShowB2BAgentCopyLoginCredentialsModal(true))
    }
  }, [flash.timestamp])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      radius={16}
      size={600}
      opened={b2bAgentStore.show_b2b_agent_copy_login_credentials_modal}
      onClose={() => {
        dispatch(updateShowB2BAgentCopyLoginCredentialsModal(false))
      }}
    >
      <VStack spacing="12px">
        <HStack spacing="12px">
          <Typography variant="subheadline">Login Credentials</Typography>
        </HStack>
        <HStack spacing="12px">
          <Divider></Divider>
        </HStack>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <VStack spacing="12px">
            <VStack spacing="12px" style={{ height: '350px' }}>
              <VStack spacing="12px" alignItems="flex-start">
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 auto',
                    background: XelaColor.Gray12,
                    borderRadius: '12px',
                    padding: '12px',
                    width: '100%',
                  }}
                >
                  <VStack spacing="4px">
                    <HStack spacing="12px">
                      <Typography
                        variant="caption-bold"
                        color={XelaColor.Gray4}
                        style={{
                          width: '70px',
                        }}
                      >
                        Login URL :
                      </Typography>
                      <Typography variant="body-small">{credentials.login_url}</Typography>
                    </HStack>
                    <HStack spacing="12px">
                      <Typography
                        variant="caption-bold"
                        color={XelaColor.Gray4}
                        style={{
                          width: '70px',
                        }}
                      >
                        Email :
                      </Typography>
                      <Typography variant="body-small">{credentials.email}</Typography>
                    </HStack>
                    <HStack spacing="12px">
                      <Typography
                        variant="caption-bold"
                        color={XelaColor.Gray4}
                        style={{
                          width: '70px',
                        }}
                      >
                        Password :
                      </Typography>
                      <Typography variant="body-small">{credentials.password}</Typography>
                    </HStack>
                  </VStack>
                </Box>
                <HStack justifyContent="flex-end" spacing="12px">
                  <Button
                    size={'small'}
                    label="Copy"
                    variant="primary"
                    onClick={() => {
                      clipboard.copy(
                        // eslint-disable-next-line max-len
                        `Your Login Credentials are: \nLogin URL: ${credentials.login_url}\nEmail: ${credentials.email}\nPassword: ${credentials.password}`
                      )
                    }}
                  />
                </HStack>
              </VStack>
            </VStack>
            <HStack justifyContent="flex-end" spacing="12px">
              <Button
                size={'small'}
                label="Close"
                variant="secondary"
                onClick={() => {
                  dispatch(updateShowB2BAgentCopyLoginCredentialsModal(false))
                }}
              />
            </HStack>
          </VStack>
        </div>
      </VStack>
    </Modal>
  )
}

export default CopyLoginCredentialsAgentModal
