import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Email = App.Models.Email

export interface EmailState {
  reload: boolean
  email_sent_type: string
  show_email_send_modal: boolean
  show_imap_config_modal: boolean
  email?: Email
}

const initialState: EmailState = {
  reload: false,
  email_sent_type: '',
  show_email_send_modal: false,
  show_imap_config_modal: false,
  email: undefined,
}

export const emailSlice = createSlice({
  name: 'emailSlice',
  initialState,
  reducers: {
    showEmailSendModal(state, action: PayloadAction<{ type: string; email?: Email }>) {
      state.email_sent_type = action.payload.type
      state.email = action.payload.email
      state.show_email_send_modal = true
    },
    hideEmailSendModal(state) {
      state.show_email_send_modal = false
      state.email = undefined
      state.email_sent_type = ''
    },
    showImapConfigModal(state) {
      state.show_imap_config_modal = true
    },
    hideImapConfigModal(state) {
      state.show_imap_config_modal = false
    },
    updateReloadEmails(state, action: PayloadAction<boolean>) {
      state.reload = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  showEmailSendModal,
  hideEmailSendModal,
  showImapConfigModal,
  hideImapConfigModal,
  updateReloadEmails,
} = emailSlice.actions

export default emailSlice.reducer
