import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

export class DoughnutDataset {
  label: string
  data: Array<number>
  backgroundColor: Array<string>
  borderColor?: Array<string>
  borderWidth: number
  hoverOffset: number

  constructor(
    label: string,
    data: Array<number>,
    backgroundColor: Array<string>,
    borderColor?: Array<string>,
    borderWidth = 0,
    hoverOffset = 8
  ) {
    this.label = label
    this.data = data
    this.backgroundColor = backgroundColor
    this.borderColor = borderColor
    this.borderWidth = borderWidth
    this.hoverOffset = hoverOffset
  }
}

ChartJS.register(ArcElement, Tooltip, Legend)

export interface DoughnutChartProps extends HTMLAttributes<HTMLDivElement> {
  labels: Array<string>
  datasets: Array<DoughnutDataset>
  cutout?: number
  padding?: number
  width?: string
}

const DoughnutChartComponent = styled.div<{ width?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`

export const DoughnutChart: FC<DoughnutChartProps> = ({
  labels,
  datasets,
  padding = 16,
  width = '500px',
  cutout = 100,
}) => {
  const options = {
    cutout: cutout,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      padding: padding,
    },
  }

  const ds = []
  for (let i = 0; i < datasets.length; i++) {
    ds.push(datasets[i])
    if (i < datasets.length - 1) {
      ds.push({ label: undefined, data: undefined, weight: 0.8 })
    }
  }

  const data = {
    labels,
    datasets: ds,
  }

  return (
    <DoughnutChartComponent width={width}>
      <Doughnut options={options} data={data} />
    </DoughnutChartComponent>
  )
}

export default DoughnutChart
