import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { BranchUsersDrawerProps } from '@/Types/BranchUsersTypes'
import User = App.Models.User

export interface UserState {
  avatar: string | null
  associative_branches: string[]
  branch_id: string | null
  user_id: string | null
  user: User | null
  is_disabled: boolean
  is_associative_branches_changed: boolean
  is_user_active_status_changed: boolean
  show_password_reset_modal: boolean
  show_user_associative_branches_drawer: boolean
  show_branch_user_drawer: boolean
  counsellor_id: string | null
  counselling_counselor_id: string | null
  admission_manager_id: string | null
  visa_manager_id: string | null
  visitor_manager_id: string | null
  ielts_manager_id: string | null
  branch_users_list: BranchUsersDrawerProps[] | []
  show_main_branch_drawer: boolean
  new_branch_id: string | null
  is_main_branch_changed: boolean
  assigned_leads_count_data: {
    leads_count: number
    counsellings_count: number
    admissions_count: number
    visas_count: number
    visitor_leads_count: number
    visitor_visas_count: number
    coaching_leads_count: number
    tasks_count: number
    follow_ups_count: number
  } | null
}

const initialState: UserState = {
  avatar: null,
  associative_branches: [],
  branch_id: null,
  user_id: '',
  user: null,
  is_disabled: false,
  is_associative_branches_changed: false,
  is_user_active_status_changed: false,
  show_password_reset_modal: false,
  show_user_associative_branches_drawer: false,
  show_branch_user_drawer: false,
  counsellor_id: null,
  counselling_counselor_id: null,
  admission_manager_id: null,
  visa_manager_id: null,
  visitor_manager_id: null,
  ielts_manager_id: null,
  branch_users_list: [],
  show_main_branch_drawer: false,
  new_branch_id: null,
  is_main_branch_changed: false,
  assigned_leads_count_data: null,
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    updateAvatar(state, action: PayloadAction<string>) {
      state.avatar = action.payload
    },

    updateIsUserAssociativeBranchesChanged(
      state,
      action: PayloadAction<{
        show: boolean
        associative_branches: string[]
        user: User | null
        user_id: string
        branch_id: string | null
      }>
    ) {
      state.is_associative_branches_changed = action.payload.show
      state.associative_branches = action.payload.associative_branches
      state.user_id = action.payload.user_id
      state.user = action.payload.user
      state.branch_id = action.payload.branch_id
    },

    updateIsUserActiveStatusChanged(
      state,
      action: PayloadAction<{
        show: boolean
        is_disabled: boolean
        user_id: string
        user: User | null
      }>
    ) {
      state.is_user_active_status_changed = action.payload.show
      state.is_disabled = action.payload.is_disabled
      state.user_id = action.payload.user_id
      state.user = action.payload.user
    },

    updateShowPasswordResetModal(
      state,
      action: PayloadAction<{
        show: boolean
        user_id: string
        user: User | null
      }>
    ) {
      state.show_password_reset_modal = action.payload.show
      state.user_id = action.payload.user_id
      state.user = action.payload.user
    },
    updateShowUserAssociativeBranchesDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        associative_branches: string[]
        user: User | null
        branch_users_list: BranchUsersDrawerProps[] | []
      }>
    ) {
      state.show_user_associative_branches_drawer = action.payload.show
      state.associative_branches = action.payload.associative_branches
      state.user = action.payload.user
      state.branch_users_list = action.payload.branch_users_list
    },
    updateShowBranchUserDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        user_id: string | null
        branch_id: string | null
        assigned_leads_count_data: {
          leads_count: number
          counsellings_count: number
          admissions_count: number
          visas_count: number
          visitor_leads_count: number
          visitor_visas_count: number
          coaching_leads_count: number
          tasks_count: number
          follow_ups_count: number
        } | null
      }>
    ) {
      state.show_branch_user_drawer = action.payload.show
      state.user_id = action.payload.user_id
      state.branch_id = action.payload.branch_id
      state.assigned_leads_count_data = action.payload.assigned_leads_count_data
    },
    updateBranchUsersList(
      state,
      action: PayloadAction<{
        branch_users_list: BranchUsersDrawerProps[] | []
      }>
    ) {
      state.branch_users_list = action.payload.branch_users_list
    },
    updateAssociateBranches(
      state,
      action: PayloadAction<{
        associative_branches: string[]
      }>
    ) {
      state.associative_branches = action.payload.associative_branches
    },
    updateShowMainBranchDrawer(
      state,
      action: PayloadAction<{
        show: boolean
        branch_id: string | null
        user: User | null
        branch_users_list: BranchUsersDrawerProps[] | []
      }>
    ) {
      state.show_main_branch_drawer = action.payload.show
      state.branch_id = action.payload.branch_id
      state.user = action.payload.user
      state.branch_users_list = action.payload.branch_users_list
    },
    updateNewBranchId(state, action: PayloadAction<string | null>) {
      state.new_branch_id = action.payload
    },
    updateIsMainBranchChanged(state, action: PayloadAction<boolean>) {
      state.is_main_branch_changed = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateAvatar,
  updateIsUserAssociativeBranchesChanged,
  updateIsUserActiveStatusChanged,
  updateShowPasswordResetModal,
  updateShowUserAssociativeBranchesDrawer,
  updateShowBranchUserDrawer,
  updateBranchUsersList,
  updateAssociateBranches,
  updateShowMainBranchDrawer,
  updateNewBranchId,
  updateIsMainBranchChanged,
} = userSlice.actions

export default userSlice.reducer
