import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface B2BContractState {
  show_b2b_contract_drawer: boolean
}

const initialState: B2BContractState = {
  show_b2b_contract_drawer: false,
}

export const b2bContractSlice = createSlice({
  name: 'b2bContractSlice',
  initialState,
  reducers: {
    updateShowB2BContractDrawer: (state, action: PayloadAction<boolean>) => {
      state.show_b2b_contract_drawer = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateShowB2BContractDrawer } = b2bContractSlice.actions

export default b2bContractSlice.reducer
