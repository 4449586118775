import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import HStack from '@/XelaReact/HStack/HStack'
import SvgIcon from '@/XelaReact/SvgIcon/SvgIcon'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface PaginationShortProps extends HTMLAttributes<HTMLDivElement> {
  count: number
  defaultPage?: number
  btnColor?: string
  btnHoverColor?: string
  nextBtnIcon?: ReactNode
  prevBtnIcon?: ReactNode
  textColor?: string
  currentTextColor?: string
  onNextClick?: (nextPage: number) => void
  onPrevClick?: (prevPage: number) => void
}

const PaginationShortComponent = styled(HStack)<{
  btnColor: string
  btnHoverColor: string
  currentPage: number
  count: number
}>`
  user-select: none;

  & .pagination-short-btn-prev,
  & .pagination-short-btn-next {
    fill: ${(props) => props.btnColor};
    cursor: pointer;
  }

  & .pagination-short-btn-prev:hover,
  & .pagination-short-btn-next:hover {
    fill: ${(props) => props.btnHoverColor};
  }

  ${(props) =>
    props.currentPage == 1 &&
    css`
      & .pagination-short-btn-prev {
        opacity: 0.4;
        cursor: initial;
      }

      & .pagination-short-btn-prev:hover {
        fill: ${props.btnColor};
      }
    `}

  ${(props) =>
    props.currentPage == props.count &&
    css`
      & .pagination-short-btn-next {
        opacity: 0.4;
        cursor: initial;
      }

      & .pagination-short-btn-next:hover {
        fill: ${props.btnColor};
      }
    `}
`

export const PaginationShort: FC<PaginationShortProps> = ({
  count,
  defaultPage = 1,
  btnColor = XelaColor.Gray2,
  btnHoverColor = XelaColor.Blue3,
  nextBtnIcon,
  prevBtnIcon,
  textColor = XelaColor.Gray2,
  currentTextColor = XelaColor.Blue3,
  onNextClick,
  onPrevClick,
  ...props
}) => {
  const [currentPage, setPage] = useState(defaultPage)

  return (
    <PaginationShortComponent
      count={count}
      currentPage={currentPage}
      btnHoverColor={btnHoverColor}
      btnColor={btnColor}
      spacing="32px"
      {...props}
    >
      <div
        className="pagination-short-btn-prev"
        onClick={() => {
          const val = currentPage - 1
          if (val < 1) return
          setPage(val)
          if (onPrevClick) onPrevClick(val)
        }}
      >
        {prevBtnIcon ? (
          prevBtnIcon
        ) : (
          <SvgIcon isInherit={true}>
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.91421 8.50004L6.06066 11.6465L5.35355 12.3536L1 8.00004L5.35355 3.64648L6.06066 4.35359L2.91421 7.50004H14.2071V8.50004H2.91421Z"
                fill="#1D1929"
              />
            </svg>
          </SvgIcon>
        )}
      </div>
      <HStack width="auto" spacing="8px">
        <Typography color={currentTextColor} variant="headline">
          {currentPage}
        </Typography>
        <Typography color={textColor} variant="button-medium">
          /
        </Typography>
        <Typography color={textColor} variant="button-medium">
          {count}
        </Typography>
      </HStack>
      <div
        className="pagination-short-btn-next"
        onClick={() => {
          const val = currentPage + 1
          if (val > count) return
          setPage(val)
          if (onNextClick) onNextClick(val)
        }}
      >
        {nextBtnIcon ? (
          nextBtnIcon
        ) : (
          <SvgIcon isInherit={true}>
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.85355 3.64648L14.2071 8.00004L9.85355 12.3536L9.14645 11.6465L12.2929 8.50004H1V7.50004H12.2929L9.14645 4.35359L9.85355 3.64648Z"
                fill="#1D1929"
              />
            </svg>
          </SvgIcon>
        )}
      </div>
    </PaginationShortComponent>
  )
}

export default PaginationShort
