import * as React from 'react'

function IconHome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.825.12a.5.5 0 00-.65 0L0 6.27v7.23A1.5 1.5 0 001.5 15h4a.5.5 0 00.5-.5v-3a1.5 1.5 0 013 0v3a.5.5 0 00.5.5h4a1.5 1.5 0 001.5-1.5V6.27L7.825.12z"
        fill="#E6ECFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6.73v6.77a.5.5 0 00.5.5H5v-2.5a2.5 2.5 0 015 0V14h3.5a.5.5 0 00.5-.5V6.73L7.5 1.159 1 6.73zm14-.46v7.23a1.5 1.5 0 01-1.5 1.5h-4a.5.5 0 01-.5-.5v-3a1.5 1.5 0 00-3 0v3a.5.5 0 01-.5.5h-4A1.5 1.5 0 010 13.5V6.27L7.175.12a.5.5 0 01.65 0L15 6.27z"
        fill="#003CFF"
      />
    </svg>
  )
}

export default IconHome
