import React, { CSSProperties, FC, useEffect, useState } from 'react'

const XImage: FC<{ src: string; alt: string; defaultSrc: string; style?: CSSProperties }> = ({
  src,
  alt,
  defaultSrc,
  style = {},
}) => {
  const [imgSrc, setImgSrc] = useState(src)
  const [imgError, setImgError] = useState(false)

  const handleError = () => {
    setImgError(true)
  }

  useEffect(() => {
    setImgError(false)
    setImgSrc(src)
  }, [src])

  return <img style={style} src={imgError ? defaultSrc : imgSrc} alt={alt} onError={handleError} />
}

export default XImage
