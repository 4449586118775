import React, { FC, HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface CheckboxProps extends HTMLAttributes<HTMLLabelElement> {
  name: string
  value: string
  label?: string
  caption?: string
  additional?: ReactNode
  checked?: boolean
  size?: 'large' | 'medium' | 'small'
  labelColor?: string
  captionColor?: string
  defaultColor?: string
  activeColor?: string
  iconColor?: string
  disabled?: boolean
  width?: string
  icon?: 'tick' | 'minus'
  customContent?: ReactNode
  onChangeHandle?: (value: boolean) => void
}

const CheckBoxComponent = styled.label<{
  activeColor?: string
  defaultColor?: string
  checked?: boolean
  size?: 'large' | 'medium' | 'small'
  disabled?: boolean
  width?: string
  iconColor?: string
  icon?: 'tick' | 'minus'
}>`
  display: block;
  width: ${(props) => props.width && props.width};

  ${(props) => props.disabled && 'opacity: 0.4;'}
  & input[type="checkbox"] {
    appearance: none;
    background-color: inherit;
    margin: 0;
    font: inherit;
    color: ${(props) => props.defaultColor};
    width: ${(props) => (props.size == 'large' ? '32px' : props.size == 'medium' ? '24px' : '20px')};
    height: ${(props) => (props.size == 'large' ? '32px' : props.size == 'medium' ? '24px' : '20px')};
    border: 2px solid ${(props) => props.defaultColor};
    border-radius: ${(props) => (props.size == 'large' ? '12px' : props.size == 'medium' ? '9px' : '8px')};
    min-width: ${(props) => (props.size == 'large' ? '32px' : props.size == 'medium' ? '24px' : '20px')};
  }

  & input[type='checkbox']:hover {
    border: 2px solid ${(props) => (props.disabled ? props.defaultColor : props.activeColor)};
  }

  & input[type='checkbox']:checked {
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${(
      props
    ) => (props.icon == 'minus' ? 'M1 7.5H14' : 'M1 7L5.5 11.5L14 3')}' stroke='${(props) =>
      props.iconColor?.replace(/#/g, '%23')}' stroke-linecap='square'/%3E%3C/svg%3E");
    background-color: ${(props) => props.activeColor};
    background-repeat: no-repeat;
    background-size: ${(props) => (props.size == 'small' ? '12px 15px' : '15px 15px')};
    background-position: center;
  }
`

export const Checkbox: FC<CheckboxProps> = ({
  name,
  value,
  label,
  caption,
  additional,
  checked = false,
  size = 'medium',
  disabled = false,
  labelColor = XelaColor.Gray2,
  captionColor = XelaColor.Gray7,
  defaultColor = XelaColor.Gray11,
  activeColor = XelaColor.Blue3,
  iconColor = XelaColor.White,
  width,
  icon = 'tick',
  customContent,
  onChangeHandle,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleOnChange = () => {
    setIsChecked(!isChecked)
    if (onChangeHandle) {
      onChangeHandle(!isChecked)
    }
  }

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <CheckBoxComponent
      width={width ? width : 'auto'}
      activeColor={activeColor}
      defaultColor={defaultColor}
      checked={checked}
      size={size}
      disabled={disabled}
      iconColor={iconColor}
      icon={icon}
      {...props}
    >
      <HStack width="auto" spacing="12px">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleOnChange}
          disabled={disabled}
          name={name}
          value={value}
        />
        <VStack justifyContent="center">
          {label && (
            <Typography variant="button-medium" as="span" color={labelColor}>
              {label}
            </Typography>
          )}
          {caption && (
            <Typography variant="caption" as="span" color={captionColor}>
              {caption}
            </Typography>
          )}
          {customContent && customContent}
        </VStack>
        {additional && additional}
      </HStack>
    </CheckBoxComponent>
  )
}

export default Checkbox
