import './bootstrap.jsx'
import '../css/app.css'
import './RedactorX/redactorx.js'
import './RedactorX/plugins/variable/variable.js'
import './RedactorX/redactorx.css'

import React from 'react'
import { createInertiaApp } from '@inertiajs/react'
import { Provider } from 'react-redux'
import { MantineProvider } from '@mantine/core'
import { store } from '@/Store'
import 'dayjs/locale'
import { Notifications } from '@mantine/notifications'
import { XelaColor } from '@/XelaReact'
import { createRoot } from 'react-dom/client'
import { Config } from 'ziggy-js'
import { ModalsProvider } from '@mantine/modals'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import mixpanel from 'mixpanel-browser'
import { Channel } from 'laravel-echo'
import { FirebaseApp } from 'firebase/app'
import { AnalyticsBrowser } from '@june-so/analytics-next'
import Authenticated from '@/Layouts/Authenticated'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import B2BAuthenticated from '@/Layouts/B2B/B2BAuthenticated'
import AdminAuthenticated from '@/Layouts/Admin/AdminAuthenticated'
import HubAuthenticated from '@/Layouts/Hub/HubAuthenticated'
import StudentAuthenticated from '@/Layouts/Student/StudentAuthenticated'

declare global {
  function route(name: string, params?: string | Record<string, unknown>, absolute?: boolean, config?: Config): string

  function track(name: string, properties?: Record<string, unknown>): void

  function isProduction(): boolean

  function checkEnvironment(environment: string): boolean

  interface Window {
    env: string
    Echo: {
      private(channel: string): Channel
    }
    FirebaseApp: FirebaseApp
    Analytics: AnalyticsBrowser
    RedactorX: (
      selector: string,
      settings?: {
        [key: string]: unknown
      }
    ) => {
      editor: {
        setContent: (content: { html: string }) => void
        setFocus: (at: string) => void
      }
      destroy: () => void
    }
  }
}

// Initialize Bugsnag
Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
})
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

// Initialize Mixpanel
mixpanel.init('105c43050143620960a32b189bb6fb64', {
  debug: true,
})

window.Analytics = AnalyticsBrowser.load({
  writeKey: 'w0mqz4X0mMdKhU2N',
})

window.track = function (name: string, properties?: Record<string, unknown>) {
  if (import.meta.env.MODE === 'production') {
    mixpanel.track(name, properties)
    window.Analytics.track(name, properties)
  }
}

window.isProduction = function () {
  return window.env === 'production'
}

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'SwiftWebApp'

createInertiaApp({
  progress: {
    color: XelaColor.Blue3,
  },
  title: (title) => `${title ? title + ' - ' : ''}${appName}`,
  resolve: async (name) => {
    const module = await resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx'))

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Page = module.default
    if (
      name.includes('Apps') ||
      name.includes('Auth') ||
      name.startsWith('Form') ||
      name.startsWith('GuestInsurance')
    ) {
      // Wrap the Page component with the Layout if it doesn't have a layout defined
      return Page
    }

    Page.layout =
      Page.layout ||
      ((page: React.ReactNode) => {
        if (name.startsWith('B2B')) {
          return <B2BAuthenticated>{page}</B2BAuthenticated>
        } else {
          if (name.startsWith('Hub')) {
            return <HubAuthenticated>{page}</HubAuthenticated>
          } else {
            if (name.startsWith('Admin')) {
              return <AdminAuthenticated>{page}</AdminAuthenticated>
            } else {
              if (name.startsWith('Student')) {
                return <StudentAuthenticated>{page}</StudentAuthenticated>
              } else {
                return <Authenticated>{page}</Authenticated>
              }
            }
          }
        }
      })

    return Page
  },
  setup({ el, App, props }) {
    const root = createRoot(el)

    let app = <App {...props} />
    if (import.meta.env.MODE === 'production' && ErrorBoundary) {
      app = (
        <ErrorBoundary>
          <App {...props} />
        </ErrorBoundary>
      )
    }

    root.render(
      <Provider store={store}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: 'Nunito Sans, sans-serif',
            cursorType: 'pointer',
            primaryColor: 'blue',
            primaryShade: 7,
            colors: {
              blueX: [
                XelaColor.Blue10,
                XelaColor.Blue9,
                XelaColor.Blue8,
                XelaColor.Blue7,
                XelaColor.Blue6,
                XelaColor.Blue5,
                XelaColor.Blue4,
                XelaColor.Blue3,
                XelaColor.Blue2,
                XelaColor.Blue1,
              ],
              blue: [
                XelaColor.Blue10,
                XelaColor.Blue9,
                XelaColor.Blue8,
                XelaColor.Blue7,
                XelaColor.Blue6,
                XelaColor.Blue5,
                XelaColor.Blue4,
                XelaColor.Blue3,
                XelaColor.Blue2,
                XelaColor.Blue1,
              ],
            },
          }}
        >
          <ModalsProvider>
            <Notifications position={'top-center'} autoClose={2000} zIndex={9999} limit={5} />
            {app}
          </ModalsProvider>
        </MantineProvider>
      </Provider>
    )
  },
})
