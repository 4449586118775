import React, { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

export interface ShadowProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

const ShadowComponent = styled.div<ShadowProps>`
  & > * {
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08),
      0px 4px 56px rgba(0, 0, 0, 0.08);
  }
`;

export const Shadow: FC<ShadowProps> = ({ children, ...props }) => {
  return <ShadowComponent {...props}>{children}</ShadowComponent>;
};

export default Shadow;
