import React, { FC, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export class BreadcrumbItem {
  icon?: ReactNode
  title?: string
  click?: () => void
  action?: ReactNode

  constructor(
    icon?: ReactNode,
    title?: string,
    click?: () => void,
    action?: ReactNode
  ) {
    this.icon = icon
    this.title = title
    this.click = click
    this.action = action
  }
}

export interface BreadcrumbProps extends HTMLAttributes<HTMLDivElement> {
  items: BreadcrumbItem[]
  currentItemColor?: string
  itemColor?: string
  divider?: ReactNode
  click?: () => void
}

const BreadcrumbComponent = styled(HStack)<{ itemColor: string }>`
  min-height: 42px;
  color: ${(props) => props.itemColor};
  fill: ${(props) => props.itemColor};
  stroke: ${(props) => props.itemColor};

  & a {
    text-decoration: none;
    display: flex;
    gap: 8px;
  }

  & a:hover {
    opacity: 0.75;
  }
`

export const Breadcrumb: FC<BreadcrumbProps> = ({
  items,
  currentItemColor = XelaColor.Blue3,
  itemColor = XelaColor.Gray5,
  divider = '/',
  ...props
}) => {
  return (
    <BreadcrumbComponent spacing="12px" itemColor={itemColor} {...props}>
      {items.map((item, index) => (
        <HStack key={index} spacing="12px" width="auto">
          <HStack width="auto" spacing="8px">
            {(item.title || item.icon) && item.click ? (
              <a onClick={item.click} style={{ cursor: 'pointer' }}>
                {item.icon && item.icon}
                {item.title && (
                  <Typography
                    as="span"
                    color={
                      items.length == index + 1 ? currentItemColor : itemColor
                    }
                    variant="caption"
                  >
                    {item.title}
                  </Typography>
                )}
              </a>
            ) : (
              <>
                {item.icon && item.icon}{' '}
                {item.title && (
                  <Typography
                    as="span"
                    color={
                      items.length == index + 1 ? currentItemColor : itemColor
                    }
                    variant="caption"
                  >
                    {item.title}
                  </Typography>
                )}
              </>
            )}
            {item.action && item.action}
          </HStack>
          {items.length != index + 1 && (
            <Typography variant="caption" as="span">
              {divider}
            </Typography>
          )}
        </HStack>
      ))}
    </BreadcrumbComponent>
  )
}

export default Breadcrumb
