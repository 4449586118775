import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import { Box } from '@mantine/core'
import Notification = App.Models.Notification

const EmailMessageReceivedNotification: FC<{
  notification: Notification
  portal?: string
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        if (notification.data.lead_type === 'App\\Models\\Lead') {
          router.visit(route('leads.id', { id: notification.data.lead_id, show_tab: 'email' }))
        } else {
          if (notification.data.lead_type === 'App\\Models\\IeltsLead') {
            router.visit(route('ielts-leads.id', { id: notification.data.lead_id, show_tab: 'email' }))
          } else {
            if (notification.data.lead_type === 'App\\Models\\VisitorLead') {
              router.visit(route('visitor-leads.id', { id: notification.data.lead_id, show_tab: 'email' }))
            }
          }
        }
      }}
    >
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small" color={XelaColor.Gray6}>
          New Email received from
        </Typography>
        <Typography variant="body-small-bold" color={XelaColor.Orange3}>
          #{notification.data.lead_name}
        </Typography>
      </HStack>
      <HStack spacing="12px" height="100%" style={{ padding: '6px 12px' }}>
        <Box
          style={{
            width: '8px',
            height: '30px',
            backgroundColor: XelaColor.Blue3,
            borderRadius: '4px',
          }}
        ></Box>
        <HStack
          spacing="3px"
          style={{
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          <VStack>
            <Typography variant="body-small-bold">
              {notification.data.snippet.length > 50
                ? notification.data.snippet.substring(0, 50) + '...'
                : notification.data.snippet}
            </Typography>
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  )
}

export default EmailMessageReceivedNotification
