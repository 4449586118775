import React, { FC, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import XSelect from '@/Mantine/XSelect'
import useMeta from '@/Hooks/useMeta'
import { useLocalStorage } from '@mantine/hooks'
import useAuth from '@/Hooks/useAuth'
import { Modal } from '@mantine/core'
import { router } from '@inertiajs/react'
import XSelectWithRole from '@/Mantine/XSelectWithRole'
import { useDispatch } from 'react-redux'
import { updateIsLoginAsNewUser } from '@/Store/globalSlice'

const ConfirmModal: FC<{ show: boolean; setShow: (show: boolean) => void; setValue: (show: string) => void }> = ({
  show,
  setShow,
  setValue,
}) => {
  const auth = useAuth()
  const meta = useMeta()
  const dispatch = useDispatch()

  const [branchId, setBranchId] = useState<string>('')
  const [userId, setUserId] = useState<string>('')

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={500}
      opened={show}
      onClose={() => {
        setShow(false)
      }}
    >
      <VStack spacing={'12px'}>
        <HStack>
          <Typography variant="subheadline" color={XelaColor.Gray3}>
            Login as User
          </Typography>
        </HStack>
        <HStack>
          <Divider variant={'dotted'} color={XelaColor.Gray10}></Divider>
        </HStack>
        <VStack
          spacing="12px"
          style={{
            minHeight: '300px',
          }}
        >
          <XSelect
            searchable
            label="Select Branch"
            value={auth.branch.id.toString()}
            data={meta.branches.map((branch) => ({
              label: branch.branch_name,
              value: branch.id.toString(),
            }))}
            onChange={(value) => {
              if (value) {
                setBranchId(value)
              }
            }}
          ></XSelect>
          <XSelectWithRole
            searchable
            label="Select User"
            value={auth.user.id.toString()}
            data={
              branchId
                ? meta.branchesUsers[branchId].users.map((counsellor) => ({
                    label: counsellor.name,
                    value: counsellor.id.toString(),
                    group: counsellor.branch.branch_name,
                    role: counsellor.role.role_name,
                  }))
                : meta.branchesUsers[auth.branch.id.toString()].users.map((counsellor) => ({
                    label: counsellor.name,
                    value: counsellor.id.toString(),
                    group: counsellor.branch.branch_name,
                    role: counsellor.role.role_name,
                  }))
            }
            onChange={(value) => {
              if (value) {
                setUserId(value)
              }
            }}
          ></XSelectWithRole>
        </VStack>
        <HStack justifyContent={'flex-end'} style={{ paddingTop: '20px' }} spacing="12px">
          <Button
            label="Cancel"
            variant="secondary"
            onClick={() => {
              setShow(false)
            }}
          />
          <Button
            label={'Login as User'}
            variant="primary"
            onClick={() => {
              router.post(
                route('settings.login-as-user-team-member', { id: userId }),
                {},
                {
                  preserveScroll: false,
                  preserveState: false,
                  onSuccess: () => {
                    setValue('mCeGTvibPjpqrzwf_Hmh3J9QGe38k9sbwsJDb4h0DDw')
                  },
                  onFinish: () => {
                    setShow(false)
                    dispatch(updateIsLoginAsNewUser(true))
                  },
                }
              )
            }}
          />
        </HStack>
      </VStack>
    </Modal>
  )
}

const ChangeLoggedInUsers: FC = () => {
  const auth = useAuth()
  const [show, setShow] = useState(false)
  const [loggedInValue, setLoggedInValue] = useLocalStorage({
    key: 'CHgb9ZP1pyuUUIjm8rGmnM1TGEIfH1EKgab7Rae50UE',
    defaultValue: '6yjs7vZFPTxnKgP1B4wwvRAgs5MB_mOXNRo17a0xhXM',
  })

  if (loggedInValue === 'mCeGTvibPjpqrzwf_Hmh3J9QGe38k9sbwsJDb4h0DDw' || auth.user.is_system_user) {
    return (
      <>
        <div
          style={{
            cursor: 'pointer',
            padding: '1px 8px',
            position: 'absolute',
            top: 0,
            left: 'calc(50% - 62px)',
            height: '16px',
            background: XelaColor.Blue3,
            zIndex: 9999,
            color: 'white',
            fontSize: '8px',
            fontWeight: 'bold',
            borderRadius: '8px',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
          onClick={() => {
            setShow(true)
          }}
        >
          Login as User
        </div>
        <ConfirmModal show={show} setShow={setShow} setValue={setLoggedInValue}></ConfirmModal>
      </>
    )
  }

  return <></>
}

export default ChangeLoggedInUsers
