import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Admission = App.Models.Admission

export interface AdmissionState {
  is_no_admission: boolean
  cold_reason: string
  selected_admission_id: string | null
  is_drawer_opened: boolean
  admission: Admission | null
  is_admission_manager_changed: boolean
  admission_manager_id: string
  admission_manager_id_one: string
  admission_manager_id_two: string
  admission_manager_id_three: string
  admission_manager_id_four: string
  admission_manager_id_five: string
  admission_id: string
  branch_id: string | null
  selected_admission_ids: string[]
  selected_admissions: Admission[]
  reload_all_admission_leads: boolean
  quick_action_show_admission_manager_modal: boolean
  branchIds: string[]
  admissionManagerIds: string[]
  current_admission_leads_filters: {
    [key: string]: string
  }
  previous_admission_leads_filters: {
    [key: string]: string
  }
  show_all_admission_leads_filter_modal: boolean
}

const initialState: AdmissionState = {
  is_no_admission: false,
  cold_reason: '',
  selected_admission_id: null,
  is_drawer_opened: false,
  admission: null,
  is_admission_manager_changed: false,
  admission_manager_id: '',
  admission_manager_id_one: '',
  admission_manager_id_two: '',
  admission_manager_id_three: '',
  admission_manager_id_four: '',
  admission_manager_id_five: '',
  admission_id: '',
  branch_id: null,
  selected_admission_ids: [],
  selected_admissions: [],
  reload_all_admission_leads: false,
  quick_action_show_admission_manager_modal: false,
  branchIds: [],
  admissionManagerIds: [],
  current_admission_leads_filters: {
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
  previous_admission_leads_filters: {
    lead_form_id: '',
    lead_source: '',
    sub_lead_source: '',
    preferred_country: '',
    created_at: '',
    city: '',
    state: '',
    from_date: '',
    to_date: '',
  },
  show_all_admission_leads_filter_modal: false,
}

export const admissionSlice = createSlice({
  name: 'admissionSlice',
  initialState,
  reducers: {
    updateIsNoAdmission(state, action: PayloadAction<boolean>) {
      state.is_no_admission = action.payload
    },
    updateNoAdmissionReason(state, action: PayloadAction<string>) {
      state.cold_reason = action.payload
    },
    updateSelectedAdmissionId(state, action: PayloadAction<string>) {
      state.selected_admission_id = action.payload
    },
    updateIsDrawerOpened(state, action: PayloadAction<boolean>) {
      state.is_drawer_opened = action.payload
    },
    updateAdmission(state, action: PayloadAction<Admission | null>) {
      state.admission = action.payload
    },
    updateIsAdmissionManagerChanged(
      state,
      action: PayloadAction<{
        show: boolean
        admission_manager_id: string
        admission_manager_id_one: string
        admission_manager_id_two: string
        admission_manager_id_three: string
        admission_manager_id_four: string
        admission_manager_id_five: string
        admission_id: string
      }>
    ) {
      state.is_admission_manager_changed = action.payload.show
      state.admission_manager_id = action.payload.admission_manager_id
      state.admission_manager_id_one = action.payload.admission_manager_id_one
      state.admission_manager_id_two = action.payload.admission_manager_id_two
      state.admission_manager_id_three = action.payload.admission_manager_id_three
      state.admission_manager_id_four = action.payload.admission_manager_id_four
      state.admission_manager_id_five = action.payload.admission_manager_id_five
      state.admission_id = action.payload.admission_id
    },
    updateAdmissionBranchId(state, action: PayloadAction<string | null>) {
      state.branch_id = action.payload
    },
    updateSelectedAdmissionIds(state, action: PayloadAction<string[]>) {
      state.selected_admission_ids = action.payload
    },
    updateSelectedAdmissions(state, action: PayloadAction<Admission[]>) {
      state.selected_admissions = action.payload
    },
    updateQuickActionShowAdmissionManagerModal(
      state,
      action: PayloadAction<{ show: boolean; branch_id: string | null }>
    ) {
      state.quick_action_show_admission_manager_modal = action.payload.show
      state.branch_id = action.payload.branch_id
    },
    updateBranchIds(state, action: PayloadAction<string[]>) {
      state.branchIds = action.payload
    },
    updateAdmissionManagerIds(state, action: PayloadAction<string[]>) {
      state.admissionManagerIds = action.payload
    },
    reloadAllAdmissionLeads(state, action: PayloadAction<boolean>) {
      state.reload_all_admission_leads = action.payload
    },
    updateCurrentAdmissionLeadFilters(state, action: PayloadAction<{ [key: string]: string }>) {
      state.current_admission_leads_filters = action.payload
    },
    updatePreviousAdmissionLeadFilters(state, action: PayloadAction<{ [key: string]: string }>) {
      state.previous_admission_leads_filters = action.payload
    },
    showAllAdmissionLeadFilterModal(state) {
      state.show_all_admission_leads_filter_modal = true
    },
    hideAllAdmissionLeadFilterModal(state) {
      state.show_all_admission_leads_filter_modal = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  updateIsNoAdmission,
  updateNoAdmissionReason,
  updateSelectedAdmissionId,
  updateIsDrawerOpened,
  updateAdmission,
  updateIsAdmissionManagerChanged,
  updateAdmissionBranchId,
  updateSelectedAdmissionIds,
  updateSelectedAdmissions,
  updateQuickActionShowAdmissionManagerModal,
  updateBranchIds,
  updateAdmissionManagerIds,
  reloadAllAdmissionLeads,
  updateCurrentAdmissionLeadFilters,
  updatePreviousAdmissionLeadFilters,
  showAllAdmissionLeadFilterModal,
  hideAllAdmissionLeadFilterModal,
} = admissionSlice.actions

export default admissionSlice.reducer
