import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface DividerProps extends HTMLAttributes<HTMLHRElement> {
  variant?: 'dashed' | 'dotted' | 'solid'
  vertical?: boolean
  color?: string
  width?: string
  verticalHeight?: string
}

const DividerComponent = styled.hr<DividerProps>`
  margin: 0;
  border: none;
  background: none;
  display: block;

  ${(props) =>
    !props.vertical
      ? props.variant == 'dotted'
        ? css`
            background-image: linear-gradient(
              to right,
              ${props.color} 20%,
              rgba(255, 255, 255, 0) 0%
            );
            background-position: top;
            background-size: 8px 2px;
            background-repeat: repeat-x;
            width: ${props.width};
            height: 2px;
          `
        : props.variant == 'dashed'
        ? css`
            background-image: linear-gradient(
              to right,
              ${props.color} 60%,
              rgba(255, 255, 255, 0) 0%
            );
            background-position: top;
            background-size: 12px 6px;
            background-repeat: repeat-x;
            width: ${props.width};
            height: 1px;
          `
        : css`
            border-top: 1px solid ${props.color};
            width: ${props.width};
          `
      : props.variant == 'dotted'
      ? css`
          background-image: linear-gradient(
            to bottom,
            ${props.color} 20%,
            rgba(255, 255, 255, 0) 0%
          );
          background-position: left;
          background-size: 2px 8px;
          background-repeat: repeat-y;
          height: ${props.verticalHeight};
          width: 2px;
        `
      : props.variant == 'dashed'
      ? css`
          background-image: linear-gradient(
            to bottom,
            ${props.color} 60%,
            rgba(255, 255, 255, 0) 0%
          );
          background-position: left;
          background-size: 6px 12px;
          background-repeat: repeat-y;
          width: 1px;
          height: ${props.verticalHeight};
        `
      : css`
          border-left: 1px solid ${props.color};
          height: ${props.verticalHeight};
        `}
`

export const Divider: FC<DividerProps> = ({
  variant = 'solid',
  vertical = false,
  color = XelaColor.Gray11,
  verticalHeight = '200px',
  width = '100%',
  ...props
}) => {
  return (
    <DividerComponent
      variant={variant}
      vertical={vertical}
      color={color}
      width={width}
      verticalHeight={verticalHeight}
      {...props}
    />
  )
}

export default Divider
