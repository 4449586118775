import React, { useEffect, useState } from 'react'
import { Divider, HStack, Step, Steps, VStack, XelaColor } from '@/XelaReact'
import { Drawer } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import AddNewLeadFromB2BForm from '@/Layouts/B2B/Forms/AddNewLeadFromB2BForm'
import UploadBulkDocumentsB2BForm from '@/Layouts/B2B/Forms/UploadBulkDocumentsB2BForm'
import { updateShowB2BAddLeadModal } from '@/Store/B2B/b2bLeadSlice'

const B2BAddQuickLeadModal = () => {
  const dispatch = useDispatch()
  const b2bLeadStore = useSelector((state: RootState) => state.b2bLead)

  const [firstStepStatus, setFirstStepStatus] = useState<'current' | 'success'>('current')
  const [secondStepStatus, setSecondStepStatus] = useState<'default' | 'current' | 'success'>('default')

  useEffect(() => {
    if (b2bLeadStore.lead_form_submitted) {
      setFirstStepStatus('success')
      setSecondStepStatus('current')
    }
  }, [b2bLeadStore.lead_form_submitted])

  useEffect(() => {
    if (b2bLeadStore.lead_documents_uploaded) {
      setSecondStepStatus('success')
    }
  }, [b2bLeadStore.lead_documents_uploaded])

  useEffect(() => {
    if (b2bLeadStore.show_b2b_add_lead_modal) {
      setFirstStepStatus('current')
      setSecondStepStatus('default')
    }
  }, [b2bLeadStore.show_b2b_add_lead_modal])

  return (
    <Drawer
      position="left"
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      size={800}
      opened={b2bLeadStore.show_b2b_add_lead_modal}
      onClose={() => {
        dispatch(updateShowB2BAddLeadModal(false))
      }}
      styles={{
        body: {
          height: '100%',
        },
      }}
    >
      <VStack spacing="12px" height="100%">
        <HStack justifyContent="center">
          <Steps
            size={'medium'}
            titleCurrentColor={XelaColor.Gray3}
            captionCurrentColor={XelaColor.Gray7}
            indicatorBgSuccessColor={XelaColor.Green1}
            indicatorBgDefaultColor={XelaColor.Gray10}
            steps={[
              new Step(1, '', 'Add Lead Details', firstStepStatus),
              new Step(2, '', 'Upload Documents', secondStepStatus),
            ]}
            variant="horizontal"
          />
        </HStack>
        <HStack>
          <Divider />
        </HStack>
        {firstStepStatus === 'current' && <AddNewLeadFromB2BForm />}
        {secondStepStatus === 'current' && <UploadBulkDocumentsB2BForm />}
      </VStack>
    </Drawer>
  )
}

export default B2BAddQuickLeadModal
