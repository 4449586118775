import * as React from 'react'

function IconB2BHub(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.5 10.5a2 2 0 100 4 2 2 0 000-4zM2.5.5a2 2 0 100 4 2 2 0 000-4z" fill="#E6ECFF" />
      <path
        d="M8.5.5l-2 2m0 0l2 2m-2-2h3a3 3 0 013 3v5m-10-6a2 2 0 100-4 2 2 0 000 4zm0 0v5a3 3 0 003 3H8m-1.5 2l2-2-2-2m6 0a2 2 0 100 4 2 2 0 000-4z"
        stroke="#003CFF"
      />
    </svg>
  )
}

export default IconB2BHub
