import React, { forwardRef, ReactNode, useState } from 'react'
import {
  Button,
  createPolymorphicComponent,
  createStyles,
  MantineSize,
  Stack,
  Text,
  Textarea,
  TextareaProps,
} from '@mantine/core'
import { XelaColor } from '@/XelaReact'
import { omit } from 'lodash'

interface XTextareaProps extends TextareaProps {
  placeholder?: string
  label?: ReactNode
  size?: MantineSize
}

const useStyles = createStyles(
  (
    theme,
    params: {
      value?: string | number | readonly string[] | undefined
      placeholder?: string
      focused?: boolean
      label?: ReactNode
      size?: MantineSize
      icon?: ReactNode
      minRows?: number
      maxRows?: number
    }
  ) => ({
    root: {
      fontFamily: 'Nunito Sans',
      position: 'relative',
      width: '100%',
    },

    input: {
      fontSize: '13px',
      fontWeight: 700,
      minHeight: params.minRows ? params.minRows * 26 : 52,
      padding: params.label
        ? params.size === 'sm'
          ? '22px 22px 2px'
          : '26px 22px 6px'
        : params.size === 'sm'
        ? '2px 4px 2px'
        : '6px 12px 6px',
      outline: 'none',
      border: 'none',
      borderRadius: params.size === 'sm' ? '16px' : '18px',
      '&:hover, &:focus': {
        outline: 'none',
        border: 'none',
      },
      '&::placeholder': {
        color: XelaColor.Gray10,
        fontWeight: 400,
      },
    },

    label: {
      width: '100%',
      background: XelaColor.White,
      fontSize: params.focused || params.placeholder || params.value ? '10px' : '13px',
      lineHeight: '15px',
      fontWeight: 600,
      position: 'absolute',
      pointerEvents: 'none',
      paddingLeft: '23px',
      transition: 'all 300ms ease',
      paddingTop: params.label ? `${params.focused || params.placeholder || params.value ? '8px' : '12px'}` : '0px',
      zIndex: 1,
      color: XelaColor.Gray3,
      '&:hover, &:focus': {
        paddingLeft: '25px',
      },
    },
  })
)

// eslint-disable-next-line react/display-name
const _XTextarea = forwardRef<
  HTMLTextAreaElement,
  XTextareaProps & {
    hasBorder?: boolean
    buttonLabel?: string
    buttonOnClick?: () => void
  }
>(({ children, ...props }, ref) => {
  props.hasBorder = props.hasBorder ?? true
  const [focused, setFocused] = useState(false)

  const { classes } = useStyles({
    label: props.label,
    placeholder: props.placeholder,
    icon: props.icon,
    value: props.value,
    focused,
    minRows: props.minRows,
    maxRows: props.maxRows,
  })

  return (
    <Stack spacing={4} style={{ width: '100%' }}>
      <div
        style={{
          background: XelaColor.White,
          position: 'relative',
          width: '100%',
          border: 'none',
          height: props.buttonOnClick ? (props.minRows ? props.minRows * 48 : 144) : 'auto',
          borderRadius: '16px',
          margin: '1px',
          padding: '5px 10px 5px 5px',
          outline: focused
            ? props.hasBorder
              ? `1.5px solid ${XelaColor.Blue3}`
              : 'none'
            : props.hasBorder
            ? `1px solid ${XelaColor.Gray11}`
            : 'none',
        }}
      >
        <Textarea
          autoComplete={'off'}
          ref={ref}
          {...omit(props, ['error', 'hasBorder', 'buttonLabel', 'buttonOnClick'])}
          value={props.value}
          classNames={classes}
          onFocus={(event) => {
            if (!props.readOnly) {
              setFocused(true)
            }

            if (props.onFocus) {
              props.onFocus(event)
            }
          }}
          onBlur={(event) => {
            if (!props.readOnly) {
              setFocused(false)
            }

            if (props.onBlur) {
              props.onBlur(event)
            }
          }}
          onChange={(event) => {
            if (props.onChange) {
              props.onChange(event)
            }
          }}
        >
          {children}
        </Textarea>
        {props.buttonOnClick && (
          <Button
            variant="outline"
            color="blueX"
            size="xs"
            radius={12}
            style={{ position: 'absolute', right: 5, bottom: 5, zIndex: 2 }}
            onClick={() => {
              if (props.buttonOnClick) {
                props.buttonOnClick()
              }
            }}
          >
            {props.buttonLabel ? props.buttonLabel : 'Comment'}
          </Button>
        )}
      </div>
      {props.error && (
        <Text size={'xs'} c="red">
          {props.error}
        </Text>
      )}
    </Stack>
  )
})
export const XTextarea = createPolymorphicComponent<
  'textarea',
  XTextareaProps & {
    hasBorder?: boolean
    buttonLabel?: string
    buttonOnClick?: () => void
  }
>(_XTextarea)
