import React, { FC, HTMLAttributes, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components'
import Divider from '@/XelaReact/Divider/Divider'
import HStack from '@/XelaReact/HStack/HStack'
import SvgIcon from '@/XelaReact/SvgIcon/SvgIcon'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'
import { Box } from '@mantine/core'

export interface AccordionProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  title?: string
  caption?: string
  content: ReactNode
  controlsPosition?: 'left' | 'right'
  openIcon?: ReactNode
  closeIcon?: ReactNode
  closeBg?: string
  openBg?: string
  titleCloseColor?: string
  captionCloseColor?: string
  titleOpenColor?: string
  captionOpenColor?: string
  divider?: ReactNode
  isOpen?: boolean
  width?: string
  padding?: string
  icon?: ReactNode // Add icon prop
}

const AccordionComponent = styled(VStack)<{
  width: string
  padding: string
  open: boolean
}>`
  padding: ${(props) => props.padding};

  & .xela-accordion-content-container {
    width: ${(props) => props.width};
    ${(props) =>
      !props.open &&
      css`
        overflow: hidden;
        height: 0;
      `}
    opacity: ${(props) => (props.open ? '1' : '0')};

    transition: height ease 0.2s, opacity ease 0.25s;
  }

  & .xela-divider {
    margin-top: 12px;
  }

  & .xela-content {
    width: ${(props) => props.width};
    margin-top: 12px;
  }
`

export const Accordion: FC<AccordionProps> = ({
  title,
  caption,
  content,
  controlsPosition = 'right',
  openIcon,
  closeIcon,
  closeBg = XelaColor.Gray12,
  openBg = XelaColor.Gray12,
  titleCloseColor = XelaColor.Gray2,
  titleOpenColor = XelaColor.Blue3,
  captionOpenColor = XelaColor.Gray7,
  captionCloseColor = XelaColor.Gray7,
  divider,
  isOpen = false,
  width = 'auto',
  padding = '16px',
  icon,

  ...props
}) => {
  const [open, setOpen] = useState(isOpen)

  return (
    <AccordionComponent
      open={open}
      width={width}
      padding={padding}
      borderRadius="12px"
      bg={open ? openBg : closeBg}
      {...props}
    >
      <HStack
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpen(!open)
        }}
        spacing="12px"
      >
        {controlsPosition === 'left' &&
          (!open ? (
            openIcon ? (
              openIcon
            ) : (
              <SvgIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1984 11.1999V6.3999H12.7984V11.1999H17.5984V12.7999H12.7984V17.5999H11.1984V12.7999H6.39844V11.1999H11.1984Z"
                    fill="#1D1929"
                  />
                </svg>
              </SvgIcon>
            )
          ) : closeIcon ? (
            closeIcon
          ) : (
            <SvgIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.39844 12H17.5984" stroke="#1D1929" />
              </svg>
            </SvgIcon>
          ))}

        <HStack spacing={'12px'}>
          {icon && <Box className={'flex-center w-8 h-8 bg-blue-12 rounded-lg'}>{icon}</Box>}
          <VStack>
            {title && (
              <Typography color={open ? titleOpenColor : titleCloseColor} variant="body-bold">
                {title}
              </Typography>
            )}
            {caption && (
              <Typography variant="caption" color={open ? captionOpenColor : captionCloseColor}>
                {caption}
              </Typography>
            )}
          </VStack>
        </HStack>

        {controlsPosition === 'right' &&
          (!open ? (
            openIcon ? (
              openIcon
            ) : (
              <SvgIcon>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1984 11.1999V6.3999H12.7984V11.1999H17.5984V12.7999H12.7984V17.5999H11.1984V12.7999H6.39844V11.1999H11.1984Z"
                    fill="#1D1929"
                  />
                </svg>
              </SvgIcon>
            )
          ) : closeIcon ? (
            closeIcon
          ) : (
            <SvgIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.39844 12H17.5984" stroke="#1D1929" />
              </svg>
            </SvgIcon>
          ))}
      </HStack>
      <div className="xela-accordion-content-container">
        {divider !== false && <div className="xela-divider">{divider ? divider : <Divider variant="solid" />}</div>}
        <div className="xela-content" style={{ height: '100%' }}>
          {content}
        </div>
      </div>
    </AccordionComponent>
  )
}

export default Accordion
