import React, { FC, HTMLAttributes, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import HStack from '@/XelaReact/HStack/HStack'
import Typography from '@/XelaReact/Typography/Typography'
import VStack from '@/XelaReact/VStack/VStack'
import { XelaColor } from '@/XelaReact/XelaColor/XelaColor'

export interface ToastProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  avatar?: ReactNode
  title: string
  content?: ReactNode
  icon?: ReactNode
  close?: boolean
  bg?: string
  closeColor?: string
  firstAction?: ReactNode
  secondAction?: ReactNode
  titleColor?: string
  shadow?: boolean
  isShown: number
  hide: () => void
  position?:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'middle-left'
    | 'middle-center'
    | 'middle-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
  width?: string
  borderRadius?: string
  autoRemove?: boolean
  autoRemoveTime?: number
  toastMargin?: string
}

const ToastComponent = styled.div``

const Wrapper = styled.div<{ position: string; toastMargin: string }>`
  position: fixed;
  z-index: 700;
  width: inherit;
  outline: 0;

  ${(props) =>
    props.position == 'top-left' &&
    css`
      top: ${props.toastMargin};
      left: ${props.toastMargin};
    `}

  ${(props) =>
    props.position == 'top-center' &&
    css`
      top: ${props.toastMargin};
      left: 50%;
      transform: translate(-50%, 0);
    `}

  ${(props) =>
    props.position == 'top-right' &&
    css`
      top: ${props.toastMargin};
      right: ${props.toastMargin};
    `}

  ${(props) =>
    props.position == 'middle-left' &&
    css`
      top: 50%;
      left: ${props.toastMargin};
      transform: translate(0, -50%);
    `}

  ${(props) =>
    props.position == 'middle-center' &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  ${(props) =>
    props.position == 'middle-right' &&
    css`
      top: 50%;
      right: ${props.toastMargin};
      transform: translate(0, -50%);
    `}

  ${(props) =>
    props.position == 'bottom-left' &&
    css`
      bottom: ${props.toastMargin};
      left: ${props.toastMargin};
    `}

  ${(props) =>
    props.position == 'bottom-center' &&
    css`
      bottom: ${props.toastMargin};
      left: 50%;
      transform: translate(-50%, 0);
    `}

  ${(props) =>
    props.position == 'bottom-right' &&
    css`
      bottom: ${props.toastMargin};
      right: ${props.toastMargin};
    `}
`

const StyledModal = styled.div<{
  bg: string
  borderRadius: string
  width: string
  shadow: boolean
  isShown: number
}>`
  z-index: 100;
  background: ${(props) => props.bg};
  position: relative;
  margin: auto;
  border-radius: ${(props) => props.borderRadius};
  padding: 16px;
  width: ${(props) => props.width};
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0 12px 16px rgba(0, 0, 0, 0.08), 0 4px 56px rgba(0, 0, 0, 0.08);
    `}

  opacity: ${(props) => (props.isShown == 1 ? 1 : 0)};

  transition: opacity 0.2s ease-in-out;

  ${(props) =>
    props.isShown == 1 &&
    css`
      @keyframes append-animate {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      animation: append-animate 0.2s linear;
    `}
  & .xela-toast-icon {
    align-self: stretch;
  }

  & .xela-toast-actions-container {
    margin-top: 8px;
  }
`

const CloseButton = styled.div<{ closeColor: string }>`
  cursor: pointer;
  stroke: ${(props) => props.closeColor};

  &:hover {
    opacity: 0.6;
  }
`

export const Toast: FC<ToastProps> = ({
  shadow = true,
  isShown,
  hide,
  position = 'top-center',
  width = '360px',
  avatar,
  icon,
  title,
  content,
  close = false,
  bg = XelaColor.White,
  closeColor = XelaColor.Gray2,
  firstAction,
  secondAction,
  titleColor = XelaColor.Gray2,
  borderRadius = '18px',
  autoRemove = true,
  autoRemoveTime = 2000,
  toastMargin = '16px',
}) => {
  if (autoRemove) {
    const timer = setTimeout(() => {
      hide()
    }, autoRemoveTime)
    if (isShown == 0) clearTimeout(timer)
  }

  const toast = (
    <ToastComponent>
      <Wrapper position={position} toastMargin={toastMargin}>
        <StyledModal isShown={isShown} width={width} bg={bg} borderRadius={borderRadius} shadow={shadow}>
          <HStack spacing="12px">
            {icon && <div className="xela-toast-icon">{icon}</div>}
            {avatar && avatar}
            <VStack>
              <Typography color={titleColor} variant="body-bold">
                {title}
              </Typography>
              {content && content}
              {firstAction !== undefined && secondAction !== undefined && (
                <HStack className="xela-toast-actions-container" spacing="18px">
                  {firstAction}
                  {secondAction}
                </HStack>
              )}
            </VStack>

            {firstAction !== undefined && secondAction === undefined && firstAction}
            {close && (
              <CloseButton closeColor={closeColor} onClick={hide}>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 2L13.5 14M1.5 14L13.5 2" />
                </svg>
              </CloseButton>
            )}
          </HStack>
        </StyledModal>
      </Wrapper>
    </ToastComponent>
  )

  return isShown == 0 ? null : createPortal(toast, document.body)
}

export default Toast
