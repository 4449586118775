import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { useForm } from '@inertiajs/react'
import { updateShowUploadDocumentModal } from '@/Store/leadSlice'
import FileUpload from '@/XelaReact/FileUpload/FileUpload'
import { IconX } from '@tabler/icons-react'
import XSelect from '@/Mantine/XSelect'

const UploadBulkDocumentsModal: FC<{
  portal?: string
}> = () => {
  const dispatch = useDispatch()
  const leadStore = useSelector((state: RootState) => state.lead)
  const [loading, setLoading] = useState(false)

  const { data, setData, post, errors, reset, recentlySuccessful } = useForm<{
    lead_id: string | null
    belongs_to: string
    documents: File[]
  }>({
    lead_id: null,
    belongs_to: 'Applicant',
    documents: [],
  })

  useEffect(() => {
    if (recentlySuccessful) {
      dispatch(
        updateShowUploadDocumentModal({
          show: false,
          document_entity: '',
          document_entity_id: null,
          lead_id: null,
          belongs_to: '',
        })
      )
      reset()
    }
  }, [recentlySuccessful])

  useEffect(() => {
    if (leadStore.lead_id !== null) {
      setData((prev) => {
        const datum: { [key: string]: string | null } = {}
        datum['lead_id'] = leadStore.lead_id

        return {
          ...prev,
          ...datum,
        }
      })
    }
  }, [leadStore.lead_id])

  return (
    <Modal
      centered
      overlayProps={{
        color: '#000',
        opacity: 0.55,
        blur: 3,
      }}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      trapFocus={false}
      radius={16}
      size={700}
      opened={leadStore.show_upload_document_modal}
      onClose={() => {
        dispatch(
          updateShowUploadDocumentModal({
            show: false,
            document_entity: '',
            document_entity_id: null,
            lead_id: null,
            belongs_to: '',
          })
        )
        reset()
      }}
    >
      <HStack justifyContent="space-between">
        <HStack>
          <Typography variant={'subheadline'}>Upload Bulk Documents</Typography>
        </HStack>
        <HStack width="50%" justifyContent="flex-end">
          <XSelect
            error={errors.belongs_to}
            data={['Applicant', 'Spouse', 'Kid 1', 'Kid 2', 'Kid 3', 'Kid 4', 'Kid 5']}
            value={data.belongs_to}
            onChange={(value) => {
              if (value) {
                setData((prev) => {
                  const datum: { [key: string]: string } = {}
                  datum['belongs_to'] = value

                  return {
                    ...prev,
                    ...datum,
                  }
                })
              }
            }}
          />
        </HStack>
      </HStack>
      <div style={{ padding: '10px', position: 'relative', width: '100%' }}>
        <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ color: 'blueX' }} />
        <VStack spacing="12px">
          <HStack>
            <Divider variant={'dotted'}></Divider>
          </HStack>
          <HStack style={{ padding: '0 5px' }}>
            <FileUpload
              height="200px"
              error={Object.values(errors).length > 0}
              helperText={Object.values(errors).join(', <br/>')}
              typeUploadText="PDF,JPG,PNG,WORD,XLSX (max. 100 MB)"
              options={{
                onDrop: (acceptedFiles: File[]) => {
                  if (acceptedFiles.length > 0) {
                    setData((prev) => {
                      const datum: { [key: string]: File[] } = {}
                      datum['documents'] = [...prev['documents'], ...acceptedFiles]

                      return {
                        ...prev,
                        ...datum,
                      }
                    })
                  }
                },
              }}
              value={null}
            ></FileUpload>
          </HStack>
          {data.documents.length > 0 && (
            <>
              <HStack>
                <Divider variant={'dotted'}></Divider>
              </HStack>
              <VStack
                spacing="4px"
                style={{
                  height: '150px',
                  overflowY: 'auto',
                  padding: '4px',
                }}
              >
                {data.documents.map((file, index) => {
                  return (
                    <HStack key={index} justifyContent="space-between">
                      <Typography variant={'body-small-bold'} style={{ width: '400px', wordBreak: 'break-all' }}>
                        {index + 1}. {file.name}
                      </Typography>
                      <Box
                        style={{
                          padding: '4px',
                          cursor: 'pointer',
                        }}
                      >
                        <IconX
                          size={16}
                          color={XelaColor.Red6}
                          onClick={() => {
                            setData((prev) => {
                              const datum: { [key: string]: File[] } = {}
                              datum['documents'] = prev.documents.filter((item, i) => i !== index)

                              return {
                                ...prev,
                                ...datum,
                              }
                            })
                          }}
                        />
                      </Box>
                    </HStack>
                  )
                })}
              </VStack>
            </>
          )}
          <HStack>
            <Divider variant={'dotted'}></Divider>
          </HStack>
          <HStack spacing={'12px'} justifyContent="flex-end">
            <Button
              label={'Cancel'}
              variant={'secondary'}
              onClick={() => {
                dispatch(
                  updateShowUploadDocumentModal({
                    show: false,
                    document_entity: '',
                    document_entity_id: null,
                    lead_id: null,
                    belongs_to: '',
                  })
                )
                reset()
              }}
            ></Button>
            <Button
              label={'Import'}
              onClick={() => {
                setLoading(true)
                post('/documents/bulk-upload', {
                  preserveState: true,
                  preserveScroll: true,
                  onFinish: () => {
                    setLoading(false)
                  },
                })
              }}
            ></Button>
          </HStack>
        </VStack>
      </div>
    </Modal>
  )
}

export default UploadBulkDocumentsModal
