const DOCUMENT_TYPES = [
  { value: 'Passport', label: 'Passport', group: 'Personal' },
  {
    value: 'Passport Size Picture',
    label: 'Passport Size Picture',
    group: 'Personal',
  },
  {
    value: 'Passport Admission',
    label: 'Passport Admission',
    group: 'Personal',
  },
  { value: 'PAN Card', label: 'PAN Card', group: 'Personal' },
  { value: 'Aadhaar Card', label: 'Aadhaar Card', group: 'Personal' },
  { value: '10th Marksheet', label: '10th Marksheet', group: 'Academics' },
  {
    value: '10th Pass Certificate',
    label: '10th Pass Certificate',
    group: 'Academics',
  },
  { value: '12th Marksheet', label: '12th Marksheet', group: 'Academics' },
  {
    value: '12th Pass Certificate',
    label: '12th Pass Certificate',
    group: 'Academics',
  },
  {
    value: 'UG Diploma Marksheet',
    label: 'UG Diploma Marksheet',
    group: 'Academics',
  },
  {
    value: 'UG Diploma Certificate',
    label: 'UG Diploma Certificate',
    group: 'Academics',
  },
  {
    value: 'UG Diploma Backlog Certificate',
    label: 'UG Diploma Backlog Certificate',
    group: 'Academics',
  },
  {
    value: 'Bachelor Marksheet',
    label: 'Bachelor Marksheet',
    group: 'Academics',
  },
  { value: 'Bachelor Degree', label: 'Bachelor Degree', group: 'Academics' },
  { value: 'Transcripts', label: 'Transcripts', group: 'Academics' },
  {
    value: 'Bachelor Backlog Certificate',
    label: 'Bachelor Backlog Certificate',
    group: 'Academics',
  },
  {
    value: 'Masters Marksheet',
    label: 'Masters Marksheet',
    group: 'Academics',
  },
  { value: 'Masters Degree', label: 'Masters Degree', group: 'Academics' },
  {
    value: 'Masters Backlog Certificate',
    label: 'Masters Backlog Certificate',
    group: 'Academics',
  },
  {
    value: 'PG Diploma Marksheet',
    label: 'PG Diploma Marksheet',
    group: 'Academics',
  },
  {
    value: 'PG Diploma Certificate',
    label: 'PG Diploma Certificate',
    group: 'Academics',
  },
  {
    value: 'Experience Letter/Employment Certificate',
    label: 'Experience Letter/Employment Certificate',
    group: 'Work Experience',
  },
  {
    value: 'Salary Slips/Experience Pay Slip',
    label: 'Salary Slips/Experience Pay Slip',
    group: 'Work Experience',
  },
  {
    value: 'Employer Recommendation Letter',
    label: 'Employer Recommendation Letter',
    group: 'Work Experience',
  },
  { value: 'Resume/CV', label: 'Resume/CV', group: 'Supporting Documents' },
  {
    value: 'Statement of Purpose/Motivation Letter',
    label: 'Statement of Purpose/Motivation Letter',
    group: 'Supporting Documents',
  },
  {
    value: 'Letter of Recommendation',
    label: 'Letter of Recommendation',
    group: 'Supporting Documents',
  },
  {
    value: 'IELTS/PTE/TOEFL Result',
    label: 'IELTS/PTE/TOEFL Result',
    group: 'Supporting Documents',
  },
  {
    value: 'SAT/GRE/GMAT ResultCard',
    label: 'SAT/GRE/GMAT ResultCard',
    group: 'Supporting Documents',
  },
  {
    value: 'GIC Certificate',
    label: 'GIC Certificate',
    group: 'Admission Document',
  },
  {
    value: 'College Admission Form',
    label: 'College Admission Form',
    group: 'Admission Document',
  },
  {
    value: 'Admission Fee Receipt',
    label: 'Admission Fee Receipt',
    group: 'Admission Document',
  },
  {
    value: 'Conditional Acceptance Letter',
    label: 'Conditional Acceptance Letter',
    group: 'Admission Document',
  },
  {
    value: 'Unconditional Offer',
    label: 'Unconditional Offer',
    group: 'Admission Document',
  },
  {
    value: 'Offer Letter',
    label: 'Offer Letter',
    group: 'Admission Document',
  },
  {
    value: 'Final Acceptance Letter',
    label: 'Final Acceptance Letter',
    group: 'Admission Document',
  },
  { value: 'CoE', label: 'CoE', group: 'Admission Document' },
  {
    value: 'Tuition Fee Payment Proof (TT)',
    label: 'Tuition Fee Payment Proof (TT)',
    group: 'Admission Document',
  },
  {
    value: 'Fee Payment Receipt from University',
    label: 'Fee Payment Receipt from University',
    group: 'Admission Document',
  },
  {
    value: 'Acceptance Letter',
    label: 'Acceptance Letter',
    group: 'Admission Document',
  },
  {
    value: 'Conditional Letter',
    label: 'Conditional Letter',
    group: 'Admission Document',
  },
  {
    value: 'Admission Letter',
    label: 'Admission Letter',
    group: 'Admission Document',
  },
  {
    value: 'I20',
    label: 'I20',
    group: 'Admission Document',
  },
  {
    value: 'Visa Application Form',
    label: 'Visa Application Form',
    group: 'Visa Document',
  },
  {
    value: 'PH4',
    label: 'PH4',
    group: 'Family Documents',
  },
  {
    value: 'PK1_PLUS',
    label: 'PK1_PLUS',
    group: 'Family Documents',
  },
  {
    value: 'Marriage Certificate',
    label: 'Marriage Certificate',
    group: 'Family Documents',
  },
  {
    value: 'Birth Certificate',
    label: 'Birth Certificate',
    group: 'Family Documents',
  },
  {
    value: 'Clarification of income/Origin of Funds',
    label: 'Clarification of income/Origin of Funds',
    group: 'Financials',
  },
  {
    value: 'Bank Statement',
    label: 'Bank Statement',
    group: 'Financials',
  },
  {
    value: 'Bank Verification Letter',
    label: 'Bank Verification Letter',
    group: 'Financials',
  },
  {
    value: 'Balance Certificate',
    label: 'Balance Certificate',
    group: 'Financials',
  },
  {
    value: 'Property Sale deed',
    label: 'Property Sale deed',
    group: 'Sponsor Financials',
  },
  {
    value: 'Retirement Certificate',
    label: 'Retirement Certificate',
    group: 'Sponsor Financials',
  },
  {
    value: 'Financial Documents',
    label: 'Financial Documents',
    group: 'Visa Document',
  },
  { value: 'Cover Letter', label: 'Cover Letter', group: 'Visa Document' },
  { value: 'Sponsor Letter', label: 'Sponsor Letter', group: 'Visa Document' },
  { value: 'Insurance', label: 'Insurance', group: 'Visa Document' },
  {
    value: 'Insurance Payment Document',
    label: 'Insurance Payment Document',
    group: 'Visa Document',
  },
  {
    value: 'Visa Appointment Letter',
    label: 'Visa Appointment Letter',
    group: 'Visa Document',
  },
  {
    value: 'VFS Fee Receipt',
    label: 'VFS Fee Receipt',
    group: 'Visa Document',
  },
  {
    value: 'Visa Decision Document',
    label: 'Visa Decision Document',
    group: 'Visa Document',
  },
  {
    value: 'Visa Refusal Letter',
    label: 'Visa Refusal Letter',
    group: 'Visa Document',
  },
  {
    value: 'CAS Letter',
    label: 'CAS Letter',
    group: 'Visa Document',
  },

  {
    value: 'Personal Form',
    label: 'Personal Form',
    group: 'Visa Document',
  },
  {
    value: 'Family Form',
    label: 'Family Form',
    group: 'Visa Document',
  },
  {
    value: 'General Form',
    label: 'General Form',
    group: 'Visa Document',
  },
  {
    value: 'Embassy Fee Receipt',
    label: 'Embassy Fee Receipt',
    group: 'Visa Document',
  },
  {
    value: 'Medical Certificate',
    label: 'Medical Certificate',
    group: 'Visa Document',
  },
  {
    value: 'Submission Letter',
    label: 'Submission Letter',
    group: 'Visa Document',
  },
  {
    value: 'Email',
    label: 'Email',
    group: 'Visa Document',
  },
  {
    value: 'Accommodation Proof',
    label: 'Accommodation Proof',
    group: 'Visa Document',
  },
  {
    value: 'Confirmation of Enrolment',
    label: 'Confirmation of Enrolment',
    group: 'Internal Payments',
  },
  {
    value: 'Pre-Visa Fee Payment',
    label: 'Pre-Visa Fee Payment',
    group: 'Internal Payments',
  },
  {
    value: 'Post-Visa Fee Payment',
    label: 'Post-Visa Fee Payment',
    group: 'Internal Payments',
  },
  {
    value: 'Insurance Transaction Document',
    label: 'Insurance Transaction Document',
    group: 'Insurance Document',
  },
  {
    value: 'Insurance Policy Document',
    label: 'Insurance Policy Document',
    group: 'Insurance Document',
  },
  {
    value: 'Insurance Claim Document',
    label: 'Insurance Claim Document',
    group: 'Insurance Document',
  },
  {
    value: 'Insurance Renewal Document',
    label: 'Insurance Renewal Document',
    group: 'Insurance Document',
  },
  {
    value: 'Insurance Cancellation Document',
    label: 'Insurance Cancellation Document',
    group: 'Insurance Document',
  },
  { value: 'MOI Letter', label: 'MOI Letter', group: 'Academics' },
  { value: 'Others', label: 'Others', group: 'Others' },
]
export default DOCUMENT_TYPES
